<div>
    <mat-form-field appearance="outline" style="margin-top: 1em;">
        <mat-label>Укажите период</mat-label>
        <mat-date-range-input matInput [rangePicker]="picker">
            <input matStartDate placeholder="Начало" name="actualDate1" [(ngModel)]="dateStart">
            <input matEndDate placeholder="Конец" name="actualDate2" [(ngModel)]="dateEnd">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker class="B"></mat-date-range-picker>
    </mat-form-field>

    <button mat-icon-button *ngIf="dateStart || dateEnd" (click)="clearData();"
        class="icon_middle"><mat-icon>close</mat-icon></button>

    <button style="margin-left: 8px;" mat-raised-button color="primary"
        (click)="findReports()"><mat-icon>search</mat-icon>Поиск</button>
    <!-- <button   class="icon_middle" mat-icon-button color="primary" (click)="findReports()"><mat-icon>search</mat-icon></button> -->
</div>

<app-zreport-add *ngIf="addMode === 0 || addMode === 1" (addZComplete)="addedReport($event)"
    (addModeOut)="addMode=$event" [type]=1 [ukrop]="ukrop"></app-zreport-add>
<app-zreport-add *ngIf="addMode === 0 || addMode === 2" (addZComplete)="addedReport($event)"
    (addModeOut)="addMode=$event" [type]=2 [ukrop]="ukrop" [softCheques]="softCheques"></app-zreport-add>

<mat-spinner *ngIf="loading" style="margin: 0 auto;"></mat-spinner>

<div *ngIf="!reports?.length && !loading">Не найдено</div>

<div class="zcontainer" *ngIf="!loading">
    <mat-card class="example-card" *ngFor="let report of reports"
        [style.background-color]="(report.ReportStatusId === 3 ? 'red' : (report.ReportStatusId === 2 ? '#aaffaa' : 'none'))">

        <mat-card-header>
            <mat-card-title>
                {{typeName(report.ReportTypeId)}} - {{statusName(report.ReportStatusId)}}
            </mat-card-title>
            <mat-card-subtitle>{{report.ReportDate | date:'dd.MM.yyyy' }}</mat-card-subtitle>
        </mat-card-header>

        <img mat-card-image class="zreport" [src]="imageUrl(report) | secureU | async" [alt]="report.FileDate" />

        <mat-card-content>

            <p>
                <em>отправлено {{report.FileDate | date:'dd.MM.yyyy HH:mm:ss' }} </em>
                <em *ngIf="report.ReportStatusId > 1">обработано</em>
            </p>
        </mat-card-content>

        <mat-card-actions>
            <button mat-button (click)="removeReport(report)" [disabled]="report.ReportStatusId === 2">
                <mat-icon>delete</mat-icon>Удалить
            </button>
        </mat-card-actions>

    </mat-card>
</div>