import { RouterModule, Routes } from '@angular/router';

import { AdvanceListComponent } from './pages/advance-list/advance-list.component';
import { BotAuthComponent } from './pages/bot-auth/bot-auth.component';
import { CatalogPageComponent } from './pages/catalog/catalog-page/catalog-page.component'
import { ClientInfoComponent } from './pages/client/client-info/client-info.component';
import { ClientsListComponent } from './pages/client/clients-list/clients-list.component';
import { CreateOrderComponent } from './pages/orders/edit/create-order/create-order.component';
import { DatasheetComponent } from './pages/datasheet/datasheet.component';
import { EditOrderComponent } from './pages/orders/edit/edit-order/edit-order.component';
import { HomePageComponent } from './pages/home/home-page/home-page.component'
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './pages/notifications/notifications/notifications.component';
import { OrderInfoComponent } from './pages/orders/info/order-info/order-info.component';
import { OrderListComponent } from './pages/orders/info/order-list/order-list.component';
import { OrsInfoComponent } from './pages/ors/ors-info/ors-info.component';
import { QuestionareOrsComponent } from './pages/quests/questionare-ors/questionare-ors.component';
import { QuestsListComponent } from './pages/quests/quests-list/quests-list.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { SynchronizationPageComponent } from './pages/synchronization/synchronization-page/synchronization-page.component';
import { canActivateAdmin, canActivateLoggedIn } from './services/storage/auth.guard';
import { AdminPageComponent } from './pages/admin/admin-page/admin-page.component';
import { AgentDeviceComponent } from './pages/admin/agent-device/agent-device.component';
import { AgentConnPageComponent } from './pages/admin/agent-conn-page/agent-conn-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'quests',
    component: QuestsListComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'quests/:id',
    component: QuestsListComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'quest/:id',
    component: QuestionareOrsComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'create-order/:id',
    component: CreateOrderComponent,
    canActivate: [canActivateLoggedIn]
  },
  {

    path: 'edit-order/:id',
    component: EditOrderComponent,
    canActivate: [canActivateLoggedIn]

  },
  {
    path: 'orders',
    component: OrderListComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'orderinfo/:id',
    component: OrderInfoComponent,
    canActivate: [canActivateLoggedIn],


  },
  {
    path: 'orders/:dt1/:dt2',
    component: OrderListComponent,
    canActivate: [canActivateLoggedIn]
  },


  {
    path: 'clients',
    component: ClientsListComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'settings',
    component: SettingsPageComponent,
  },
  {
    path: 'sync',
    component: SynchronizationPageComponent,
    canActivate: [canActivateLoggedIn]
  },

  {
    path: 'catalog',
    component: CatalogPageComponent,
    canActivate: [canActivateLoggedIn],
  },
  {
    path: 'client/:id',
    component: ClientInfoComponent,
    canActivate: [canActivateLoggedIn]
  },
  {
    path: 'ors/:id',
    component: OrsInfoComponent,
    canActivate: [canActivateLoggedIn]
  },

  {
    path: 'advances',
    component: AdvanceListComponent,
    canActivate: [canActivateLoggedIn]
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [canActivateAdmin],
  },
  {
    path: 'admin/agent/:id',
    component: AgentDeviceComponent,
    canActivate: [canActivateAdmin],
  },
  
  {
    path: 'admin/conn/:id',
    component: AgentConnPageComponent,
    canActivate: [canActivateAdmin],
  },
  
  {
    path: 'bot-auth/:token',
    component: BotAuthComponent
  },

  {
    path: 'notifications',
    component: NotificationsComponent
  },

  {
    path: 'datasheet',
    component: DatasheetComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
