<!-- form #userForm="ngForm" (ngSubmit)="onSend(userForm)" -->
<div mat-dialog-content>
  <p>{{good.name}}

    <button style="float: right;" mat-icon-button (click)="manual()"
      [style]="!manualEdit ? 'display: inline; border:0':'display: none;'">
      <mat-icon>edit</mat-icon>
    </button>
  </p>
  <small style="color: gray;">[{{good.codeExch}}] {{good.country}}/{{good.contract}}</small>
  <p *ngIf="good.stateId" [ngStyle]="{'background-color':good.stateColor}">

  <small>{{good.stateName}}</small>


  </p>

  <p>
    Цена:
    <span class="currency">{{price | currency :'RUB':'symbol':'1.2-2':'ru'}}
    </span>
  </p>


  <p>
    <span> <!--*ngIf="quantity && quantity>0"-->
      Сумма:
      <span class="currency">
        {{quantity * price | currency :'RUB':'symbol':'1.2-2':'ru'}}
      </span>
    </span>

  </p>

  <!-- (dblclick)="disabled=!disabled" -->
  <!-- <mat-form-field class="csto" appearance="fill">
    <mat-label>Кол-во</mat-label>
    <input matInput type="number" name="quantity" [(ngModel)]="quantity" [disabled]="!manualEdit">
  </mat-form-field>
  <button mat-icon-button (click)="manual()" [style]="!manualEdit ? 'display: inline;':'display: none;'">
    <mat-icon>edit</mat-icon>
  </button> -->

  <div style="text-align: center;">

    <!-- <button class="icon_middle but_minus" mat-icon-button (click)="add(-1)"><mat-icon
        class="icon_mid">remove</mat-icon></button> -->
    <mat-button-toggle checked="false" class="but_add minus" mat-icon-button (click)="add(-1)"><mat-icon
        class="icon_mid">remove</mat-icon></mat-button-toggle>

    <mat-form-field class="quantity_input" style="line-height: 23.6px;" appearance="outline">
      <mat-label>Кол-во</mat-label>
      <input matInput #quantityInput type="number" name="quantity" (keyup)="priceChange($event)"
        [value]="quantity ? quantity : ''" (keyup.enter)="onSend()">
      <!-- cdkFocusInitial -->
    </mat-form-field>

    <mat-button-toggle checked="false" class="but_add plus" (click)="add(+1)"><mat-icon
        class="icon_mid">add</mat-icon></mat-button-toggle>
    <!-- <button class="icon_middle but_plus" mat-icon-button (click)="add(+1)"><mat-icon
        class="icon_mid">add</mat-icon></button> -->

  </div>

  <!-- <button class="icon_middle but_plus" mat-icon-button (click)="manual()"
    [style]="!manualEdit ? 'display: inline; border:0':'display: none;'">
    <mat-icon class="icon_mid">edit</mat-icon>
  </button> -->
  <!-- <br> -->

  <div [style]="manualEdit ? 'display: block;':'display: none;'">
    <mat-form-field class="sto" appearance="outline">
      <mat-label>Код акции</mat-label>
      <input matInput type=" text" [(ngModel)]="promotionCode">
    </mat-form-field>
  </div>




  <div> <!--  *ngIf="good.ratioPackage>1" -->

    <div style="text-align: center; height: 36px;">
      <span class="sto" *ngIf="well(); else badBranch" class='goodRatio'>{{goodRatioLabel()}}</span>

      <ng-template #badBranch>
        <span>
          <button class="sto" color="warn" mat-flat-button (click)="addBox(true)">{{badRatioLabel()}} </button>
        </span>
      </ng-template>

    </div>

    <div *ngIf="good.minPart > 1 || good.ratioPackage > 1">
      <!--  -->
      <span class="csto" style="display: inline-block;">

        <!-- <span class="air-span ">
        <button class="air-button110-l sto" mat-flat-button color="warn" (click)="add(-1)" [disabled]="!quantity">
          -1</button>
      </span> -->

        <span class="air-span " *ngIf="good.minPart  && good.minPart > 1 && good.minPart !== good.ratioPackage">
          <button class="air-button110-l sto" mat-flat-button color="warn" (click)="add(-good.minPart)"
            [disabled]="!quantity || quantity<good.minPart">
            -{{good.minPart}}</button>
        </span>

        <span class="air-span " *ngIf="good.ratioPackage>1">
          <button class="air-button110-l sto" mat-flat-button color="warn" (click)="removeBox()"
            [disabled]="!quantity || quantity<good.ratioPackage">
            -{{good.ratioPackage}}</button>
        </span>


      </span>


      <span class="csto" style="display: inline-block;">

        <!-- <span class="air-span ">
        <button class="air-button110-r sto" mat-flat-button color="primary" (click)="add(1)" cdkFocusInitial>
          +1</button>
      </span> -->

        <span class="air-span " *ngIf="good.minPart  && good.minPart>1 && good.minPart !== good.ratioPackage">
          <button class="air-button110-r sto" mat-flat-button color="primary" (click)="add(good.minPart)">
            +{{good.minPart}}</button>
        </span>

        <span class="air-span " *ngIf="good.ratioPackage>1">
          <button class="air-button110-r sto" mat-flat-button color="primary" (click)="addBox()">
            +{{good.ratioPackage}}</button>
        </span>


      </span>

    </div>

  </div>

  <div style="height: 36px;">
    <span *ngIf="quantity && quantity>0 && quantity<good.minPart">
      <button class="sto" mat-button color="accent" (click)="add(good.minPart-quantity)">
        минимальная партия:
        {{good.minPart}} </button>
    </span>
  </div>

  <!-- pre>
    {{good | json}}  
    </pre -->
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="accent" class="air-button110-r" [mat-dialog-close]="">Отмена</button>
  <button mat-flat-button color="primary" class="air-button110-r" (click)="onSend()"
    [disabled]="(!quantity || quantity  < good.minPart )">Добавить</button>
</div>

<!-- /form -->