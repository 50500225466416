<p>notifications works!</p>

<button class="button button-primary" (click)="subscribeToNotifications()">
    Subscribe
</button>

<button class="button button-primary" (click)="notifyMe()">
  notifyMe
</button>

