import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { ErpPrice } from 'src/app/models/goods/product';
import { OrderModel } from 'src/app/models/order.model';
import { CurrentOrderService } from 'src/app/services/data/current-order.service';
import { DbService } from 'src/app/services/data/idb.service';
import { OrdersService } from 'src/app/services/data/orders.service';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { ErpService } from 'src/app/services/net/erp.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-order-edit-debug',
  templateUrl: './order-edit-debug.component.html',
  styleUrls: ['./order-edit-debug.component.scss']
})
export class OrderEditDebugComponent implements OnInit, OnDestroy {
  orderInfo: OrderModel | undefined
  buypoint: Client | undefined;
  prices: ErpPrice | undefined;
  subs: Subscription[] = []

  constructor(public currentOrder: CurrentOrderService) {


  }


  ngOnInit(): void {

    const s1 = this.currentOrder.buypoint$.subscribe({
      next: (b) => {
        this.buypoint = b
      }
    })

    const s2 = this.currentOrder.orderInfo$.subscribe({
      next: (o) => {
        this.orderInfo = o
      }
    })

    const s3 = this.currentOrder.prices$.subscribe({
      next: (p) => {
        this.prices = p
      }
    })

    this.subs = [s1, s2, s3]

  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe())
    this.subs = []
  }

}
