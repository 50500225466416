import { Component } from '@angular/core';


//  ManukyanRS@Dalimo.ru!SimakovAA@Dalimo.ru

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
}
