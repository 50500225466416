import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/models/client.model';
import { OnlineBlock, OnlineCategory, OnlineMatrix } from 'src/app/models/goods/matrix-online';
import { UkropRest } from 'src/app/models/Ukrop/Rest';

import { RpaClientService } from 'src/app/services/net/rpa-client.service';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';

@Component({
  selector: 'app-ors-rests',
  templateUrl: './ors-rests.component.html',
  styleUrls: ['./ors-rests.component.scss']
})
export class OrsRestsComponent implements OnInit {

  @Input() client: Client | undefined

  rests: UkropRest[] = []
  displayedColumns: string[] = ['p_name', 'p_quantity', 'pos/neg']

  date = new Date() // дата запроса
  xdate: string | Date | undefined // дата остатков
  loading = false


  hasRemains = false
  hasError = false
  errorMessage = ''
  total = 0
  shop = 0
  shopMinus = 0

  matrix: OnlineMatrix | undefined;
  matrixCounter = 0
  matrixCounter0 = 0




  constructor(private http: UkropClientService,
    private datePipe: DatePipe,
    private snack: MatSnackBar,
    private api: RpaClientService,
  ) {

  }

  ngOnInit(): void {
    this.setId('')
  }

  setMatrix(m: OnlineMatrix | undefined) {
    this.matrix = m




    // this.inverted = []

    // this.matrix?.goods.forEach(g => {
    //   if (g.alccodes && g.alccodes.length > 0) {
    //     g.alccodes.forEach(a => {
    //       this.inverted.push({
    //         code: a,
    //         good: g,

    //       })
    //     })
    //   }

    // })

  }




  setId(dt: string): void {


    if (dt !== null && dt !== undefined && dt !== '') {
      const dat = new Date(Date.parse(dt))
      this.date = dat
    } else {
      this.date = new Date()
    }

    this.Update()
  }


  Update() {
    if (!this.client) {
      return
    }

    this.api.getMatrixOnline(this.client?.id, this.date).subscribe({
      next: (matrix) => {
        this.setMatrix(matrix)
        this.UpdateRests()
      },
      error: (err) => {
        console.error(err)
        this.snack.open("не удалось получить матрицу на дату", "ok", { duration: 900 })
        this.UpdateRests()
      }
    })


  }

  private UpdateRests() {
    this.rests = []
    this.errorMessage = ''
    this.loading = true
    this.hasRemains = false
    this.hasError = false

    const datestring = this.datePipe.transform(this.date, 'yyyy-MM-dd') as string
    if (this.client) {

      this.http.getRests(this.client.inn, this.client.kpp, datestring).subscribe({
        next: data => {
          this.mapData(data)
        },
        error: err => {
          this.snack.open(`Ошибка:${err}`, 'Закрыть', { duration: 5000 })
        },
        complete: () => {
          this.loading = false

        }
      })


    }
  }

  changed(): void {
    const text = this.datePipe.transform(this.date, 'yyyy-MM-dd') ?? ''
    this.setId(text)
  }

  mapData(data: UkropRest[]): void {
    this.total = 0
    this.shop = 0
    this.shopMinus = 0
    this.matrixCounter = 0

    this.hasRemains = false

    if (!data || data.length <= 0) {
      return
    }


    this.xdate = data[0].d_date
    this.hasRemains = true

    if (data.length == 1 && data[0].p_quantity === 0) {
      return
    }
    this.rests = data

    this.rests.forEach((element) => {
      this.total += element.p_quantity
      this.shop += element.p_quantity_shop_positive
      this.shopMinus -= element.p_quantity_shop_negative

    })


    if (!this.matrix) {
      this.matrix = {

      } as OnlineMatrix
    }

    if (!this.matrix.categories) {
      this.matrix.categories = [] as OnlineCategory[]
    }

    const nullBlock = {
      show: this.matrix.name === undefined,
      rests: [] as UkropRest[],
      restOnBlock: 0,
      positiveOnBlock: 0,
      negativeOnBlock: 0,
      blockTitle: "не в матрице"
    } as OnlineBlock;


    const restCodes = new Set<string>(this.rests.map(s => s.p_alccode))


    const nullBlocks: OnlineBlock[] = [
      nullBlock
    ]


    const nullMatrix = {
      categoryId: undefined,
      categoryName: "Не в матрице",
      blocks: nullBlocks
    } as OnlineCategory



    this.matrix.categories.push(nullMatrix)

    restCodes.forEach(restCode => {

      const goods = data.filter(d => d.p_alccode === restCode)
      let codeAccepted = false

      this.matrix?.categories?.forEach(category => {

        category.blocks.forEach(block => {
          if (!block.rests) {
            block.rests = [] as UkropRest[]
            block.color = 'rgb(255, 90, 0)'
            block.restOnBlock = 0
            block.positiveOnBlock = 0
            block.negativeOnBlock = 0
          }

          if (codeAccepted) return

          if (block.items) {
            const check = block.items.findIndex(i => i.alcoCode === restCode)

            if (check >= 0) {
              block.rests.push(...goods)

              block.restOnBlock += goods.map(g => g.p_quantity).reduce((prev, curr) => prev + curr, 0)
              block.positiveOnBlock += goods.map(g => g.p_quantity_shop_positive).reduce((prev, curr) => prev + curr, 0)
              block.negativeOnBlock += goods.map(g => g.p_quantity_shop_negative).reduce((prev, curr) => prev + curr, 0)

              block.color = '#AAFFAA'
              codeAccepted = true
              block.items.splice(check, 1)

            }
          }
        })

        if (codeAccepted) return

      })

      if (!codeAccepted) {

        nullBlock.rests.push(...goods)
        nullBlock.restOnBlock += goods.map(g => g.p_quantity).reduce((prev, curr) => prev + curr, 0)
        nullBlock.positiveOnBlock += goods.map(g => g.p_quantity_shop_positive).reduce((prev, curr) => prev + curr, 0)
        nullBlock.negativeOnBlock += goods.map(g => g.p_quantity_shop_negative).reduce((prev, curr) => prev + curr, 0)
      }

    })
    this.matrixCounter = 0
    this.matrixCounter0 = 0
    this.matrix?.categories.forEach(category => {

      category.blocks.forEach(block => {
        block.items?.forEach(i => {
          if (!block.rests) {
            block.rests = [] as UkropRest[]
            block.color = 'rgb(255, 90, 0)'
            block.restOnBlock = 0
            block.positiveOnBlock = 0
            block.negativeOnBlock = 0
          }

          block.rests.push({
            p_alccode: i.alcoCode,
            p_name: i.name,
            p_quantity: 0,
            p_quantity_shop_negative: 0,
            p_quantity_shop_positive: 0,
          } as UkropRest)
        })
      })



      if (category.categoryId) {
        category.successfull = category.blocks.map(b => b.restOnBlock > 0 ? 1 as number : 0).reduce((summ, curr) => summ + curr, 0)
        category.rest = category.blocks.map(b => b.restOnBlock).reduce((summ, curr) => summ + curr, 0)

        this.matrixCounter0 += category.successfull
        this.matrixCounter += category.blocks.length
      }

    })








  }

  getColor(res: UkropRest) {
    if (!res) return 'none'

    if (res.p_quantity_shop_positive !== 0 || res.p_quantity_shop_negative !== 0)
      return '#FF6666'

    if (res.matrix) {
      if (res.p_quantity > 0) return '#AAFFAA'
      else return 'rgb(255, 90, 0)'
    }

    return 'none'
  }



}
