<h1>Добро пожаловать в RPA</h1>

<span *ngIf="usagePercent ">  Занято памяти: {{usagePercent}}% ({{usage}} из {{quota}} ГБ) </span>

<div *ngIf="isAdmin || true">
    <h3>Сервера</h3>

    <ul>
        <li><a href="{{api}}swagger">{{api}}</a></li>
        <li><a href="{{ukrop}}">{{ukrop}}</a></li>
    </ul>

    <div *ngIf="user && conn">
        <h3>Пользователь</h3>
        <pre *ngIf="user"> {{user | json}} <br></pre>
        <hr>
        <pre
            *ngIf="user"> {{ dt(user.iat) | json}} &lt;&lt; {{ dt(user.nbf) | json}}  &lt;&lt; {{ dt(user.exp) | json}} <br></pre>
        <hr>
        <h3>Подключение</h3>
        <b>{{x}}</b>
        <pre *ngIf="conn.user"> {{conn | json}} <br></pre>

        <!-- span *ngIf="conn.token" style="overflow-wrap: anywhere;">token:
        {{conn.token}}<br><br></span -->
    </div>
    <div> deviceId: {{deviceId}} </div>
    {{version}} {{prod}}

</div>