import { version } from './version'

export const environment = {
  production: true,
  version: version,
  headerClass: 'header_rc',

  API_URL: "https://rpa-api.extpost.ru/rc/BN/orders/",
  UKROP_API_URL: "https://rpa-api.extpost.ru/rc/ukrop/"

}