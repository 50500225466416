<div *ngIf="job; else emptyJob" class="wide">

    <div class="icon">
        <div *ngIf="!job.error && !job.completed">
            <mat-progress-spinner [diameter]="30" strokeWidth="5" mode="determinate" class="job-state-progress"
                [value]="(job.count>0 ? job.step * 100.0 / job.count : 100)">
            </mat-progress-spinner>
        </div>
        <div *ngIf="job.error">
            <mat-icon class="job-state-icon" style="color: red">error</mat-icon>

        </div>

        <div *ngIf="job.completed && !job.error">
            <mat-icon class="job-state-icon" style="color: green">verified</mat-icon>
        </div>

    </div>
    <div class="progress">
        <span> {{job.name}} {{job.step}}/{{job.count}}
            <!-- <span *ngIf="job.count">
                {{(job.progress())}}
            </span> -->
            <br>
            <span class="progress-spent" style="color: orangered;" *ngIf="job">{{job.spent}} </span>
        </span>

        <span *ngIf="job.error"> {{job.message}} </span>
        <!-- <mat-progress-bar mode="determinate" [value]="(job.count>0 ? job.step * 100.0 / job.count : 100)">
        </mat-progress-bar> -->
        <!-- <mat-progress-spinner mode="determinate" class="" [value]="(job.count>0 ? job.step * 100.0 / job.count : 100)">
        </mat-progress-spinner> -->

    </div>
</div>

<ng-template #emptyJob>
    <div class="wide red">empty sync job </div>
</ng-template>