import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute } from '@angular/router'
import { Subscription, SubscriptionLike, from } from 'rxjs'
import { AgentModel } from 'src/app/models/agent.model'
import { Client } from 'src/app/models/client.model'
import { Quest, QuestType } from 'src/app/models/quests/quest.model'
import { Connection } from 'src/app/models/server'
import { DbService } from 'src/app/services/data/idb.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'
import { SyncService } from 'src/app/services/sync/sync.service'



@Component({
  selector: 'app-quest-list',
  templateUrl: './quests-list.component.html',
  styleUrls: ['./quests-list.component.scss'],
})
export class QuestsListComponent implements OnInit, OnDestroy {
  quests: Quest[] = []

  loading = false
  pageSize = 40
  page = 0
  total = 0

  stateFilter = ['1', '2']
  filterName = ''
  sub?: Subscription

  buypointId = ''
  buypoint?: Client
  agent: AgentModel | undefined
  qTypes: QuestType[] = []

  questType = 0
  db: DbService | undefined
  connection: Connection | undefined

  constructor(
    public connMgr: ConnectionManagerService,
    public route: ActivatedRoute,
    private snack: MatSnackBar,
    private sync: SyncService
    //private api: QuestsHttpService
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(x => {
      this.buypointId = x.get('id') as string;
    })

    const q = this.connMgr.activeServerObservable().subscribe((srv) => {
      this.init(srv);
      this.connection = srv
    })
    this.subscriptions.push(q)

    const s = this.sync.syncEnd.subscribe(() => { this.init(this.connection); console.log('sub') })
    this.subscriptions.push(s)


  }

  getClient() {
    if (this.buypointId && !this.buypoint) {
      this.db?.get('clients', this.buypointId).then(x => { this.buypoint = x; })
    }

  }

  init(conn: Connection | undefined) {

    this.quests = []
    this.qTypes = []

    if (conn == undefined) {
      return
    }

    // console.log(`init quests ${conn.user.name}`)

    this.agent = <AgentModel>{
      id: conn.user.Id
    }

    if (this.agent?.id === undefined) return

    const x = from(this.connMgr.getDb()).subscribe((d) => {
      this.db = d
      this.qTypes = this.db?.questTypes?.sort((a, b) => { if (a.name > b.name) return 1; if (a.name < b.name) return -1; return 0 }) ?? []
      this.getClient()
      this.runSearch(0)
    })

    this.subscriptions.push(x)


  }

  pageEvent(pageIndex: number) {
    this.runSearch(pageIndex)
  }

  runSearch(page: number) {

    if (!this.db) return

    if (page === 0) {
      this.quests = []
    }

    this.loading = true



    if (this.sub) {
      this.sub.unsubscribe()
    }

    const filter = {
      States: this.stateFilter ? this.stateFilter.map((s) => +s) : [],
      Page: page,
      PageSize: this.pageSize,
      QuestType: this.questType,
      Modified: undefined,
      BuypointId: (this.buypointId !== undefined && this.buypointId !== '') ? this.buypointId : undefined,
      BuypointName: this.filterName


    };



    this.sub = this.db.findQuests(filter) //this.api.questsFind(filter)
      .subscribe({
        next: (data) => {
          if (data) {
            this.quests.push(...data.items)
            this.total = data.total
            this.page = page
          } else {
            this.page = 0
          }
        },
        error: (error) => {
          this.snack.open('Не удалось загрузить задачи', 'ok', { duration: 5000 })
          console.log(error)
        },
        complete: () => {
          this.loading = false

        },
      })
  }

  getZQuestText(quest: Quest): string {
    if (!quest.items) return ''
    const textItem = quest.items[1]
    return textItem?.value ? `Сообщение бухгалтера: ${textItem?.value}` : ''
  }

  getQuestText(quest: Quest): string {
    if (!quest.items) return ''
    const textItem = quest.items[0]
    return textItem?.value ? `${textItem?.value}` : ''
  }

  subscriptions: SubscriptionLike[] = []

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => { subscription.unsubscribe() })
    this.subscriptions = []

  }
}
