<div *ngIf="data">
    <h1>{{data.name}}</h1>
    <p>{{data.code}}</p>

    <ol>
        <li *ngFor="let conn of data.connections"
            [ngStyle]="{'background-color': conn.deviceId === deviceId ? 'yellow' : 'none'}">
            <p>{{conn.userAgent}}</p>
            <p>deviceId <i>{{conn.deviceId}}</i></p>
            created: {{conn.created | date:'dd.MM.yyyy hh:mm'}} updated: {{conn.updated | date:'dd.MM.yyyy hh:mm'}}

            <!-- button mat-icon-button [routerLink]="['/admin/device', conn.Id]">
                <mat-icon>
                    edit
                </mat-icon>
            </button -->
        </li>
    </ol>


    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="delta">
            <ul>
                <li *ngFor="let item of delta.items">
                    {{item.kind}}:<b>{{item.count}}</b>
                </li>
            </ul>
            <!-- pre>   {{delta | json}} </pre -->
        </mat-tab>
        <mat-tab label="full">
            <ul>
                <li *ngFor="let item of full.items">
                    {{item.kind}}: <b>{{item.count}}</b>


                    <button mat-icon-button (click)="killSnapShot(item)">
                        <mat-icon>delete</mat-icon>
                    </button>

                </li>
            </ul>
            <!-- pre> {{full | json}} </pre -->
        </mat-tab>
        <mat-tab label="Third">Content 3</mat-tab>
    </mat-tab-group>



</div>