<app-order-info-lines [order]="orderInfo" [orderMain]="main"></app-order-info-lines>

<!-- <mat-tab-group>
  
   <mat-tab label="Состав">
    <app-order-info-lines [order]="orderInfo" [orderMain]="main"></app-order-info-lines>
  </mat-tab> 


  <mat-tab label="Журнал" *ngIf="orderInfo.statusLog && orderInfo.statusLog.length">
    <div class="row">
      <div class="list">
        <div *ngFor="let item of orderInfo.statusLog" class="line">
          <app-order-status-indicator [id]="item.statusId"></app-order-status-indicator>

          {{item.dt | date:'dd.MM.yyyy HH:mm:ss'}} {{item.message}}

        </div>
      </div>
    </div>

  </mat-tab>

  <mat-tab *ngIf="orderInfo.messages && orderInfo.messages.length">
    <ng-template mat-tab-label>
      <span [matBadge]="orderInfo.messages.length" matBadgeColor="accent" class="example-tab-icon">
        Сообщения &nbsp;
      </span>
    </ng-template>
    <div class="row">
      <div class="list">
        <div *ngFor="let item of orderInfo.messages" class="line">

          <small> {{item.messageDate | date:'dd.MM.yyyy HH:mm:ss'}} </small>
          <b>{{item.name}}</b>: {{item.description}}

        </div>
      </div>
    </div>


  </mat-tab>


</mat-tab-group> -->