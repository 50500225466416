import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { OrderModel } from 'src/app/models/order.model'
import { Client } from 'src/app/models/client.model';

import { CurrentOrderService } from 'src/app/services/data/current-order.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss'],
})
export class EditOrderComponent implements OnInit, OnDestroy {
  id = ''

  orderInfo: OrderModel | undefined
  buypoint: Client | undefined;
  ignore = 0
  subs: Subscription[] = []
  isAdmin = false

  constructor(
    private route: ActivatedRoute,
    public currentOrder: CurrentOrderService,
    private router: Router,
    private cnmng: ConnectionManagerService

  ) {
    this.isAdmin = cnmng.isAdmin()
  }

  ngOnInit() {

    const s1 = this.cnmng.activeServerObservable()
      .subscribe(x => {
        this.ignore++
        if (this.ignore > 1) {
          this.router.navigateByUrl('/orders')
        }
      })

    this.route.paramMap.subscribe((params) => {
      this.currentOrder.init(params.get('id') as string)
    })

    const s2 = this.currentOrder.buypoint$.subscribe({
      next: (b) => {
        this.buypoint = b
      }
    })

    const s3 = this.currentOrder.orderInfo$.subscribe({
      next: (o) => {
        this.orderInfo = o
      }
    })

    this.subs = [s1, s2, s3]

  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe())
    this.subs = []
  }




  onCompleteConfirm() {
    void this.router.navigate(['/orders']) //, { queryParams: { id: id } }
  }


}
