import { Component } from '@angular/core';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent {
/**
 *
 */
constructor(
  public loclstg: LocalStorageService,
  private appSettings: AppSettingsService,
) {
   

}


themeset() {
  this.appSettings.themeSet()
}

fontSet() {
  this.appSettings.sizeFontSet()
}

settingsSave() {
  this.appSettings.settingsSave()
}





saveStrg() {
  if (!this.loclstg.searchOptions.swipeSensitive) {
    this.loclstg.searchOptions.swipeSensitive = 80
  }
  this.loclstg.save()
}

}
