<div *ngIf="debetDoc !== undefined">
  <h1 mat-dialog-title>Количество документов: {{debetDoc.length}}</h1>
  <div mat-dialog-content>
    <p>{{data.filial.name}} / {{data.org.name}}</p>
    <!-- <b>Дата документа:</b> <span> {{debetDoc.date | date:'dd.MM.YYYY'}}</span> -->

    <!-- <p><b>Тип документа:</b><span> {{debetDoc.type}}</span></p> -->
    <!-- <p *ngIf="debetDoc.comment"><b>Коментарий: </b> <span> {{debetDoc.comment}} </span> </p> -->

    <mat-form-field class="input_field" appearance="outline">
      <mat-label>Сумма</mat-label>
      <input matInput type="number" [max]="data.summ.toFixed(2)" [formControl]="summaControl">
      <mat-error *ngIf="summaControl.hasError('max')"> Сумма СФ {{data.summ |
        currency:'RUB':'symbol':'1.2-2':'ru'}} </mat-error>
    </mat-form-field>




    <div *ngIf="errorMessage" style="color:red">Ошибка: {{errorMessage}}</div>

    <!-- <p>Изменение документа станет невозможным.</p> -->
    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="accept"
        [disabled]="summaControl.value<=0 || summaControl.hasError('max')">
        Подтверждаю АП</mat-checkbox>
    </section>
    <br>
    <button mat-raised-button color="accent"
      [disabled]="!summaControl.value || !accept || summaControl.value<=0 || summaControl.hasError('max')"
      (click)="createCashOrder()">Акцептовать</button>
    <!-- [mat-dialog-close]="myControl.value" -->
  </div>
</div>

<div mat-dialog-actions style="float: right;">
  <button mat-raised-button (click)="exit()">Закрыть</button>
</div>