import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { Options, SearchOptions, Connection, User } from 'src/app/models/server';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private options!: Options

  constructor() {
    this.load()
  }

  load() {
    let tmp: Options | undefined = (this.read('options') as Options);

    if (tmp !== undefined && tmp.widevineID === undefined) {
      tmp = undefined
      localStorage.removeItem('options')
    }

    if (tmp === undefined) {

      const wid = uuidv4()
      tmp = {
        widevineID: wid,
        // servers: [],
        users: [],
        searchOptions: {
          activeContract: true,
          noBlock: false,
          name: '',
          soloDate: false,
          orderByDeliveryDate: false
        } as SearchOptions
      } as Options

      this.write('options', tmp)
    }

    this.options = tmp
  }


  save() {
    this.write('options', this.options)
  }

  public get users(): User[] {
    if (!this.options.users) {
      this.options.users = []
    }
    return this.options.users
  }

  public set users(usersValue: User[]) {
    this.options.users = usersValue
    this.save()
  }

  public get activeUser() {
    return this.options.users?.find(x => x.active) ?? {} as User
  }

  public setActiveUser(value: User) {
    this.options.users.forEach(x => x.active = false)
    this.options.users.find(x => x.user.Code === value.user.Code)!.active = true
    this.save()
  }

  public get connections(): Connection[] {
    if (!this.options.users) {
      this.options.users = []
    }
    return this.options.users.find(x => x.active)?.connections ?? []
  }

  public set connections(value: Connection[]) {
    if (!this.options.users.find(x => x.active)?.connections)
      return
    this.options.users.find(x => x.active)!.connections = value
    this.save()
  }


  public get widevineId(): string {
    return this.options.widevineID
  }

  public get searchOptions(): SearchOptions {
    if (!this.options.searchOptions) {
      this.options.searchOptions = {
        activeContract: true,
        noBlock: false,
        name: '',
        swipe: true,
        swipeSensitive:80
      } as SearchOptions
    }
    return this.options.searchOptions
  }

  public set searchOptions(value: SearchOptions) {
    this.options.searchOptions = value
    this.save()
  }

  public deleteLocalStorage() {
    localStorage.removeItem('options')
    this.load()
    //    this.servChange.next({} as Server)
  }


  private read(key: string): unknown {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    }
    return
  }

  private write(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value))
  }


}
