import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, from, take } from 'rxjs';
import { OrderLine, OrderModel } from 'src/app/models/order.model';
import { GoodCategory, Matrix, MatrixGood } from 'src/app/models/sync.model';
import { UkropRest } from 'src/app/models/Ukrop/Rest';
import { DbService } from 'src/app/services/data/idb.service';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';
import { QuantityDialogData, QuantityDialogPlainComponent } from '../quantity-dialog/quantity-dialog.component';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { ErpPrice } from 'src/app/models/goods/product';
import { CurrentOrderService } from 'src/app/services/data/current-order.service';
import { Client } from 'src/app/models/client.model';
import { AppSettingsService } from 'src/app/services/app-settings.service';

@Component({
  selector: 'app-edit-order-matrix',
  templateUrl: './edit-order-matrix.component.html',
  styleUrls: ['./edit-order-matrix.component.scss']
})
export class EditOrderMatrixComponent implements OnInit, OnDestroy {
  @Input() client: Client | undefined
  orderInfo: OrderModel | undefined
  category: string | undefined
  prices: ErpPrice | undefined;



  matrix: Matrix | undefined;
  matrixBlock: MatrixBlock[] = []
  restDate: Date | undefined
  onlyAvailable = false
  ukropLoading = false
  ukropAvailable = false
  db: DbService

  openAll: boolean = false
  isOwn = false

  subscriptions: Subscription[] = [];

  constructor(
    cm: ConnectionManagerService,
    settings: AppSettingsService,
    private ukropClient: UkropClientService,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    private datePipe: DatePipe,
    private currentOrder: CurrentOrderService,
  ) {
    this.db = cm.getCurrentDb()
    this.openAll = settings.options.autoMatrix
  }


  ngOnInit(): void {


    this.category = this.client?.categoryId

    try {
      const promise = this.db.getMatrixByCategory(this.category)
      const sub = from(promise)
      const smc = sub.subscribe(m => {
        this.setMatrix(m);

      })

      this.subscriptions.push(smc)


    } catch (error: any) {
      this.snack.open(`getMatrixByCategory: ${error.message}`, 'ok', { duration: 50000 })
    }


    // const sbp = this.currentOrder.buypoint$
    //   .pipe()
    //   .subscribe({
    //     next: (b) => {
    //       console.log(b)
    //       this.category = b?.categoryId

    //       try {
    //         const promise = this.db.getMatrixByCategory(this.category)
    //         const sub = from(promise)
    //         const smc = sub.subscribe(m => {
    //           this.setMatrix(m);

    //         })

    //         this.subscriptions.push(smc)


    //       } catch (error: any) {
    //         this.snack.open(`getMatrixByCategory: ${error.message}`, 'ok', { duration: 50000 })
    //       }


    //     }
    //   })

    // this.subscriptions.push(sbp)

    const soi = this.currentOrder.orderInfo$.subscribe({
      next: (o) => {
        this.orderInfo = o
      }
    })

    this.subscriptions.push(soi)

    const sps = this.currentOrder.prices$.subscribe({
      next: (p) => {
        this.prices = p
      }
    })

    this.subscriptions.push(sps)



  }


  private async setMatrix(m: Matrix | undefined) {

    try {
      this.matrix = m;
      await this.mapMatrixToGoods();
      this.loadUkrop();
      // this.distBlock()

      this.matrix?.goodCategories.forEach(c => {
        c.hidded = !this.openAll;
        if (!c.name) {
          c.name = "Без категории";
        }
      });



    } catch (error: any) {
      this.snack.open(`setMatrix: ${error.message}`, 'ok', { duration: 50000 })
    }

  }

  async mapMatrixToGoods() {
    this.matrix?.goods.forEach(async element => {
      element.good = await this.db.get("goods", element.goodId)
      if (element.good && element.good.rests && element.good.rests.length > 0) {
        element.storeRest = element.good.rests[0].quantity
      }
    });
  }


  loadUkrop() {

    this.ukropLoading = true

    const datestring = this.datePipe.transform(new Date(), 'yyyy-MM-dd') as string
    if (!this.orderInfo?.buypoint) {
      return
    }

    const getClientObservable = from(this.db.getClient(this.orderInfo.buypointId))

    const sgc = getClientObservable.subscribe((client) => {

      if (!client) {
        this.snack.open(`Ошибка: ТТ не найдена`, 'Закрыть', { duration: 5000 })
        return
      }

      this.isOwn = client.isOwn

      const sur = this.ukropClient.getRests(client.inn, client.kpp, datestring).subscribe({
        next: data => {
          this.mapUkropData(data)
        },
        error: err => {

          console.log(`Не удалось загрузить остатки ТТ: ${client.inn}/${client.kpp}`)
          this.snack.open(`Не удалось загрузить остатки ТТ: ${err.message}`, 'Закрыть', { duration: 5000 })
          this.distBlock()
          this.ukropLoading = false
        },
        complete: () => {

          this.distBlock()
          this.ukropLoading = false
          //          this.distinctMatrixBlocks()
        }
      })

      this.subscriptions.push(sur)
    })

    this.subscriptions.push(sgc)



  }

  mapUkropData(data: UkropRest[]) {
    if (!data || data.length === 0) return

    if (data.length > 0) {
      const l1 = data[0]
      const date1 = new Date(Date.parse(l1.d_date)).getTime()
      const date2 = new Date().getTime()
      const difference = (date2 - date1) / (23 * 3600 * 1000);
      if (difference <= 7) {
        this.ukropAvailable = true
      }

    }

    this.restDate = new Date(data[0].d_date)

    data.forEach(u => {

      this.matrix?.goods.forEach(g => {
        g.alccodes.forEach(a => {
          if (a === u.p_alccode) {
            g.point_rests = u.p_quantity;
          }

        })
      })

    })

  }




  getLines(goods: MatrixGood[] | undefined): MatrixGood[] {
    const res = goods?.filter(x => !this.onlyAvailable || (x.storeRest && x.storeRest > 1)) ?? []
    return res
  }

  getLinesWihoutBlock(cat: GoodCategory): MatrixGood[] {
    const res = this.matrix?.goods.filter((f) => !f.blockId && f.categoryId === cat.id && (!this.onlyAvailable || f.storeRest))
    return res ?? []
  }

  clickOnItem(row: MatrixGood) {


    if (this.orderInfo === undefined) return

    const item = row.good;

    if (!item) return;

    const rest = item.rests !== undefined ? item.rests[0].quantity : 0

    if (rest > 0 && item.prices && Object.keys(item.prices).length > 0 && this.client?.priceType) {

      const perPrice = this.personalPrice(row)?.price
      const curPrice = item.prices[this.client.priceType]
      const dd = {
        line: {
          price: (curPrice !== perPrice && perPrice) ? perPrice : curPrice,
          goodId: item.code,
          good: item.name,
          orderId: this.orderInfo.id,
          amount: item.ordered

        } as OrderLine,

        ok: false
      } as QuantityDialogData

      this.openDialog(dd)
    }

  }

  openDialog(dd: QuantityDialogData): void {


    const dialogRef = this.dialog.open(QuantityDialogPlainComponent, {
      width: '600px',
      data: dd,
    })

    const sdac = dialogRef.afterClosed().subscribe((result: QuantityDialogData) => {
      if (result?.line != undefined && result.line.amount != undefined && result.line.amount > 0 && result.ok) {
        this.currentOrder.addOrUpdateItem(result.line)
      }
    })

    // маньячу
    this.subscriptions.push(sdac)
  }


  distBlock() {

    const distBlocks = Array.from(new Set(this.matrix?.goods.map(x => x.blockId))).filter(x => x)

    this.matrixBlock = distBlocks.map(id => {
      const blockGood = this.matrix?.goods.find(g => g.blockId === id)
      const blockGoods = this.matrix?.goods.filter(g => g.blockId === id)
      let rest = 0
      let store = 0
      blockGoods?.forEach(x => { rest = rest + (x.point_rests ?? 0); store += x.storeRest ?? 0; })
      return {
        title: blockGood?.block,
        show: false,
        goodCategId: blockGood?.categoryId,
        blockId: blockGood?.id,
        goods: blockGoods,
        rest: rest,
        storeRest: store
      } as MatrixBlock
    })
  }

  getBlockByCateg(categ: string) {
    return this.matrixBlock.filter(x => x.goodCategId == categ)
  }

  ordered(goodid: string) {
    return this.orderInfo?.items.find(x => x.goodId === goodid)?.amount ?? 0
  }
  orderedBlock(block: MatrixBlock) {
    let ordered = 0

    block.goods?.forEach(x => {
      ordered += this.orderInfo?.items?.find(o => o.goodId === x.goodId)?.amount ?? 0
    })

    return ordered
  }


  colorBlock(block: MatrixBlock) {
    const countsTT = block.goods?.find(x => x.point_rests ?? 0 > 0)
    if (!countsTT && this.orderedBlock(block) < 1 || !this.ukropAvailable)
      return false

    return true

  }

  personalPrice(item: MatrixGood) {

    if (item.good && item.good.prices !== undefined && item.good.prices && Object.keys(item.good.prices).length && this.prices && this.prices.items?.length && this.client?.priceType) {

      const price = item.good.prices[this.client?.priceType]
      const good = this.prices?.items.find(x => x.goodId === item.good?.code && price !== x.price)
      return good

    }

    return undefined
  }

  public showPrice(item: MatrixGood) {
    if (item.good && item.good.prices && Object.keys(item.good.prices).length > 0 && this.client && this.client.priceType)
      return item.good.prices[this.client.priceType]

    return "0"
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}


interface MatrixBlock {
  blockId: string
  title: string
  goodCategId: string
  show: boolean
  goods: MatrixGood[] | undefined
  rest: number
  storeRest: number
}