import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, from } from 'rxjs';
import { Connection, Server, User, UserData } from 'src/app/models/server';
import { LocalStorageService } from './local-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from '../data/idb.service';
import { myDbWrapper } from '../data/myDbWrapper';
import { ResolveEnd, Router } from '@angular/router';
import { navList } from 'src/app/layout/main-menu/main-menu.component';

@Injectable({
  providedIn: 'root'
})
export class ConnectionManagerService {

  private db: DbService | undefined

  private servChange: BehaviorSubject<Connection | undefined>
  private userChange: BehaviorSubject<User | undefined>
  private locked = true
  //  private serversChanges = new Subject<Server[]>();

  constructor(private local: LocalStorageService,
    public router: Router,
    private snack: MatSnackBar,
  ) {
    const a = this.activeServer
    const u = this.activeUser
    this.servChange = new BehaviorSubject<Connection | undefined>(a);
    this.userChange = new BehaviorSubject<User | undefined>(u);

    router.events.pipe(
      filter((e): e is ResolveEnd => e instanceof ResolveEnd)
    ).subscribe((e: ResolveEnd) => {

      // const top = ['/quests', '/home', '/orders', '/clients', '/catalog', '/settings', '/sync']
      const top = navList.map(x=>x.url)
      const isTop = top.findIndex(a => e.urlAfterRedirects.startsWith(a))

      this.locked = isTop < 0
      // if (e.urlAfterRedirects === '/quests' && e.) {
      //   // Do something
      //   console.log(e);
      // }
    });


  }

  //#region db
  public get isLocked(): boolean {
    return this.locked
  }

  public getCurrentDb(): DbService {
    if (this.db === undefined)
      throw Error("нельзя получить экземпляр БД")

    return this.db

  }

  public async getDb(): Promise<DbService | undefined> {
    if (!this.activeServer)
      return undefined

    if (this.db === undefined || this.db.dbGetName() !== this.activeServer.id) {
      const wrap = new myDbWrapper(this.activeServer.id)
      const idb = await wrap.open()
      this.db = new DbService(idb)
    }
    return this.db

  }
  //#endregion

  //#region connections
  public get activeServer(): Connection | undefined {
    return this.local.connections.find(s => s.active)
  }

  public set activeServer(conn: Connection | undefined) {

    if (this.locked) {
      throw Error("нельзя сменить активного пользователя!")
    }


    if (this.activeServer?.id === conn?.id) {
      return
    }

    if (this.db !== undefined) {
      this.db.close()
      this.db = undefined
    }

    let nextconn: Connection | undefined = undefined
    this.local.connections.forEach(s => {
      if (s.id === conn?.id) {
        s.active = true
        nextconn = s
      }
      else {
        s.active = false
      }
    })

    this.servChange.next(nextconn)
    this.local.save()
  }


  public get Connections(): Connection[] {
    const ret = this.local.connections.sort((a, b) => { if (a?.title > b?.title) return 1; if (a?.title < b?.title) return -1; return 0 })
    return ret

  }

  addConnection(server: Connection) {


    const connections = this.local.connections

    const check = connections.find(a => a.id === server.id)
    if (check !== undefined) {
      this.snack.open("Подключение уже существует!", "ok", { duration: 2000 })
      return
    }


    connections.forEach(s => { s.active = false })
    server.active = true
    connections.push(server)
    this.local.connections = connections
    this.servChange.next(server)
    this.getDb() // мб await добавить
  }

  updateConnection(current: Connection) {
    const connections = this.local.connections

    const idx = connections.findIndex(a => a.id === current.id)
    if (idx < 0) {
      // error!!
      return
    }

    connections[idx] = current
    this.local.connections = connections
    this.servChange.next(current)
    this.getDb()

  }


  deleteConnection(conn: Connection): boolean {
    const servers = this.local.connections
    const i = servers.indexOf(conn)
    if (i < 0) {
      console.error("not found")
      return false
    }

    servers.splice(i, 1)


    if (conn.active) {

      if (servers.length <= 0) {
        this.servChange.next(undefined)
      } else {
        if (i >= servers.length) {
          servers[servers.length - 1].active = true
          this.servChange.next(servers[servers.length - 1])
        } else {
          servers[i].active = true
          this.servChange.next(servers[i])
        }
      }

    }

    try {
      const wrp = new myDbWrapper("");

      if (this.db) {
        this.db.close()
      }
      from(wrp.deleteXDB(conn.id)).subscribe(() => {
        console.log(`deleted idb ${conn.title}`)
        this.db = undefined
        this.getDb()
      })
    } catch (error) {
      console.log(error)
    }

    this.local.connections = servers
    console.log(`удалено соединение ${conn.title}`)
    return true

  }

  public activeServerObservable(): Observable<Connection | undefined> {
    return this.servChange.asObservable()
  }
  //#endregion

  //#region  users
  public get users(): User[] {
    return this.local.users
  }

  public get activeUser() {
    return this.local.activeUser ?? {} as User
  }

  public setActiveUser(value: User) {
    this.local.setActiveUser(value)
    this.userChange.next(this.activeUser)
  }


  addUser(userValue: UserData, token: string, server: Server) {
    const userlist = this.local.users

    const existUser = this.local.users.find(x => x.user.Id === userValue.Id && x.user.Server === userValue.Server)

    if (existUser !== undefined) {
      this.snack.open("Пользователь уже существует!", "ok", { duration: 2000 })
      return
    }

    userlist.forEach(s => { s.active = false })
    const user: User = {
      active: true,
      user: userValue,
      connections: [{
        rereshToken: token,
        active: true,
        accessToken: '',
        id: userValue.Server + '-' + userValue.Code,
        server: server,
        title: `[${userValue.Server}] ${userValue.name}`,
        user: userValue
      }],
      accessToken: token
    }
    userlist.push(user)
    this.local.users = userlist
    this.setActiveUser(user)


  }

  delUser(usr: User) {
    const userlist = this.local.users

    const i = userlist.indexOf(usr)

    if (i < 0) {
      this.snack.open("Пользователь не найден!", "Закрыть", { duration: 2000 })
      return
    }

    usr.connections.forEach(x => {
      this.deleteConnection(x)
    })

    userlist.splice(i, 1)

    if (usr.active) {

      if (userlist.length <= 0) {
        this.userChange.next(undefined)
      } else {
        if (i >= userlist.length) {
          userlist[userlist.length - 1].active = true
          this.userChange.next(userlist[userlist.length - 1])
        } else {
          userlist[i].active = true
          this.userChange.next(userlist[i])
        }
      }

    }

    this.local.users = userlist
    return true

  }


  public activeUserchange() {
    return this.userChange.asObservable()
  }

  //#endregion


  //#region userRoles 

  isAdmin() {
    const role = 'rpa-users-admin'
    if (this.activeUser?.user?.role?.find(x => x === role)
      // || isDevMode()
    )
      return true
    return false
  }

  isSuper() {
    const role = 'rpa-users-super'
    if (this.activeUser?.user?.role?.find(x => x === role))
      return true
    return false
  }

  isAgent(){
    const role = 'rpa-users-agent'
    if (this.activeUser?.user?.role?.find(x => x === role))
      return true
    return false
  }



  //#endregion

}
