import { Component, HostListener, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, UrlTree } from '@angular/router'
import { Observable, Subject, from } from 'rxjs'
import { Client } from 'src/app/models/client.model'

import { Quest, QuestItem } from 'src/app/models/quests/quest.model'
import { DialogServiceService } from 'src/app/services/confirm/dialog-service.service'
import { ComponentCanDeactivate } from 'src/app/services/confirm/pending-changes-guard'
import { DbService } from 'src/app/services/data/idb.service'

import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'


@Component({
  selector: 'app-questionare-ors',
  templateUrl: './questionare-ors.component.html',
  styleUrls: ['./questionare-ors.component.scss'],
})
export class QuestionareOrsComponent implements OnInit, ComponentCanDeactivate {
  id = ''
  quest: Quest = <Quest>{}
  loading = true
  saving = false
  error = false
  saved = true

  trigger: Subject<QuestItem> = new Subject<QuestItem>()
  db: DbService
  client: Client | undefined

  constructor(

    cm: ConnectionManagerService,
    public route: ActivatedRoute,
    private snack: MatSnackBar,
    private confirmator: DialogServiceService,

  ) {
    this.db = cm.getCurrentDb()
  }

  @HostListener('window:beforeunload', ['$event'])


  // interface implementation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unloadNotification($event: any) {
    if (!this.saved) {
      $event.returnValue =
        'Есть несохранённые измения. Вы уверены, что хотите покинуть страницу?'
    }
  }

  canDeactivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //
    console.log('canDeactivate')

    if (!this.saved) {
      return this.confirmator.confirm(
        'Есть несохранённые измения. Вы уверены, что хотите покинуть страницу?',
        'ВНИМАНИЕ!'
      )
    }
    return true
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((p) => {
      this.id = p.get('id') ?? '0'

      this.loadQuest()
    })
  }

  loadQuest() {
    this.error = false
    this.loading = true



    const sq = from(this.db.get("quests", this.id))
    //   this.api.getQuest(this.id).subscribe(
    sq.subscribe(
      {
        next: (q) => {
          if (!q) return
          this.setQuest(q)
        },
        error: (e) => {
          console.log(e)
          this.snack.open('не удалось загрузить задачу', 'ok', { duration: 5000 })
          this.error = true
        },
        complete: () => {
          this.loading = false
        }
      })
  }
  setQuest(q: Quest) {
    if (q && q.buypoint) {
      this.db.getClient(q.buypoint.id).subscribe(s => {
        this.client = s
      })
    }

    q.items
      .filter(
        (i) =>
          i.questDefinition.questDefType == 1 || i.questDefinition.questDefType == 2
      )
      .forEach((i) => {
        i.value = +(i.value ?? 0)
      })

    q.items
      .filter(
        (i) =>
          (i.questDefinition.questDefType == 4 ||
            i.questDefinition.questDefType == 5) &&
          i.value === undefined
      )
      .forEach((i) => {
        i.value = ''
      })

    q.items
      .filter((i) => i.questDefinition.questDefType == 8)
      .forEach((i) => {
        if (i.value) {
          // для зависимых эелементов ищем ID
          i.valueId = i.questDefinition.items.find((x) => x.listValue === i.value)?.id
        }
      })

    this.quest = q
  }

  list() {
    return this.quest?.items.filter((f) => f.questDefinition.pos > 10)
  }

  customChanged(event: QuestItem) {
    this.saved = false

    if (event.questDefinition.questDefType === 8) {
      console.log(event)

      const notifyList = this.quest.items.filter(
        (g) => g.questDefinition.dependentId == event.questDefinitionId
      )

      notifyList.forEach((item) => this.triggerChanges(item)) // trigger changes :))
    }
  }

  triggerChanges(item: QuestItem): void {
    this.trigger.next(item)
  }

  confirm() {
    const nulls = this.quest.items.filter(
      (i) =>
        i.questDefinition.mandatory &&
        !i.hidden && // hidden используется только для связанных структур
        (!i.value || i.value === '')
    )

    if (nulls && nulls.length > 0) {
      this.snack.open(
        `Все поля, отмеченные '*', обязательны к заполнению! (${nulls.length})`,
        'ok',
        { duration: 3300 }
      )
    } else {
      this.confirmator
        .confirm('Закрыть задачу? Внести изменения будет нельзя!', 'Внимание!')
        .subscribe((ok) => {
          if (ok === true) {
            this.save(3)
          }
        })
    }
  }

  getParent(row: QuestItem) {
    if (
      row === undefined ||
      row.questDefinition.dependentId === undefined ||
      row.questDefinition.dependentId === null
    ) {
      return undefined
    }

    const a = this.quest.items.find(
      (q) => q.questDefinitionId === row.questDefinition.dependentId
    )
    if (a) {
      return a
    }

    return undefined
  }

  save(state = 2) {
    this.saving = true
    this.loading = true
    this.quest.items
      .filter(
        (i) => i.questDefinition.questDefType == 1 || i.questDefinition.questDefType == 2
      )
      .forEach((i) => (i.value = i.value ? i.value.toString() : ''))

    this.quest.stateId = state
    this.quest.modified = 1


    const sq = from(this.db.update("quests", this.quest))

    //this.api.updateQuest(this.quest).subscribe(
    sq.subscribe(
      {
        next: (q) => {
          this.quest = q
          this.saved = true

          this.loading = false
          this.saving = false
        },
        error: (e) => {
          let m = e;
          if (m.message !== undefined) {
            m = m.message
          }

          this.snack.open(m, 'ok', { duration: 5000 })
          console.log(e)

          this.loading = false
          this.saving = false
          this.error = true
        }
      }
    )
  }
}
