
import { PreorderWrapper, SyncFetch, SyncFetchItem, SyncJob, SyncMapItem } from "src/app/models/sync.model";
import { RpaClientService } from "../net/rpa-client.service";

import { DbService } from "../data/idb.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AbstractSyncDataJob } from "./AbstractSyncDataJob";

export class SyncOrdersSendJob extends AbstractSyncDataJob {
    override    async run(): Promise<boolean> {

        const send = await this.db.getCommitedOrders()
        if (!send || send.length === 0) {
            this.completed = true
            return true

        }

        this.count = send.length

        const i: SyncFetchItem[] = send.map(x => {
            //TODO: delete
            x.orders1c = []
            // чуть маньячим.
            x.items.forEach(i => {
                if (isNaN(i.amount)) {
                    i.amount = 0
                }
                if (isNaN(i.price)) {
                    i.price = 0
                }
                i.summa = i.amount * i.price

            })

            if (isNaN(x.summa)) {
                x.summa = 0
            }

            const w: PreorderWrapper = {
                id: x.id,
                preorder: x,


            }

            const r: SyncFetchItem =
            {
                id: x.id,
                preorder: w,
                state: 5,
                client: null,
                good: null,
                goodGroup: null,
                matrix: null,
                advance: null,
                debet: null,
                quest: null
            }


            return r
        })

        const d: SyncFetch = {
            items: i
        } as SyncFetch




        try {
            const r = await this.httpCredit.syncSendPromise(d)
            await this.save(r)
            this.step = send.length
            this.completed = true

        } catch (error: unknown) {

            this.error = true
            this.completed = true
            if (error instanceof Error) {
                this.message = error.message

            } if (error instanceof HttpErrorResponse) {
                this.message = error.message

            } else {
                console.error(error)
                this.message = 'unknown error'
                // if (error.error !== undefined) { this.message = error.error }
            }


            return false
        }


        return true
    }

    private async save(r: SyncFetch) {

        r.items.forEach(async (p) => {
            if (!p.preorder || !p.preorder.preorder) {
                console.error(`update status error: no preorder`)
                return
            }

            if (!(p.preorder.preorder.date instanceof Date)) {
                p.preorder.preorder.date = new Date(Date.parse(p.preorder.preorder.date))
            }

            console.log(`update status ${p.id}`)
            await this.db.update('orders', p.preorder.preorder);

        });

    }
}