import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, isDevMode } from '@angular/core';
import { SecurePipe, SecureUPipe } from './services/http/secure.pipe';

import { ActListComponent } from './pages/ors/act-list/act-list.component';
import { AdvanceListComponent } from './pages/advance-list/advance-list.component';
import { AdvanceStatusComponent } from './pages/advance-list/advance-status/advance-status.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './services/http/auth-interceptor';
import { BotAuthComponent } from './pages/bot-auth/bot-auth.component';
import { BrowserModule } from '@angular/platform-browser';
import { CatalogPageComponent } from './pages/catalog/catalog-page/catalog-page.component'
import { ClientInfoComponent } from './pages/client/client-info/client-info.component';
import { ClientInfoDialogComponent } from './pages/client/client-info-dialog/client-info-dialog.component';
import { ClientsListComponent } from './pages/client/clients-list/clients-list.component';
import { ConfirmDialogComponent } from './services/confirm/confirm-dialog/confirm-dialog.component';
import { ConfirmatorDialogComponent } from './components/confirmator-dialog/confirmator-dialog.component';
import { CreateAdvanceDialogComponent } from './pages/client/advances/create-advance-dialog/create-advance-dialog.component';
import { CreateOrderButtonComponent } from './components/orders/create-order-button/create-order-button.component';
import { CreateOrderComponent } from './pages/orders/edit/create-order/create-order.component';
import { CustomDateAdapter } from './custom-date-adapter';
import { DatasheetComponent } from './pages/datasheet/datasheet.component';
import { DebetDocListComponent } from './pages/client/debet-doc-list/debet-doc-list.component';
import { EditOrderComponent } from './pages/orders/edit/edit-order/edit-order.component';
import { EditOrderInfoComponent } from './pages/orders/edit/edit-order-info/edit-order-info.component';
import { EditOrderMatrixComponent } from './pages/orders/edit/edit-order-matrix/edit-order-matrix.component';
import { EditOrderSelectGoodsPlainComponentComponent } from './pages/orders/edit/edit-order-select-goods-plain-component/edit-order-select-goods-plain-component.component';
import { GroupsModalComponent } from './components/goods/groups-modal/groups-modal.component';
import { GroupsTreeComponent } from './components/goods/groups-tree/groups-tree.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomePageComponent } from './pages/home/home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MainMenuComponent } from './layout/main-menu/main-menu.component';
import { MaterialModule } from './material.module';
import { NetworkIndicatorComponent } from './components/misc/network-indicator/network-indicator.component';
import { NotificationsComponent } from './pages/notifications/notifications/notifications.component';
import { OrderCommitButtonComponent } from './components/orders/order-commit-button/order-commit-button.component';
import { OrderConfirmDialogComponent } from './components/orders/order-confirm-dialog/order-confirm-dialog.component';
import { OrderEditDebugComponent } from './pages/orders/edit/order-edit-debug/order-edit-debug.component';
import { OrderInfoComponent } from './pages/orders/info/order-info/order-info.component';
import { OrderInfoLinesComponent } from './pages/orders/info/order-info-lines/order-info-lines.component';
import { OrderInfoTableComponent } from './pages/orders/info/order-info-table/order-info-table.component';
import { OrderListComponent } from './pages/orders/info/order-list/order-list.component';
import { OrderListItemComponent } from './pages/orders/info/order-list-item/order-list-item.component';
import { OrderStatusIndicatorComponent } from './components/orders/order-status-indicator/order-status-indicator.component';
import { OrsInfoComponent } from './pages/ors/ors-info/ors-info.component';
import { OrsRestsComponent } from './pages/ors/ors-rests/ors-rests.component';
import { PointOrdersComponent } from './pages/orders/point-orders/point-orders.component';
import { QuantityDialogPlainComponent } from './pages/orders/edit/quantity-dialog/quantity-dialog.component';
import { QuestCustomDependSelectComponent } from './pages/quests/quest-custom-depend-select/quest-custom-depend-select.component';
import { QuestCustomSelectComponent } from './pages/quests/quest-custom-select/quest-custom-select.component';
import { QuestIconComponent } from './pages/quests/quest-icon/quest-icon.component';
import { QuestItemDayOfWeekComponent } from './pages/quests/quest-item-day-of-week/quest-item-day-of-week.component';
import { QuestItemInfoDialogComponent } from './pages/quests/quest-item-info-dialog/quest-item-info-dialog.component';
import { QuestionareOrsComponent } from './pages/quests/questionare-ors/questionare-ors.component';
import { QuestionareOrsPhotoAddComponent } from './pages/quests/questionare-ors-photo-add/questionare-ors-photo-add.component';
import { QuestionareOrsPhotoComponent } from './pages/quests/questionare-ors-photo/questionare-ors-photo.component';
import { QuestsListComponent } from './pages/quests/quests-list/quests-list.component';
import { SelectOrderClientComponent } from './pages/orders/edit/select-order-client/select-order-client.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { SyncDialogComponent } from './pages/synchronization/sync-dialog/sync-dialog.component';
import { SyncViewComponent } from './pages/synchronization/sync-view/sync-view.component';
import { SynchronizationPageComponent } from './pages/synchronization/synchronization-page/synchronization-page.component';
import { UpdateIndicatorComponent } from './components/misc/update-indicator/update-indicator.component';
import { UserServerComponent } from './components/user-server/user-server.component';
import { ZReportListComponent } from './pages/ors/z-report-list/z-report-list.component';
import { ZreportAddComponent } from './pages/ors/zreport-add/zreport-add.component';
import { ZreportPreviewComponent } from './pages/ors/act-list/zreport-preview/zreport-preview.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { EditOrderAdviceComponent } from './pages/orders/edit/edit-order-advice/edit-order-advice.component';
import { EditOrderSelectGoodsItemComponent } from './pages/orders/edit/edit-order-select-goods-item/edit-order-select-goods-item.component';
import { SwipeDirective } from './swipe.directive';
import { AdminPageComponent } from './pages/admin/admin-page/admin-page.component';
import { AgentDevicesComponent } from './pages/admin/agent-devices/agent-devices.component';
import { AgentDeviceComponent } from './pages/admin/agent-device/agent-device.component';
import { AgentConnPageComponent } from './pages/admin/agent-conn-page/agent-conn-page.component';
import { PropertiesComponent } from './pages/settings-page/properties/properties.component';
import { UserManagmetComponent } from './pages/settings-page/user-managmet/user-managmet.component';
import { ClientDeliveryInfoComponent } from './pages/client/client-delivery-info/client-delivery-info.component';
import { TokenGeneratorComponent } from './pages/admin/token-generator/token-generator.component';
import { OrderLogComponent } from './pages/orders/order-log/order-log.component';

//BrowserAnimationsModule

@NgModule({
  declarations: [
    AppComponent,
    SecurePipe,
    SecureUPipe,
    MainMenuComponent,
    HomePageComponent,
    CatalogPageComponent,
    GroupsModalComponent,
    GroupsTreeComponent,
    NetworkIndicatorComponent,
    UpdateIndicatorComponent,
    UserServerComponent,
    ClientsListComponent,
    ClientInfoDialogComponent,
    ClientInfoComponent,
    OrsInfoComponent,
    OrsRestsComponent,
    ActListComponent,
    ZReportListComponent,
    ConfirmatorDialogComponent,
    ZreportAddComponent,
    QuestsListComponent,
    QuestIconComponent,
    DebetDocListComponent,
    CreateOrderButtonComponent,
    PointOrdersComponent,
    OrderStatusIndicatorComponent,
    CreateAdvanceDialogComponent,
    SynchronizationPageComponent,
    QuestionareOrsComponent,
    QuestIconComponent,
    QuestCustomSelectComponent,
    QuestCustomDependSelectComponent,
    QuestItemDayOfWeekComponent,
    QuestionareOrsPhotoComponent,
    QuestItemInfoDialogComponent,
    QuestionareOrsPhotoAddComponent,
    ZreportPreviewComponent,

    CreateOrderComponent,
    EditOrderComponent,
    QuantityDialogPlainComponent,
    EditOrderInfoComponent,
    EditOrderSelectGoodsPlainComponentComponent,
    OrderCommitButtonComponent,
    OrderConfirmDialogComponent,
    OrderListComponent,
    OrderInfoComponent,
    OrderInfoTableComponent,
    OrderInfoLinesComponent,
    SelectOrderClientComponent,


    SettingsPageComponent,
    EditOrderMatrixComponent,
    SyncViewComponent,
    OrderListItemComponent,
    OrderEditDebugComponent,
    AdvanceListComponent,
    ConfirmDialogComponent,
    AdvanceStatusComponent,
    SyncDialogComponent,
    BotAuthComponent,
    DatasheetComponent,
    NotificationsComponent,
    DateFilterComponent,
    EditOrderAdviceComponent,
    EditOrderSelectGoodsItemComponent,
    SwipeDirective,
    AdminPageComponent,
    AgentDevicesComponent,
    AgentDeviceComponent,
    AgentConnPageComponent,
    PropertiesComponent,
    UserManagmetComponent,
    ClientDeliveryInfoComponent,
    TokenGeneratorComponent,
    OrderLogComponent,

  ],
  imports: [
    MaterialModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      //registerWhenStable:30000'
      registrationStrategy: 'registerImmediately'
    })
  ],

  providers: [
    BrowserModule,
    DatePipe,
    CurrencyPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    // EditOrderComponent,
    // GroupSelectComponent,
    // QuantityDialogPlainComponent,
    // UpdateNotificationBottomSheetComponent,
    // ConfirmDialogComponent,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
