<div class="page-content-block">

    <h2>Синхронизация </h2>
    <i>код устройства для техподдержки: {{deviceId}}</i>

    <!-- span *ngIf="sync.syncStatus"><b> Идет синхронизация </b> <mat-spinner style="display: inline-block;"
    color="accent" diameter="30"></mat-spinner> </span -->

    <!-- <mat-checkbox [(ngModel)]="full">полная</mat-checkbox> -->

    <section class="example-section">
        <h4>Выберите пункты синхронизации</h4>
        <p *ngFor="let sync of categories">
            <mat-checkbox [(ngModel)]="sync.checked">{{sync.name}}</mat-checkbox>

        </p>
    </section>

    <button #holdsync mat-raised-button color="primary" (click)="run()" (mousedown)="mousedown()" (mouseup)="mouseup()"
        (mouseleave)="mouseup()" (touchend)="mouseup()" (touchstart)="mousedown()"
        [disabled]=" !someChecked() || timer> 0">Синхронизировать <span *ngIf="timer > 0">:
            {{timer/1000}}</span></button>

</div> 