
import { StoreNames, StoreValue } from 'idb';
import { filter, from, Observable } from 'rxjs';

import type { IDBPCursorWithValue, IDBPDatabase, IndexNames } from 'idb'

import { Client } from 'src/app/models/client.model';

import { Good, Group, Matrix } from 'src/app/models/sync.model';
import { PagedList } from 'src/app/models/PagedList';
import { myDBSchema } from './myDBSchema';
import { OrderModel } from 'src/app/models/order.model';
import { DebetDoc } from 'src/app/models/DebetDocs';
import { PagedQuests, Quest, QuestType } from 'src/app/models/quests/quest.model';
import { GoodState, PriceType } from 'src/app/models/goods/product';



// сервис больше НЕ встаиваемый.
// получение экземпляра зависит от текущего выбранного пользователя
// поэтому сделал фабрику в ConnectionManagerService
export class DbService {


  // поэтому сделал фабрику в ConnectionManagerService

  priceTypes: PriceType[] = [

    {
      code: "03",
      name: "Магазины"
    },
    {
      code: "04",
      name: "Область"
    },
    {
      code: "05",
      name: "Контрактная"
    },
    {
      code: "13",
      name: "Хорека"
    },
    {
      code: "06",
      name: "Для ОптСекций"
    },

  ]

  goodStates: GoodState[] = [
    // { id: 0, name: "", color: "#FFFFF" },
    { id: 1, name: "MML", color: "#FFFFF" },
    { id: 2, name: "Лидер продаж", color: "#FFFFF" },
    { id: 3, name: "На вывод", color: "#FF8888" },
    { id: 4, name: "Новинка", color: "#88FF88" },
    { id: 5, name: "Постоянный ассортимент", color: "#FFFFF" },
    { id: 6, name: "Предзаказ", color: "#FFFFF" },
    { id: 7, name: "Распродажа", color: "#FFFF88" },
  ]

  constructor(private db: IDBPDatabase<myDBSchema>) {

  }

  close() {
    this.db.close();
  }

  dbGetName() {
    return this.db.name
  }



  //#region  generics

  async add<Name extends StoreNames<myDBSchema>>(tp: Name, item: StoreValue<myDBSchema, Name>): Promise<StoreValue<myDBSchema, Name>> {

    await this.db.add(tp, item);
    return { ...item };
  }


  async clear<Name extends StoreNames<myDBSchema>>(tp: Name): Promise<void> {

    await this.db.clear(tp);

  }


  async put<Name extends StoreNames<myDBSchema>>(tp: Name, item: StoreValue<myDBSchema, Name>): Promise<StoreValue<myDBSchema, Name>> {

    await this.db.put(tp, item);
    return { ...item };
  }

  async get<Name extends StoreNames<myDBSchema>>(tp: Name, key: string): Promise<StoreValue<myDBSchema, Name> | undefined> {

    return this.db.get(tp, key)

  }

  async getAll<Name extends StoreNames<myDBSchema>>(tp: Name): Promise<StoreValue<myDBSchema, Name>[] | undefined> {

    return this.db.getAll(tp)

  }

  async getAllFromIndex<Name extends StoreNames<myDBSchema>, Key extends IndexNames<myDBSchema, Name>>(tp: Name, key: Key, value: any): Promise<StoreValue<myDBSchema, Name>[] | undefined> {

    return this.db.getAllFromIndex(tp, key, value)

  }

  async update<Name extends StoreNames<myDBSchema>>(tp: Name, item: StoreValue<myDBSchema, Name>): Promise<StoreValue<myDBSchema, Name>> {

    await this.db.put(tp, item);
    return { ...item };
  }

  async deleteItem<Name extends StoreNames<myDBSchema>>(tp: Name, item: IDBKeyRange) {
    return this.db.delete(tp, item);
  }

  public async addArray<Name extends StoreNames<myDBSchema>>(tp: Name, array: StoreValue<myDBSchema, Name>[]): Promise<void> {

    try {
      if (!array || array.length <= 0) return

      const tx = this.db.transaction(tp, 'readwrite');
      const store = tx.objectStore(tp);
      for (let i = 0; i < array.length; i++) {

        // НЕ ждём окончания отдельной вставки. будем ждать окончания всей транзакции.
        store.put(array[i]);
      }
      // тут ждём окончания всей транзакции
      await tx.done;

    } catch (error: unknown) {
      console.error(error)
      throw (error)
    }
  }

  public async deleteArray<Name extends StoreNames<myDBSchema>>(tp: Name, array: string[]): Promise<void> {
    if (!array || array.length <= 0) return

    const tx = this.db.transaction(tp, 'readwrite');
    const store = tx.objectStore(tp);
    for (let i = 0; i < array.length; i++) {

      // НЕ ждём окончания отдельной вставки. будем ждать окончания всей транзакции.
      store.delete(array[i]);
    }
    // тут ждём окончания всей транзакции
    await tx.done;
  }

  //#endregion

  //#region groups


  async addGroup(group: Group): Promise<Group> {

    await this.db.put('groups', group);
    return { ...group };
  }



  async getAllGroups(): Promise<Group[]> {

    const widgets = await this.db.getAll('groups');
    return widgets;
  }

  getPGroupsByParent(code: string): Observable<Group[]> {
    return from(this.getGroupsByParent(code))
  }


  private async getGroupsByParent(code: string): Promise<Group[]> {

    const singleKeyRange = IDBKeyRange.only(code);

    return await this.db.getAllFromIndex('groups', 'by-parent', singleKeyRange)

  }

  private async getPTopGroups(): Promise<Group[]> {

    const singleKeyRange = IDBKeyRange.only(0);

    const result = await this.db.getAllFromIndex('groups', 'by-level', singleKeyRange)
    return result

  }

  public getTopGroups(): Observable<Group[]> {
    return from(this.getPTopGroups())
  }

  //#endregion

  //#region goods



  private async getPGoods(filter: GoodsFilter): Promise<PagedList<Good>> {

    let startTime = performance.now()
    let count = 0
    let source: Good[] = []
    let tmp: Good[] = []
    const res: PagedList<Good> = { items: [] as Good[] } as PagedList<Good>
    // parent: Group, page: number = 0, pagesize: number = 25


    // try {
    //   throw new Error('dawd')
    // } catch (error) {
    //   return Promise.reject(new Error('на 0 делить нельзя - ' + error))
    // }



    try {
      //дерево тест

      res.page = filter.page
      res.pageSize = filter.pageSize
      const singleKeyRange = IDBKeyRange.bound(filter.left,
        filter.right, false, false)


      const objectStore = this.db.transaction("goods", "readonly").objectStore("goods");
      const index = objectStore.index("by-left")


      source = await index.getAll(singleKeyRange)
    } catch (error) {
      // throw new Error('Ошибка при поиске по группе товаров' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по группе товаров - ' + error))
    }


    try {
      if (filter.name === undefined || filter.name === '') {
        tmp = source
      } else {
        tmp = source.filter(v => { return v.codeExch === filter.name || searchLike(v.name, filter.name) })
      }
    } catch (error) {
      // throw new Error('Ошибка при поиске по названию -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по названию - ' + error))
    }


    try {
      tmp = searchPrice(filter, tmp);
    } catch (error) {
      // throw new Error('Ошибка при поиске по цене -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по цене - ' + error))
    }

    try {
      tmp = searchByContract(filter, tmp);
    } catch (error) {
      // throw new Error('Ошибка при поиске по контракту -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по контракту - ' + error))
    }

    try {
      tmp = searchByCountry(filter, tmp);
    } catch (error) {
      // throw new Error('Ошибка при поиске по стране -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по стране - ' + error))
    }

    try {
      tmp = searchByState(filter, tmp);
    } catch (error) {
      // throw new Error('Ошибка при поиске по стране -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при поиске по статусу - ' + error))
    }



    count = tmp.length

    let endTime = performance.now()
    const s1 = Math.round(10 * (endTime - startTime)) / 10
    // console.log(`idb get goods: read cursor ${} ms`)
    startTime = performance.now()

    res.total = count

    try {
      const cmp = this.getComparator(filter)

      tmp = tmp.sort((a, b) => cmp(a, b, filter.priceType))
    } catch (error) {
      // throw new Error('Ошибка при сортировке -' + JSON.stringify(error))
      return Promise.reject(new Error('Ошибка при сортировке - ' + error))
    }



    endTime = performance.now()
    const s2 = Math.round(10 * (endTime - startTime)) / 10
    // console.log(`idb get goods: sort ${} ms`)
    startTime = performance.now()

    try {
      let n = filter.page * filter.pageSize
      res.items = []


      while (n < tmp.length && n < (filter.page + 1) * filter.pageSize) {


        const good = tmp[n]

        if (good.stateId) {
          const state = this.goodStates.find(s => s.id == good.stateId)
          if (state) {
            good.stateColor = state.color
          }
        }

        res.items.push(good)
        n++
      }

      endTime = performance.now()

      const s3 = Math.round(10 * (endTime - startTime)) / 10
      const str = `idb get goods: read/sort/pagination ${s1}/${s2}/${s3} ms`
      console.log(str)
    } catch (error) {
      return Promise.reject(new Error('Ошибка при пагинации - ' + error))
      // throw new Error('Ошибка при пагинации -' + JSON.stringify(error))
    }




    return res
  }

  getComparator(filter: GoodsFilter) {
    if (filter.order === "name") {
      if (filter.orderDesc) {
        return this.alfaDescGoodSort
      } else {
        return this.alfaGoodSort
      }
    }


    if (filter.order === "price") {
      if (filter.orderDesc) {
        return this.priceDescGoodSort
      } else {
        return this.priceGoodSort
      }
    }


    return this.alfaGoodSort
  }

  alfaGoodSort(a: Good, b: Good): number {
    if (a === undefined || a.name === undefined) return -1;
    return a.name.localeCompare(b?.name ?? "z")
  }

  alfaDescGoodSort(a: Good, b: Good): number {
    if (b === undefined || b.name === undefined) return -1;
    return b.name.localeCompare(a?.name ?? "z")
  }

  priceGoodSort(a: Good, b: Good, priceType: string): number {
    // if (b === undefined || b.prices === undefined || b.prices.length <= 0) return -1;
    // if (a === undefined || a.prices == undefined || a.prices.length <= 0) return 1;
    if (b === undefined || b.prices === undefined || Object.keys(b.prices).length <= 0) return -1;
    if (a === undefined || a.prices == undefined || Object.keys(a.prices).length <= 0) return 1;

    if (!priceType) {
      priceType = Object.keys(a.prices)[0]
    }

    if (!a.prices[priceType]) {
      return -1
    }
    if (!b.prices[priceType]) {
      return 1
    }

    return a.prices[priceType] > b.prices[priceType] ? 1 :
      a.prices[priceType] < b.prices[priceType] ? -1 : 0
      ;
  }


  priceDescGoodSort(a: Good, b: Good, priceType: string): number {
    // if (a === undefined || a.prices == undefined || a.prices.length <= 0) return 1;
    // if (b === undefined || b.prices === undefined || b.prices.length <= 0) return -1;
    if (a === undefined || a.prices == undefined || Object.keys(a.prices).length <= 0) return 1;
    if (b === undefined || b.prices === undefined || Object.keys(b.prices).length <= 0) return -1;

    if (!priceType) {
      priceType = Object.keys(a.prices)[0]
    }

    if (!a.prices[priceType]) {
      return 1
    }
    if (!b.prices[priceType]) {
      return -1
    }
    // return a.prices[0].price < b.prices[0].price ? 1 :
    //   a.prices[0].price > b.prices[0].price ? -1 : 0
    //   ;
    return a.prices[priceType] < b.prices[priceType] ? 1 :
      a.prices[priceType] > b.prices[priceType] ? -1 : 0
      ;
  }




  // parent: Group, page: number = 0, pagesize: number = 25
  public getGoods(filter: GoodsFilter): Observable<PagedList<Good>> {
    return from(this.getPGoods(filter)) // parent, page, pagesize
  }
  //#endregion

  //#region clients




  private async getClientsPromise() {

    const allClients = await this.db.getAll('clients')
    return allClients
  }

  public getClients(): Observable<Client[]> {
    return from(this.getClientsPromise())
  }

  private async getClientPromise(clientId: string) {
    const client = await this.db.get('clients', clientId)
    return client
  }


  public getClient(clientId: string) {
    return from(this.getClientPromise(clientId))
  }
  //#endregion



  //#region   orders

  public async getOrders(dt1: Date, dt2: Date | undefined): Promise<OrderModel[]> {

    const keyRange = IDBKeyRange.bound(dt1, dt2) // , true, true
    const objectStore = this.db.transaction("orders", "readonly").objectStore("orders");
    const index = objectStore.index("by-date")
    const r = await index.getAll(keyRange)
    return r

  }

  public async getClientOrders(buypointId: string): Promise<OrderModel[]> {
    const keyRange = IDBKeyRange.only(buypointId)
    const objectStore = this.db.transaction("orders", "readonly").objectStore("orders");
    const index = objectStore.index("by-client")
    const ra = await index.getAll(keyRange)

    const r = ra.sort((a, b) => { if (a?.date < b?.date) return 1; if (a?.date > b?.date) return -1; return 0 })

    return r
  }


  public async getCommitedOrders(): Promise<OrderModel[]> {

    const objectStore = this.db.transaction("orders", "readonly").objectStore("orders");
    const index = objectStore.index("by-status")
    const keyRange = IDBKeyRange.only(2)
    const result = await index.getAll(keyRange)
    return result
  }

  //#endregion

  async addMatrix(matrix: Matrix[]) {

    if (!matrix) return
    const tx = this.db.transaction('matrix', 'readwrite');
    const store = tx.objectStore('matrix');
    for (let i = 0; i < matrix.length; i++) {
      // НЕ ждём окончания отдельной вставки. будем ждать окончания всей транзакции.
      store.put(matrix[i]);
    }
    // тут ждём окончания всей транзакции
    await tx.done;
  }


  public async getMatrixByCategory(catId: string | undefined): Promise<Matrix | undefined> {

    if (!catId) {
      return;
    }

    const keyRange = IDBKeyRange.only(catId)
    const objectStore = this.db.transaction("matrix", "readonly").objectStore("matrix");
    const index = objectStore.index("by-category")
    const r = await index.get(keyRange)
    return r;
  }



  public async getClientDocs(buypointId: string): Promise<DebetDoc[]> {
    const keyRange = IDBKeyRange.only(buypointId)
    const objectStore = this.db.transaction("debets", "readonly").objectStore("debets");
    const index = objectStore.index("by-client")
    const r = await index.getAll(keyRange)
    const r2 = r.sort((a, b) => { if (a?.datePay > b?.datePay) return 1; if (a?.datePay < b?.datePay) return -1; return 0 })
    return r
  }

  //#region quests
  //////////////////////////////////////////////////////////////////////////////////////////////// quests


  public findQuests(filter: {
    States: number[] | undefined;
    Page: number;
    PageSize: number;
    QuestType: number | undefined;
    Modified: boolean | undefined;
    BuypointId: string | undefined;
    BuypointName: string | undefined;
  }): Observable<PagedQuests | undefined> {

    return from(this.findQuestsAsync(filter))

  }

  public async findModifiedQuestsAsync(): Promise<Quest[] | undefined> {
    const keyRange = IDBKeyRange.only(1)
    const objectStore = this.db.transaction("quests", "readonly").objectStore("quests");
    const index = objectStore.index("by-modified")
    const r = await index.getAll(keyRange)
    return r
  }

  public async findQuestsAsync(filter: {
    States: number[] | undefined;
    Page: number;
    PageSize: number;
    QuestType: number | undefined;
    Modified: boolean | undefined;
    BuypointId: string | undefined;
    BuypointName: string | undefined;
  }): Promise<PagedQuests | undefined> {



    const objectStore = this.db.transaction("quests", "readonly").objectStore("quests");

    let cursor: IDBPCursorWithValue<myDBSchema, ['quests'], "quests"> | null

    if (filter && filter.BuypointId) {
      const keyRange = IDBKeyRange.only(filter.BuypointId)
      const index = objectStore.index("by-client")
      const c = await index.openCursor(keyRange)
      cursor = c
    } else {

      cursor = await objectStore.openCursor()
    }
    if (!cursor) return {
      items: [],
      total: 0,
      page: filter.Page,
      pageSize: filter.PageSize,
    } as PagedQuests;

    const result: Quest[] = []
    let total = 0;
    let idx = 0;
    while (cursor) {



      let match = true

      if (filter) {

        if (filter.States && filter.States.indexOf(cursor.value.stateId) < 0) {
          match = false
        }

        if (match && filter.Modified !== undefined && (cursor.value.modified === 1) !== filter.Modified) {
          match = false
        }

        if (match && filter.QuestType && cursor.value.questSet?.questTypeId !== filter.QuestType) {
          match = false
        }

        if (match && filter.BuypointName && !searchLike(cursor.value.buypoint?.name.toLowerCase(), filter.BuypointName)) {
          match = false
        }

      }

      // if (cursor.value.statusId === 2) {
      // }



      if (match) {

        total++;
        idx++;

        if (idx >= filter.Page * filter.PageSize && idx < ((filter.Page + 1) * filter.PageSize)) {
          result.push(cursor.value)
        }


      }

      cursor = await cursor.continue();

    }
    return {
      items: result,
      page: filter.Page,
      pageSize: filter.PageSize,
      total: total

    } as PagedQuests


  }




  //TODO: хардкод, но вроде редко добавляем
  public questTypes: QuestType[] = [
    {
      id: 15,
      name: "Обеспечить наличие на полке",
      ors: true,
      auto: false
    },
    {
      id: 14,
      name: "Стикеры на фруктовых винах",
      ors: true,
      auto: false
    },
    {
      id: 13,
      name: "Проверка работы RP Market в торговых точках",
      ors: false,
      auto: false
    },
    {
      id: 12,
      name: "Привезти договор",
      ors: true,
      auto: false
    },
    {
      id: 11,
      name: "Забрать ключ ЕГАИС",
      ors: true,
      auto: false
    },
    {
      id: 10,
      name: "Согласование затрат по продлению ОФД",
      ors: false,
      auto: false
    },
    {
      id: 9,
      name: "Согласование затрат по замене ключа ЕГАИС",
      ors: false,
      auto: false
    },
    {
      id: 8,
      name: "Согласование затрат по замене ФН",
      ors: false,
      auto: false
    },
    {
      id: 7,
      name: "Забрать кассу",
      ors: true,
      auto: false
    },
    {
      id: 6,
      name: "Проблемы с задолженностью",
      ors: true,
      auto: false
    },
    {
      id: 5,
      name: "Отгрузить ТТ",
      ors: true,
      auto: false
    },
    {
      id: 4,
      name: "Проблемы инфообмена",
      ors: false,
      auto: true
    },
    {
      id: 3,
      name: "Долги по z-отчётам",
      ors: false,
      auto: true
    },
    {
      id: 2,
      name: "Анкета ОРС",
      ors: true,
      auto: false
    },
    {
      id: 1,
      name: "Анкета грузополучателя (trade)",
      ors: false,
      auto: false
    }

  ]

  //#endregion





}


export interface GoodsFilter {
  left: number
  right: number
  page: number
  pageSize: number
  name: string | undefined

  order: "name" | "price" | undefined
  orderDesc: boolean

  priceType: string
  startPrice?: number
  endPrice?: number

  contractId?: string
  country?: string

  state?: number

}



function searchLike(text: string | undefined, pattern: string | undefined) {
  if (typeof pattern !== 'string' || text === null) {
    return false
  }

  if (pattern === undefined) { return true }

  if (text == undefined) { return false }

  pattern = pattern.toUpperCase()
  // Remove special chars
  pattern = pattern.replace(
    new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'),
    '\\$1'
  )

  // Replace % and _ with equivalent regex
  while (pattern.indexOf('  ') > -1) {
    pattern = pattern.replace('  ', ' ')
  }

  while (pattern.indexOf(' ') > -1) {
    pattern = pattern.replace(' ', '.*')
  }

  // while (pattern.indexOf('  ') > -1) {
  pattern = pattern.replace(/_/g, '.')
  // }
  //pattern = pattern.replaceAll(' ', '.*').replace(/_/g, '.')
  // Check matches

  return RegExp(pattern, 'gi').test(text.toUpperCase())
}

function searchPrice(filter: GoodsFilter, tmp: Good[]) {
  if (filter.startPrice || filter.endPrice) {
    tmp = tmp.filter(g =>
      // g.prices && g.prices[0].price >= (filter.startPrice ?? 0) && g.prices[0].price <= (filter.endPrice ?? Number.MAX_VALUE)
      g.prices && g.prices[filter.priceType] >= (filter.startPrice ?? 0) && g.prices[filter.priceType] <= (filter.endPrice ?? Number.MAX_VALUE)
    )
  }
  return tmp
}

function searchByContract(filter: GoodsFilter, tmp: Good[]) {
  if (filter.contractId) {
    tmp = tmp.filter(g =>
      g.contractCode && g.contractCode === filter.contractId
    )
  }
  return tmp
}
function searchByCountry(filter: GoodsFilter, tmp: Good[]) {
  if (filter.country) {
    tmp = tmp.filter(g =>
      g.country && g.country.startsWith(filter.country as string)
    )
  }
  return tmp
}

function searchByState(filter: GoodsFilter, tmp: Good[]) {
  if (filter.state) {
    tmp = tmp.filter(g =>
      g.stateId && g.stateId === filter.state
    )
  }
  return tmp
}


