<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="hasError">
  <h2 class="warning">
    возникла ошибка при загрузке заказа

  </h2>
  <button mat-raised-button (click)="reload()">
    <mat-icon>refresh</mat-icon> обновить
  </button>
</div>

<div *ngIf="orderInfo" class="">
  <!-- <app-order-status-indicator [statusId]="orderInfo.statusId"></app-order-status-indicator> -->
  <!-- <strong>{{orderInfo.number}}</strong> от {{orderInfo.date | date:'dd.MM.yyyy HH:mm'}}<br /> -->
  <span style="font-weight: bold;">{{orderInfo.number}} от {{orderInfo.date | date:'dd.MM.yyyy HH:mm'}}</span>
  <br><br>

  <span *ngIf="buypoint; else ttNotFound">
    {{buypoint.name}}

    <!-- <button *ngIf="buypoint.isOwn" mat-icon-button [routerLink]="['/ors',buypoint.id]" color="primary" class="icon"> -->
    <mat-icon *ngIf="buypoint.isOwn" mat-icon-button [routerLink]="['/ors',buypoint.id]" color="primary"
      class="icon">link</mat-icon>
    <!-- </button> -->

    <!-- <button *ngIf="!buypoint.isOwn" mat-icon-button [routerLink]="['/client',buypoint.id]" color="primary" class="icon"> -->
    <mat-icon *ngIf="!buypoint.isOwn" mat-icon-button [routerLink]="['/client',buypoint.id]" color="primary"
      class="icon">link</mat-icon>
    <!-- </button> -->

  </span>

  <span *ngIf="orderInfo.firm"> <br> <span class="primary">Организация: </span> {{orderInfo.firm}} </span>
  <br>
  <span class="primary">Сумма: </span>
  {{orderInfo.summa | currency:'RUB':'symbol':'1.2-2':'ru'}} <span class="warn"
    *ngIf="orderInfo.summa1C !== orderInfo.summa"> (1с: {{orderInfo.summa1C | currency:'RUB':'symbol':'1.2-2':'ru'}})
  </span>
  <br>
  <span class="primary" *ngIf="orderInfo.dateDelivery">Заказ на </span> {{orderInfo.dateDelivery | date: 'dd.LL.yyyy'}}
  <span class="primary" *ngIf="orderInfo.deliveryComment"> ({{orderInfo.deliveryComment}}) </span>


  <span *ngIf="orderInfo.note">Коментарий:
    <span class="primary">({{orderInfo.note}})</span>
  </span>
  <span class="warn" *ngIf="orderInfo.error "><br>Ошибка: {{orderInfo.error}} </span>

  {{orderInfo.isWebbed ? " web " : ""}}
  {{orderInfo.is1Ced ? " 1c " : ""}}



  <ng-container *ngIf="orderInfo.orders1c?.length ">
    <br>
    <ng-container *ngIf="preOrder">
      <mat-chip-listbox [(ngModel)]="preOrder.id" selectable="false" hideSingleSelectionIndicator="true">
        <mat-chip-option *ngIf="!check1cItems()" color="accent" [value]="orderInfo.id" (click)="preOrder = orderInfo">
          <app-order-status-indicator style="display: inline-block; background-color: white; border-radius: 50%;"
            [statusId]="orderInfo.statusId" [onlyIcon]="true"></app-order-status-indicator>
          {{orderInfo.number}}</mat-chip-option>

          <mat-chip-option *ngFor="let preord of orderInfo.orders1c;" (click)="preOrder = newPreord(preord)"
          [value]="preord.id">
          <app-order-status-indicator style="display: inline-block; background-color: white; border-radius: 50%;"
            [statusId]="preord.statusId" [onlyIcon]="true"></app-order-status-indicator>
          {{preord.number}}
        </mat-chip-option>

      </mat-chip-listbox>

    </ng-container>
    <div *ngIf="!orderMain() && preOrder">
      <!-- <app-order-status-indicator style="display: inline-block;"
        [statusId]="preOrder.statusId"></app-order-status-indicator> -->
      <div *ngIf="preOrder?.firm"> <span class="primary"> Организация: </span>
        {{preOrder.firm}} <br></div>
      <strong *ngIf="preOrder?.dateDelivery"><span class="primary">Доставка </span>
        {{preOrder.dateDelivery
        | date:'dd.LL.yyyy'}}</strong>

      <div *ngIf="preOrder?.note" style="color: darkorange;">Коментарий: {{preOrder.note}}</div>
      <!--  class="warn" -->
      <div *ngIf="preOrder?.error" class="warn"><br>Ошибка: {{preOrder.error}}</div>
    </div>

  </ng-container>



  <ng-template #ttNotFound>
    {{orderInfo.buypoint}}
  </ng-template>

  <hr>

  <div *ngIf="preOrder">
    <app-order-info-table [(orderInfo)]="preOrder" [main]="orderMain()">
      <!-- [main]="orderInfo.id === preOrder.id" -->
    </app-order-info-table>
  </div>
