import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  @Input() date1: Date = new Date()
  @Input() date2: Date = new Date()

  @Output() date1Change = new EventEmitter<Date>();
  @Output() date2Change = new EventEmitter<Date>();
  @Output() dateChange = new EventEmitter<boolean>();
  // public completeConfirm(ord: OrderModel) {
  //   this.orderChange.emit(ord)
  // }

  preiodDate: boolean = false

  constructor(private localStorage: LocalStorageService) {
    this.preiodDate = localStorage.searchOptions.soloDate
  }

  changed() {
    if (!this.date1 || !this.date2) return
    if (!this.preiodDate) {
      this.date2 = this.date1
    }

    if (this.date1 > this.date2) {
      this.date1 = this.date2
    }

    this.date1Change.emit(this.date1)
    this.date2Change.emit(this.date2)
    this.dateChange.emit(true);

  }

  changeDate(plus: boolean) {
    if (plus) {
      // this.date1.setDate(this.date1.getDate() + 1)
      this.date1 = new Date(this.date1.getFullYear(), this.date1.getMonth(), this.date1.getDate() + 1)
    }
    else {
      // this.date1.setDate(this.date1.getDate() - 1)
      this.date1 = new Date(this.date1.getFullYear(), this.date1.getMonth(), this.date1.getDate() - 1)
    }
    this.date2 = this.date1
    this.changed()
  }

  saveLocal() {
    this.localStorage.searchOptions.soloDate = this.preiodDate
    this.localStorage.save()
  }



}
