import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterSoftCheque } from 'src/app/filters/SoftChequeFilter';
import { Client } from 'src/app/models/client.model';
import { SoftCheque } from 'src/app/models/Ukrop/softCheque';
import { ZReport } from 'src/app/models/Ukrop/zreport';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';
import { ZreportPreviewComponent } from './zreport-preview/zreport-preview.component';
import { Observable, catchError, finalize, forkJoin, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Department } from 'src/app/models/Ukrop/Department';
import { FormControl } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/services/confirm/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/services/confirm/ConfirmDialogData';

@Component({
  selector: 'app-act-list',
  templateUrl: './act-list.component.html',
  styleUrls: ['./act-list.component.scss']
})
export class ActListComponent implements OnInit {

  @Input() client: Client | undefined
  @Input() department: Department | undefined
  acts: SoftCheque[] = []
  zReports: ZReport[] = []
  loading: boolean = true
  error: boolean = false
  file = new FormControl()
  progress: number = 0
  loadCheckId: number = 0
  variantView: string = 'list'

  constructor(
    private api: UkropClientService,
    private dialog: MatDialog,
    private snack: MatSnackBar
  ) { }

  ngOnInit(): void {

    if (this.client && this.department) {
      const filterZReports: FilterSoftCheque = {
        companyInn: this.client.inn,
        companyKpp: this.client.kpp,
        // periodStart: '2021-12-23',
        // periodEnd: this.datePipe.transform(new Date(), 'YYYY-MM-dd') as string
      }


      const filterSoftCheque: FilterSoftCheque = {
        departmentId: this.department.Id
        // companyInn: this.client.inn,
        // companyKpp: this.client.kpp,
        // periodStart: '2021-12-23',
        // periodEnd: this.datePipe.transform(new Date(), 'YYYY-MM-dd') as string
      }
      const fork = forkJoin([
        this.api.findSoftCheque(filterSoftCheque),
        this.api.findZReports(filterZReports)
      ])

      fork
        .pipe(
          catchError((err) => { this.error = true; return of(err) }),
          finalize(() => { this.loading = false })
        )
        .subscribe(x => {
          if (this.error) {
            this.snack.open(`Не удалось загрузить акты - ${x.message}`, 'Закрыть', { duration: 5000 })
            return
          }

          this.acts = x[0];
          this.zReports = x[1];

        })
      // this.api.findSoftCheque(filter).subscribe(act => this.acts = act)

      // this.api.findZReports(filter).subscribe(rep => { this.zReports = rep })
    }

  }


  downloadFile(id: number) {
    const name = `${id}.pdf`
    this.api.downloadAct(id).subscribe(x => this.transformFile(x, name))
  }

  transformFile(blob: Blob, fileName: string): void {
    const a = document.createElement('a')
    const objectUrl = URL.createObjectURL(blob)
    a.href = objectUrl
    a.download = fileName
    a.click()
    URL.revokeObjectURL(objectUrl)
  }

  imgPreview(act: SoftCheque) {
    const zrep = this.zReports.find(x => x.SoftChequeId === act.Id || (act.IdOld && x.SoftChequeId === act.IdOld))
    const zreps = this.zReports.filter(x => x.SoftChequeId === act.Id || x.SoftChequeId === act.IdOld)
    const result = this.dialog.open(ZreportPreviewComponent, { data: zrep })

    result.afterClosed().subscribe((x: ZReport) => {
      if (x) {
        let i = this.zReports.findIndex(z => z.Id == x.Id)
        this.zReports[i] = x
      }
    })
  }

  haveZrep(act: SoftCheque) {
    if (this.zReports.find(x => x.SoftChequeId == act.Id || (act.IdOld && x.SoftChequeId == act.IdOld))) {
      return true
    }
    return false
  }

  statusreport(act: SoftCheque) {
    const find = this.zReports.find(x => x.SoftChequeId === act.Id || (act.IdOld && x.SoftChequeId == act.IdOld))
    if (find) return find.ReportStatusId
    return 0
  }

  setChequeStatusConfirm(cheque: SoftCheque, status: number) {
    const res = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Подтверждение повторной выгрузки',
        question: `Вы точно хотите перевыгрузить акт аренды ${cheque.Id} за ${formatDate(cheque.ChequeDate, 'dd.MM.YYYY', 'ru-RU')}`,
        confirmButtonText: 'Да'
      } as ConfirmDialogData
    })

    res.afterClosed().subscribe(x => { if (x?.result) { this.setChequeStatus(cheque, status) } })

  }

  setChequeStatus(cheque: SoftCheque, status: number) {
    const old = cheque.Status
    const oldId = cheque.StatusId
    cheque.Status = 'обновление'
    cheque.StatusId = -1

    // this.crmSvc.setChequeStatus(cheque.Id, status).subscribe(
    this.api.setDocumentStatus(cheque.Id, status).subscribe(
      (cl) => {
        cheque.Status = cl.documentStatus
        cheque.StatusId = cl.documentStatusId
        // this.find()
      },
      (err) => {
        this.snack.open('Ошибка смены статуса чека', 'закрыть', { duration: 3000 })
        if (err.error && err.error.Status) {
          cheque.Status = err.error.Status
          cheque.StatusId = err.error.StatusId
        } else {
          cheque.Status = old
          cheque.StatusId = oldId
        }
      }
    )
  }


  uploadFile(event: any, cheque: SoftCheque) {
    try {
      const file = (event.target as HTMLInputElement).files?.item(0)
      this.file.setValue(file)
      this.addUserTask(cheque.Id)
    } catch (error) {
      this.snack.open(`Ошибка загрузки фала - ${error}`, 'Закрыть', { duration: 5000 })
    }

  }



  addUserTask(chequeId: number) {
    const limit = 5.05
    if (this.file.value.size > limit * 1024 * 1024) {
      this.snack.open(`Размер файла не должен превышать ${limit} Мб`, 'Закрыть', { duration: 6000 })
      return
    }
    if (this.department && this.client) {
      this.loadCheckId = chequeId
      this.api
        .addUserTask(new Date(), this.client.inn, this.client.kpp, 2, this.file.value, chequeId)
        .subscribe(x => {
          if (x.type == HttpEventType.UploadProgress) {
            this.progress = Math.round(x.loaded / (x.total ?? 1) * 100)
          }
        },
          err => { this.snack.open(`Что то пошло не так: ${err}`, 'Закрыть', { duration: 5000 }); this.progress = 0; this.file.reset(); this.loadCheckId = 0 },
          () => { this.snack.open('Чек ИП по аренде загружен', 'Закрыть', { duration: 3000 }); this.progress = 0; this.file.reset(); this.loadCheckId = 0; this.ngOnInit(); })
    }
  }



}


