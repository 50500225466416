import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection, Server, User, UserData } from 'src/app/models/server';

import jwt_decode from 'jwt-decode';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { RpaIdClientService } from 'src/app/services/net/rpa-id-client.service';

@Component({
  selector: 'app-bot-auth',
  templateUrl: './bot-auth.component.html',
  styleUrls: ['./bot-auth.component.scss']
})
export class BotAuthComponent implements OnInit {
  token = "";
  user: UserData | undefined;
  error = false
  message = ""
  server = ""
  agentCode = ""
  servers: Server[] = []



  /**
   *
   */

  constructor(private route: ActivatedRoute,
    private api: RpaIdClientService,
    public connMgr: ConnectionManagerService,
    private router: Router) {


  }

  ngOnInit(): void {


    this.api.getServers().subscribe({
      next: x => {
        this.servers = x;

        if (!x || x.length <= 0) {
          this.error = true
          this.message = "получен пустой список серверов"
          return
        }

        this.route.paramMap.subscribe((params) => {
          this.setToken(params.get('token') as string)
        })

      },
      error: (err) => {
        this.error = true
        this.message = "ошибка загрузки списка"
      }
    })


  }



  setToken(token: string) {
    this.token = token
    this.user = jwt_decode<UserData>(token)

    // не прочитали токен
    if (!this.user) {

      this.error = true
      this.message = "не удалось прочесть сообщение"
      return
    }

    this.agentCode = this.user.Code
    this.server = this.user.Server


    // в токене указан неизвестный код сервера
    const findserv = this.servers.find(x => x.name === this.server)
    if (!findserv) {

      this.error = true
      this.message = "ошибка выбора сервера подключения"
      return
    }


    this.connMgr.addUser(this.user, token, findserv)

    this.router.navigate(["sync"])
    this.server = ''
    this.agentCode = ''
  }


  private genId() {
    return this.server + "-" + this.agentCode;
  }



} 