<mat-form-field class="example-form-field" appearance="fill">
  <mat-label>поиск</mat-label>
  <input matInput type="text" [(ngModel)]="pref.searchOptions.name" (ngModelChange)="runFilter()">
  <button *ngIf="pref.searchOptions.name" matSuffix mat-icon-button aria-label="Clear"
    (click)="pref.searchOptions.name=''; runFilter()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<br>
<!-- <mat-slide-toggle class="example-margin" [(ngModel)]="pref.searchOptions.activeContract" (ngModelChange)="runFilter()">
  Доступен договор
</mat-slide-toggle>

<mat-slide-toggle class="example-margin" [(ngModel)]="pref.searchOptions.noBlock" (ngModelChange)="runFilter()">
  Заблокированные
</mat-slide-toggle> -->


<mat-slide-toggle class="example-margin " [disabled]="debetLoading" [(ngModel)]="pref.searchOptions.hasDebet"
  (ngModelChange)="runFilter()">
  <mat-icon class="orangeIcon icon_middle">notification_important
  </mat-icon>
</mat-slide-toggle>

<mat-slide-toggle class="example-margin" [disabled]="debetLoading" [(ngModel)]="pref.searchOptions.hasOverDebet"
  (ngModelChange)="runFilter()">
  <mat-icon class="redIcon icon_middle">warning</mat-icon>
</mat-slide-toggle>

<button mat-icon-button (click)="runFilter()">
  <mat-icon>search</mat-icon>
</button>

<div class="clients">
  <span>Найдено {{filclients.length}}</span>
  <ol>
    <li *ngFor="let point of filclients" class="point">
      <!--  [ngClass]="hide(point) ? 'hidepoint':''" -->
      <a (click)="info(point)"> {{point.name}}</a>

      <span *ngIf="!selectMode">

        <!-- <mat-icon *ngIf="!point.active" class='extClient' style="color: red;">
          delete</mat-icon>

        <mat-icon *ngIf="point.locked" class='extClient' style="color: red;">
          lock</mat-icon> -->

        <span *ngIf="point.quests" class="task-indicator icon_middle">

          <span class="task-indicator-text">{{point.quests}}</span>
          <mat-icon class="task-indicator-icon">priority_high</mat-icon>

        </span>

        <mat-icon *ngIf="point.debet && point.debet > 0" class="orangeIcon icon_middle">notification_important
        </mat-icon>
        <mat-icon *ngIf="point.overtimeDebet && point.overtimeDebet > 0" class="redIcon icon_middle">warning</mat-icon>

        <mat-icon *ngIf="point.isOwn" class='ownClient icon_middle'>eco</mat-icon>

        <mat-icon *ngIf="!point.isOwn" class='extClient icon_middle'>pan_tool</mat-icon>


        <!-- <mat-icon *ngIf="!point.activeContracts" class='extClient' style="color: gray;">
          block</mat-icon> -->


      </span>
    </li>
  </ol>

</div>



<!-- div style="display: block;" *ngIf="!selectMode">
  <button mat-raised-button (click)="syncClients()">
    синхронизация клиентов
  </button>
</div -->