<button class="marg" *ngIf="!addMode" mat-raised-button color="primary" (click)="setAddMode(true)">Добавить: {{typename}}</button>

<div class="container" *ngIf="addMode">
  <span>Добавление: {{typename}}</span>
  <form (ngSubmit)="submitUser()">

    <!-- Progress Bar -->
    <div class="progress form-group" *ngIf="progress > 0">
      progress {{progress}}
      <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
      </div>

      <mat-progress-bar class="example-margin" color="primary" mode="determinate" [value]="progress">
      </mat-progress-bar>

    </div>

    <div class="form-group">

      <button class="marg" type="button" mat-raised-button (click)="fileInput.click()">
        <mat-icon>add_a_photo</mat-icon>
        Добавить фото
      </button>
      <input hidden #fileInput type="file" id="file" (change)="uploadFile($event)">


    </div>


    <mat-form-field class="marg">
      <mat-label>Дата Z-отчёта</mat-label>

      <input matInput [matDatepicker]="picker" name="actualDate" [formControl]="actualDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker class="B"></mat-datepicker>

      <!-- input matInput placeholder="PДата Z-отчёта" -->
      <!-- mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-hint>Hint</mat-hint -->
    </mat-form-field>

    <br>

    <mat-form-field *ngIf="softCheques?.length" style="min-width: 201px;">
      <mat-label>Акт аренды оборудования</mat-label>
      <mat-select [(ngModel)]="actulaCheque" name="cheque">
        <mat-option *ngFor="let act of softCheques" [value]="act.Id">
          {{act.ChequeDate | date:"dd.MM.YYYY"}} {{act.ChequeAmount | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="form-group warning" *ngIf="error">{{error}}</div>

    <div class="form-group">
      <button style="margin-right: 1em;" mat-raised-button color="accent" (click)="setAddMode(false)">Отмена</button>
      <button mat-raised-button color="primary">Сохранить</button>
    </div>
    <!-- span class="error">{{api.errorMessage}}</span -->
  </form>
</div>