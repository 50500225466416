import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncClientsJob extends SyncRealDataJob {

    override async clean(): Promise<void> {
        await this.db.clear("clients")
         
    }
    async save(r: SyncFetch) {

        const add = r.items.filter(a => a.client && a.state === 1).map(a => a.client!.buyPoint);
        const del = r.items.filter(a => a.client && a.state === 2).map(a => a.id);
        await this.db.deleteArray('clients', del);
        await this.db.addArray('clients', add);
    }

}
