import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SyncStartQuery } from 'src/app/filters/SyncFilter';
import { Connection, User } from 'src/app/models/server';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-agent-devices',
  templateUrl: './agent-devices.component.html',
  styleUrls: ['./agent-devices.component.scss']
})
export class AgentDevicesComponent {


  data: any = ''
  conn: Connection | undefined;
  user: User | undefined;

  constructor(private api: RpaHttpService,
    private cm: ConnectionManagerService,
    private snack: MatSnackBar) {



    const subUser = cm.activeUserchange().subscribe(x => {

      this.conn = x?.connections?.find(x => x.active)
      this.user = x

      if (!this.user) {
        return
      }

      const start: SyncStartQuery = {
        kinds: [],
        full: false,
        deviceUID: "",
        refreshToken: this.user.accessToken
      }

      api.post("/v2/api/admin/GetAgents", start).subscribe({
        next: (data) => {
          this.data = data
        }
      })


    })



  }
}
