import { Component, Input } from '@angular/core';
import { SyncJob } from 'src/app/models/sync.model';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-sync-view',
  templateUrl: './sync-view.component.html',
  styleUrls: ['./sync-view.component.scss']
})
export class SyncViewComponent {
  @Input() job: SyncJob | undefined
  isAdmin: boolean = false
  constructor(private conmng: ConnectionManagerService) {
    this.isAdmin = conmng.isAdmin()
  }


}
