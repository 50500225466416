import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrderLine, OrderModel, Orders1c } from 'src/app/models/order.model';
import { OrdersService } from 'src/app/services/data/orders.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-order-list-item',
  templateUrl: './order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss']
})
export class OrderListItemComponent implements OnInit {
  @Input() order!: OrderModel
  @Output() orderChange = new EventEmitter<OrderModel>();


  private ord: OrdersService | undefined

  itemsMinus: boolean = false
  orders1cTotal: number = 0


  /**
   *
   */
  constructor(public connMgr: ConnectionManagerService,
    private router: Router,) {

    const db = connMgr.getCurrentDb()
    this.ord = new OrdersService(db)
  }

  ngOnInit(): void {
    this.order.warn = this.count1cWarnings(this.order)

    this.order.totalAmount = this.order.items?.map(i => i.amount).reduce((sum, val) => sum + val, 0)
    this.order.summa = this.order.items?.map(i => i.summa).reduce((sum, val) => sum + val, 0)

    if (this.order.orders1c && this.order.orders1c.length) {
      this.order.orders1c.forEach((ord1c) => {

        ord1c.amount = this.order.items?.filter(x => x.order1cId === ord1c.id).map(x => x.amount1C).reduce((a, b) => a + b, 0)
        this.orders1cTotal += ord1c.amount


        ord1c.summa = this.order.items?.filter(x => x.order1cId === ord1c.id).map(x => x.summa1C).reduce((a, b) => a + b, 0)


      })

      if (this.orders1cTotal !== this.order.totalAmount ) {
        this.itemsMinus = true
      }


    }

  }
  count1cWarnings(order: OrderModel) {
    if (!order.orders1c || !order.orders1c.length) {
      return false
    }

    return order.orders1c.find(x => x.note?.trim()) ? true : false
  }

  copyOrder(order: OrderModel, allitems: boolean = true): void {

    if (!this.ord) { return }
    let items: OrderLine[] = []
    if (allitems) {
      items = order.items?.map(x => { return { amount: x.amount, good: x.good, goodId: x.goodId, summa: x.summa, price: x.price } as OrderLine })
    }
    else {
      items = order.items?.filter(x => x.amount1C === 0 || x.price1C === 0 || x.summa1C === 0).map(x => { return { amount: x.amount, good: x.good, goodId: x.goodId, summa: x.summa, price: x.price } as OrderLine })
    }

    const copy = {
      id: '',
      agent: order.agent,
      agentId: order.agentId,
      buypoint: order.buypoint,
      buypointId: order.buypointId,
      date: new Date(),
      dateDelivery: undefined, // да, undefined
      deliveryComment: `КОПИЯ ${order.number ?? ''} ${order.deliveryComment}`,
      filialId: order.filialId,
      filial: order.filial,
      firm: order.firm,
      items: items,
      statusId: 1,
      summa: order.items?.map(x => x.price * x.amount).reduce((a, b) => a + b, 0),

      vendorId: order.vendorId
    } as OrderModel

    this.ord.createOrder(copy)

      .subscribe({
        next: (order) => {
          void this.router.navigate(['/edit-order', order.id])
        },
        error: (serror) => {

          //  this.loading = false
          //   this.snack.open(serror, 'ok', { duration: 3800 })
        },
      })
  }



  check1cItems() {
    const allitems = this.order?.items?.length
    const items1c = this.order?.items?.filter(x => x.order1cId).length
    const state = (this.order.statusId === 1 || this.order.statusId === 2 || this.order.statusId === 11)
    if (items1c !== allitems && !state) return true

    return false
  }

  checkItemslength() {
    const items1c = this.order?.items?.filter(x => x.order1cId).length
    if (!items1c) return true
    return false
  }





  public completeConfirm(ord: OrderModel) {
    this.orderChange.emit(ord)
  }

}
