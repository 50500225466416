import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZReport } from 'src/app/models/Ukrop/zreport';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';

@Component({
  selector: 'app-zreport-preview',
  templateUrl: './zreport-preview.component.html',
  styleUrls: ['./zreport-preview.component.scss']
})
export class ZreportPreviewComponent {

  constructor(
    private dialogRef: MatDialogRef<ZreportPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public zReport: ZReport,
    private ukropApi: UkropClientService,
    private snack: MatSnackBar

  ) { }


  getStatus(status: number) {
    switch (status) {
      case 1:
        return 'Новый'
      case 2:
        return 'Принят'
      case 3:
        return 'Отклонен'
      case 4:
        return 'Передан'
      case 5:
        return 'Доставлен'
      default:
        return `Неизвестно (${status})`
    }
  }

  imageUrl(report: ZReport): string {
    return this.ukropApi.zReportUrl(report.FileUrl)

  }

  setStatus(statusId: number) {

    this.ukropApi.setStatusSoftCheque(this.zReport.Id, statusId)
      .subscribe({
        next: x => {
          // this.zReport = x;
          this.dialogRef.close(x)
        },
        error: err => {
          this.snack.open(`Ошибка смены статуса - ${err}`, 'Закрыть', { duration: 5000 })
        }
      })


  }



}
