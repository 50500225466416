<!--button mat-icon-button>
    <span style="color: red;">
        <mat-icon>network_check</mat-icon>
    </span>
</button>
<span>
    <button mat-icon-button>
        <span style="color: lime;">
            <mat-icon>wifi</mat-icon>
        </span>

    </button>
</span>
<button mat-icon-button>
    <span style="color: orangered;">
        <mat-icon>wifi_off</mat-icon>
    </span>

</button>

<span>
    state: {{online}}
</span -->

<button mat-icon-button (click)="checkConnect()">


    <span *ngIf="online === undefined; else branch">
        <span style="color: red;">
            <mat-icon>network_check</mat-icon>
        </span>
    </span>
    <ng-template #branch>
        <span *ngIf="online" style="color: lime;">
            <mat-icon>wifi</mat-icon>
        </span>
        <span *ngIf="!online" style="color: orangered;">
            <mat-icon>wifi_off</mat-icon>
        </span>
    </ng-template>
</button>