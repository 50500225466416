
import { Injectable, } from '@angular/core';
import { Observable, Subject,  from,  of } from 'rxjs';

import { SyncItemType, SyncJob } from 'src/app/models/sync.model';


import { RpaClientService } from '../net/rpa-client.service';
import { SyncHeaderJob } from './SyncHeaderJob';

import { ConnectionManagerService } from '../storage/connection-manager.service';
import { SyncDoneJob } from './SyncDoneJob';
import { SyncTimerService } from './Sync-timer.service';

import { SyncUserConnectionService } from './sync-user-connection.service';


@Injectable({
  providedIn: 'root'
})
export class SyncService {


  public jobs: SyncJob[] = []
  public running = false
  public syncEnd: Subject<boolean> = new Subject()

  constructor(

    private cm: ConnectionManagerService,
    private httpCredit: RpaClientService,
    private syncTimerService: SyncTimerService,
    private syncUser: SyncUserConnectionService,
  ) { }

  public run(para: SyncRunParameters): Observable<boolean> {
    if (this.running) {
      return of(false)
    }

    return from(this.runSync(para))
  }


  reset() {
    // console.error("МЕТОД НЕ РЕАЛИЗОВАН. Заглушка делает вид, что произошла отмена")
    this.running = false
  }

  async runSync(para: SyncRunParameters): Promise<boolean> {

    const db = await this.cm.getDb()

    if (!db) return false

    this.running = true
    this.jobs = []


    await this.syncUser.updConn()

    const vjobber = new SyncHeaderJob("head", "Запуск", this.httpCredit, db, this.cm)



    this.jobs.push(vjobber)

    vjobber.setCat(para)
     
    const started = await vjobber.doWork(para.full)

    if (!started) {
      await timeout(500)
      this.running = false

      this.running = false
      return false // TODO something!
    }

    this.jobs.push(...vjobber.result);

    let done = false
    let ok = true
    while (!done) {

      const next = this.jobs.find(j => !j.completed)
      if (next === undefined) {
        done = true
        break
      }

      const r = await next.doWork(para.full)

      if (!r) {
        ok = false
        break // TODO проверить. по-идее, после ошибки продолжать нельзя
      }

    }

    if (ok) {
      const djobber = new SyncDoneJob("done", "Завершение", this.httpCredit, db)
      this.jobs.push(djobber)
      djobber.setCat(para)
      await djobber.doWork(para.full)

    }

    await timeout(500)
    this.running = false
    this.syncEnd.next(true)
    this.syncTimerService.setTimer(10000)
    return ok


  }


  // public async syncAll(sync: SyncCategory[]) {

  //   this.syncStatus = true
  //   this.logState.next("Начало синхронизации")

  //   try {
  //     if (sync.find(x => x.id === 'catalog' && x.checked)) {
  //       await this.syncProducts()
  //     }
  //     if (sync.find(x => x.id === 'clients' && x.checked)) {
  //       await this.syncClients()
  //     }

  //   } catch (e: any) {
  //     this.syncStatus = false
  //     const a = JSON.stringify(e.message)
  //     this.logState.next(a)
  //   }

  // }


  public async syncProducts() {
    throw Error("not implemented")
    //   this.readyGoods = false
    //   this.syncStatus = true
    //   await this.dbs.deleteGood()
    //   await this.dbs.deleteGroups()
    //   this.httpCredit.syncStart('goods').
    //     subscribe({
    //       next: async x => {
    //         await this.productFetch(x)
    //       },
    //       error: err => { console.log(err); this.syncStatus = false; this.errorSub.next(err.message) },
    //     })
  }


  // private async productFetch(syncStart: SyncStart) {
  //   this.valueCatalog = 0
  //   for (let i = 0; i < syncStart.partitionCount; i++) {

  //     const filter: SyncFetchFilter = {
  //       datakey: syncStart.dataKey,
  //       partition: i
  //     }

  //     const partion = await this.httpCredit.syncFetchPromise(filter)//.catch((err) => { console.log(err); this.errorSub.next(err.message) })
  //     this.valueCatalog = 100 / syncStart.partitionCount * (i + 1)

  //     await this.addProducts(partion)

  //     //      this.logState.next(`Добавлена порция данных ${i + 1} из ${syncStart.partitionCount}`)

  //     if (i + 1 === syncStart.partitionCount) {
  //       this.readyGoods = true;
  //       this.logState.next('Синхронизация товаров завершена')
  //       this.allReady()
  //     }
  //   }
  // }

  // private async addProducts(partition: SyncFetch) {

  //   if (partition.groups && partition.groups.length > 0) {
  //     this.logState.next(`${partition.partId} группы  номенклатуры ${partition.groups.length}`)
  //     for (let i = 0; i < partition.groups.length; i++) {
  //       await this.dbs.addGroup(partition.groups[i])
  //     }
  //   }

  //   if (partition.goods && partition.goods.length > 0) {
  //     this.logState.next(`${partition.partId} номенклатура ${partition.goods.length}`)
  //     await this.dbs.addGoods(partition.goods)
  //   }

  //   if (partition.matrix && partition.matrix.length > 0) {
  //     this.logState.next(`${partition.partId} матрицы ${partition.matrix.length}`)
  //     await this.dbs.addMatrix(partition.matrix)
  //   }



  //   // if (index + 1 === all) {
  //   //   this.readyGoods = true;
  //   //   this.allReady()
  //   // }
  // }

  // private allReady() {
  //   if (this.readyClients && this.readyGoods) {
  //     this.syncStatus = false;
  //     this.logState.next('Синхронизация завершена')
  //   }
  // }


}



function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



export interface SyncRunParameters {
  full: boolean,
  categories: SyncItemType[]
}