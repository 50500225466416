
import { SyncFetch } from "src/app/models/sync.model";
import { AbstractSyncDataJob } from "./AbstractSyncDataJob";


export abstract class SyncRealDataJob extends AbstractSyncDataJob {




    override async run(full: boolean): Promise<boolean> {

        if (this.map === undefined)
            return false


            if (full){
                await this.clean()
            }


        const n = Math.ceil(this.map.count / this.pageSize)

        let nettime = 0
        let savetime = 0


        for (let index = 0; index < n; index++) {

            try {

                const t0 = new Date()
                const r = await this.httpCredit.syncFetchPromise({
                    key: this.map.key,
                    kind: this.map.kind,
                    page: index,
                    pageSize: this.pageSize
                })

                const t1 = new Date()
                await this.save(r)

                const t2 = new Date()

                nettime += t1.getTime() - t0.getTime()
                savetime += t2.getTime() - t1.getTime()

                this.step += r.items.length


            } catch (error: any) {

                this.error = true
                this.message = error.message
                this.completed = true
                return false
            }
        }


        this.spent = (this.spent ?? "") + `(net: ${nettime / 1000}  db: ${savetime / 1000}) `



        this.completed = true

        return true

    }

    abstract clean():Promise<void>
    abstract save(r: SyncFetch): Promise<void>



}