<mat-progress-bar [mode]="currentOrder.loading || currentOrder.loadingPrice ? 'indeterminate' : 'determinate'"
  class="progress">
</mat-progress-bar>
<!-- *ngIf="loading"  -->

<div *ngIf="orderInfo; else noOrder">
  <mat-tab-group animationDuration="10ms">

    <!-- info -->
    <mat-tab>
      <ng-template mat-tab-label>
        <span *ngIf="!currentOrder.loading" [matBadge]="orderInfo.totalAmount" matBadgeOverlap="false">
          <mat-icon>info</mat-icon>
        </span>
      </ng-template>

      <ng-template matTabContent>

        <div *ngIf="!currentOrder.loading">
          <app-edit-order-info> </app-edit-order-info>
        </div>
        <div *ngIf="currentOrder.hasError" class="warning">
          <h1>
            возникла ошибка
          </h1>
          <h2>
            {{currentOrder.errorMessage}}
          </h2>
        </div>
      </ng-template>
    </mat-tab>





    <!--  MATRIX -->
    <mat-tab *ngIf="buypoint && buypoint.categoryId && buypoint.categoryId !==''">
      <ng-template mat-tab-label>
        <mat-icon>assignment</mat-icon>
      </ng-template>
      <app-edit-order-matrix [client]="buypoint" >
      </app-edit-order-matrix>
    </mat-tab>


    <!--  ACTUAL price list -->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>shopping_cart</mat-icon>
      </ng-template>
      <!-- <ng-template matTabContent> -->
        <app-edit-order-select-goods-plain-component [client]="buypoint">

        </app-edit-order-select-goods-plain-component>
      <!-- </ng-template> -->
    </mat-tab>
<!-- ADVICE -->
    <mat-tab      >
      <ng-template mat-tab-label>
        <mat-icon>thumb_up</mat-icon>
      </ng-template>
      <app-edit-order-advice ></app-edit-order-advice>

    </mat-tab>

    <!-- BUTTONS -->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>done</mat-icon>
      </ng-template>
      <div class="tab-container">
        <h2>Действия</h2>

        <app-order-commit-button [order]="orderInfo" mode="delete" class="air" (completeConfirm)="onCompleteConfirm()">
        </app-order-commit-button>

        <app-order-commit-button [order]="orderInfo" mode="commit" class="air" (completeConfirm)="onCompleteConfirm()">
        </app-order-commit-button>
      </div>
    </mat-tab>

    <mat-tab *ngIf="isAdmin" >
      <ng-template mat-tab-label>
        <mat-icon style="color: blueviolet;">menu</mat-icon>
      </ng-template>
      <app-order-edit-debug></app-order-edit-debug>

    </mat-tab>

  </mat-tab-group>
</div>

<ng-template #noOrder>

  <h2 *ngIf="!currentOrder.loading" style="color: red;">заказ не найден</h2>
</ng-template>