import { Good, Group, SyncFetch, GoodCountry, GoodContract } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";



export class SyncGoodsJob extends SyncRealDataJob {


    override async clean(): Promise<void> {
        await this.db.clear("groups")
        await this.db.clear("goods")
    }


    async save(r: SyncFetch) {



        const groups = r.items.filter(a => a.goodGroup)
        const addGroups = groups.filter(a => a.state === 1).map(a => a.goodGroup as Group);
        const delGroups = groups.filter(a => a.state === 2).map(a => a.id);
        await this.db.deleteArray('groups', delGroups);
        await this.db.addArray("groups", addGroups);


        const goods = r.items.filter(a => a.good)
        const add = goods.filter(a => a.state === 1).map(a => a.good as Good);
        const del = goods.filter(a => a.state === 2).map(a => a.id);
        await this.db.deleteArray('goods', del);
        await this.db.addArray('goods', add);

        if (this.step + this.pageSize >= this.count) {

            const allGoods = await this.db.getAll('goods')

            if (allGoods?.length) {

                const distCountries = allGoods.map(x => x.country)
                    .filter((value, index, array) => array.indexOf(value) === index).map(x => { return { country: x.trim() } as GoodCountry })

                await this.db.addArray('goodCountries', distCountries)

                const distContracts = allGoods.map(x => { return { contract: x.contract, contractCode: x.contractCode } })
                    .filter((value, index, array) => array.map(x => x.contractCode).indexOf(value.contractCode) === index).
                    map(x => { return { contract: x.contract, contractCode: x.contractCode } as GoodContract })

                await this.db.addArray('goodContracts', distContracts)
            }

        }


    }

}
