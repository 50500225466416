<mat-spinner *ngIf="!docsLoaded; else loaded"></mat-spinner>


<ng-template #loaded>



  <h2 *ngIf="groupedDebts.length">Задолженность</h2>
  <ul>
    <li class="debet" *ngFor="let org of groupedDebts">

      {{org.orgName}}, {{org.agentName}}
      <!-- ({{debet.filial}}) -->

      <span *ngIf="org.debet > 0; else credit" class="primary">Долг: {{org.debet |
        currency:'RUB':'symbol':'1.2-2':'ru'}}</span>

      <ng-template #credit>
        <span style="color: darkgoldenrod;">Переплата: {{ org.debet |
          currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
      </ng-template>

      <div *ngIf="org.over > 0 " class="warn">Просрочено: {{org.over |
        currency:'RUB':'symbol':'1.2-2':'ru'}}</div>

      <div *ngIf="org.advanceCount">АП: {{org.advanceCount}} шт. на сумму
        {{org.advanceSum |
        currency:'RUB':'symbol':'1.2-2':'ru'}} </div>
      <!-- <div *ngIf="advanceSumByOrg(org.orgId)">АП: {{advanceSumByOrg(org.orgId).count}} шт. на сумму
        {{advanceSumByOrg(org.orgId).sum |
        currency:'RUB':'symbol':'1.2-2':'ru'}} </div> -->

    </li>


  </ul>




  <h2>Задолженность по документам</h2>


  <span *ngIf="listFilial.length+listOrgs.length > 2">

    <mat-form-field appearance="outline" class="marg-button">
      <mat-label>Филиал</mat-label>
      <mat-select [(ngModel)]="choosendFilial" (selectionChange)="applyFilter()" [disabled]="showCheckBox">
        <mat-option>Все филиалы</mat-option>
        <mat-option *ngFor="let filial of listFilial;" [value]="filial"> {{filial.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="marg-button">
      <mat-label>Организация</mat-label>
      <mat-select [(ngModel)]="choosenOrg" (selectionChange)="applyFilter()" [disabled]="showCheckBox">
        <mat-option>Все организации</mat-option>
        <mat-option *ngFor=" let org of listOrgs" [value]="org">{{org.name}} </mat-option>
      </mat-select>
    </mat-form-field>

  </span>

  <mat-form-field *ngIf="listAgents.length > 1" appearance="outline" class="marg-button">
    <mat-label>Агент</mat-label>
    <mat-select [(ngModel)]="choosenAgent" (selectionChange)="applyFilter()" [disabled]="showCheckBox">
      <!-- <mat-option>Все агенты</mat-option> -->
      <mat-option *ngFor=" let ag of listAgents" [value]="ag">{{ag.name}} </mat-option>
    </mat-select>
  </mat-form-field>

  <div>

    <button class="marg-button" mat-raised-button (click)="refresh()" [disabled]="showCheckBox">
      <mat-icon>refresh</mat-icon> обновить
    </button>

    <button class="marg-button" *ngIf=" !showCheckBox" mat-raised-button (click)="showCheckBox=true" color="primary"
      [disabled]="!choosenOrg || !choosendFilial || filialLocked">Оплата </button>

    <button class="marg-button" *ngIf="  showCheckBox" mat-raised-button color="accent" (click)="openDialog()"
      [disabled]="choosenSum <= 0 || !choosenOrg || !choosendFilial">
      К оплате: {{choosenSum | currency:'RUB':'symbol':'1.2-2':'ru'}} </button>
    <button mat-raised-button color="basic" *ngIf="showCheckBox" (click)="cancel()">Отмена</button>

  </div>



  <div *ngIf="DebetDocs.length; else nullData">

    <mat-list>

      <ng-container *ngFor="let debet of DebetDocs;let i = index">
        <mat-list-item>
          <span matListItemTitle class="text_display">{{debet.filial}} / <span
              class="text_display">{{debet.organization}}</span>
          </span>
          <span matListItemLine><b>Агент:</b> {{debet.agent}} </span>
          <span matListItemLine><b>Документ:</b> {{debet.type}} № {{debet.number}} </span>
      

          <span matListItemLine><b>Дата:</b> {{debet.date | date:'dd.MM.YYYY'}} <b>Сумма:</b> {{debet.summ |
            currency:'RUB':'symbol':'1.2-2':'ru'}} </span>

          <span matListItemLine
            [ngClass]="(calcDays(debet.datePay, dateNow) <= 7 && calcDays(debet.datePay, dateNow) >= 0)  ? 'priamry' : ''"><b>Дата
              оплаты:</b>
            {{debet.datePay |
            date:'dd.MM.YYYY'}}
          </span>


          <span matListItemLine>
            <span *ngIf="debet.debetSumm >0; else credit"
              [ngClass]=" biggerDate(dateNow, debet.datePay) ? 'warn' : 'primary'">
              <!-- [ngStyle]="{'color': debet.isOver ? 'red' : 'blue'}" -->
              <b>Долг:</b> {{debet.debetSumm |
              currency:'RUB':'symbol':'1.2-2':'ru'}} </span>
            <ng-template #credit>
              <span matListItemLine style="color: darkgoldenrod;"><b>Переплата:</b> {{-debet.debetSumm |
                currency:'RUB':'symbol':'1.2-2':'ru'}} </span>
            </ng-template>

            <!-- <span *ngIf="debet.containsCashOrder"> <b>Есть АП:</b> {{debet.prepaymentSumm |
              currency:'RUB':'symbol':'1.2-2':'ru'}}</span> -->
            <span *ngIf="debet.prepaymentSumm"> <b>Есть АП:</b> {{debet.prepaymentSumm |
              currency:'RUB':'symbol':'1.2-2':'ru'}}</span>

          </span>
          <span matListItemLine *ngIf="debet.comment" style="white-space: normal;"><b>Коментарий:
            </b>{{debet.comment}}</span>

          <mat-checkbox matListItemMeta *ngIf="showCheckBox"
            [disabled]="round(debet.debetSumm-(debet.prepaymentSumm ?? 0), 2) <=0"
            (change)="chooseDocs(debet,$event.checked )"></mat-checkbox>


        </mat-list-item>

        <mat-divider style="margin-top: 10px;"></mat-divider>
        <br>
      </ng-container>

    </mat-list>

  </div>

  <ng-template #nullData>
    <div *ngIf="errorMessage ">Ошибка: {{errorMessage}}</div>
    <div *ngIf="!errorMessage  ">нет данных</div>
  </ng-template>

</ng-template>