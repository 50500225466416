import { AdvanceWrapper, DebetWrapper } from "./DebetDocs"
import { Client } from "./client.model"
import { OrderModel } from "./order.model"
import { Quest } from "./quests/quest.model"

export interface SyncMap {

  items: SyncMapItem[]
  accessToken: string | undefined

}

export interface SyncMapItem {
  kind: SyncItemType
  key: string
  count: number

}
export interface SyncFetch {

  items: SyncFetchItem[]

}


export interface SyncFetchItem {
  id: string
  state: number
  client: SyncClientView | null
  good: Good | null
  goodGroup: Group | null
  quest: QuestWrapper | null
  matrix: Matrix | null
  preorder: PreorderWrapper | null
  debet: DebetWrapper | null
  advance: AdvanceWrapper | null

}

export interface QuestWrapper {
  id: string
  quest: Quest
}



export interface PreorderWrapper {
  id: string
  preorder: OrderModel
}

export interface SyncClientView {
  buyPoint: Client

}

export type SyncItemType = 'Clients' | 'Orders' | 'Quests' | 'Goods' | 'Matrix' | 'Debets' | 'Advances'

export interface Good {
  code: string;
  codeExch: string;
  name: string | undefined;
  active: boolean;
  groupCode: string;
  goodCategory: string;
  goodCategoryCode: string;
  nds: number;
  egais: Egai[] | undefined;
  contract: string;
  contractCode: string;
  minPart: number;
  topSale: boolean;
  quantum: number;
  stm: boolean;
  cert: boolean;
  
  stateId: number | undefined;
  stateName: string | undefined;
  stateColor: string | undefined;
  
  
  measure: string;
  measureBase: string;
  country: string;
  brandNew: string;
  half: boolean;
  separate: boolean;
  hoReCa: string;
  isBrandNew: boolean;
  fgiS_MT: boolean;
  k2: boolean;
  ratioPackage: number;
  created: string;
  brandNewTmp: string;

  prices: Price | undefined;

  displayRest: number;
  displayPrice: number;
  
  displayClientPrice: number | undefined;
  displaySalePrice: number | undefined;

  rests: Rest[] | undefined;
  level: number;
  left: number;
  right: number;
  ordered: number | undefined;
}

export interface Rest {
  filialId: string;
  quantity: number;
}

export interface Price {
  // name: string;
  // price: number;
  [key: string]: number;
}



export interface Egai {
  alccode: string;
  kvasp: string;
  name: string;
  volume: number;
  alcohol: number;
}

export interface Group {
  code: string;
  codeExch: string;
  name: string;
  parent: string;
  level: number;
  active: boolean;
  left: number;
  right: number;
  created: string;
}


export interface Matrix {

  id: string;
  name: string;
  category: string;
  categoryId: string;
  freeSKU: number;
  goodCategories: GoodCategory[];
  goods: MatrixGood[];
}

export interface GoodCategory {
  id: string;
  name: string;

  // считаем на лету, api не отдаёт это свойство
  hidded: boolean
}

export interface MatrixGood {
  id: string;
  name: string;
  categoryId: string;
  blockId: number | undefined;
  goodId: string;
  block: string;
  alccodes: string[];


  // считаем на лету, api не отдаёт это свойства
  point_rests: number | undefined;
  storeRest: number | undefined;
  good: Good | undefined

}

export interface GoodContract {
  contract: string
  contractCode: string
}

export interface GoodCountry{
  country: string
}




export interface SyncCategory {
  id: SyncItemType
  name: string
  url?: string
}

export interface SyncCategoryModel {
  id: SyncItemType
  name: string
  checked: boolean
}

export interface SyncJob {
  id: string
  name: string
  error: boolean
  message: string
  completed: boolean
  step: number
  count: number
  spent: string | undefined


  doWork(full: boolean): Promise<boolean>
  progress(): number
}

export const syncCategories: SyncCategory[] = [
  {
    id: 'Clients',
    name: 'Клиенты',
    url: 'clients'
  },
  {
    id: 'Goods',
    name: 'Каталог',
    url: 'catalog'
  },
  {
    id: 'Quests',
    name: 'Задача',
    url: 'quests',
  },
  {
    id: 'Matrix',
    name: 'Матрица',
  },
  {
    id: 'Orders',
    name: 'Заказы',
    url: 'orders'
  },
  {
    id: 'Debets',
    name: 'Задолженность',
    url: 'clients'
  },
  {
    id: 'Advances',
    name: 'Авансы',
    url: 'advances'
  }
]