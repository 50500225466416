import { Component, OnInit, OnDestroy } from '@angular/core'

import { MatDialog } from '@angular/material/dialog'

import { Client } from 'src/app/models/client.model'
import { OrderLine, OrderModel } from 'src/app/models/order.model'
import { QuantityDialogData, QuantityDialogPlainComponent } from '../quantity-dialog/quantity-dialog.component'
import { SelectOrderClientComponent } from '../select-order-client/select-order-client.component'
import { CurrentOrderService } from 'src/app/services/data/current-order.service'
import { Subscription } from 'rxjs'
import { ErpPrice } from 'src/app/models/goods/product'



@Component({
  selector: 'app-edit-order-info',
  templateUrl: './edit-order-info.component.html',
  styleUrls: ['./edit-order-info.component.scss'],
})
export class EditOrderInfoComponent implements OnInit, OnDestroy {

  orderInfo: OrderModel | undefined

  deliveryComment = ''
  delivery: Date | undefined
  erpPrice?: ErpPrice
  buypoint = ''
  buypointId = ''
  headerChanged = false


  minDate: Date
  subs: Subscription[] = []

  constructor(public dialog: MatDialog,
    public currentOrder: CurrentOrderService,) {

    this.minDate = new Date() //(new Date()).getDate() + (1000 * 60 * 60 * 24));
    this.minDate.setDate(this.minDate.getDate() + 1)
  }


  ngOnInit(): void {

    const s1 = this.currentOrder.orderInfo$.subscribe({
      next: (o) => {
        this.orderInfo = o


        if (o !== undefined) {
          this.buypoint = o.buypoint
          this.buypointId = o.buypointId
          this.deliveryComment = o.deliveryComment

          if (o.dateDelivery instanceof Date) {
            this.delivery = o.dateDelivery
          } else {
            if (o.dateDelivery === undefined) {
              // оставляем неопределенную дату
              //this.delivery = new Date()
            }
            else {
              this.delivery = new Date(Date.parse(o.dateDelivery))
            }
          }

        }


      }
    })

    const s2 = this.currentOrder.buypoint$.subscribe({
      next: (b) => {

        this.buypoint = b?.name ?? ''
        this.buypointId = b?.id ?? ''

      }
    })

    const s3 = this.currentOrder.prices$.subscribe({
      next: pr => {
        this.erpPrice = pr
      }
    })

    this.subs = [s1, s2, s3]



  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe())
    this.subs = []
  }



  onDeliveryChanged(type: string, event: any): void {

    if (!this.orderInfo) return
    this.headerChanged = true
    const dvalue = event?.value
    this.delivery = dvalue
  }

  onCommentChanged(event: Event): void {

    if (!this.orderInfo) return
    this.headerChanged = true
  }

  save(): void {
    if (!this.orderInfo) return


    this.currentOrder.updateHeader(this.deliveryComment, this.delivery, this.buypointId, this.buypoint)

  }

  public deleteItem(itemId: string): void {
    if (!this.orderInfo) return

    this.currentOrder.deleteItem(itemId)

  }

  clickOnItem = (line: OrderLine): void => {

    this.openDialog(line)
  }

  openDialog(line: OrderLine): void {
    const dd = {
      line: line,
      ok: false,

    } as QuantityDialogData

    const dialogRef = this.dialog.open(QuantityDialogPlainComponent, {
      width: '600px',
      data: dd,
    })

    dialogRef.afterClosed().subscribe((result: QuantityDialogData) => {
      if (result?.line != undefined && result.line.amount != undefined && result.line.amount > 0 && result.ok) {


        this.currentOrder.addOrUpdateItem(result.line)

      }
    })
  }


  changeClient() {
    this.openClientDialog()
  }



  openClientDialog(): void {



    const dialogRef = this.dialog.open(SelectOrderClientComponent, {
      width: '90%',
      data: {},
    })

    dialogRef.afterClosed().subscribe((result: Client) => {
      if (result && this.buypointId !== result.id) {

        this.buypoint = result.name
        this.buypointId = result.id
        this.headerChanged = true

      }
    })
  }


  loading: boolean = false
  updOrderPrice() {
    if (!this.orderInfo || !this.erpPrice) return
    this.loading = true
    this.currentOrder.updateOrderItemsPrice(this.orderInfo, this.erpPrice).then(x => {
      this.orderInfo = x;
    })
      .finally(() => { this.loading = false })
  }

}
