import { DatePipe } from '@angular/common'
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { SubscriptionLike,  from } from 'rxjs'
import { OrderModel } from 'src/app/models/order.model'
import { SyncItemType } from 'src/app/models/sync.model'
import { SyncDialogComponent } from 'src/app/pages/synchronization/sync-dialog/sync-dialog.component'
import { OrdersService } from 'src/app/services/data/orders.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'
import { LocalStorageService } from 'src/app/services/storage/local-storage.service'
import { SyncService } from 'src/app/services/sync/sync.service'


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, OnDestroy {
  @Output() completed = new EventEmitter<boolean>()
  public date1: Date
  public date2: Date
  public loading = false
  public hasError = false
  public errorMessage = ''
  public orders: OrderModel[] = []
  // private allOrders: OrderModel[] = []

  preiodDate  = true
  // delivDate: boolean = false
  // groupOrders: boolean = true

  sub: SubscriptionLike | undefined
  subscriptions: SubscriptionLike[] = []

  private ord: OrdersService | undefined

  constructor(
    public dialog: MatDialog,

    public connMgr: ConnectionManagerService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private sync: SyncService,
    public localStrg: LocalStorageService
  ) {


    const tempDate = new Date();
    this.date1 = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());
    this.date2 = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());


  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const dt1 = params.get('dt1')

      if (dt1 !== null && dt1 !== undefined && dt1 !== '') {
        const dat = new Date(Date.parse(dt1))
        this.date1 = dat

        const dt2 = params.get('dt2')
        if (!dt2) {
          this.date2 = new Date(2099, 1, 1)
        } else {
          const dat2 = new Date(Date.parse(dt2))
          this.date2 = dat2
        }

        this.preiodDate = params.get('period') === 'true' ? true : false
      }

      if (this.date1 > this.date2) {
        this.date1 = this.date2
      }

      const r = this.connMgr.activeServerObservable().subscribe(() => {

        from(this.connMgr.getDb()).subscribe((db) => {
          if (!db) return
          this.ord = new OrdersService(db)
          this.updateData()
        })

      })
      this.subscriptions.push(r)

      const s = this.sync.syncEnd.subscribe(x => this.updateData())
      this.subscriptions.push(s)

    })
  }


  swipe(plus: boolean) {
    if (plus) {
      this.date1 = new Date(this.date1.getFullYear(), this.date1.getMonth(), this.date1.getDate() + 1)
    }
    else {
      this.date1 = new Date(this.date1.getFullYear(), this.date1.getMonth(), this.date1.getDate() - 1)
    }

    this.date2 = this.date1
    this.changed()
  }

  total = 0
  commited = 0
  commitedSumm = 0
  totalSumm = 0

  needSync = 0

  mapData(data: OrderModel[]): void {
    // this.allOrders = data
    this.orders = data
    // this.groupOrds()

    this.resetCounters()

  }

  dateModeChange() {
    this.localStrg.save()
  }

  changed(): void {
    // if(!this.date1 || !this.date2 ) return
    // if (!this.preiodDate) {
    //   this.date2 = this.date1
    // }

    // if (this.date1 > this.date2) {
    //   this.date1 = this.date2
    // }


    const dt1 = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
    const dt2 = this.datePipe.transform(this.date2, 'yyyy-MM-dd')
    void this.router.navigate(['/orders', dt1, dt2])
  }

  changeDate(plus: boolean) {
    if (plus) {
      this.date1.setDate(this.date1.getDate() + 1)
    }
    else {
      this.date1.setDate(this.date1.getDate() - 1)
    }
    this.date2 = this.date1
    this.changed()
  }

  updateData() {
    this.resetCounters()
    this.hasError = false

    if (!this.ord) {
      this.orders = []
      return
    }

    this.loading = true

    if (this.sub !== undefined) {
      this.sub.unsubscribe()
    }

    let dt2: Date = new Date(this.date2)
    dt2 = new Date(dt2.setDate(dt2.getDate() + 1));

    if (!this.localStrg.searchOptions.orderByDeliveryDate) {
      this.sub = this.ord.getOrders(this.date1, dt2)
        .subscribe({
          next: (data) => {
            this.mapData(data)
            this.loading = false
          },
          error: (error) => {
            this.loading = false
            this.hasError = true
            this.errorMessage = JSON.stringify(error)
          },
        })
    }
    else {
      const dt1 = new Date(this.date1.getFullYear(), this.date1.getMonth(), this.date1.getDate())
      const dt2 = new Date(this.date2.getFullYear(), this.date2.getMonth(), this.date2.getDate(), 23, 59, 59)
      this.ord.getOrdersByDeliveryDate(dt1, dt2)
        .then(data => {
          if (data) {
            this.mapData(data)

          }

        })
        .catch(error => {
          this.hasError = true
          this.errorMessage = JSON.stringify(error)
        })
        .finally(() => {
          this.loading = false
        })
    }

  }

  private resetCounters() {
    this.total = 0
    this.commitedSumm = 0
    this.totalSumm = 0
    this.commited = 0
    this.needSync = 0

    if (!this.orders) return

    this.orders.forEach((element) => {
      this.total++

      if (element.statusId === 6) {
        this.commitedSumm += element.summa
        this.commited++
      }

      if (element.statusId === 2) {
        this.needSync++
      }

      this.totalSumm += element.summa ?? 0

    })

  }

  public completeConfirm(ord: OrderModel) {
    const idx = this.orders.findIndex(a => a.id === ord.id)
    if (idx < 0) {
      console.error('cant find order')
      return
    }

    this.orders[idx] = ord
    this.resetCounters()
  }

  orderChanged(order: OrderModel): void {
    const idx = this.orders.findIndex((e) => e.id == order.id)
    this.orders[idx] = order
  }


  syncDialog() {

    // const dialogRef = this.dialog.open(SynchronizationPageComponent, {
    //   width: '99%',
    // });

    // dialogRef.afterClosed().subscribe((result) => {

    //   this.updateData()

    // });


    const cat: SyncItemType[] = ['Orders']



    const dialogRef = this.dialog.open(SyncDialogComponent, {
      width: '99%',
      data: {
        categories: cat,
        full: false,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.updateData()

    });


  }

  // groupOrds() {
  //   //если отключено то не показывать батьку, включено только батьку
  //   if (this.groupOrders) {
  //     let onlyFather = this.allOrders.filter(x => !x.preorderId || x.id === x.preorderId)
  //     this.orders = onlyFather

  //   }
  //   else {
  //     let withoutFather = this.allOrders.filter(x => x.id !== x.preorderId)
  //     this.orders = withoutFather
  //   }
  //   this.resetCounters()


  // }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }
}

