import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RpaHttpService } from './http/rpa-http.service';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private api: RpaHttpService,
    private ctor: LocalStorageService) { }


  addPushSubscriber(sub: PushSubscription): Observable<boolean> {

    console.log(sub)

    const js = sub.toJSON()

    if (!js) {

      console.log("empty sub")
      return of(false)
    }

    console.log(js)

    const request = {
      endpoint: js.endpoint,
      expirationTime: js.expirationTime,
      keys: js.keys,
      hwid: this.ctor.widevineId

    }

    return this.api.post("/v2/api/Push/Subscribe", request)



  }

  notifyMe(text: string) {

    const request = {
      text: text,
      hwid: this.ctor.widevineId
    }

    return this.api.post("/v2/api/Push/NotifyMe", request)

  }



}
