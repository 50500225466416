import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-advance-status',
  templateUrl: './advance-status.component.html',
  styleUrls: ['./advance-status.component.scss']
})
export class AdvanceStatusComponent {

  @Input() advanceState?: number
  @Input() withText: boolean = false
  @Input() withInfo: boolean = false

}
