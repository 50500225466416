import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupModel } from 'src/app/models/goods/product';

@Component({
  selector: 'app-groups-modal',
  templateUrl: './groups-modal.component.html',
  styleUrls: ['./groups-modal.component.scss']
})
export class GroupsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<GroupsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupModel,
  ) { }


  public onSelectedNode(n: unknown) {
    this.dialogRef.close(n)
  }

}
