import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaSizeService {

  public isMobile: boolean = false

  constructor(breakpointObserver: BreakpointObserver) {

    breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        // Breakpoints.Small
        Breakpoints.TabletPortrait,
      ]
    ).subscribe(
      (state: BreakpointState) => {

        if (state.matches) {
          this.isMobile = true
        }
        else {
          this.isMobile = false
        }

      }
    )
  }





}
