<div *ngIf="data">
    <h1>{{data.name}}</h1>
    <p>{{data.code}}</p>

    <ol>
        <li *ngFor="let conn of data.connections"
        [ngStyle]="{'background-color': conn.deviceId === deviceId ? 'yellow' : 'none'}"
        >
            <p>{{conn.userAgent}}</p>
            <p>deviceId <i>{{conn.deviceId}}</i></p>
            created: {{conn.created | date:'dd.MM.yyyy hh:mm'}} updated: {{conn.updated | date:'dd.MM.yyyy hh:mm'}}

            <button mat-icon-button [routerLink]="['/admin/conn', conn.id]">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
        </li>
    </ol>

</div>

<pre>{{data | json}}</pre>