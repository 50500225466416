<h3>агенты</h3>
 
<table>
    <tr *ngFor="let agent of data" 
    [ngStyle]="{'background-color': agent.agentId === conn?.user?.Id ? 'yellow' : 'none'}"
    >
        <td>{{agent.team}}<br>
        <b>{{agent.code}}</b>
        {{agent.name}}</td>
        <td>{{agent.connectionsCount}}</td>
        <td>
            <button mat-icon-button [routerLink]="['/admin/agent',agent.agentId]">
            <mat-icon>
                edit
            </mat-icon>
        </button>
    </td>

    </tr>
</table>