<div class="container">
  <h4>{{this.questItem.questDefinition.name}}

    <span *ngIf="this.questItem.questDefinition.mandatory"> *</span>

    <span style="color: green;"> {{hint()}} </span>


  </h4>

  <mat-button-toggle-group [(ngModel)]="days" multiple="true" (change)="groupChanged()">
    <mat-button-toggle [value]="item.code" *ngFor="let item of AllDays" color="primary"
      [disabled]="disabled">
      {{item.name}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
