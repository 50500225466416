import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConnectionManagerService } from "../storage/connection-manager.service";
import { RpaHttpService } from "./rpa-http.service";
import { UrkopGateHttpService } from "./urkop-gate-http.service";



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  api: string
  ukrop: string
  constructor(private srvrs: ConnectionManagerService,
    rpa: RpaHttpService,
    ukrop: UrkopGateHttpService,

  ) {

    this.api = rpa.getServerUrl()
    this.ukrop = ukrop.getServerUrl()

  }

  // реализация интерфейса HttpInterceptor. там написано ANY, значит ANY
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler) {


    let authReq = req

    const token = this.srvrs.activeServer?.accessToken;

    if (token === undefined) {
      return next.handle(authReq);
    }




    if (req.url.startsWith(this.api) || req.url.startsWith(this.ukrop)) {

      authReq = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${token}`)
          .set('Access-Control-Allow-Origin', "*")
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}