<!-- <div class="page-content-block"> -->
<h1 *ngIf="quests; else noquest">Задачи</h1>
<h2 *ngIf="this.buypoint">{{this.buypoint.name}}
  <button class="icon_middle " mat-icon-button (click)="buypoint = undefined; buypointId = '';runSearch(0)"> <mat-icon>close</mat-icon>
  </button>
</h2>

<ng-template #noquest>
  <h1>Задачи не найдены</h1>
</ng-template>



<mat-form-field class="filter-name-field" appearance="fill">
  <mat-label>название клиента</mat-label>
  <input matInput type="text" [(ngModel)]="filterName" (ngModelChange)="runSearch(0)">
  <button mat-icon-button *ngIf="filterName" matSuffix mat-icon-button aria-label="Clear"
    (click)="filterName='';runSearch(0)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<mat-form-field class="air">
  <mat-label>тип задачи</mat-label>
  <mat-select (selectionChange)="runSearch(0)" [(ngModel)]="questType" panelClass="panel_width">
    <!--  -->
    <mat-option [value]=0>Любой тип</mat-option>
    <mat-option *ngFor="let val of qTypes" [value]="val.id">{{val.name}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-button-toggle-group [(ngModel)]="stateFilter" multiple="true" (change)="runSearch(0)" class="buttons">
  <mat-button-toggle value="1">
    <app-quest-icon [questState]="1"></app-quest-icon>
  </mat-button-toggle>
  <mat-button-toggle value="2">
    <app-quest-icon [questState]="2"></app-quest-icon>
  </mat-button-toggle>
  <mat-button-toggle value="3">
    <app-quest-icon [questState]="3"></app-quest-icon>
  </mat-button-toggle>
</mat-button-toggle-group>


<mat-button-toggle-group [(ngModel)]="loading" (change)="runSearch(0)" class="g2 buttons">
  <mat-button-toggle>
    <mat-icon>search</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>


<p *ngIf="quests">найдено задач {{total}}.
</p>

<mat-progress-bar mode="buffer" *ngIf="loading; else noprogress" class="progress">
</mat-progress-bar>

<ng-template #noprogress>
  <div class="progrss">&nbsp;</div>
</ng-template>






<!-- </div> -->

<mat-card class="example-card" *ngFor="let quest of quests">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <app-quest-icon [quest]="quest"></app-quest-icon>
    </div>
    <mat-card-title> {{quest.questSet?.questType?.name}}
      <mat-card-subtitle>{{quest.questSet?.name}}</mat-card-subtitle>
    </mat-card-title>

  </mat-card-header>
  <mat-card-content>
    Статус: <b>{{quest.state}}</b> <br>
    Сроки:
    {{quest.start | date:'dd.MM.yyyy'}}-{{quest.finish | date:'dd.MM.yyyy'}}<br>
    <p *ngIf="quest.text">Задача: <b>{{quest.text}}</b></p>
    <p>{{quest.buypoint?.name}}</p>
    <p *ngIf="quest.questSet?.questTypeId === 3">
      <b>
        {{getZQuestText(quest)}}
      </b>
    </p>

    <p *ngIf="quest.questSet?.questTypeId === 4" class="formatted">
      {{getQuestText(quest)}}
    </p>


  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button [routerLink]="['/quest', quest.id]" color="primary"
      *ngIf="!quest.questSet?.questType?.auto   ">

      перейти к задаче
    </button>
  </mat-card-actions>
</mat-card>

<button mat-raised-button [disabled]="loading" *ngIf="(page+1)*pageSize<total" class="big-red-button"
  (click)="pageEvent(page+1)" color="warn">ЕЩЕ
  {{(total-(page+1)*pageSize)}}</button>