<button *ngIf="!addMode" mat-raised-button color="primary"
  (click)="setAddMode(true)">Добавить: {{typename}}</button>

<div class="container" *ngIf="addMode">
  <form (ngSubmit)="submitUser()">

    <!-- Progress Bar -->
    <div class="progress form-group" *ngIf="progress > 0">
      progress {{progress}}
      <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
        [style.width.%]="progress">
      </div>

      <mat-progress-bar class="example-margin" color="primary" mode="determinate"
        [value]="progress">
      </mat-progress-bar>

    </div>

    <div class="form-group">

      <button type="button" mat-raised-button (click)="fileInput.click()">
        <mat-icon>add_a_photo</mat-icon>
        Выбрать фото
      </button>
      <input hidden #fileInput type="file" id="file" (change)="uploadFile($event)">
      {{fileName}}

    </div>





    <div class="form-group warning" *ngIf="error">{{error}}</div>

    <div class="form-group">
      <button mat-raised-button color="accent" (click)="setAddMode(false)">Отмена</button>
      <button mat-raised-button color="primary">Сохранить</button>
    </div>

  </form>
</div>
