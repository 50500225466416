import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs'

export class BaseHttpService {

    constructor(protected http: HttpClient) { }

    protected getServerUrl(): string {
        return "virtual method";
    }

    public wrapUrl(path: string): string {

        const root = this.getServerUrl();

        if (root.endsWith('/') && path.startsWith('/')) {
            const tmp = path.substring(1)
            return `${root}${tmp}`;
        }
        else {
            if (!root.endsWith('/') && !path.startsWith('/')) {
                return `${root}/${path}`
            }
            else {
                return `${root}${path}`;
            }
        }

    }


    public get<T>(path: string): Observable<T> {
        const uri = this.wrapUrl(path)
        return this.http.get<T>(uri)
            .pipe(catchError(err => { return this.formatErrors(err) }))
    }

    public getImage(path: string) {
        //const headers = this.getAuthHeaders();

        //   const uri = this.wrapUrl(path)
        return this.http.get(path, {
            //      headers: headers,
            responseType: 'blob'
        })
    }


    public post<T>(path: string, body: object = {}): Observable<T> {
        const uri = this.wrapUrl(path)
        return this.http.post<T>(uri, body)
            .pipe(catchError(err => { return this.formatErrors(err) }))
    }

    public postForm(path: string, formData: FormData) {
        const uri = this.wrapUrl(path)
        return this.http.post(uri, formData, {
            reportProgress: true,
            observe: 'events',
        })
            // .pipe(catchError(err => { return this.formatErrors(err) }))
    }




    private formatErrors(error: any, uri: string = '') {
        switch (error.status) {
            case 0: {
                // if (error?.message && error.message.indexOf('failure response') > -1) {
                //     return throwError(() => new Error('Сервер недоступен (0)'))
                // } else {

                // }
                return throwError(() => new Error('Неизвестная ошибка сервера (0)'))
            }
            case 404: {
                return throwError(() => new Error('Не найден (404)'))
            }
            case 401: {
                return throwError(() => new Error('Не авторизован, повторите синхронизацию для повторной авторизации (401)'))
            }
            case 403: {
                return throwError(() => new Error('Доступ запрещен (403)'))
            }
            case 500: {
                // console.error(error.error.title + '\n' + error.error.detail)
                return throwError(() => new Error('Ошибка сервера - ' + error.error.title))
            }
        }
        if (error?.message) {
            return throwError(() => error.message + ' [' + uri + ']')
        }
        return throwError(() => error)
    }

}