import { Component, Input, OnInit } from '@angular/core';

import { Connection } from 'src/app/models/server';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SyncService } from 'src/app/services/sync/sync.service';

@Component({
  selector: 'app-user-server',
  templateUrl: './user-server.component.html',
  styleUrls: ['./user-server.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'cdk-overlay-pane-custom' }
    }]
})

export class UserServerComponent implements OnInit {

  server: Connection | undefined

  mobile = false

  @Input() onlyText = false
  @Input() mobileView = false

  constructor(
    public cm: ConnectionManagerService,
    public sync: SyncService
  ) { }

  ngOnInit(): void {
    // this.getStorage()
    this.cm.activeServerObservable().subscribe(() => { this.getStorage() })
    this.cm.activeUserchange().subscribe(() => { this.getStorage() })
  }

  getStorage() {
    // //TODO: Возможно опираться надо будет не на код агента
    this.server = this.cm.activeServer
    // this.servers = [...this.localStorage.connections]

  }

  activeServer(e: Connection | undefined) {
    // const ss = this.localStorage.connections.find(x => x.agentCode === this.server.agentCode)
    // if (!ss) return

    this.cm.activeServer = e

    //console.error(e)

  }



}
