import { ChangeDetectorRef, Component, ViewChild, AfterViewInit } from '@angular/core';

import { ConnectionManagerService } from './services/storage/connection-manager.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaMatcher } from '@angular/cdk/layout';
import { SyncService } from './services/sync/sync.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SyncDialogComponent } from './pages/synchronization/sync-dialog/sync-dialog.component';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { SyncItemType, syncCategories } from './models/sync.model';
import { SyncTimerService } from './services/sync/Sync-timer.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaSizeService } from './services/media-size.service';
import { AppSettingsService } from './services/app-settings.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('drawer') drawer!: MatSidenav

  version = environment.version
  headerColor = environment.headerClass
  // mobileQuery: MediaQueryList
  dbActivated = false
  time: number = 0
  showSync: boolean = false
  url: string = ''

  constructor(public sync: SyncService,
    // private media: MediaMatcher,
    cm: ConnectionManagerService,
    private snack: MatSnackBar,
    // changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private timer: SyncTimerService,
    public media: MediaSizeService,
    private appSettings: AppSettingsService
    ) {
    // this.mobileQuery = media.matchMedia('(max-width: 600px)')
    // this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    // this.mobileQuery.addListener(this._mobileQueryListener)


    cm.getDb().then(() => {
      this.dbActivated = true
      console.log('db create hook in app component')
    }).catch((reason) => {
      let str = 'произошла ошибка'
      this.dbActivated = true
      if (reason instanceof DOMException) {
        str = reason.message
      }


      this.snack.open(str, 'ok', { duration: 5000 })
    })




    this.timer.timerObs().subscribe(x => { this.time = x })
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const url = e.url.split('/')[1]
        this.url = url;
        if (syncCategories.find(x => x.url === this.url)) {
          this.showSync = true
          return
        }
        this.showSync = false
      }
    })

  }

  ngAfterViewInit() {
    // if (!this.mobileQuery.matches) {
    if (!this.media.isMobile) {
      setTimeout(() => {
        this.drawer.open()
      }, 0);


    }

  }

  // private _mobileQueryListener: () => void


  openSyncDialog() {
    if (this.sync.running) {
      this.dialog.open(SyncDialogComponent, { width: '99%', })
    }
    else {
      // const url = this.router.url.split('/')[1]
      let s = syncCategories.filter(x => x.url === this.url)
      if (!s || !s.length)
        return
      const cat: SyncItemType[] = s.map(x => x.id)
      this.dialog.open(SyncDialogComponent, {
        width: '99%',
        data: {
          categories: cat,
          full: false,
        }
      });


    }
  }




}
