import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SyncItemType } from "src/app/models/sync.model";

import { SyncStartQuery } from 'src/app/filters/SyncFilter';
import { Connection, User } from 'src/app/models/server';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-agent-conn-page',
  templateUrl: './agent-conn-page.component.html',
  styleUrls: ['./agent-conn-page.component.scss']
})
export class AgentConnPageComponent {

  data: any = ''
  conn: Connection | undefined;
  user: User | undefined;
  connId: string | undefined;
  deviceId = ''
  delta: any
  full: any
  agentConn: any;

  constructor(private api: RpaHttpService,
    private cm: ConnectionManagerService,
    private stor: LocalStorageService,
    route: ActivatedRoute,
    private snack: MatSnackBar) {

    this.deviceId = this.stor.widevineId

    route.paramMap.subscribe(p => {
      this.connId = p.get("id") ?? undefined

      this.subCon()
    })



  }


  subCon() {

    if (this.connId === undefined) {
      return
    }

    const subUser = this.cm.activeUserchange().subscribe(x => {

      this.conn = x?.connections?.find(x => x.active)
      this.user = x

      this.subApi()
    })


  }

  subApi() {

    if (!this.user) {
      return
    }



    this.api.get(`/v2/api/admin/GetConn/${this.connId}`).subscribe({
      next: (data: any) => {
        this.data = data

        if (!data && !data.connections) return

        this.agentConn = data.connections[0]

        const cat: SyncItemType[] = [
          'Advances', 'Clients', 'Debets', 'Goods', 'Matrix', 'Orders', 'Quests'
        ];


        /// delta
        const deltaQuery = {
          deviceUID: this.agentConn.deviceId,
          full: false,
          kinds: cat,
          agentId: data.agentId

        }

        this.api.post(`/v2/api/admin/GetSyncHeader`, deltaQuery).subscribe({
          next: (data) => {
            this.delta = data
          }
        })





        /// full

        const fullQuery = {
          deviceUID: this.agentConn.deviceId,
          full: true,
          kinds: cat,
          agentId: data.agentId

        }

        this.api.post(`/v2/api/admin/GetSyncHeader`, fullQuery).subscribe({
          next: (data) => {
            this.full = data
          }
        })




      }
    })



  }


  killSnapShot(item: any) {
    /*{
        "kind": "Advances",
        "key": "sync-55f99c0a-d4b7-4465-8488-1d7054071f0a-2-0000042644-Advances-snapshot",
        "count": 481
      }, */


    const fullQuery = {
      deviceUID: this.agentConn.deviceId,
      kind: item.kind,
      agentId: this.data.agentId

    }

    this.api.post(`/v2/api/admin/ResetSnapshot`, fullQuery).subscribe({
      next: (data) => {
        this.full = data
      }
    })





  }

}
