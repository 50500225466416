<h1 mat-dialog-title>Синхронизация</h1>
<div mat-dialog-content>
    <div *ngFor="let job of sync.jobs">
        <app-sync-view [job]="job"></app-sync-view>

    </div>

</div>
<div mat-dialog-actions>
    <span *ngIf="!sync.running">
        <button mat-raised-button color="primary" (click)="reset()">закрыть</button>
    </span>
</div>