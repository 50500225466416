import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { QuestItem } from 'src/app/models/quests/quest.model'


@Component({
  selector: 'app-quest-item-day-of-week',
  templateUrl: './quest-item-day-of-week.component.html',
  styleUrls: ['./quest-item-day-of-week.component.scss'],
})
export class QuestItemDayOfWeekComponent implements OnInit {
  @Input() disabled = true
  @Input() questItem!: QuestItem
  @Output() xevnt: EventEmitter<QuestItem> = new EventEmitter()

 

  AllDays: Day[] = [
    { code: 1, name: 'пн' },
    { code: 2, name: 'вт' },
    { code: 4, name: 'ср' },
    { code: 8, name: 'чт' },
    { code: 16, name: 'пт' },
    { code: 32, name: 'сб' },
    { code: 64, name: 'вс' },
  ]

  days: number[] = []

  ngOnInit(): void {
    const tmp = +this.questItem.value

    if (tmp === 0) {
      this.questItem.value = '127'
    }

    this.map()
  }

  // всё не так, как кажется.
  // value - это битовая маска, причём 0=рабочий день, а 1 - ВЫХОДНОЙ
  map() {
    const base = +(this.questItem?.value ?? '0')

    for (let i = 0; i < 7; i++) {
      const flag = Math.pow(2, i)
      this.days[i] = base & flag ? flag : 0
    }
  }

  groupChanged() {
    this.questItem.value = this.days
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toString()

    this.xevnt.next(this.questItem)
  }

  hint() {
    const tmp = +this.questItem.value
    switch (tmp) {
      case 0:
      case 127: {
        return 'пн-вс'
      }
      case 63: {
        return 'пн-сб'
      }
      case 31: {
        return 'пн-пт'
      }
      default: {
        let res = ''
        for (let i = 0; i < 7; i++) {
          const flag = Math.pow(2, i)
          if (tmp & flag ? flag : 0) {
            if (res !== '') res += ','

            res += this.AllDays[i].name
          }
        }
        return res
      }
    }
    return ''
  }
}

interface Day {
  code: number
  name: string
}
