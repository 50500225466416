import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, ReplaySubject, Subscription } from 'rxjs'

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { ConfirmDialogData } from './ConfirmDialogData'

@Injectable({
  providedIn: 'root',
})
export class DialogServiceService {
  subscription?: Subscription 
  constructor(public dialog: MatDialog) {}

  public confirm(question: string, title: string): Observable<boolean> {
    const _result = new ReplaySubject<boolean>()

    const data: ConfirmDialogData = {
      title: title,
      question: question,
      result: false,
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '75%',
      data: data,
    })

    if (this.subscription) {
      this.subscription.unsubscribe()
    }

    this.subscription = dialogRef.afterClosed().subscribe((data) => {
      if (data && data.result) {
        _result.next(data.result)
      } else {
        _result.next(false)
      }
    })

    return _result.asObservable()
  }
}
