<span [ngSwitch]="advanceState">

    <!-- <ng-template ngSwitchCase="1">
        <mat-icon style="color: cornflowerblue;">smartphone</mat-icon> <span *ngIf="withText"> Создан</span>
    </ng-template> -->

    <ng-template ngSwitchCase="1">
        <span style="color: cornflowerblue;" class="indicator">
            <mat-icon style="color: cornflowerblue;" class="midd" mat-list-icon>
                smartphone
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Создан (1) </small>
        </span>
        <span *ngIf="withInfo"> - Агент создал ПКО, есть возможность редактировать, подтвердить или удалить. </span>

    </ng-template>

    <!-- <ng-template ngSwitchCase="2">
        <mat-icon style="color: rgb(255, 140, 0);">smartphone</mat-icon> <span *ngIf="withText"> Подтвержден</span>
    </ng-template> -->
    <ng-template ngSwitchCase="2">
        <span style="color: rgb(255, 140, 0);" class="indicator">
            <mat-icon style="color: rgb(255, 140, 0);" class="midd" mat-list-icon>
                smartphone
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Подтвержден (2)</small>
        </span>
        <span *ngIf="withInfo"> - Все изменения в ПКО проверены, правильность подтверждена агентом. Для отправки в
            1С необходимо синхронизироваться.. </span>

    </ng-template>

    <!-- <ng-template ngSwitchCase="3">
        <mat-icon style="color: green;">cloud_upload</mat-icon> <span *ngIf="withText"> Отправлен в 1с</span>
    </ng-template> -->
    <ng-template ngSwitchCase="3">
        <span style="color: green;" class="indicator">
            <mat-icon style="color: green;" class="midd" mat-list-icon fontSet="material-icons-outlined">
                cloud_upload
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Отправлен в 1с (3)</small>
        </span>
        <span *ngIf="withInfo"> - ПКО успешно передан в 1С, ожидается его проведение. </span>

    </ng-template>

    <!-- <ng-template ngSwitchCase="4">
        <mat-icon style="color: green;">check_circle_outline</mat-icon> <span *ngIf="withText"> Проведен в 1с</span>
    </ng-template> -->

    <ng-template ngSwitchCase="4">
        <span style="color: green;" class="indicator">
            <mat-icon style="color: green;" class="midd" mat-list-icon>
                check_circle_outline
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Проведен в 1с (4)</small>
        </span>
        <span *ngIf="withInfo"> - ПКО на точку созданы, ожидает внесения денежных средств. </span>

    </ng-template>


    <!-- <ng-template ngSwitchCase="5">
        <mat-icon style="color: rgb(255, 140, 0);">cloud_upload</mat-icon> <span *ngIf="withText"> Выгружен</span>
    </ng-template> -->

    <ng-template ngSwitchCase="5">
        <span style="color: rgb(255, 140, 0);" class="indicator">
            <mat-icon style="color: rgb(255, 140, 0);" class="midd" mat-list-icon fontSet="material-icons-outlined">
                cloud_upload
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Выгружен (5)</small>
        </span>
        <span *ngIf="withInfo"> - После подтверждения агентом и последующей синхронизации ПКО уходит на сервер,
            который передаёт его в 1С. </span>

    </ng-template>

    <!-- <ng-template ngSwitchCase="6">
        <mat-icon class="warn">error_outline</mat-icon> <span *ngIf="withText"> Ошибка в 1с</span>
    </ng-template> -->

    <ng-template ngSwitchCase="6">
        <span style="color: red" class="indicator">
            <mat-icon style="color: red" class="midd" mat-list-icon>
                error_outline
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Ошибка в 1с (6)</small>
        </span>
        <span *ngIf="withInfo"> - При проведении произошла ошибка. Текст ошибки виден в комментарии. </span>

    </ng-template>

    <ng-template ngSwitchCase="7">
        <span style="color: rgb(255, 140, 0);" class="indicator">
            <mat-icon style="color: rgb(255, 140, 0);" class="midd" mat-list-icon>
                check_circle_outline
            </mat-icon>
            <small class="status-indicator-text" *ngIf="withText"> Создан в 1с (7)</small>
        </span>
        <span *ngIf="withInfo"> - 1С приняла ПКО, ожидается его проведение. </span>

    </ng-template>

    <ng-template ngSwitchDefault>Не известный статус</ng-template>

</span>