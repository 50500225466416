<div class="tab-container">



  <br>
  <mat-toolbar>

    <mat-form-field appearance="outline" style="margin-top: 1em;">
      <mat-label>Дата остатков</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateChange)="changed()">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  </mat-toolbar>

  <mat-spinner *ngIf="loading"></mat-spinner>
  <div *ngIf="!loading && hasError">
    <span class="warning">{{errorMessage}}</span>
  </div>


  <div *ngIf="!loading && !hasError">

    <h4 *ngIf="hasRemains">
      Всего {{total}} по состоянию на {{xdate | date:'dd.MM.yyyy HH:mm'}}
      <p style="font-weight: bold; color: red;" *ngIf="shop">
        Партионные остатки: {{shop}}
      </p>
      <p style="font-weight: bold; color: red;" *ngIf="shopMinus">
        Отрицательные остатки: {{shopMinus}}
      </p>

      <p *ngIf="matrix && matrix.name" style="background-color: #AAFFAA;">Матрица {{matrix.name}}: {{matrixCounter}} SKU (на
        остатках {{matrixCounter0}})</p>
    </h4>

    <h4 *ngIf="!hasRemains">
      нет данных об остатках
    </h4>



    <table cellspacing="0" cellpadding='0'>

      <tr>
        <th>Название</th>
        <th>Остаток</th>
        <th>Партионный / Отрицательный</th>
      </tr>

      <ng-container *ngFor="let cat of matrix?.categories">
        <tr style="background-color: rgb(176, 172, 206);">
          <td>
            <h2>{{cat.categoryName}}

              <span *ngIf="cat.categoryId">{{cat.successfull}}/{{cat.blocks.length}}</span>
            </h2>

          </td>
          <td class="tdr">
            {{cat.rest}}
          </td>
          <td class="tdr"></td>

        </tr>
        <ng-container *ngIf="cat.blocks">

          <ng-container *ngFor="let block of cat.blocks">
            <tr [style.background-color]="block.color" (click)="block.show=!block.show">
              <td>
                <mat-icon *ngIf="!block.show">keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="block.show">keyboard_arrow_down</mat-icon>
                <b>{{block.blockTitle}}</b>
              </td>

              <td class="tdr">{{block.restOnBlock}}</td>
              <td class="tdr"> <span [style.background-color]="block.positiveOnBlock ?'yellow':'none'">
                  {{block.positiveOnBlock}} </span> /
                <span [style.background-color]="block.negativeOnBlock !==0 ?'yellow':'none'">
                  {{block.negativeOnBlock}}</span>
              </td>
            </tr>

            <ng-container *ngFor="let restblock of block.rests">
              <tr *ngIf="block.show" [style.background-color]="getColor(restblock)">
                <td>

                  <span style="margin-left: 1em;">

                    <span>
                      <button mat-icon-button [matTooltip]="restblock.p_alccode" matTooltipPosition="right">

                        <mat-icon style="color: dodgerblue">info</mat-icon>

                      </button>
                    </span>
                    {{restblock.p_name}} ({{restblock.p_capacity}} л)</span>
                </td>

                <td class="tdr">{{restblock.p_quantity}}</td>
                <td class="tdr"> <span
                    [ngStyle]="{'background-color': restblock.p_quantity_shop_positive !== undefined && restblock.p_quantity_shop_positive !== 0 ? 'yellow' : 'none'}">
                    {{restblock.p_quantity_shop_positive}} </span> /
                  <span
                    [ngStyle]="{'background-color': restblock.p_quantity_shop_negative !== undefined && restblock.p_quantity_shop_negative !== 0 ? 'yellow' : 'none'}">
                    {{restblock.p_quantity_shop_negative}}</span>
                </td>
              </tr>


            </ng-container>
          </ng-container>
        </ng-container>

      </ng-container>




      <!-- ng-container *ngFor="let res of rests">

        <tr *ngIf="!res.matrixBlockId" [style.background-color]="getColor(res)">
          <td>{{res.p_name}} ({{res.p_capacity}} л)</td>

          <td class="tdr">{{res.p_quantity}}</td>
          <td class="tdr"> <span
              [ngStyle]="{'background-color': res.p_quantity_shop_positive !== undefined && res.p_quantity_shop_positive !== 0 ? 'yellow' : 'none'}">
              {{res.p_quantity_shop_positive}} </span> /
            <span
              [ngStyle]="{'background-color': res.p_quantity_shop_negative !== undefined && res.p_quantity_shop_negative !== 0 ? 'yellow' : 'none'}">
              {{res.p_quantity_shop_negative}}</span>
          </td>
        </tr>


      </ng-container -->

    </table>
    <span style="color: rgb(255, 115, 0)"></span>
  </div>


</div>


<!-- pre>
  {{matrix | json}}
</pre -->