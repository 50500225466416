import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncAdvancesJob extends SyncRealDataJob {


    override async clean(): Promise<void> {
        await this.db.clear("advances")
         
    }

    async save(r: SyncFetch) {



        const add = r.items.filter(a => a.advance && a.state === 1).map(a => a.advance!.advance);
        const del = r.items.filter(a => a.advance && a.state === 2).map(a => a.id);

        await this.db.deleteArray('advances', del);

        add.forEach(p => {
            p.date = new Date(p.date)
            p.docDate = new Date(p.docDate)
            p.created = new Date(p.created)
        });

        await this.db.addArray('advances', add);


    }

}
