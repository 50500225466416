import { Component, Input, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { QuestImage, QuestItem } from 'src/app/models/quests/quest.model'
import { DialogServiceService } from 'src/app/services/confirm/dialog-service.service'
import { QuestsHttpService } from 'src/app/services/net/quests-http.service'


@Component({
  selector: 'app-questionare-ors-photo',
  templateUrl: './questionare-ors-photo.component.html',
  styleUrls: ['./questionare-ors-photo.component.scss'],
})
export class QuestionareOrsPhotoComponent implements OnInit {
  @Input() disabled = false
  @Input() questItem!: QuestItem

  loading = false
  images: QuestImage[] = []

  error = false

  constructor(
    private api: QuestsHttpService,
    private snack: MatSnackBar,
    private confirmator: DialogServiceService
  ) {



  }

  ngOnInit(): void {

    this.updateData()
  }

  public updateData() {
    this.loading = true

    this.api.getImages(this.questItem.id).subscribe(
      (l) => {
        this.mapImageList(l)
        this.error = false
      },
      () => {
        this.snack.open("не удалось загрузить картинки", 'Ok', { duration: 3000 })
        this.error = true
        this.loading = false
      },
      () => {
        this.loading = false
      }
    )
  }

  private mapImageList(l: QuestImage[]) {
    this.images = l
    if (!this.questItem) {
      this.snack.open('ошибка загрузки', 'Ok')
      return
    }

    this.questItem.value =
      this.images && this.images.length > 0 ? this.images.length.toString() : ''
  }

  imageAdded(event: QuestImage) { // TODO test again!
    this.images.unshift(event as QuestImage)
    this.mapImageList(this.images)
  }


  public imgUrl(img: QuestImage): string {
    return this.api.getImageUrl(img)
    // const result=`${environment.api}/v2/api/QuestImage/Photo?img=${img.id}`
    // /v2/api/QuestImage/Photo?img=
    // return result
  }

  remove(img: QuestImage) {
    this.confirmator.confirm('Удалить изображение?', 'Внимание!').subscribe((ok) => {
      if (ok === true) {
        this.loading = true
        this.api.deleteImage(img).subscribe(
          (l) => {
            this.mapImageList(l)
          },
          (error) => {
            this.snack.open(JSON.stringify(error), 'Ok')
          },
          () => {
            this.loading = false
          }
        )
      }
    })
  }
}
