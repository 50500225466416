import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
 
import { SubscriptionLike } from 'rxjs'
import { AgentModel } from 'src/app/models/agent.model'
import { Client } from 'src/app/models/client.model'
import { OrderModel } from 'src/app/models/order.model'
import { OrdersService } from 'src/app/services/data/orders.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'


@Component({
  selector: 'app-point-orders',
  templateUrl: './point-orders.component.html',
  styleUrls: ['./point-orders.component.scss'],
})
export class PointOrdersComponent implements OnInit, OnDestroy {
  @Input() buypoint!: Client

  agent: AgentModel | undefined
  loading = false
  orders: OrderModel[] = []
  error = false
  subscriptions: SubscriptionLike[] = []
  private svc: OrdersService

  constructor(
    
    public connMgr: ConnectionManagerService,

    private snack: MatSnackBar
  ) {
    const db = connMgr.getCurrentDb();
    this.svc = new OrdersService(db)
  }

  ngOnInit(): void {
 
 

    this.load()
  }

  load() {
    this.loading = true
    const sub2 = this.svc.getClientOrders(this.buypoint.id).subscribe((orders) => {
      this.orders = orders
      this.loading = false
    })


    this.subscriptions.push(sub2)
  }


  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }
}
