<div style="min-height: 83vh;" swipe (next)="swipe(true)" (previous)="swipe(false)" >
  <h1>Заказы</h1>



  <mat-slide-toggle style="margin-left: 0.5em;" [(ngModel)]="localStrg.searchOptions.orderByDeliveryDate"
    (ngModelChange)="dateModeChange();updateData()">
    <span *ngIf="!localStrg.searchOptions.orderByDeliveryDate;else deliverdate"> По дате создания</span>
    <ng-template #deliverdate>По дате доставки</ng-template>
  </mat-slide-toggle>

  <app-date-filter [(date1)]="date1" [(date2)]="date2" (dateChange)="changed()"></app-date-filter>

  <!-- <button style="display: block;" mat-flat-button [color]="needSync>0 ? 'accent': 'primary'" (click)='syncDialog()'>
  синхронизация {{needSync}}
</button> -->

  <mat-spinner *ngIf="loading"></mat-spinner>
  <h1 class="warning" *ngIf="hasError">
    возникла ошибка
  </h1>
  <h2 class="warning" *ngIf="hasError">
    {{errorMessage }}
  </h2>
  <h3 *ngIf="total" class="example-card">
    Заказов {{total}} на сумму {{totalSumm | currency:'RUB':'symbol':'1.2-2':'ru'}}
    <span *ngIf="commited">
      Проведено {{commited}}, на сумму
      {{commitedSumm | currency:'RUB':'symbol':'1.2-2':'ru'}}
    </span>

  </h3>

  <span *ngIf="!loading && !hasError">
    <span *ngFor=" let ord of orders">
      <app-order-list-item [order]="ord" (orderChange)="completeConfirm($event)"></app-order-list-item>
    </span>
  </span>

</div>