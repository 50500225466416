<div class="rests-list" (click)="clickOnItem(item)">

    <span  [ngStyle]="{'background-color': item.displaySalePrice ? 'yellow' : 'none'}">
        <div class="rests-item">{{item.name}} </div>
        
        
        <span class="label">на складе</span>
        <span class="air amount">{{item.displayRest}}</span>
        <span class="label">Цена</span>

        <span *ngIf="item.displayClientPrice;else NoPersPrice">

            <span class="air">
                <span class="discounted">
                    {{item.displayPrice | currency :'RUB':'symbol':'1.2-2':'ru'}}
                </span>
                <span class="air currency">
                    {{item.displayClientPrice | currency :'RUB':'symbol':'1.2-2':'ru'}}
                </span>
            </span>

        </span>
        <ng-template #NoPersPrice>
            <span class="air currency">
                {{item.displayPrice | currency:'RUB':'symbol':'1.2-2':'ru'}}
            </span>

        </ng-template>

        <span *ngIf="item.displaySalePrice ">
            <span class="air currency">
                Акция {{item.displaySalePrice | currency :'RUB':'symbol':'1.2-2':'ru'}}
            </span>
        </span>

        <span *ngIf="item.stateId" [ngStyle]="{'background-color':item.stateColor}" class="airmax">{{item.stateName}} </span>


        <!-- span *ngIf="item.promo>0" class="promo">Cкидка<span *ngIf="item.promo>0">
        {{item.promo/(item.price+item.promo)
        | percent:'1.1' }}
      </span></span -->
        <span *ngIf="item.ordered">
            <span class="label"><span class="ordered">в заказе {{item.ordered}}</span>
            </span>
        </span>

    </span>
</div>