import { Component } from '@angular/core';
import { Server, Connection, UserData,  Profile, AuthResponse, User } from 'src/app/models/server';

import { RpaIdClientService } from 'src/app/services/net/rpa-id-client.service';

import { SyncService } from 'src/app/services/sync/sync.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmatorDialogComponent, ConfirmData } from 'src/app/components/confirmator-dialog/confirmator-dialog.component';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
 import { MediaSizeService } from 'src/app/services/media-size.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-user-managmet',
  templateUrl: './user-managmet.component.html',
  styleUrls: ['./user-managmet.component.scss']
})
export class UserManagmetComponent {
 //sync
 public msg = ""
 running = false
 complete = false
 message = ""
 //сервера
 servers: Server[] = []
 server = ''
 email = ''
 externalToken = ''
 activeUser: User = {} as User
 profiles: Profile[] = []
 // profile: Profile | undefined
 profile = new FormControl<Profile | undefined>(undefined)
 profiles$!: Observable<Profile[]>
 // mobile = false
 appleInput  = ''

 constructor(public sync: SyncService,

   public connMgr: ConnectionManagerService,
   private api: RpaIdClientService,
   private snack: MatSnackBar,
   private dialog: MatDialog,
   private router: Router,
   public media: MediaSizeService,
   
    
 ) {
   this.activeUser = JSON.parse(JSON.stringify(this.connMgr.activeUser))

 }


 ngOnInit(): void {

   this.getServers()
   this.getProfiles()
 }

 
 private auto() {
   this.profiles$ = this.profile.valueChanges.pipe(
     startWith(''),
     map(value => {
       const name = typeof value === 'string' ? value : value?.agentName;
       return name ? this._filter(name as string) : this.profiles.slice();
     }),
   );

 }

 private _filter(name: string): Profile[] {
   if (!name) return this.profiles
   const filterValue = name.toLowerCase();

   return this.profiles.filter(prof => prof.agentName.toLowerCase().includes(filterValue));
 }

 displayFn(prof: Profile): string {
   return prof && prof.agentName ? prof.agentName : '';
 }

 isAdmin() {
   return this.connMgr.isAdmin()
 }

 isSuper() {
   return this.connMgr.isSuper()
 }


 changeActiveUser() {
   this.connMgr.setActiveUser(this.activeUser)
   // this.profile = undefined
   this.profile.setValue(undefined)
   this.getProfiles()
 }

 //serv
 getServers() {
   this.api.getServers().subscribe({
     next: x => {
       this.servers = x;

       if (!x || x.length <= 0) {
         this.snack.open("получен пустой список регионов", "Ok", { duration: 1500 })
       }

     },
     error: err => {
       console.log(err)
       this.snack.open("ошибка загрузки списка регионов", "Ok", { duration: 1500 })
     }
   })
 }

 getProfiles() {
   if (!this.connMgr.activeUser || !this.connMgr.activeUser.user?.Email || !this.connMgr.activeUser.user?.Server) {
     return
   }
   this.profiles = []
   this.auto()
   const filter = {
     email: this.connMgr.activeUser.user.Email,
     server: this.connMgr.activeUser.user.Server,
     sendEmail: true,
   }
   this.api.getProfiles(filter).subscribe({
     next: x => { this.profiles = x.profiles; this.auto() },
     error: err => { this.snack.open('Ошибка загрузки агентов', 'Закрыть', { duration: 5000 }) }
   })

 }


 requestAccess(conection?: Connection) {

   this.running = true


   if (conection) {
     const decodeToken = jwt_decode<UserData>(conection.rereshToken)
     this.email = decodeToken.Email
     this.server = decodeToken.Server

   }

   // let token: string

   this.api.requestAccess(this.email, this.server, true).subscribe({
     next: x => {
       if (x) {
         if (x.successfull) {

           this.message = x.message
         }
         else {
           this.message = `Ошибка: ${x.message}`
         }
       }
       else {
         this.message = `Ошибка: авторизация не удалась`
       }


       this.snack.open(this.message, 'Закрыть', { duration: 5000 })
     },
     error: err => {
       console.log(err);
       this.message = err.message
       this.snack.open(this.message, 'Закрыть', { duration: 5000 })
       this.complete = true
       this.running = false
     },
     complete: () => {
       this.complete = true
       this.running = false
     }
   })

 }

 checkTimeConn(connection: Connection) {
   if (connection.rereshToken) {
     const decodeToken = jwt_decode<UserData>(connection.rereshToken)
     const conDate = new Date(decodeToken.exp * 1000)
     if (conDate.getTime() < new Date().getTime()) {
       return true
     }
   }
   return false
 }

 //редактирование серверов
 deleteServ(conn: Connection) {

   const dialogConfirm = this.dialog.open(ConfirmatorDialogComponent, {
     data: {
       title: 'Удаление',
       question: `Удалить подключение  ${conn.title}?`,
       buttonOkName: 'Да',
       buttonCancelName: 'Отмена'
     } as ConfirmData
   })

   dialogConfirm.afterClosed().subscribe(x => {
     if (x) {
       this.connMgr.deleteConnection(conn)
     }
   })
 }

 deleteUser() {
   const dialogConfirm = this.dialog.open(ConfirmatorDialogComponent, {
     data: {
       title: 'Удаление',
       question: `Удалить подключение  ${this.connMgr.activeUser.user.name}?`,
       buttonOkName: 'Да',
       buttonCancelName: 'Отмена'
     } as ConfirmData
   })

   dialogConfirm.afterClosed().subscribe(x => {
     if (x) {
       this.connMgr.delUser(this.connMgr.activeUser)
       this.activeUser = JSON.parse(JSON.stringify(this.connMgr.activeUser))
       this.getProfiles()
     }
   })



 }

 activeServ(serv: Connection) {

   const curr = this.connMgr.activeServer

   if (curr?.user?.Code === serv.user?.Code && curr?.user?.Server === serv?.user?.Server) {
     return true
   }
   return false
 }





 async deleteAll() {

   const dialogConfirm = this.dialog.open(ConfirmatorDialogComponent, {
     data: {
       title: 'Удаление',
       question: 'Удалить базы данных и все настройки подюклячений?',
       buttonOkName: 'Да',
       buttonCancelName: 'Отмена'
     } as ConfirmData
   })

   dialogConfirm.afterClosed().subscribe(x => {
     if (x) {

       this.connMgr.users.forEach(x => {
         this.connMgr.delUser(x)
       })

       this.connMgr.Connections.forEach(async (x) => {

         this.connMgr.deleteConnection(x)
       })

       // document.location.reload() // зачем?

     }
   })


 }

 applyExternalToken() {
   this.router.navigate(['bot-auth', this.externalToken])
 }

 addConn(email?: string, server?: string) {
   if (!email || !server) return
   this.api.requestAccess(email, server, false).subscribe(x => { this.addUpdcon(x) })
 }

 addUpdcon(auth: AuthResponse) {
   const token = auth.payload
   const user = jwt_decode<UserData>(token)
   // не прочитали токен
   if (!user) {
     this.snack.open('Не удалось добавить подключение', 'Закрыть', { duration: 5000 })
     return
   }

   const agentCode = user.Code
   const server = user.Server

   // в токене указан неизвестный код сервера
   const findserv = this.servers.find(x => x.name === server)
   if (!findserv) {
     this.snack.open('Ошибка выбора сервера подключения', 'Закрыть', { duration: 5000 })
     return
   }

   const connectionId = server + '-' + agentCode;

   const current = this.connMgr.Connections.find(x => x.id === connectionId)

   // такое подключение уже есть
   if (current) {

     this.snack.open('Уже есть такое подключение - обновлено', 'Закрыть', { duration: 5000 })
     const refTokenDecode = jwt_decode<UserData>(current.rereshToken)

     if (refTokenDecode.exp < user.exp) {

       // обновляем токен
       current.rereshToken = token
       current.user = user

       this.connMgr.updateConnection(current)
       return
     }

   }


   const serverad: Connection = {
     id: connectionId,
     active: false,
     title: "[" + findserv.name + "] " + user.name,
     accessToken: "",
     rereshToken: token,
     user: user,
     server: findserv
   }

   this.connMgr.addConnection(serverad)

 }

 appleReg() {
   const splitHref = this.appleInput.split('/')
   const tokenInx = splitHref.findIndex(x => x === 'bot-auth') + 1

   // console.log(tokenInx)
   // console.log(splitHref[tokenInx])
   this.router.navigate(['bot-auth', splitHref[tokenInx]])
 }


}
