import { registerLocaleData } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import localeRu from '@angular/common/locales/ru'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

registerLocaleData(localeRu, 'ru')

if (environment.production) {
  console.log("enable Prod Mode")
  enableProdMode();
} else {
  console.log("Dev Mode")
}

platformBrowserDynamic().bootstrapModule(AppModule)

  .catch(err => console.error(err));




// platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
//   if ('serviceWorker' in navigator && environment.production) {
//     navigator.serviceWorker.register('./ngsw-worker.js');
//   }
// })
//   .catch(console.error);
