import { Injectable } from '@angular/core';
import { RpaHttpService } from '../http/rpa-http.service';
import { Observable, Observer, fromEvent, map, merge, of } from 'rxjs';
import { ErpPrice } from 'src/app/models/goods/product';
import { Good } from 'src/app/models/sync.model';

@Injectable({
  providedIn: 'root'
})
export class ErpService {


  private online = false

  constructor(private rpa: RpaHttpService,) {

    this.checkNet().subscribe(o => {
      this.online = o;
    })

  }


  getPrice(buypointId: string): Observable<ErpPrice | undefined> {

    if (!this.online) { return of(undefined) }

    return this.rpa.post<ErpPrice>('/v2/api/erp/getprice_v2', { buypointId })

  }

  getOffer(buypointId: string): Observable<Good[] | undefined> {
    if (!this.online) { return of(undefined) }
    return this.rpa.post<Good[]>('/v2/api/erp/GetOffer', { buypointId })
  }


  checkNet() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }


}

