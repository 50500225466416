import { SyncItemType, SyncJob, syncCategories } from "src/app/models/sync.model";

import { lastValueFrom } from "rxjs";
import { AbstractSyncDataJob } from "./AbstractSyncDataJob";
import { SyncClientsJob } from "./SyncClientsJob";

import { SyncGoodsJob } from "./SyncGoodsJob";
import { SyncQuestsJob } from "./SyncQuestsJob";
import { SyncMatrixJob } from "./SyncMatrixJob";
import { SyncOrdersJob } from "./SyncOrdersJob";
import { SyncOrdersSendJob } from "./SyncOrdersSendJob";
import { HttpErrorResponse } from "@angular/common/http";
import { SyncDebetsJob } from "./SyncDebetsJob";
import { SyncAdvancesJob } from "./SyncAdvancesJob";
import { SyncQuestsSendJob } from "./SyncQuestsSendJob";
import { SyncAdvancesSendJob } from "./SyncAdvancesSendJob";
import { SyncRunParameters } from "./sync.service";
import { SyncStartQuery } from "src/app/filters/SyncFilter";
import { RpaClientService } from "../net/rpa-client.service";
import { DbService } from "../data/idb.service";
import { ConnectionManagerService } from "../storage/connection-manager.service";
import jwtDecode from "jwt-decode";
import { UserData } from "src/app/models/server";


export class SyncHeaderJob extends AbstractSyncDataJob {

    cat: SyncItemType[] = [];
    full = false;


    /**
     *
     */
    constructor(id: string,
        name: string,
        hc: RpaClientService,
        db: DbService,
        private cm: ConnectionManagerService) {
        super(id, name, hc, db);

    }

    public setCat(para: SyncRunParameters) {
        this.cat = para.categories
        this.full = para.full
    }

    result: SyncJob[] = []

    override async run(): Promise<boolean> {

        this.result = []

        this.count = 1


        this.cat.forEach(c => {
            let job: SyncJob | undefined

            switch (c) {
                case 'Orders':
                    job = new SyncOrdersSendJob(c, "Выгрузка заказов", this.httpCredit, this.db);
                    break
                case 'Quests':
                    job = new SyncQuestsSendJob(c, "Выгрузка задач", this.httpCredit, this.db);
                    break
                case 'Advances':
                    job = new SyncAdvancesSendJob(c, 'Выгрузка авансов', this.httpCredit, this.db)
                    break
            }

            if (job !== undefined) {

                this.result.push(job)
            }
        })



        const start: SyncStartQuery = {
            kinds: this.cat,
            full: this.full,
            deviceUID: "update in httpCredit.syncStart",
            refreshToken: this.cm.activeServer?.rereshToken
        }

        const sq = this.httpCredit.syncStart(start)

        try {
            const head = await lastValueFrom(sq)

            if (head.accessToken) {
                const conn = this.cm.activeServer
                if (conn) {
                    const accUser = jwtDecode<UserData>(head.accessToken)

                    console.log(accUser.name)

                    conn.user = accUser
                    conn.accessToken = head.accessToken

                    this.cm.updateConnection(conn)
                }
            }


            head.items.forEach(element => {

                if (element.count > 0) {
                    const tmp = syncCategories.find(a => a.id == element.kind)
                    if (tmp !== undefined) {

                        let job: AbstractSyncDataJob | undefined

                        switch (element.kind) {
                            case 'Clients': {

                                job = new SyncClientsJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Goods': {

                                job = new SyncGoodsJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Matrix': {

                                job = new SyncMatrixJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Quests': {

                                job = new SyncQuestsJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Orders': {

                                job = new SyncOrdersJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Debets': {

                                job = new SyncDebetsJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            case 'Advances': {

                                job = new SyncAdvancesJob(element.kind, tmp.name, this.httpCredit, this.db);
                                break;
                            }
                            default: {

                                // its mock!!! SyncDataJob - is abstract
                                throw Error("неизвестный тип задачи синхронизации")
                                break;
                            }

                        }

                        if (job !== undefined) {
                            job.setMap(element)
                        
                            this.result.push(job)
                        }

                    } else {
                        console.error(`неизвестная категория ${element.kind}`)
                    }
                }


            });



        } catch (error: unknown) {

            this.error = true

            if (error instanceof Error) {
                this.message = error.message

            } if (error instanceof HttpErrorResponse) {

                switch (error.status) {
                    case 0: {
                        this.message = "Сервер недоступен. Проверьте доступ в интернет или попробуйте позже. " + error.message;
                        break;
                    }
                    // case 401: this.message = "Доступ запрещён. Попробуйте заново войти в приложение"; break;
                    case 401: this.message = "Доступ запрещён. Попробуйте позже или заново авторизоваться"; break;
                    default: this.message = error.message; break;
                }
            } else {
                console.error(error)
                this.message = 'Неизвестная ошибка'
                // if (error.error !== undefined) { this.message = error.error }
            }


            return false
        } finally {
            this.step++
            this.completed = true
        }



        return true

    }

}