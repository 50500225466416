<div class="page-content-block">
    <h2>Разделы справки</h2>
    <mat-accordion>

        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title class="datasheet-panel-title">
                    ПРИЛОЖЕНИЕ
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                <strong>Пользователь</strong>
                - учётная запись, с которой сотрудник регистрируется в приложении по электронной почте в системе ДАЛИМО.
            </p>
            <p>
                <strong>Привязанный агент</strong>
                - торговый представитель, от имени которого происходит работа в приложении. Для супервайзера имеется
                возможномть привязать к своей учётной записи (пользователю) несколько торговых агентов.
            </p>
            <p>
                <strong>Оффлайн функционал</strong>
                - возможности приложения, доступные без интернета. Вы можете создавать заказы, набивать товар(будут
                показаны каталог и цены на момент последней синхронизации), создавать авансы(ПКО). Отпавить созданные
                документы в 1С сможете добравшись до интернета и синхронизировавшись.
            </p>
            <p>
                <strong>Онлайн функционал</strong>
                - возможности приложения, доступные только при подключении к сети интернет. Синхронизация, загрузка
                остатков точек ОРС, матрицы и Z-отчётов, просмотр и отправка актов аренды - эти действия возможны только
                при подключении к сети.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    СИНХРОНИЗАЦИЯ
                </mat-panel-title>
            </mat-expansion-panel-header>

            <p>
                <span class="tab">Полностью</span> пересмотрена работа в РП-Маркете.
                Если раньше всё, что Вы нажимали на своём устройстве, сразу
                передавалось на сервер и была возможность работать только онлайн, то теперь Вы можете набивать заказы,
                находясь и без интернета. Сейчас любое Ваше действие в приложении остаётся лишь в нём до нажатия кнопки
                <button style="height: 24px;" mat-raised-button color="primary">Синхронизировать</button>. Ни заказы, ни
                авансы не уйдут в 1с,
                пока Вы не синхронизируетесь. Возможность работы
                на нескольких устройствах остаётся. Но помните о правильной последовательности синхронизации. Например,
                если Вы создавали заказ на одном устройстве, то увидить его на другом сможете лишь после того как
                подтвердите его на нём, синхронизируетесь с сервером.
            </p>

            <p>
                <span class="tab">Синхронизация</span> возможна как для всех изменений агента(во вкладке
                "Синхронизация"), так и только для
                заказов, авансов, клиентов и т.д. в соответсвующей вкладке. Например, если Вы ждёте обновления статуса
                срочного заказа, то не обязательно прыгать между вкладками, можете пользоваться кнопкой <mat-icon
                    class="icon_middle icon_sync">sync</mat-icon>
                во вкладке "Заказы".
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    КЛИЕНТЫ
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Клиенты - добавление и исключение точек в РПМаркет происходит автоматически минут через 10 после внесения
                изменений в 1с.</p>
            <p><strong>Статусы:</strong></p>
            <div class="desc_block"><mat-icon class="icon_middle" style="color:green;">eco</mat-icon> - ОРС</div>
            <div class="desc_block"><mat-icon class='icon_middle' style="color:darkslateblue;">pan_tool</mat-icon> -
                своя лицензия
            </div>
            <div class="desc_block">
                <mat-icon class="icon_middle" style="color: orange;">notification_important
                </mat-icon> - Точки с задолженностью.
            </div>
            <div class="desc_block">
                <mat-icon class="icon_middle" style="color: red;">warning
                </mat-icon> - Точки с просроченной задолженностью.
            </div>
            <div class="desc_block">
                <mat-icon class="icon_middle" style="color: red;">priority_high
                </mat-icon> - Невыполненные задачи.
            </div>
            <br>
            <p><strong>Фильтры:</strong></p>
            <!-- <div class="desc_block">Доступен договор - При включении показывает только точки, с которыми в 1С есть
                действующий договор.
            </div>
            <div class="desc_block">Заблокированные - При включении показывает также те точки, у которых в 1С стоит
                блок.</div> -->
            <div class="desc_block">
                <mat-icon class="icon_middle" style="color: orange;">notification_important
                </mat-icon> - При включении показывает только точки с задолженностью..
            </div>
            <div class="desc_block">
                <mat-icon class="icon_middle" style="color: red;">warning
                </mat-icon> - При включении показывает только точки с просроченной задолженностью.
            </div>
        </mat-expansion-panel>





        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title class="datasheet-panel-title">
                    ЗАКАЗЫ
                </mat-panel-title>
            </mat-expansion-panel-header>

            <p>
                <mat-icon fontSet=" material-icons-outlined" class="status_indicator"
                    style="color:darkorange;margin-left: 4px;">
                    sms </mat-icon> <span> - В заказе есть коментарии </span>
            </p>


            <p><strong>Статусы :</strong></p>
            <app-order-status-indicator class="desc_block" *ngFor="let status of [1,2,11,4 ,8,9, 7,6,5,3,12, 13,14,15,16,17,18 ]"
                class="status_indicator" [statusId]="status" [onlyIcon]="false"
                [description]="true"></app-order-status-indicator>
            <br>
            <div><strong>Фильтры:</strong></div>
        </mat-expansion-panel>

        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    АВАНСЫ (ПКО)
                </mat-panel-title>

            </mat-expansion-panel-header>
            <p>Создание Приходного Кассового Ордера не влияет на дебиторскую задолженность до разнесения денежных
                средств!!! Это просто удобный документ-заготовка для более быстрого разнесения.</p>
            <strong>Статусы:</strong>
            <app-advance-status *ngFor="let status of [1,2,5,3,7,4,6]" class="status_indicator" style="display: block;"
                [advanceState]="status" [withText]="true" [withInfo]="true"></app-advance-status>
        </mat-expansion-panel>

        <mat-expansion-panel class="datasheet-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title class="datasheet-panel-title">
                    ПОДДЕРЖКА
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                <strong>Бот в Telegram: </strong> <a href="https://t.me/DalimoHelpDesk_bot">HelpDesk</a>
            </p>
        </mat-expansion-panel>

    </mat-accordion>
</div>