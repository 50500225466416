<div style="min-height: 83vh;" swipe (next)="swipe(true)" (previous)="swipe(false)">
    <h1>Авансы</h1>
    <!-- <mat-form-field appearance="outline" class="matselect" style="max-width: 201px">
    <mat-label>Выберите дату</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="advanceDate" (dateChange)="findCashOrders()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field> -->

    <app-date-filter [(date1)]="advanceDateStart" [(date2)]="advanceDateEnd"
        (dateChange)="findCashOrders()"></app-date-filter>

    <mat-form-field appearance="outline" class="matselect">
        <mat-label>Филиал</mat-label>
        <mat-select [(ngModel)]="choosenFilial" (selectionChange)="applyFilter();  ">
            <mat-option>Все филиалы</mat-option>
            <mat-option *ngFor="let filial of listFilial;" [value]="filial?.id"> {{filial?.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="matselect">
        <mat-label>Организация</mat-label>
        <mat-select [(ngModel)]="choosenOrg" (selectionChange)="applyFilter()">
            <mat-option>Все организации</mat-option>
            <mat-option *ngFor=" let org of listOrgs" [value]="org?.id">{{org?.name}} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-spinner *ngIf="loading"></mat-spinner>

    <h3 *ngIf="!loading && !cashOrdersView.length">Нет авансов</h3>

    <div *ngIf="cashOrdersView.length">

        <h2>Количество: {{cashOrdersView.length }} шт. / Сумма: {{advSum() | currency:'RUB':'symbol':'1.2-2':'ru'}}</h2>


        <mat-accordion>

            <ng-container *ngFor="let avans of cashOrdersView ">

                <mat-expansion-panel>

                    <mat-expansion-panel-header>
                        <mat-panel-title class="CashOrdersList">
                            <span><app-advance-status [advanceState]="avans.state"></app-advance-status></span>

                            <span>{{avans.date | date:'dd.MM.YYYY'}} </span>
                            <span>{{avans.summ | currency:'RUB':'symbol':'1.2-2':'ru'}} </span>
                            <span>{{avans.number}}</span>
                            <span *ngIf="!avans.number"> - </span>

                        </mat-panel-title>
                        <mat-panel-title>

                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul>

                        <li><span>
                                <b>ТТ : </b> {{avans.buyPoint}}
                                <mat-icon style="cursor: pointer;color: blue;"
                                    (click)="linkOrsOrTT(avans.buyPointId)">link</mat-icon>
                            </span>
                        </li>

                        <li><b>Организация :</b> {{avans.organization}} </li>
                        <li><b>Филиал :</b> {{avans.filial}} </li>
                        <li><span><b>Документ :</b> {{avans.docType}} № {{avans.docNumber}} от {{avans.docDate | date:
                                'dd.MM.YYYY'}} </span></li>
                        <li><b>Состояние : </b>

                            <span><app-advance-status [advanceState]="avans.state"
                                    [withText]="true"></app-advance-status></span>

                        </li>
                        <li *ngIf="avans.error"><b>Ошибка : </b>

                            <span> {{avans.error}} </span>

                        </li>
                        <li><b>Источник : </b>
                            <span *ngIf="avans.source === 1">Из ЛК <mat-icon
                                    style="color: blue; font-size: 22px;">language

                                </mat-icon>
                            </span>
                            <span *ngIf="avans.source === 2">Из 1С <mat-icon
                                    style="color: rgb(177, 153, 22); font-size: 22px;">
                                    copyright</mat-icon>
                            </span>
                        </li>
                        <br>
                        <div *ngIf="avans.source === 1 && avans.state === 1">

                            <li style="list-style-type: none"><button mat-raised-button color="primary"
                                    (click)="confirmAdvance(avans)">Подтвердить</button>
                            </li>

                            <br>

                        </div>

                        <li *ngIf="avans.source === 1 && (avans.state === 1 || avans.state === 2)"
                            style="list-style-type: none"><button mat-raised-button color="warn"
                                (click)="deleteAdvance(avans)">Удалить АП</button></li>

                        <!-- <div *ngIf="isAdmin && avans.source === 1 && (avans.state === 4 || avans.state === 2) ">

                        <li style="list-style-type: none"><button mat-raised-button color="warn"
                                (click)="deleteAdvance(avans)">Удалить АП</button></li>
                    </div> -->
                    </ul>


                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>


    </div>

</div>