import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { QuestDefList, QuestItem } from 'src/app/models/quests/quest.model'


import { QuestItemInfoDialogComponent } from '../quest-item-info-dialog/quest-item-info-dialog.component'

@Component({
  selector: 'app-quest-custom-depend-select',
  templateUrl: './quest-custom-depend-select.component.html',
  styleUrls: ['./quest-custom-depend-select.component.scss'],
})
export class QuestCustomDependSelectComponent implements OnInit {
  @Input() disabled = true
  @Input() questItem!: QuestItem
  @Input() parent?: QuestItem
  @Input() trigger?: Subject<QuestItem>

  @Output() xevnt: EventEmitter<QuestItem> = new EventEmitter()

  itemSource: BehaviorSubject<QuestDefList[]> = new BehaviorSubject<QuestDefList[]>([])

  items: Observable<QuestDefList[]>
  info: string | undefined

  constructor(public dialog: MatDialog) {
    this.items = this.itemSource.asObservable()
  }

  ngOnInit(): void {
    if (this.parent) {
      const parentId = this.questItem.questDefinition.dependentId
      //    const recepient = this

      if (this.trigger && parentId) {
        this.trigger.subscribe({
          next: (chg) => {
            // TODO test it.
            this.handleTrigger(chg)
          }
        })

        this.fiterItemsByParent()
      }
      //  this.setItems([])
    } else {
      this.setItems(this.questItem.questDefinition.items)
    }
  }

  setItems(items: QuestDefList[]) {
    // поле доступно для редактирования только если есть варианты выбора
    // TODO - аккуратно переделать, когда потребуется получать варианты выбора по сети
    this.questItem.hidden = !(items && items.length > 0)

    const current: QuestDefList | undefined = items.find(
      (f) => f.listValue === this.questItem.value
    )

    this.info = current?.info
    this.questItem.value = current?.listValue ?? ''
    this.questItem.valueId = current?.id

    this.itemSource.next(items)
  }

  handleTrigger(chg: QuestItem) {
    const parentId = this.questItem.questDefinition.dependentId

    // если изменилось значение в списке вышестоящего элемента

    if (chg.questDefinition.dependentId === parentId) {
      this.fiterItemsByParent()
    }
  }

  private fiterItemsByParent() {
    const depValId = this.parent?.valueId ?? 0
    const depVal = this.parent?.value

    // если выбрано значение в списке вышестоящего элемента
    let temp: QuestDefList[] = []

    if (depValId > 0) {
      switch (this.questItem.questDefinition.dependencyKind) {
        case 3: {
          const rule = JSON.parse(this.questItem.questDefinition.dependencyRule) as Rule

          if (rule.matchValue) {
            const check = rule.matchValue.filter((r) => r === depVal)

            if (check && check.length > 0) temp = this.questItem.questDefinition.items
            else temp = []

            break
          }

          if (rule.notMatchValue) {
            const check = rule.notMatchValue.filter((r) => r === depVal)

            if (!check || check.length === 0) temp = this.questItem.questDefinition.items
            else temp = []

            break
          }

          break
        }
        case 5:
        case 4: {
          temp = this.questItem.questDefinition.items.filter(
            (x) => x.parentId === depValId
          )

          break
        }
      }
    }

    this.setItems(temp)
  }

  mySelectionChanged() {
    const index = this.questItem.questDefinition.items.find(
      (d) => d.id === this.questItem.valueId
    )

    this.info = index?.info
    this.questItem.value = index?.listValue ?? ''

    this.xevnt.next(this.questItem)
  }

  infoShow() {
    this.dialog.open(QuestItemInfoDialogComponent, {
      width: '800px',
      data: { name: this.questItem.value, info: this.info },
    })
  }
}

interface Rule {
  matchValue: string[] | undefined
  notMatchValue: string[] | undefined
}
