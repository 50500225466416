<mat-spinner *ngIf="loading; else branch"></mat-spinner>

<ng-template #branch>

  <h3>найдено заказов {{orders.length}}</h3>

  <!-- button mat-raised-button (click)="load()">
    <mat-icon>refresh</mat-icon>
    обновить
  </button -->

  <span *ngIf="orders.length">
    <span *ngFor=" let ord of orders">
      <app-order-list-item [order]="ord"></app-order-list-item>
    </span>
  </span>