import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { OrderModel } from 'src/app/models/order.model'


@Component({
  selector: 'app-order-info-table',
  templateUrl: './order-info-table.component.html',
  styleUrls: ['./order-info-table.component.scss'],
})
export class OrderInfoTableComponent {
  @Input() orderInfo!: OrderModel
  @Input() main: boolean = false
  @Output() orderInfoChange = new EventEmitter<OrderModel>();

  

}
