import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { QuestItem } from 'src/app/models/quests/quest.model'


@Component({
  selector: 'app-quest-custom-select',
  templateUrl: './quest-custom-select.component.html',
  styleUrls: ['./quest-custom-select.component.scss'],
})
export class QuestCustomSelectComponent implements OnInit {
  @Input() disabled = true
  @Input() questItem?: QuestItem
  @Output() xevnt: EventEmitter<QuestItem> = new EventEmitter()


  secret = '$65FF'
  selected = ''
  hiddenValue = ''



  ngOnInit(): void {
    if (!this.questItem) return

    if (
      !this.questItem.value ||
      this.questItem.value === '' ||
      this.questItem.questDefinition.items.find(
        (i) => i.listValue === this.questItem?.value
      )
    ) {
      this.selected = this.questItem.value?.toString()
    } else {
      this.selected = this.secret
    }

    this.hiddenValue = this.questItem.value?.toString()
  }

  onChanges(event: unknown) {
    if (!this.questItem) return // TODO test again

    this.questItem.value =
      this.selected === this.secret ? this.hiddenValue : this.selected

    this.xevnt.emit(this.questItem)
  }
}
