import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs';
import { ConfirmatorDialogComponent, ConfirmData } from 'src/app/components/confirmator-dialog/confirmator-dialog.component';
import { FilterSoftCheque } from 'src/app/filters/SoftChequeFilter';

import { DepartmentProfile } from 'src/app/models/Ukrop/Department';
import { SoftCheque } from 'src/app/models/Ukrop/softCheque';
import { ZReport } from 'src/app/models/Ukrop/zreport';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';


@Component({
  selector: 'app-z-report-list',
  templateUrl: './z-report-list.component.html',
  styleUrls: ['./z-report-list.component.scss']
})
export class ZReportListComponent implements OnInit {

  @Input() public ukrop: DepartmentProfile | undefined
  reports: ZReport[] = []
  softCheques: SoftCheque[] = []
  dateStart: Date | null = new Date()
  dateEnd: Date | null = new Date()
  loading: boolean = true

  addMode = 0

  constructor(private http: UkropClientService,
    private dialog: MatDialog,
    private snack: MatSnackBar) {
    this.dateStart?.setMonth(this.dateStart?.getMonth() - 3)
  }

  ngOnInit(): void {
    this.findReports()
    this.findSoftCheques()
  }

  clearData() {
    this.dateStart = null
    this.dateEnd = null
    this.findReports()
  }

  findReports() {
    this.loading = true
    if (this.ukrop) {
      const filter: FilterSoftCheque = {
        companyInn: this.ukrop.Department.Inn,
        companyKpp: this.ukrop.Department.Kpp,
        periodStart: this.dateStart,
        periodEnd: this.dateEnd
      } as FilterSoftCheque
      this.http.findZReports(filter)
        .pipe(finalize(() => { this.loading = false }))
        .subscribe(rep => { this.reports = rep })
    }
  }

  findSoftCheques() {
    if (!this.ukrop) return

    const filter: FilterSoftCheque = {
      // companyInn: this.ukrop.Department.Inn,
      // companyKpp: this.ukrop.Department.Kpp
      departmentId: this.ukrop.Department.Id
    }
    this.http.findSoftCheque(filter).subscribe(x => this.softCheques = x.filter(s=>s.StatusId !==4))
  }

  typeName(typeId: number) {
    switch (typeId) {
      case 1:
        return 'z-отчёт'
      case 2:
        return 'чек ИП по аренде'
      default:
        return 'неизвестно'
    }
  }

  statusName(statusId: number) {
    switch (statusId) {
      case 1:
        return 'создан'
      case 2:
        return 'принят'
      case 3:
        return 'отклонен'
      case 4:
        return 'передан'
      case 5:
        return 'доставлен'
      default:
        return 'неизвестно'
    }
  }

  addedReport(event: ZReport) { //TODO test again
    this.reports.unshift(event as ZReport)
  }

  removeReport(report: ZReport) {
    const dial = this.dialog.open(ConfirmatorDialogComponent, {
      data: {
        buttonCancelName: 'Отмена',
        buttonOkName: 'Да',
        title: 'Удаление z-отчета',
        question: `Вы уверены что хотите удалить ${this.typeName(report.ReportTypeId)}?`
      } as ConfirmData
    })

    dial.afterClosed().subscribe(x => { if (x) { this.deleteReport(report) } })


  }

  imageUrl(report: ZReport): string {
    return this.http.zReportUrl(report.FileUrl)

  }

  deleteReport(report: ZReport) {
    this.http.removeZReport([report.Id]).subscribe({
      next: () => this.findReports(),
      error: err => { this.snack.open(`Ошибка: ${err}`, 'Закрыть', { duration: 5000 }) }
    })
  }



}
