import { Component, Input, OnInit } from '@angular/core'
import { Quest } from 'src/app/models/quests/quest.model'


@Component({
  selector: 'app-quest-icon',
  templateUrl: './quest-icon.component.html',
  styleUrls: ['./quest-icon.component.scss'],
})
export class QuestIconComponent implements OnInit {
  @Input() quest?: Quest
  @Input() questState = 0

 

  ngOnInit(): void {
    if (this.quest) {
      this.questState = this.quest.stateId
    }
  }
}
