import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncQuestsJob extends SyncRealDataJob {


    override async clean(): Promise<void> {
        await this.db.clear("quests")
         
    }

    async save(r: SyncFetch) {



        const add = r.items.filter(a => a.quest && a.state === 1).map(a => a.quest!.quest);
        const del = r.items.filter(a => a.quest && a.state === 2).map(a => a.id);


        await this.db.deleteArray('quests', del);

        add.forEach(p => {
            if (!(p.created instanceof Date)) {
                p.created = new Date(Date.parse(p.created))
            }

            if (p.modified === undefined) {
                p.modified = 0
            }
        });

        await this.db.addArray('quests', add);

    }

}
