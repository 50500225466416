import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { AuthResponse, Profile, Server } from 'src/app/models/server';
import { RpaIdClientService } from 'src/app/services/net/rpa-id-client.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-token-generator',
  templateUrl: './token-generator.component.html',
  styleUrls: ['./token-generator.component.scss']
})
export class TokenGeneratorComponent implements OnInit {

  /**
   *
   */
  //сервера
  servers: Server[] = [
    {
      name: "RP-OLD",
      title: "RP"
    },
    {
      name: "BN",
      title: "Далимо"
    },

  ]


  server = ''


  profiles: Profile[] = []
  profile = new FormControl<Profile | undefined>(undefined)
  profiles$!: Observable<Profile[]>
  responce: AuthResponse | undefined;

  constructor(private api: RpaIdClientService,
    public connMgr: ConnectionManagerService,
    private snack: MatSnackBar,
  ) {


  }



  ngOnInit(): void {

    console.log("init")
  }


  updateAgents() {
    const filter = {
      email: this.connMgr.activeUser.user.Email,
      server: this.server,
      sendEmail: true,

    }

    this.api.getProfiles(filter).subscribe({
      next: x => { this.profiles = x.profiles; this.auto() },
      error: err => { this.snack.open('Ошибка загрузки агентов', 'Закрыть', { duration: 5000 }) }
    })
  }

  private auto() {
    this.profiles$ = this.profile.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.agentName;
        return name ? this._filter(name as string) : this.profiles.slice();
      }),
    );

  }


  private _filter(name: string): Profile[] {
    if (!name) return this.profiles
    const filterValue = name.toLowerCase();

    return this.profiles.filter(prof => prof.agentName.toLowerCase().includes(filterValue));
  }

  displayFn(prof: Profile): string {
    return prof && prof.agentName ? prof.agentName : '';
  }


  requestAccess() {
    if (!this.profile)
      return


    const pro = this.profile.getRawValue()

    if (!pro) return


    this.api.requestAccess(pro.email, pro.server, false).subscribe(r => {
      this.responce = r

    })

  }


}
