import { Component } from '@angular/core';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.scss'],
})

export class DatasheetComponent  {
  panelOpenState = true

}
