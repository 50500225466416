import { SyncJob, SyncMapItem } from "src/app/models/sync.model";
import { RpaClientService } from "../net/rpa-client.service";

import { DbService } from "../data/idb.service";

export abstract class AbstractSyncDataJob implements SyncJob {
    public id: string
    public name: string
    public completed = false

    pageSize = 128

    error = false
    message = ""
    step = 0
    count = 0
    spent: string | undefined
    map: SyncMapItem | undefined;

    protected httpCredit: RpaClientService


    constructor(id: string, name: string, hc: RpaClientService, protected db: DbService) {
        this.id = id
        this.name = name
        this.httpCredit = hc
    }


    progress(): number {
        if (this.count === 0) return 0
        return Math.round(this.step * 1000.0 / this.count) / 10.0
    }


    public setMap(map: SyncMapItem) {
        this.map = map
        this.count = map.count
    }

    async doWork(full: boolean): Promise<boolean> {

        const t1 = new Date();
        const ok = await this.run(full)

        const t2 = new Date();
        const dif = t2.getTime() - t1.getTime();

        const Seconds_from_T1_to_T2 = dif / 1.0;
        const x = Math.round(Seconds_from_T1_to_T2) / 1000;

        this.spent = (this.spent ?? "") + `${x} сек.`

        return ok

    }
    async run(full: boolean): Promise<boolean> {

        this.completed = true
        return true

    }

}