<div class="filter_block">

    <mat-button-toggle (click)="preiodDate =! preiodDate;saveLocal()" class="matButtonTogglePeriodical">
        <mat-icon *ngIf="preiodDate;else iconOneDate">manage_history_icon</mat-icon>
    </mat-button-toggle>

    <ng-template #iconOneDate>
        <mat-icon>history_icon</mat-icon>
    </ng-template>

    <ng-container *ngIf="preiodDate;else oneDate">
        <mat-form-field appearance="outline" class="">
            <mat-label>Укажите период</mat-label>
            <mat-date-range-input matInput [rangePicker]="picker">
                <input matStartDate placeholder="Начало" name="actualDate1" [(ngModel)]="date1">
                <input matEndDate placeholder="Конец" name="actualDate2" [(ngModel)]="date2" (dateChange)="changed()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker class="B"></mat-date-range-picker>
        </mat-form-field>


    </ng-container>

    <ng-template #oneDate>
        <button (click)="changeDate(false)" class="arrow"
            mat-icon-button><mat-icon>keyboard_arrow_left</mat-icon></button>
        <mat-form-field appearance="outline" class="fixed1">
            <mat-label>Дата</mat-label>
            <input matInput [matDatepicker]="picker1" name="actualDate1" [(ngModel)]="date1" (dateChange)="changed()">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 class="B"></mat-datepicker>
        </mat-form-field>
        <button (click)="changeDate(true)" class="arrow"
            mat-icon-button><mat-icon>keyboard_arrow_right</mat-icon></button>
    </ng-template>

</div>