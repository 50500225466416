import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, from, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { GroupModel as Group } from 'src/app/models/goods/product';
import { Group } from 'src/app/models/sync.model';
import { DbService } from 'src/app/services/data/idb.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';


/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: string,
    public value: Group,
    public level = 1,
    public expandable = false,
    public isLoading = false,
  ) { }
}

@Component({
  selector: 'app-groups-tree',
  templateUrl: './groups-tree.component.html',
  styleUrls: ['./groups-tree.component.scss']
})
export class GroupsTreeComponent implements OnInit {
  @Output() selectedNode: EventEmitter<Group | undefined> = new EventEmitter();
  db: DbService | undefined;

  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource | undefined;

  constructor(private cm: ConnectionManagerService) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);



  }

  ngOnInit() {

    from(this.cm.getDb()).subscribe((database) => {
      if (!database) return

      this.dataSource = new DynamicDataSource(this.treeControl, database);
      this.dataSource.data = []
      this.db = database;

      this.db.getTopGroups().subscribe(x => {
        if (this.dataSource)
          this.dataSource.data = conver(x)
      })


    })

  }



  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable;
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;


  public selectNode(node: DynamicFlatNode): void {
    this.selectedNode.emit(node.value)

  }

}

function conver(groups: Group[]): DynamicFlatNode[] {

  const result = [] as DynamicFlatNode[]

  groups.forEach(i => {
    result.push({
      expandable: i.right - i.left > 1,
      isLoading: false,
      item: i.name,
      level: i.level + 1,
      value: i
    })
  })


  result.sort((a, b) => { return a.item.localeCompare(b.item) })
  return result
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DbService,
  ) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
    console.log(collectionViewer)
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {

    node.isLoading = true;

    this._database.getPGroupsByParent(node.value.code).subscribe(a => {
      const children = conver(a)
      const index = this.data.indexOf(node);
      if (!children || index < 0) {
        // If no children, or cannot find the node, no op
        node.isLoading = false;
        return;
      }



      //    setTimeout(() => {
      if (expand) {
        const nodes = children.map(
          name => new DynamicFlatNode(name.item,
            name.value,
            node.level + 1,
            name.value.right - name.value.left > 1),
        );
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;

        for (
          let i = index + 1;
          i < this.data.length && this.data[i].level > node.level;
          i++
        ) {
          count++
        }

        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
      //   }, 150);

    })
  }
}
