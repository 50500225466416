import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyncRunParameters, SyncService } from 'src/app/services/sync/sync.service';

@Component({
  selector: 'app-sync-dialog',
  templateUrl: './sync-dialog.component.html',
  styleUrls: ['./sync-dialog.component.scss']
})
export class SyncDialogComponent {

  running = false
  runParams: SyncRunParameters;

  constructor(public sync: SyncService,
    public dialogRef: MatDialogRef<SyncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SyncRunParameters
  ) {
    dialogRef.disableClose = true
    this.runParams = data

    this.running = true


    this.sync.run(this.runParams).subscribe((ok) => {
      this.running = false
    })
    
  }

  public reset() {
    this.sync.reset()

    this.running = false

    this.dialogRef.close()
  }


  

}

