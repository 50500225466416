<h1 mat-dialog-title>Заказ {{data.order.number}}</h1>
<div mat-dialog-content>

  <p>При попытке {{data.action}} заказ возникла ошибка: </p>
  <p style="word-wrap: break-word; white-space: pre-line; display: block;  ">
    {{data.error.message}}
  </p>

  <!-- <span [innerHTML]="data.error.message" ></span> -->
  <p *ngIf="data.error.canForce">
    можно попробовать {{data.action}} ещё раз
  </p>
  <!-- pre style="font-size: 6pt;">
  {{data | json}}
  </pre -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="{force: true}" color="accent"
    [disabled]="!data.error.canForce">{{data.action}}</button>
  <button mat-raised-button [mat-dialog-close]="{force: false}"
    cdkFocusInitial>Закрыть</button>
</div>
