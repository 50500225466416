import { Component, Input } from '@angular/core'

import { OrderStatus } from 'src/app/models/order.model'
import { statusList } from 'src/app/services/data/orders.service'

@Component({
  selector: 'app-order-status-indicator',
  templateUrl: './order-status-indicator.component.html',
  styleUrls: ['./order-status-indicator.component.scss'],
})
export class OrderStatusIndicatorComponent {
  status: OrderStatus | undefined

  @Input() onlyIcon = false
  @Input() description = false

  

  private id = 0
  @Input() 
  set statusId(value: number) {
    this.id = value
    this.status = this.getStatus(this.id)
  }
  get statusId(): number {
    return this.id
  }

  // constructor() { }


  getStatus(status: number): OrderStatus {

    const idx = statusList.findIndex(a => a.id === status)
    if (idx < 0) {
      return {
        color: 'black',
        icon: 'error',
        name: 'не определён'
      } as OrderStatus
    } else {
      return statusList[idx]
    }
  }

  // debug() {
  //   alert(this.statusId)
  // }

  public get statusColor(): string {
    return this.status?.color ?? "black"
  }


  public get statusIcon(): string {
    return this.status?.icon ?? "error"
  }

  public get statusName(): string {
    return this.status?.name ?? "error"
  }





}
