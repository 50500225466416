import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
 

@Component({
  selector: 'app-select-order-client',
  templateUrl: './select-order-client.component.html',
  //styleUrls: ['./select-order-client.component.scss']
})
export class SelectOrderClientComponent {
  constructor(public dialogRef: MatDialogRef<SelectOrderClientComponent> 
    ) {

  }

  clientSelected(a: any) {
    console.log(a)
    this.dialogRef.close(a)
  }
}
