import { SyncFetch, SyncFetchItem } from "src/app/models/sync.model"
import { DebetService } from "../data/debet.service"
import { ConnectionManagerService } from "../storage/connection-manager.service"
import { AbstractSyncDataJob } from "./AbstractSyncDataJob"
import { AdvanceWrapper } from "src/app/models/DebetDocs"
import { HttpErrorResponse } from "@angular/common/http"




export class SyncAdvancesSendJob extends AbstractSyncDataJob {


    //TODO:test
    override async run(): Promise<boolean> {

        let debetSerice = new DebetService(this.db)



        const send = await debetSerice.getAdvanceByStatus(2)


        if (!send || send.length === 0) {
            this.completed = true
            return true

        }


        this.count = send.length

        const items: SyncFetchItem[] = send.map(x => {

            const adv: AdvanceWrapper = { advance: x }

            return {
                id: x.id,
                preorder: null,
                state: 5,
                client: null,
                good: null,
                goodGroup: null,
                matrix: null,
                advance: adv,
                debet: null,
                quest: null
            } as SyncFetchItem
        })

        const data: SyncFetch = {
            items: items
        }

        try {
            const res = await this.httpCredit.syncSendPromise(data)
            await this.save(res)
            this.step = send.length
            this.completed = true


        } catch (error) {
            this.error = true
            this.completed = true
            if (error instanceof Error) {
                this.message = error.message

            } if (error instanceof HttpErrorResponse) {
                this.message = error.message

            } else {
                console.error(error)
                this.message = 'unknown error'
                // if (error.error !== undefined) { this.message = error.error }
            }


            return false
        }


        return true
    }


    private async save(answer: SyncFetch) {

        answer.items.forEach(async (x) => {
            if (x.advance?.advance) {
                x.advance.advance.date = new Date(x.advance.advance.date)
                x.advance.advance.created = new Date(x.advance.advance.created)
                x.advance.advance.docDate = new Date(x.advance.advance.docDate)
                await this.db.update('advances', x.advance.advance)
            }


        })



    }


}