import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ConnectionManagerService } from "./connection-manager.service";



@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  /**
   *
   */
  constructor(private cm: ConnectionManagerService, private router: Router) { //


  }

  canActivate(): boolean | UrlTree {
    if (this.cm.activeServer !== undefined) { return true }
    else {
      // такой вот костыль. вроде даже каноничный.
      return this.router.parseUrl("/")
      //  return false
    }
  }

  canActivateAdmin(): boolean | UrlTree {
    return true
    
    // if (this.cm.activeServer !== undefined &&
    //   this.cm.activeServer.user.role.indexOf("admin") >= 0) {
    //   return true
    // }
    // else {
    //   // такой вот костыль. вроде даже каноничный.
    //   return this.router.parseUrl("/")
    //   //  return false
    // }
  }
  // canMatch(currentUser: UserToken): boolean {
  //   return true;
  // }
}

export const canActivateLoggedIn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(); // inject(UserToken), route.params.id
  };


export const canActivateAdmin: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateAdmin(); // inject(UserToken), route.params.id
  };


