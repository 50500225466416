<div class="tab-container">
<h2> Категория матрицы - {{matrix?.category}} </h2>
    <div *ngIf="ukropLoading ; else goodBranch">
        <mat-spinner></mat-spinner>
    </div>

    <ng-template #goodBranch>

        <div> <span *ngIf="restDate; else norest">Остатки ТТ по состоянию на {{restDate | date:'dd.MM.yyyy hh:mm'}}
            </span>
            <ng-template #norest>Остатки ТТ недоступны</ng-template>
        </div>

        <mat-slide-toggle [(ngModel)]="onlyAvailable">только доступные
        </mat-slide-toggle>

        

        <div *ngFor="let cat of matrix?.goodCategories" style="margin-top: 8px;">


            <button mat-button (click)="cat.hidded = !cat.hidded">
                <h3 class="cat-button">{{cat.name}}
                    <mat-icon *ngIf="cat.hidded">keyboard_arrow_right</mat-icon>
                    <mat-icon *ngIf="!cat.hidded">keyboard_arrow_down</mat-icon>
                </h3>
            </button>

            <div [class.hiddencat]="cat.hidded" style="margin-left: 8px;">

                <div *ngFor="let block of getBlockByCateg(cat.id)"
                    [class]="colorBlock(block) ? 'matrix-block' : 'matrix-block_org'">

                    <div style="cursor: pointer;" (click)="block.show = !block.show">
                        <mat-icon *ngIf="!block.show">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="block.show">keyboard_arrow_down</mat-icon>



                        <b> {{block.title}} </b><br>

                        <span class="label">Остаток</span>
                        <span class="air amount">{{ block.storeRest }}</span>


                        <span *ngIf="isOwn">

                            <span class="label">Остаток ТТ: </span>
                            <span class="air"
                                [ngClass]="{'amountTT' : (block.rest) > 0 && ukropAvailable, 'amountTT0' : (block.rest) <= 0 || !ukropAvailable }">

                                {{(ukropAvailable ? (block.rest): "недоступно")}}
                            </span>

                        </span>

                        <span *ngIf="orderedBlock(block)">
                            <span class="air label"><span class="ordered">в заказе
                                    {{ orderedBlock(block) }}</span>
                            </span>
                        </span>

                    </div>

                    <ng-container *ngIf="block.show">

                        <!-- <div class="rest-list2" *ngFor="let good of block.goods"> -->
                        <div class="rest-list2" *ngFor="let good of getLines(block.goods)">
                            <div (click)="clickOnItem(good)" [ngClass]="{'rests-item1' : good.storeRest && good.storeRest > 0,
                                 'rests-item0' : !good.storeRest || good.storeRest <= 0  }">
                                {{good.name}}<br>

                                <span class="label">на складе</span>
                                <span class="air" [ngClass]="{'amount' : (good.storeRest ?? 0 > 0)}">{{(good.storeRest
                                    ??
                                    0)}}</span>

                                <span class="label">Цена</span>

                                <span *ngIf="!personalPrice(good);else persPrice" class="air currency">
                                    <!-- {{( good.good &&
                                    good.good.prices
                                    !== undefined &&
                                    good.good.prices.length> 0?
                                    good.good.prices[0].price : 0) -->
                                    {{showPrice(good)
                                    |
                                    currency
                                    :'RUB':'symbol':'1.2-2':'ru'}}</span>

                                <ng-template #persPrice>
                                    <span class="air">
                                        <s class="label">
                                            <!-- {{(good.good && good.good.prices !== undefined &&
                                            good.good.prices.length> 0?
                                            good.good.prices[0].price : 0) -->
                                            {{showPrice(good)
                                            |
                                            currency
                                            :'RUB':'symbol':'1.2-2':'ru'}}</s> <span
                                            class="air currency">{{personalPrice(good)?.price | currency
                                            :'RUB':'symbol':'1.2-2':'ru'}}</span>
                                    </span>
                                </ng-template>

                                <!-- <span class="air currency">{{(good.good && good.good.prices &&
                                    good.good.prices.length > 0?
                                    good.good.prices[0].price : 0)|
                                    currency
                                    :'RUB':'symbol':'1.2-2':'ru'}}</span> -->

                                <span *ngIf="isOwn">

                                    <span class="label">Остаток ТТ: </span>
                                    <span class="air"
                                        [ngClass]="{'amountTT' : (good.point_rests ?? 0) > 0 && ukropAvailable, 'amountTT0' : (good.point_rests ?? 0) <= 0 || !ukropAvailable }">

                                        {{(ukropAvailable ? (good.point_rests ?? 0): "недоступно")}}
                                    </span>

                                </span>

                                <div style="display: inline-block; min-width: 140px;">


                                    <!-- span class="label">расчёт: </span>
                                    <span class="air amount">{{item.advised}}</span -->

                                    <span *ngIf="ordered(good.goodId)">
                                        <span class="air label"><span class="ordered">в заказе
                                                {{ ordered(good.goodId)}}</span>
                                        </span>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </ng-container>



                </div>

                <div *ngFor="let good of getLinesWihoutBlock(cat)">
                    <div class=" rests-list" (click)="clickOnItem(good)" [ngClass]="{'rests-item1' : good.storeRest && good.storeRest > 0,
                        'rests-item0' : !good.storeRest || good.storeRest <= 0}">
                        {{good.name}}<br>

                        <span class="label">на складе</span>
                        <span class="air" [ngClass]="{'amount' : (good.storeRest ?? 0 > 0)}">{{(good.storeRest
                            ??
                            0)}}</span>

                        <span class="label">Цена</span>

                        <span *ngIf="!personalPrice(good);else persPrice" class="air currency">
                            <!-- {{( good.good &&
                            good.good.prices
                            !== undefined &&
                            good.good.prices.length> 0?
                            good.good.prices[0].price : 0) -->
                            {{showPrice(good)
                            |
                            currency
                            :'RUB':'symbol':'1.2-2':'ru'}}</span>

                        <ng-template #persPrice>
                            <span class="air">
                                <s class="label">
                                    <!-- {{(good.good && good.good.prices !== undefined &&
                                    good.good.prices.length> 0?
                                    good.good.prices[0].price : 0) -->
                                    {{showPrice(good)
                                    |
                                    currency
                                    :'RUB':'symbol':'1.2-2':'ru'}}</s> <span
                                    class="air currency">{{personalPrice(good)?.price | currency
                                    :'RUB':'symbol':'1.2-2':'ru'}}</span>
                            </span>
                        </ng-template>


                        <span *ngIf="isOwn">

                            <span class="label">Остаток ТТ: </span>
                            <span class="air"
                                [ngClass]="{'amountTT' : (good.point_rests ?? 0) > 0 && ukropAvailable, 'amountTT0' : (good.point_rests ?? 0) <= 0 || !ukropAvailable }">

                                {{(ukropAvailable ? (good.point_rests ?? 0): "недоступно")}}
                            </span>

                        </span>

                        <span *ngIf="ordered(good.goodId)">
                            <span class="air label"><span class="ordered">в заказе
                                    {{ ordered(good.goodId)}}</span>
                            </span>
                        </span>

                    </div>

                </div>


            </div>

        </div>


    </ng-template>
</div>
<br>