import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncTimerService {


  private timer: number = 0
  private timerSubj = new Subject<number>()

  constructor() {
    this.timerSubj.next(this.timer)
  }


  setTimer(timeMs: number) {
    this.timer = timeMs
    let int = setInterval((x) => {
      this.timer -= 1000
      this.timerSubj.next(this.timer)
      if (this.timer <= 0) {
        clearInterval(int)
      }
    }, 1000)
  }

  timerObs() {
    return this.timerSubj.asObservable()
  }
}
