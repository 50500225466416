import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Connection, User } from 'src/app/models/server';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-agent-device',
  templateUrl: './agent-device.component.html',
  styleUrls: ['./agent-device.component.scss']
})
export class AgentDeviceComponent {

  data: any = ''
  conn: Connection | undefined;
  user: User | undefined;
  agentId: string | undefined;
  deviceId = ''


  constructor(private api: RpaHttpService,
    private cm: ConnectionManagerService,
    private stor: LocalStorageService,
    route: ActivatedRoute,
    private snack: MatSnackBar) {

    this.deviceId = this.stor.widevineId

    route.paramMap.subscribe(p => {
      this.agentId = p.get("id") ?? undefined

      this.subCon()
    })



  }


  subCon() {

    if (this.agentId === undefined) {
      return
    }

    const subUser = this.cm.activeUserchange().subscribe(x => {

      this.conn = x?.connections?.find(x => x.active)
      this.user = x

      this.subApi()
    })


  }

  subApi() {

    if (!this.user) {
      return
    }

    
    this.api.get(`/v2/api/admin/GetAgent/${this.agentId}`).subscribe({
      next: (data) => {
        this.data = data
      }
    })



  }
}
