import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

  readonly VAPID_PUBLIC_KEY = "BOe7PNTBfGBn5VQ5P7kh5yfjbODJFoQFaqlWy-Ehiha2UfcSjwUfOjOgWMwMnUUf7VL5d33izr5LgFeZaHmluDY";

  constructor(
    private swPush: SwPush,
    private newsletterService: NotificationsService) {

    if (!this.swPush.isEnabled) {
      console.log(!this.swPush.isEnabled)
    } else {
      this.swPush.messages.subscribe({
        next: (message) => {
          console.log(message)
        },
        error: (err) => {
          console.log(err)
        }
      })


      this.swPush.notificationClicks.subscribe({
        next: (action) => {
          console.log(action)
        },
        error: (err) => {
          console.log(err)
        }
      })

    }

  }

  subscribeToNotifications() {


    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
      .catch(err => console.error("Could not subscribe to notifications", err));




  }

  notifyMe() {
    const text = new Date().toLocaleDateString()
    this.newsletterService.notifyMe(text).subscribe()
  }

}
/*
{"publicKey":"BOe7PNTBfGBn5VQ5P7kh5yfjbODJFoQFaqlWy-Ehiha2UfcSjwUfOjOgWMwMnUUf7VL5d33izr5LgFeZaHmluDY","privateKey":"qKa6h3DGwNbU9fyx-MFD9G4ypW6829dytoYY0KCRLro"}
*/