<div class="photo-container" *ngIf="questItem">

  <mat-spinner *ngIf="loading; else branch"></mat-spinner>
</div>

<ng-template #branch>
  <div *ngIf="error; else goodBranch">
    <h3>Не загружено! Загрузить фото можно только в онлайн режиме</h3>
    <button mat-icon-button (click)="updateData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #goodBranch>
  <h3>{{questItem.questDefinition.name}}
    {{(questItem.questDefinition.mandatory ? "*" : "")}}

  </h3>

  <app-questionare-ors-photo-add [id]="questItem.id" (photoAddComplete)="imageAdded($event)" *ngIf="!disabled">
  </app-questionare-ors-photo-add>


  <div class="zcontainer">
    <span class="example-card" *ngFor="let image of images">


      <img class="zreport" [src]="imgUrl(image) | secure | async" />

      <!--             

        <img class="zreport" [src]="imgUrl(image)" />


          -->
      <div class="kill">
        <button mat-button (click)="remove(image)" color="warn" *ngIf="!disabled">
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </span>
  </div>
</ng-template>