/* eslint-disable @typescript-eslint/no-explicit-any */
// lint выклюил из-за функций обработки HTMLInputElement
import { HttpEvent, HttpEventType } from '@angular/common/http'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { QuestImage } from 'src/app/models/quests/quest.model'
import { QuestsHttpService } from 'src/app/services/net/quests-http.service'
 

@Component({
  selector: 'app-questionare-ors-photo-add',
  templateUrl: './questionare-ors-photo-add.component.html',
  styleUrls: ['./questionare-ors-photo-add.component.scss'],
})
export class QuestionareOrsPhotoAddComponent {
  @Input() id = 0
  @Output() photoAddComplete: EventEmitter<QuestImage> = new EventEmitter<QuestImage>()

  public typename = ''

  file = new FormControl()

  progress = 0

  addMode = false
  error = ''
  fileName = ''

  constructor(
    public fb: FormBuilder,
    public api: QuestsHttpService,
    public snack: MatSnackBar
    
  ) {}

  setAddMode(m: boolean) {
    this.addMode = m

    this.file.setValue(undefined)
    this.fileName = ''
  }

  uploadFile(event: any) {
    if (event.target instanceof HTMLInputElement) {
      const files = (event.target as HTMLInputElement).files
      if (files && files.length) {
        const file = files[0]
        this.file.setValue(file)
        this.fileName = file.name
      }
    }
    // this.form.patchValue({
    //   avatar: file
    // });
    // this.form.get('avatar').updateValueAndValidity()
  }

  submitUser() {
    this.error = ''

    const limit = 5.05
    try {
      if (this.file.value.size > limit * 1024 * 1024) {
        this.error = `Размер файла не должен превышать ${limit} Мб`

        this.snack.open(this.error, 'Закрыть', { duration: 7000 }  )
//        this.snack.open("", 'Закрыть', { duration: 7000 }  )

    
        return
      }
    } catch (error) {
    //  this.logger.error(error, 'QuestionareOrsPhotoAddComponent')
      this.error = 'не указан файл'
      return
    }

    this.api
      .addQuestPhoto(new Date(), this.id.toString(), 0, this.file.value)
      .subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent: {
              // this.logger.verbose(
              //   'Request has been made!',
              //   'QuestionareOrsPhotoAddComponent'
              // )
              this.progress = 1
              break
            }
            case HttpEventType.ResponseHeader: {
              // this.logger.verbose(
              //   'Response header has been received!',
              //   'QuestionareOrsPhotoAddComponent'
              // )
              this.progress = 99
              break
            }
            case HttpEventType.UploadProgress: {
              this.progress = event.total
                ? Math.round((event.loaded / event.total) * 100)
                : 0

              // this.logger.verbose(
              //   `Uploaded! ${this.progress}%`,
              //   'QuestionareOrsPhotoAddComponent'
              // )
              break
            }
            case HttpEventType.Response: {
          //    this.logger.debug(JSON.stringify(event.body))
              this.photoAddComplete.emit(event.body)

            //  this.logger.verbose(`Uploaded!`, 'QuestionareOrsPhotoAddComponent')
              setTimeout(() => {
                this.progress = 0
                this.file.reset()
                this.setAddMode(false)
              }, 500)
              break
            }
            default: {
              // this.logger.debug(
              //   `Unknown event type ${event.type}: ` + JSON.stringify(event),
              //   'QuestionareOrsPhotoAddComponent'
              // )
              break
            }
          }
        },
        (error) => {
          //this.logger.error(JSON.stringify(error), 'QuestionareOrsPhotoAddComponent')
          this.progress = 0
          this.error = 'Ошибка при отправке файла.'
          console.log(error)
        }
      )
  }
}
