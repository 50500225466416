<span style="color: red;">{{error}}</span>

<!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<!-- <div *ngIf="profile && ors"> -->
<div *ngIf="ors">
    <h1>{{ors.name}}
        <span class="warn" *ngIf="!onlineUkrop && !loading">(УКРОП НЕ ДОСТУПЕН)</span>
        <span class="warn" *ngIf="onlineUkrop && !loading && !profile">(ТТ в УКРОПе не найден)</span>
    </h1>
    <mat-tab-group>

        <mat-tab label="Реквизиты">
            <table width="100%">
                <tr>
                    <td width="50%">

                        <dl *ngIf="profile;else offlineInfo">
                            <dt>Статус</dt>
                            <dd class="g">{{profile.Department.Status}}

                                <p *ngIf="!profile.Department.Active"> <small class="warning">закрыта</small></p>
                            </dd>

                            <dt>ИНН</dt>
                            <dd class="g">{{profile.Department.Inn}}</dd>

                            <dt>КПП</dt>
                            <dd class="g">{{profile.Department.Kpp}}

                                <span *ngIf="profile.Department.Kpp !== ors?.kpp" class="warning">ошибка
                                    в
                                    реквизитах!</span>

                            </dd>
                            <dt>РАР</dt>
                            <dd class="g">{{profile.Department.Egaisnumber}}</dd>

                            <dt>Код 1С</dt>
                            <dd class="g">{{!ors.code ? '-' : ors.code }}</dd>

                            <dt>Тип цен</dt>
                            <dd class="g">{{ors.priceType}} - {{priceTypeName}} </dd>



                            <dt>Ответственный ОРС</dt>
                            <dd class="g">{{profile.TradeAgent?.Name}} </dd>


                            <ng-container *ngIf="profile.DepartmentSchema">
                                <dt>Схема работы</dt>
                                <dd class="g">{{profile.DepartmentSchema.Schema}} <span
                                        *ngIf="!profile.DepartmentSchema">Не
                                        указано</span>
                                </dd>
                                <dt>Процент</dt>
                                <dd class="g">
                                    {{profile.DepartmentSchema.SchemaValue}}
                                </dd>


                                <dt>Партнер</dt>
                                <dd class="g">{{profile.DepartmentSchema.Partner.Name}}</dd>
                                <dt>ИНН Партнера</dt>
                                <dd class="g">{{profile.DepartmentSchema.Partner.Inn}}</dd>
                            </ng-container>


                            <ng-container *ngIf="profile.DepartmentCategory">
                                <dt>Категория</dt>
                                <dd class="g">{{profile.DepartmentCategory.Name}}
                                </dd>
                            </ng-container>

                            <ng-container *ngIf="profile.PriceModel">
                                <dt>Ценовая модель</dt>
                                <dd class="g">{{profile.PriceModel.Name}}
                                </dd>
                            </ng-container>

                        </dl>

                        <ng-template #offlineInfo>
                            <dl>
                                <dt>Статус</dt>
                                <dd class="warn bold">
                                    -
                                </dd>

                                <dt>ИНН</dt>
                                <dd class="g">{{ors.inn}}</dd>

                                <dt>КПП</dt>
                                <dd class="g">{{ors.kpp}}
                                </dd>

                                <dt>РАР</dt>
                                <dd class="g">{{!ors.fsrarid ? '-' : ors.fsrarid }}</dd>
                                <dt>Код 1С</dt>
                                <dd class="g">{{!ors.code ? '-' : ors.code }}</dd>


                                <dt>Ответственный ОРС</dt>
                                <dd class="g">{{ors.agent ? ors.agent : '-'}} </dd>

                                <dt>Схема работы</dt>
                                <dd class="warn bold"> - </dd>
                                <dt>Процент</dt>
                                <dd class="warn bold"> - </dd>


                                <dt>Партнер</dt>
                                <dd class="warn bold"> - </dd>
                                <dt>ИНН Партнера</dt>
                                <dd class="warn bold"> - </dd>

                                <ng-container *ngIf="ors.category">
                                    <dt>Категория</dt>
                                    <dd class="g">{{ors.category}}
                                    </dd>
                                </ng-container>

                                <dt>Ценовая модель</dt>
                                <dd class="warn bold"> - </dd>

                            </dl>
                        </ng-template>

                    </td>
                </tr>
                <tr>
                    <td>
                        <app-create-order-button [buypointId]="ors.id"></app-create-order-button>
                    </td>
                </tr>

            </table>
            <br>

            <!-- <pre>{{ors | json}}</pre> -->
        </mat-tab>

        <!-- Доставка -->
        <mat-tab *ngIf="ors.deliveryDaysOfWeek"  label="Доставка">
            <app-client-delivery-info [buypoint]="ors"></app-client-delivery-info>
        </mat-tab>

        <!-- Задолженность -->
        <mat-tab>
            <ng-template mat-tab-label>Задолженность</ng-template>
            <ng-template matTabContent>

                <app-debet-doc-list [buypoint]="ors"></app-debet-doc-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Остатки" [disabled]="!onlineUkrop || !profile">
            <ng-template matTabContent><app-ors-rests [client]="ors"></app-ors-rests></ng-template>
        </mat-tab>

        <mat-tab label="Акты" [disabled]="!onlineUkrop || !profile">
            <ng-template matTabContent>
                <app-act-list [client]="ors" [department]="profile?.Department"></app-act-list>
            </ng-template>
        </mat-tab>

        <mat-tab label="Z-отчёты" [disabled]="!onlineUkrop || !profile">
            <ng-template matTabContent>
                <app-z-report-list [ukrop]="profile"></app-z-report-list>
            </ng-template>
        </mat-tab>

        <!--  История заказов -->
        <mat-tab *ngIf="ors">
            <ng-template mat-tab-label>История заказов</ng-template>
            <ng-template matTabContent>
                <app-point-orders [buypoint]="ors"></app-point-orders>
                <br><br>
            </ng-template>
        </mat-tab>

        <mat-tab *ngIf="isAdmin">
            <ng-template mat-tab-label>
                <mat-icon style="color: blueviolet;">menu</mat-icon>
            </ng-template>
            <pre>
            {{ors | json}}
            </pre>

        </mat-tab>

    </mat-tab-group>
</div>