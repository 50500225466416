import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncMatrixJob extends SyncRealDataJob {

    override async clean(): Promise<void> {
    //    await this.db.clear("")
         
    }


    async save(r: SyncFetch) {

      
        const add = r.items.filter(a => a.matrix && a.state === 1).map(a => a.matrix!);
        const del = r.items.filter(a => a.matrix && a.state === 2).map(a => a.id);
        await this.db.deleteArray('matrix', del);
        await this.db.addArray('matrix', add); 
    }

}
