<div>
  <div style=" float: right; display: block;">

    <mat-button-toggle-group (change)="onExplained()" [(ngModel)]="showList" name="fontStyle" aria-label="Font Style">
      <mat-button-toggle [value]="'mobile'"><mat-icon>format_list_bulleted</mat-icon></mat-button-toggle>
      <mat-button-toggle [value]="'table'"><mat-icon
          fontSet="material-icons-outlined">table_chart</mat-icon></mat-button-toggle>
      <mat-button-toggle [value]="'detailed'" [checked]="detailedOrderView "><mat-icon>table_view</mat-icon>
        <!-- <mat-icon>arrow_forward</mat-icon> -->
      </mat-button-toggle>

      <mat-button-toggle [value]="'log'"><mat-icon
          fontSet="material-icons-outlined">fingerprint</mat-icon></mat-button-toggle>


    </mat-button-toggle-group>

  </div>

  <div style="display: block; width: 100%;">

    <ng-container *ngIf="!orderMain">

      <app-order-status-indicator style="display: inline-block; margin-top: 12px;"
        [statusId]="order.statusId"></app-order-status-indicator>


      <span *ngIf="order.saleNumber">
        РН: {{order.saleNumber}} {{order.saleStatus}}
      </span>


      <span *ngIf="order.route" class="route_lable">, маршрут {{order.route}} </span>
      <span *ngIf="order.dispatcher" class="route_lable">, экспедитор {{order.dispatcher}} </span>



    </ng-container>
  </div>

  <mat-list *ngIf="showList === 'mobile'">

    <div style="font-weight: bold; margin-bottom: 12px;margin-top: 24px;">
      Количество в заказе:
      <span [class]="show1C() && contItems('amount') !== contItems('amount1C') ? 'warn' : 'primary'">
        {{contItems('amount')}} шт.
      </span>

      <span *ngIf="show1C()">
        / в 1с: <span [class]="contItems('amount') !== contItems('amount1C') ? 'warn' : 'primary'">
          {{contItems('amount1C')}} шт.
        </span>
      </span>

      <br>
      Сумма в
      заказе:
      <span
        [class]="show1C() && (contItems('summa') | currency) !== (contItems('summa1C') | currency) ? 'warn' : 'primary'">
        {{contItems('summa') | currency:'RUB':'symbol':'1.2-2':'ru'}}
      </span>

      <span *ngIf="show1C()">
        / в 1С: <span
          [class]="(contItems('summa') | currency) !== (contItems('summa1C') | currency) ? 'warn' : 'primary'">
          {{contItems('summa1C') | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </span>
      </span>

    </div>

    <ng-container *ngFor="let good of orderItems">
      <mat-divider style="margin-top: 14px;"></mat-divider>
      <mat-list-item class="list_item">
        <!-- <div matListItemIcon> <mat-icon [class]="itemChange(good) ? 'warn' :'primary' "> {{itemChange(good) ? 'priority_high' : 'done'}}</mat-icon></div> -->

        <div class="text_wrap_title" matListItemTitle>{{good.good}}</div>

        <div matListItemLine class="text_wrap">
          <span>
            Количество<span> в заказе: <span [class]="show1C() && good.amount !== good.amount1C ? 'warn ' :'primary'">
                {{good.amount}} шт.</span>
              <span *ngIf="show1C()">
                / в 1C: <span [class]="good.amount !== good.amount1C ? 'warn ' :'primary'"> {{good.amount1C}} шт.</span>
              </span>
            </span>
          </span>
        </div>

        <div matListItemLine class="text_wrap">
          <span>
            Цена<span> в заказе: <span
                [class]="show1C() && (good.price | currency) !== (good.price1C | currency) ? 'warn ' :'primary'">{{good.price|currency:'RUB':'symbol':'1.2-2':'ru'}}</span>

              <span *ngIf="show1C()">
                / в 1С:
                <span
                  [class]="(good.price | currency) !== (good.price1C | currency) ? 'warn ' :'primary'">{{good.price1C
                  | currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
              </span>
            </span>

          </span>
        </div>


        <div matListItemLine class="text_wrap">
          <span>
            Сумма<span> в заказе:
              <span
                [class]="show1C() && (good.summa | currency) !== (good.summa1C | currency) ? 'warn' : 'primary'">{{good.summa
                |
                currency:'RUB':'symbol':'1.2-2':'ru'}}</span>

              <span *ngIf="show1C()">
                / в 1С:
                <span
                  [class]="(good.summa | currency) !== (good.summa1C | currency) ? 'warn' : 'primary'">{{good.summa1C
                  | currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
              </span>
            </span>

          </span>
        </div>

        <div matListItemLine *ngIf="good.discountRegular" class="text_wrap">

          <b> Скидка:</b> <span>{{ good.discountRegular | currency:'RUB':'symbol':'1.2-2':'ru'}} </span>

        </div>
        <div matListItemLine *ngIf="good.discountPromo" class="text_wrap">

          <b> Промо:</b> <span>{{ good.discountPromo | currency:'RUB':'symbol':'1.2-2':'ru'}} </span>

        </div>

        <div matListItemLine *ngIf="good.promotionCode" class="text_wrap">

          <b> Промо:</b> <span> {{ good.promotionCode}} </span>

        </div>

        <div matListItemLine *ngIf="!nullComent(good.comment)" class="text_wrap">

          <b>Коментарий: </b> <span style="color: red;">{{ good.comment}} </span>

        </div>

      </mat-list-item>

    </ng-container>


  </mat-list>
  <div style="width: 100%; overflow-x: auto;">
    <table fixedLayout="true" [style]="showList === 'table' || showList === 'detailed' ? '' : 'display:none' " mat-table
      [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="good">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Название </th>
        <td mat-cell *matCellDef="let row">
          <span>
            {{row.good}} &nbsp;
          </span>
          <span *ngIf="row.deletedAmount" class="warning">вычерк
            {{row.deletedAmount}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef> Всего </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Кол-во </th>
        <td mat-cell *matCellDef="let row" class="tdr">
          <span *ngIf="!row.analog">{{row.amount}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef class="tdr">{{contItems('amount')}} </td>
      </ng-container>

      <ng-container matColumnDef="amount1C">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Склад </th>
        <td mat-cell *matCellDef="let row" class="tdr"> <span *ngIf="row.amount1C">
            {{row.amount1C}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef class="tdr"> {{contItems('amount1C')}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Цена</th>
        <td mat-cell *matCellDef="let row" class="tdr">
          {{row.price | currency:'RUB':'symbol':'1.2-2':'ru'}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="summa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Сумма</th>
        <td mat-cell *matCellDef="let row" class="tdr tdg">
          {{row.summa | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </td>
        <td mat-footer-cell *matFooterCellDef class="tdr tdg">

          {{contItems('summa') | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </td>
      </ng-container>


      <ng-container matColumnDef="price1C">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Цена 1C</th>
        <td mat-cell *matCellDef="let row" class="tdr">
          {{row.price1C | currency:'RUB':'symbol':'1.2-2':'ru'}} </td>

        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="summa1C">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Сумма 1C</th>
        <td mat-cell *matCellDef="let row" class="tdr tdg">
          {{row.summa1C | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </td>
        <td mat-footer-cell *matFooterCellDef class="tdr tdg">
          <!-- {{order.summa1C | currency:'RUB':'symbol':'1.2-2':'ru'}}  -->
          {{contItems('summa1C') | currency:'RUB':'symbol':'1.2-2':'ru'}}
        </td>
      </ng-container>


      <ng-container matColumnDef="regular">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Скидка</th>
        <td mat-cell *matCellDef="let row" class="tdr">
          <span *ngIf="row.discountRegular">
            {{row.discountRegular | currency:'RUB':'symbol':'1.2-2':'ru'}}
          </span>
        </td>

        <td mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>


      <!-- <ng-container matColumnDef="promo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Промо</th>
      <td mat-cell *matCellDef="let row" class="tdr">
        <span *ngIf="row.discountPromo">
          {{row.discountPromo | currency:'RUB':'symbol':'1.2-2':'ru'}} </span>
      </td>
      <td mat-footer-cell *matFooterCellDef>
      </td>
    </ng-container> -->
      <ng-container matColumnDef="promotionCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Промо</th>
        <td mat-cell *matCellDef="let row" class="tdr">
          <span *ngIf="row.promotionCode">
            {{row.promotionCode}} </span>
        </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Коментарий</th>
        <td mat-cell *matCellDef="let row" class="tdr">
          <span>
            {{row.comment}} </span>
        </td>
        <td mat-footer-cell *matFooterCellDef>
        </td>
      </ng-container>





      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="itemChange(row) ? 'accent' : ''"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Нет данных </td>
      </tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="example-first-footer-row"></tr>
    </table>
  </div>


  <div *ngIf="showList === 'log'">

    <app-order-log [id]="order.id"></app-order-log>

  </div>

</div>

<!-- pre>
  {{order | json}}
</pre -->