import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedQuests, Quest, QuestImage, QuestType } from 'src/app/models/quests/quest.model';
import { LocalStorageService } from '../storage/local-storage.service';
import { RpaHttpService } from '../http/rpa-http.service';
import { HttpEvent } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class QuestsHttpService {




  constructor(private svc: RpaHttpService,
    private localstor: LocalStorageService,
    private datePipe: DatePipe,
  ) {


  }

  // public getQuestTypes(agenId: string): Observable<QuestType[]> {
  //   return this.svc.post<QuestType[]>(`/v2/api/Quest/QuestTypesFind`, {
  //     AgentIds: [agenId],
  //   })
  // }


  // public questsFind(filter: object): Observable<PagedQuests> {
  //   const res = this.svc.post<PagedQuests>('/v2/api/Quest/QuestsFind', filter)
  //   return res
  // }

  // public getQuest(id: string): Observable<Quest> {
  //   return this.svc.get<Quest>(`/v2/api/Quest/QuestGet/${id}`)
  // }

  // public updateQuest(quest: Quest): Observable<Quest> {
  //   return this.svc.post<Quest>(`/v2/api/Quest/QuestUpd`, quest)
  // }


  public getImages(id: number): Observable<QuestImage[]> {
    return this.svc.get<QuestImage[]>(`/v2/api/QuestImage/List/${id}`)
  }

  public getImageUrl(img: QuestImage): string {
    // const token = this.localstor.activeServer.token
    const tmp = `/v2/api/QuestImage/Photo?img=${img.id}` //&token=${token}

    return this.svc.wrapUrl(tmp)
  }




  public deleteImage(img: QuestImage): Observable<QuestImage[]> {
    return this.svc.post<QuestImage[]>(`/v2/api/QuestImage/Del/`, img)
  }

  public addQuestPhoto(
    actualDate: Date,
    id: string,
    type: number,
    profileImage: File
  ): Observable<HttpEvent<object>> {

    return this.uploadFile(
      { actualDate, questId: id, type, inn: '', kpp: '', profileImage: profileImage }

    )
  }

  public errorMessage = ''
  private uploadFile(form: UploadImageForm) {
    const formData  = new FormData()
    const actualDateText = this.datePipe.transform(form.actualDate, 'yyyy-MM-dd')

    // this.logger.info(
    //   `send: ${form.inn}/${form.kpp} ${form.questId} ${actualDateText} ${form.profileImage.size}! `,
    //   'FileUploadService'
    // )
    const questId = +form.questId

    formData.append('ActualDate', actualDateText ?? "")
    formData.append('INN', form.inn)
    formData.append('KPP', form.kpp)
    formData.append('File', form.profileImage)
    formData.append('ReportTypeId', form.type.toString())
    formData.append('QuestId', questId.toString())
    //   formData.append('AgentId', this.agentId)
    if (form.softChequeId) formData.append('SoftChequeId', form.softChequeId.toString())


    //    this.logger.debug(`send: ${uri} `, 'FileUploadService')
    const uri = `/v2/api/QuestImage/Add`
    return this.svc
      .postForm(uri, formData)
    //.pipe(catchError(this.errorMgmt))
  }

  /*
  
    addUserTask(
      actualDate: Date,
      inn: string,
      kpp: string,
      type: number,
      profileImage: File,
      softChequeId?: number
    ): Observable<any> {
      const uri = `${environment.api_url}/v1/Ukrop/AddUserTask`
      return this.uploadFile({ actualDate, inn, kpp, profileImage, type, questId: '', softChequeId }, uri)
    }
  
  
   
  
    errorMgmt(error: HttpErrorResponse) {
      this.logger.error(`send: ${JSON.stringify(error)} `, 'FileUploadService')
  
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        this.errorMessage = error.error.message
      } else {
        // Get server-side error
  
        switch (error.status) {
          case 404: {
            this.errorMessage = `Not Found: ${error.message}`
            break
          }
          case 403: {
            this.errorMessage = `Access Denied: ${error.message}`
            break
          }
          case 413: {
            this.errorMessage = `Too big: ${error.message}`
            break
          }
          case 500: {
            this.errorMessage = `Internal Server Error: ${error.message}`
            break
          }
          default: {
            this.errorMessage = `Unknown Server Error: ${error.message}`
            break
          }
        }
      }
  
      return throwError(this.errorMessage)
    }
  
  */

}

// TODO - вынести и переделать
export interface UploadImageForm {
  actualDate: Date
  inn: string
  kpp: string
  profileImage: File
  type: number
  questId: string
  softChequeId?: number
}
