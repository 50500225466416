import { ConfirmError, OrderModel, OrderStatus } from 'src/app/models/order.model';
import { Observable, firstValueFrom, from } from 'rxjs';

import { DbService } from './idb.service';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UkropClientService } from '../net/ukrop-client.service';
import { UkropRest } from 'src/app/models/Ukrop/Rest';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {


  constructor(private db: DbService) {
  }


  public createOrder(order: OrderModel): Observable<OrderModel> {
    order.id = uuidv4();
    const prom = this.db.add('orders', order)
    return from(prom)
  }



  public getOrder(id: string): Observable<OrderModel | undefined> {

    const prom = this.db.get('orders', id)
    return from(prom)

  }

  getOrders(dt1: Date, dt2: Date | undefined): Observable<OrderModel[]> {

    const prom = this.db.getOrders(dt1, dt2)
    return from(prom)
  }

  getClientOrders(buypointId: string): Observable<OrderModel[]> {
    const prom = this.db.getClientOrders(buypointId)
    return from(prom)
  }


  private async updateOrderPromise(order: OrderModel): Promise<OrderModel | undefined> {

    if (order.items === undefined) {
      order.items = []
    }

    order.totalAmount = 0
    order.summa = 0

    order.items.forEach(item => {
      order.totalAmount += item.amount
      order.summa += +(item.amount * item.price)
    })

    return this.db.update('orders', order)

  }

  async checkOrder(order: OrderModel, force: boolean, rests?: UkropRest[]): Promise<ConfirmError> {

    const result = {} as ConfirmError

    try {

      const old = await this.db.get('orders', order.id)

      if (!old) {
        result.error = true
        result.message = "не найден заказ для обновленя"
        return result
      }

      // const targetStatus = statusList[order.statusId] // если не нашли - вероятно происходит дичь.
      const targetStatus = statusList.find(x => x.id === order.statusId) // если не нашли - вероятно происходит дичь.


      if (!targetStatus || !targetStatus.commit) {

        result.error = true
        // TODO заменить номера статусов на понятные названия в сообщении
        result.message = `нельзя установить статус ${order.statusId} ${targetStatus?.name}`
        return result
      }

      // const srcStatus = statusList[old.statusId]
      const srcStatus = statusList.find(x => x.id === old.statusId)

      const match = targetStatus.commit.indexOf(old.statusId)

      // if (targetStatus.id === 2) {
      //   // повтроить проведение
      //   order.orders1c.forEach(o => { if (o.statusId === 7) o.statusId = 8 })
      //   return result
      // }

      if (match < 0 && srcStatus) {

        result.error = true
        // TODO заменить номера статусов на понятные названия в сообщении
        result.message = `статус заказа "${srcStatus.name}" не допускает обновления в статуса "${targetStatus.name}"`
        return result
      }

      // если это отмена заказа и перевод в статус "новый"
      if (targetStatus.id === 11 || targetStatus.id === 1) {
        // дальше не проверяем
        order.isError = false
        order.error = ''
        return result
      }




      if (!order.items || order.items.length < 1 || order.totalAmount <= 0) {
        result.error = true
        result.message = `нельзя провести пустой заказ`
        return result
      }


      if (order.totalAmount === 666 && !force) {
        result.error = true
        result.message = `количество странное какое-то. точно подтвердить заказ?`
        result.canForce = true
        return result
      }


      if (targetStatus.id === 2 && !force) {

        const client = await firstValueFrom(this.db.getClient(order.buypointId))
        let matrix = await this.db.getMatrixByCategory(client?.categoryId)
        if (matrix && rests?.length) {

          const distBlocks = Array.from(new Set(matrix?.goods.map(x => x.blockId))).filter(x => x)
          matrix.goods.forEach(x => {
            if (rests.find(r => x.alccodes.includes(r.p_alccode)) || order.items.find(o => o.goodId == x.goodId)) {
              x.point_rests = 1
            }
          })
          let matrixBlock = distBlocks.map(id => {
            const blockGood = matrix?.goods.find(g => g.blockId === id)
            const blockGoods = matrix?.goods.filter(g => g.blockId === id)
            let rest = 0
            let store = 0
            blockGoods?.forEach(x => { rest = rest + (x.point_rests ?? 0); store += x.storeRest ?? 0; })
            return {
              title: blockGood?.block,
              show: false,
              goodCategId: blockGood?.categoryId,
              blockId: blockGood?.id,
              goods: blockGoods,
              rest: rest,
              storeRest: store
            }
          })

          if (matrixBlock.find(x => !x.rest || x.rest === 0)) {
            result.error = true
            result.message = 'не хватает в заказе позиций из матрицы: '
            result.message += '\n' + matrixBlock.filter(x => !x.rest || x.rest === 0).map(x => x.title).join('\n') 

            result.canForce = true
            return result
          }
        }


      }



    } catch (error) {

      if (error instanceof Error) {
        result.message = error.message //error.message ? error.message : error.toString()
      } else {
        result.message = "непредвиденная ошибка при проверке заказа"
        console.log(error)
      }
      result.error = true
      result.canForce = false
      return result

    }
    return result;
  }

  updateOrder(order: OrderModel): Observable<OrderModel | undefined> {


    return from(this.updateOrderPromise(order))

  }




  async ConfirmPromise(order: OrderModel, toStatus: number, force: boolean, rests?: UkropRest[]): Promise<ConfirmError | undefined> {

    const bakUp = order.statusId
    order.statusId = toStatus
    const checkResult = await this.checkOrder(order, force, rests)

    if (checkResult.error) {
      order.statusId = bakUp
    } else {
      await this.db.update('orders', order)
    }

    return checkResult

  }


  Confirm(order: OrderModel, toStatus: number, force: boolean, rests?: UkropRest[]): Observable<ConfirmError | undefined> {

    return from(this.ConfirmPromise(order, toStatus, force, rests))
  }

  getOrdersByDeliveryDate(dtStart: Date, dtEnd: Date) {
    return this.db.getAllFromIndex('orders', 'by-deliveryDate', IDBKeyRange.bound(dtStart, dtEnd))
  }




}

export const statusList: OrderStatus[] =
  [
    { id: 0, name: "Не определён", color: "black", icon: "error", commit: [], rollback: [] },

    {
      id: 1, name: "Создан", color: "cornflowerblue", icon: "smartphone", commit: [3], rollback: [],
      description: 'Можно изменять товары в заказе, день доставки, комментарии, подтвердить(2) или отменить(3).'
    },
    {
      id: 2, name: "Подтверждён", color: "#FF8C00", icon: "smartphone", commit: [1, 4], rollback: [],
      description: 'Все изменения в заказе проверены, правильность подтверждена агентом. Для отправки в 1С необходимо синхронизироваться. '
    },
    {
      id: 11, name: "Отменён", color: "#222200", icon: "mobile_off", commit: [1, 2], rollback: [],
      description: 'Заказ отменён агентом вручную '
    },
    {
      id: 4, name: "Выгружен", color: "green", icon: "send_to_mobile", commit: [], rollback: [],
      description: 'После подтверждения агентом и последующей синхронизации заказ уходит на сервер, который передаёт его в 1С. '
    },

    {
      id: 8, name: "Выгружен", color: "#FF8C00", icon: "cloud_upload", commit: [], rollback: [],
      description: 'После подтверждения агентом и последующей синхронизации заказ уже выгружен на сервер, который передаёт его в 1С. ',
      outlined: true
    },
    {
      id: 9, name: "Передан в 1С", color: "green", icon: "cloud_upload", commit: [], rollback: [],
      description: 'Заказ успешно передан в 1С, которая проверяет цены, доступность договора, задолженность и т.д. и если всё в норме, создаёт на основании заказа некоторое количество электронных заявок, состояние каждой из которых можно видеть развернув заказ. ',
      outlined: true
    },

    {
      id: 7, name: "Создан в 1С", color: "#FF8C00", icon: "check_circle_outline", commit: [], rollback: [],
      description: 'Изначальный статус при создании электронной заявки'
    },
    {
      id: 6, name: "Проведён в 1С", color: "green", icon: "check_circle_outline", commit: [], rollback: [],
      description: 'Заявка прошла первичную проверку, подготовка к созданию расходной накладной.'
    },
    {
      id: 3, name: "Ошибка в 1С", color: "red", icon: "error_outline", commit: [1, 5], rollback: [],
      description: 'При проведении произошла ошибка. Текст ошибки виден в комментарии. Если Вы её устранили, можно попробовать перепровести нажав кнопку "Сбросить ошибку"'
    },
    {
      id: 5, name: "Отклонен в 1С", color: "red", icon: "highlight_off", commit: [], rollback: [],
      description: 'Заявка распроведена и удалена в 1С. Только создавать новый заказ на этот товар.'
    },
    // { id: 10, name: "Обработан в 1С", color: "green", icon: "check_circle", commit: [], rollback: [] },
    { id: 12, name: "Отменен по времени", color: "red", icon: "help_outline", commit: [], rollback: [] },

    //новые
    { id: 13, name: "Создана РН", color: "cornflowerblue", icon: "shopping_cart", commit: [], rollback: [] },
    { id: 14, name: "РН Удалена", color: "red", icon: "remove_shopping_cart", commit: [], rollback: [] },
    { id: 15, name: "Ошибка РН", color: "red", icon: "shopping_cart", commit: [], rollback: [] },
    { id: 16, name: "Обработка РН", color: "#FF8C00", icon: "shopping_cart", commit: [], rollback: [] },
    { id: 17, name: "РН доставка", color: "#FF8C00", icon: "local_shipping", commit: [], rollback: [] },
    { id: 18, name: "РН - Успешно", color: "green", icon: "local_shipping", commit: [], rollback: [] },




  ]

/**!SECTION.status1 {
  color: ;
}

.status2 {
  color: ;
}

.status4 {
  color: ;
}

.status3 {
  color: ;
}

.status6 {
  color: ;
}

.status5 {
  color: ;
}

.status7 {
  color: ;
} */