import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DbService } from './idb.service';
import { CashOrderAdvance, DebetDoc } from 'src/app/models/DebetDocs';

@Injectable({
  providedIn: 'root'
})
export class DebetService {


  constructor(private dbService: DbService) {
  }


  //#region debetDocs
  getClientDocs(buypointId: string): Observable<DebetDoc[]> {
    const prom = this.dbService.getClientDocs(buypointId)
    return from(prom)
  }

  getDebetsbyClients(buypointIds: string[]) {
    let promises: Promise<DebetDoc[] | undefined>[] = []
    for (let id of buypointIds) {
      const prom = this.dbService.getAllFromIndex('debets', 'by-client', id)
      promises.push(prom)
    }
    return Promise.all(promises)
  }

  //#endregion

  //#region advances

  async getAdvancesByDate(dateStart: Date, dateEnd: Date) {
    let startDate = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate())
    let endDate = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(), 23, 59, 59, 59)
    let filter = IDBKeyRange.bound(startDate, endDate)
    return this.dbService.getAllFromIndex('advances', 'by-date', filter)
  }

  async getAdvanceByClient(client: string) {
    let res = await this.dbService.getAllFromIndex('advances', 'by-client', IDBKeyRange.only(client))
    return res
  }

  async getAdvanceByStatus(state: number) {
    return this.dbService.getAllFromIndex('advances', 'by-status', state)
  }

  async addAdvances(advances: CashOrderAdvance[]) {
    return this.dbService.addArray('advances', advances)
  }

  async putAdvance(advance: CashOrderAdvance) {
    return this.dbService.put('advances', advance)
  }

  async deleteAdvance(advance: CashOrderAdvance) {
    return this.dbService.deleteItem('advances', IDBKeyRange.only(advance.id))
  }


  //#endregion


}
