import { animate, state, style, transition, trigger } from '@angular/animations'
import { CurrencyPipe } from '@angular/common'
import { AfterViewInit, Component, Input, OnInit,   SimpleChanges, ViewChild } from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
 
import { OrderLine, OrderModel } from 'src/app/models/order.model'
import { MediaSizeService } from 'src/app/services/media-size.service'

@Component({
  selector: 'app-order-info-lines',
  templateUrl: './order-info-lines.component.html',
  styleUrls: ['./order-info-lines.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class OrderInfoLinesComponent implements OnInit, AfterViewInit {
  @Input() order!: OrderModel
  @Input() orderMain  = false
  // @Output() orderChange = new EventEmitter<OrderModel>();

  @ViewChild(MatSort) sort!: MatSort

  dataSource!: MatTableDataSource<OrderLine>

  displayedColumns: string[] = []
  detailedOrderView  = false
  // detailedComment: boolean = false

  showList: "mobile" | "table" | "detailed" | "log"
  orderItems: OrderLine[] = []

  constructor(
    private currencyPipe: CurrencyPipe,
    // public breakpointObserver: BreakpointObserver
    private media: MediaSizeService
  ) {
    this.onExplained()
    this.showList = media.isMobile ? "mobile" : "table"
     
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.mainItems(this.order.items))
    //this.isAdmin = this.authService.isAdmin$
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['orderMain'] && changes['orderMain'].currentValue !== changes['orderMain'].previousValue) {
      this.orderMain = changes['orderMain'].currentValue

      if (this.orderMain) {
        // this.detailedComment = false
        this.detailedOrderView = false
        this.onExplained()
      }
    }

    if (changes['order'].currentValue !== changes['order'].previousValue) {
      this.order = changes['order'].currentValue
      this.dataSource = new MatTableDataSource(this.mainItems(changes['order'].currentValue.items))
      this.orderItems = this.mainItems(changes['order'].currentValue.items)
      this.dataSource.sort = this.sort
      this.onExplained()
    }
  }

  mainItems(items: OrderLine[]) {
    // let items = this.order.items
    if (this.orderMain) {
      items = items?.filter(x => !x.order1cId)
    }
    return items?.sort((a, b) => { if (a.good > b.good) return 1; if (a.good < b.good) return -1; return 0 })
  }

  nullComent(text?: string | undefined) {
    if (text && text.trim()) {
      return false
    }
    return true
  }

  show1C() {
    return this.orderItems?.find(x => x.amount1C || x.summa1C)
  }


  onExplained() {
    // console.log(this.detailedOrderView)

    if (this.showList === 'detailed' && this.show1C()) {
      this.displayedColumns = ['good', 'amount1C', 'summa1C']
    }
    else {
      this.displayedColumns = ['good', 'amount', 'summa']
    }

    if (this.showList === 'detailed') {
      this.displayedColumns = [
        'good',
        'amount',
        'amount1C',
        'price',
        'summa',
        'price1C',
        'summa1C',
        'regular',
        // 'promo',
        'promotionCode',
        'comment',
      ]
    }



    // if (this.orderItems?.find(x => x.comment && x.comment.trim())) {
    //   this.displayedColumns.push('comment')
    // }

    if (this.dataSource && this.sort) {
      this.dataSource.sort = this.sort
    }

  }

  contItems(field: 'amount' | 'amount1C' | 'summa' | 'summa1C') {
    let count = 0
    if (!this.order || !this.order.items) {
      return count
    }
    count = this.mainItems(this.order.items).map(x => x[field]).reduce((a, b) => a + b, 0)
    return count

  }


  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort
    }
  }

  itemChange(item: OrderLine) {
    const price = this.currencyPipe.transform(item.price)
    const price1C = this.currencyPipe.transform(item.price1C)
    const summa = this.currencyPipe.transform(item.summa)
    const summa1c = this.currencyPipe.transform(item.summa1C)
    if (!this.orderMain && (price !== price1C ||
      summa !== summa1c ||
      item.amount !== item.amount1C))
      return true
    return false
  }


}
