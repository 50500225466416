<h1 mat-dialog-title> Чек ИП по аренде оборудования</h1>


<div mat-dialog-content *ngIf="zReport">
  <span> {{getStatus(zReport.ReportStatusId)}} </span><br>
  <span> {{zReport.ReportDate | date:"dd.MM.YYYY"}} </span><br>
  <span> Отправлено: {{zReport.FileDate | date:"dd.MM.YYYY"}} </span><br>
  <!-- <img style="max-width: 100%;max-height: inherit;" [src]="base+'/v1/Image/zimage?fileUrl='+needZrep.fileUrl"
    [alt]="needZrep.fileDate" /> -->
  <img style="max-width: 100%;max-height: inherit;" [src]="imageUrl(zReport) | secureU | async" [alt]="zReport.FileDate" />
</div>
<span style="color: red;" *ngIf="!zReport">Не привязан чек с актом</span>
<div mat-dialog-actions>
  <button color="primary" *ngIf="zReport?.ReportStatusId === 1" mat-button
    (click)="setStatus(4)">Передать</button>
    <!--  && isSuper -->
  <!-- <button color="accent" *ngIf="needZrep && needZrep?.reportStatusId !== 1 && isSuper" mat-button
    (click)="setStatus(1)">Сброс</button> -->
  <button mat-button [mat-dialog-close]="zReport" cdkFocusInitial>Закрыть</button>
</div>