import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncDebetsJob extends SyncRealDataJob {


    override async clean(): Promise<void> {
        await this.db.clear("debets")
         
    }
    async save(r: SyncFetch) {



        const add = r.items.filter(a => a.debet && a.state === 1).map(a => a.debet!.debetDoc);
        const del = r.items.filter(a => a.debet && a.state === 2).map(a => a.id);


        await this.db.deleteArray('debets', del);

        add.forEach(p => {
            if (!(p.date instanceof Date)) {
                p.date = new Date(Date.parse(p.date))
            }

            if (!(p.datePay instanceof Date)) {
                p.datePay = new Date(Date.parse(p.datePay))
            }
        });

        await this.db.addArray('debets', add);

    }

}
