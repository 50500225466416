
import { QuestWrapper, SyncFetch, SyncFetchItem } from "src/app/models/sync.model";
import { HttpErrorResponse } from "@angular/common/http";
import { AbstractSyncDataJob } from "./AbstractSyncDataJob";

export class SyncQuestsSendJob extends AbstractSyncDataJob{
 
 

 

    override async  run(): Promise<boolean> {

        const send = await this.db.findModifiedQuestsAsync( )
        if (!send || send.length === 0) {
            this.completed = true
            return true

        }

        this.count = send.length

        const i: SyncFetchItem[] = send.map(x => {



            const w: QuestWrapper = {
                id: x.id,
                quest: x,


            }

            const r: SyncFetchItem =
            {
                id: x.id,
                preorder: null,
                state: 5,
                client: null,
                good: null,
                goodGroup: null,
                matrix: null,
                advance: null,
                debet: null,
                quest: w
            }


            return r
        })

        const d: SyncFetch = {
            items: i
        } as SyncFetch




        try {
            const r = await this.httpCredit.syncSendPromise(d)
            await this.save(r)
            this.step = send.length
            this.completed = true

        } catch (error: unknown) {

            this.error = true
            this.completed = true
            if (error instanceof Error) {
                this.message = error.message

            } if (error instanceof HttpErrorResponse) {
                this.message = error.message

            } else {
                console.error(error)
                this.message = 'unknown error'
                // if (error.error !== undefined) { this.message = error.error }
            }


            return false
        }


        return true
    }

    private async save(r: SyncFetch) {

        r.items.forEach(async (p) => {
            if (!p.quest || !p.quest.quest) {
                console.error(`update status error: no quest`)
                return
            }

            if (!(p.quest.quest.start instanceof Date)) {
                p.quest.quest.start = new Date(Date.parse(p.quest.quest.start))
            }

            if (!(p.quest.quest.finish instanceof Date)) {
                p.quest.quest.finish = new Date(Date.parse(p.quest.quest.finish))
            }

            if (!(p.quest.quest.created instanceof Date)) {
                p.quest.quest.created = new Date(Date.parse(p.quest.quest.created))
            }

            p.quest.quest.modified = 0

            console.log(`update quest ${p.id}`)
            await this.db.update('quests', p.quest.quest);

        });

    }
}