import { Component, OnDestroy, OnInit } from '@angular/core'

import { ActivatedRoute, Router } from '@angular/router'
import { SubscriptionLike, from } from 'rxjs'
import { AgentModel } from 'src/app/models/agent.model'
import { Client } from 'src/app/models/client.model'


import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'


@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit, OnDestroy {
  public id = ''

  public buypoint: Client | undefined

  public loading = true
  public error = false
  agent: AgentModel | undefined
  isAdmin = false


  tabindex = 0

  subscriptions: SubscriptionLike[] = []
  ignoreFirst = 0
  priceTypeName = 'Не определено'

  constructor(
    private route: ActivatedRoute,
    public cm: ConnectionManagerService,

    private router: Router
  ) {

    this.isAdmin = cm.isAdmin()
  }

  ngOnInit(): void {

    const sub1 = this.cm.activeServerObservable().subscribe(x => { this.ignoreFirst++; if (this.ignoreFirst > 1) { this.router.navigate(['/home']) } })

    const sub2 = this.route.paramMap.subscribe((params) => {
      this.id = params.get('id') as string
      this.getClientDb()


    })



    this.subscriptions.push(sub2)
    this.subscriptions.push(sub1)
  }

  getClientDb() {
    this.loading = true

    from(this.cm.getDb()).subscribe((db) => {
      if (!db) return

      db.getClient(this.id).subscribe(bp => {
        this.buypoint = bp
        this.loading = false


        const pr = db.priceTypes.find(t => t.code === this.buypoint?.priceType)
        if (pr) {
          this.priceTypeName = pr.name
        }


      })
    })


  }


  // setId(id: string): void {
  //   this.id = id

  //   this.reload()
  // }

  // isAdmincheck() {
  //   this.authService.isAdmin$.subscribe(x => { this.isAdmin = x })
  //   this.authService.isLPR$.subscribe(x => { this.isLpr = x })
  // }

  reload() {
    // if (!this.agent) return

    // this.loading = true
    // this.error = false

    // this.cs.pointsObservable.subscribe((all) => {
    //   this.loading = false
    //   this.buypoint = all.find((a) => a.id === this.id)
    // })


  }


  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []

  }
}
