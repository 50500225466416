<div class="page-content-block">

    <h2>Настройки приложения</h2>
    <mat-list>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.darkTheme" (ngModelChange)="themeset()"> Темная тема
                (тест) </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.bigFont" (ngModelChange)="fontSet()"> Крупный шрифт
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.autoSearch" (ngModelChange)="settingsSave()">
                Автопоиск
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.quantityFocus" (ngModelChange)="settingsSave()">
                Автофокус ввода в диалоговом окне
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.autoMatrix" (ngModelChange)="settingsSave()">
                Раскрывать матрицу в заказе автоматически
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>

            <mat-slide-toggle [(ngModel)]="loclstg.searchOptions.swipe " (ngModelChange)="saveStrg()"> Смена даты по
                свайпу
            </mat-slide-toggle>

        </mat-list-item>
        <mat-list-item *ngIf="loclstg.searchOptions.swipe">


            Чувствительность: <mat-slider min="30" max="300" step="10">
                <input matSliderThumb [(ngModel)]="loclstg.searchOptions.swipeSensitive " (ngModelChange)="saveStrg()">
            </mat-slider>
            <strong>{{loclstg.searchOptions.swipeSensitive}} </strong>


        </mat-list-item>

    </mat-list>

</div>