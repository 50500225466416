<h2>
    Принято логистикой <span *ngIf="buypoint.logistAccepted; else nolog" style="color: green;">ДА</span>
    <ng-template #nolog>
        <span style="color: green;">НЕТ</span>
    </ng-template>
</h2>

<div *ngIf="data">
    <table>
        <tr>
            <th>тип заявки</th>
            <th>пн.</th>
            <th>вт.</th>
            <th>ср.</th> 
            <th>чт.</th>
            <th>пт.</th> 
            <th>сб.</th>
            <th>вс.</th>
        </tr>
        <tr *ngFor="let delivery of data">
            <td>{{delivery.name}}</td>
            <td [ngClass]="delivery.monClass">{{delivery.mon}}</td>
            <td [ngClass]="delivery.tueClass">{{delivery.tue}}</td>
            <td [ngClass]="delivery.wedClass">{{delivery.wed}}</td>
            <td [ngClass]="delivery.thuClass">{{delivery.thu}}</td>
            <td [ngClass]="delivery.friClass">{{delivery.fri}}</td>
            <td [ngClass]="delivery.satClass">{{delivery.sat}}</td>
            <td [ngClass]="delivery.sunClass">{{delivery.sun}}</td>


        </tr>
    </table>
</div>
