import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpaHttpService extends BaseHttpService {

  constructor(http: HttpClient) {
    super(http)
  }

  public override getServerUrl(): string {
    const url = environment.API_URL as string
    return url
  }


}
