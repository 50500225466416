import { Component, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { Connection, User, UserData } from 'src/app/models/server';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { UrkopGateHttpService } from 'src/app/services/http/urkop-gate-http.service';

import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnDestroy {

  version: string
  prod: boolean
  conn: Connection | undefined
  user: UserData | undefined
  api: string;
  ukrop: string;
  x: Date | undefined
  deviceId = ''
  subs: SubscriptionLike[] = []
  isAdmin = false


  quota: string = ''
  usage: string = ''
  usagePercent: string = ''


  constructor(public cm: ConnectionManagerService,
    rpa: RpaHttpService,
    ukrop: UrkopGateHttpService,
    localStorage: LocalStorageService,
  ) {
    this.version = environment.version
    this.prod = environment.production
    this.api = rpa.getServerUrl()
    this.ukrop = ukrop.getServerUrl()
    this.isAdmin = this.cm.isAdmin()

    const subServ = cm.activeServerObservable().subscribe((conn) => {
      this.conn = conn
      if (conn?.user.exp) {
        this.x = new Date(conn.user.exp * 1000)
        this.deviceId = localStorage.widevineId
      }
    })

    this.subs.push(subServ)

    const subUser = cm.activeUserchange().subscribe(x => {

      this.conn = x?.connections?.find(x => x.active)
      this.user = x?.user
    })

    this.subs.push(subUser)

    this.memoryQuota()


  }

  memoryQuota() {
    try {
      navigator.storage.estimate().then(x => {
        if (x.quota && x.usage) {
          this.quota = (x.quota / 1000000000).toFixed(2);
          this.usage = (x.usage / 1000000000).toFixed(2);
          this.usagePercent = ((x.usage / x.quota)).toFixed(2)
        }

      })
    } catch (error) {
      console.log('не удалось узнать квоту на память')
    }

  }


  dt(n: number): string {

    const d = new Date(n * 1000)

    return d.toISOString()
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe())
    this.subs = []
  }




}
