<mat-nav-list>
    <!--  disableRipple="true" -->
    <ng-container *ngFor="let nav of navList">

        <a *ngIf="!nav.role || (nav.role === 'auth' && show ) || (nav.role === 'isAdmin' && isAdmin)" mat-list-item [routerLink]="[nav.url]"
            [activated]="nav.active" [ngClass]="nav.active ? 'activeNav' : '' ">

            <mat-icon matListItemIcon>{{nav.icon}}</mat-icon>
            {{nav.name}}
        </a>
     
    </ng-container>
    <hr>
    Версия: {{version}}


</mat-nav-list>