import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { ConnectionManagerService } from '../storage/connection-manager.service';
import { ErpService } from '../net/erp.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from './idb.service';
import { OrdersService } from './orders.service';
import { BehaviorSubject, Observable, Subject, finalize } from 'rxjs';
import { OrderLine, OrderModel } from 'src/app/models/order.model';
import { Client } from 'src/app/models/client.model';
import { ErpPrice } from 'src/app/models/goods/product';

@Injectable({
  providedIn: 'root'
})
export class CurrentOrderService {

  loading = false
  loadingPrice = false
  hasError = false
  errorMessage = ''

  private db: DbService;
  private api: OrdersService;
  private id: string | undefined;

  private orderInfo: OrderModel | undefined;

  //TODO: проверить подписчиков на дублирование информации!!!
  private orderInfoSubject = new BehaviorSubject<OrderModel | undefined>(undefined);
  private buypointSubject = new Subject<Client | undefined>();
  private pricesSubject = new BehaviorSubject<ErpPrice | undefined>(undefined);

  orderInfo$: Observable<OrderModel | undefined> = this.orderInfoSubject.asObservable();
  buypoint$: Observable<Client | undefined> = this.buypointSubject.asObservable();
  prices$: Observable<ErpPrice | undefined> = this.pricesSubject.asObservable();


  constructor(
    private cm: ConnectionManagerService,
    private router: Router,
    private erp: ErpService,
    private snack: MatSnackBar) {
    this.db = cm.getCurrentDb()
    this.api = new OrdersService(this.db)

  }

  init(orderId: string | undefined) {


    this.orderInfoSubject = new BehaviorSubject<OrderModel | undefined>(undefined);
    this.buypointSubject = new Subject<Client | undefined>();
    this.pricesSubject = new BehaviorSubject<ErpPrice | undefined>(undefined);

    this.orderInfo$ = this.orderInfoSubject.asObservable();
    this.buypoint$ = this.buypointSubject.asObservable();
    this.prices$ = this.pricesSubject.asObservable();


    this.db = this.cm.getCurrentDb()
    this.api = new OrdersService(this.db)
    this.loading = true
    this.loadingPrice = true


    this.orderInfo = undefined

    if (!orderId) return

    this.id = orderId


    this.api.getOrder(this.id).subscribe({
      next: async (data) => {

        if (data) {

          this.erp.getPrice(data.buypointId)
            .pipe(finalize(() => { this.loadingPrice = false }))
            .subscribe({
              next: x => {
                if (!x) return

                this.pricesSubject.next(x)
              },
              error: err => {
                this.snack.open(`Не удалось загрузить персональные скидки - ${err.message}`, 'Закрыть', { duration: 5000 });
              }
            })


        }

        this.orderLoaded(data)




      },
      error: (error) => {

        this.loading = false
        this.hasError = true
        this.errorMessage = JSON.stringify(error)

      },
    })
  }

  private orderLoaded(data: OrderModel | undefined): void {

    this.orderInfoSubject.next(data)

    if (!data) {

      if (!this.hasError) {
        this.errorMessage = "не найден заказ"
        this.hasError = true
      }

      this.buypointSubject.next(undefined)


      this.loading = false
      return
    }

    this.db.getClient(data.buypointId).subscribe((b) => {

      this.buypointSubject.next(b)
    })

    this.orderInfo = data



    // if (this.orderInfo.dateDelivery === undefined) {
    //   this.orderInfo.dateDelivery = new Date()
    // }

    if (typeof this.orderInfo.dateDelivery === "string") {
      this.orderInfo.dateDelivery = new Date(Date.parse(this.orderInfo.dateDelivery))
    }

    if (!this.orderInfo.items) {
      this.orderInfo.items = [] as OrderLine[]
    }

    this.loading = false

  }


  updateHeader(comment: string, delivery: Date | undefined, buypointId: string, buypoint: string) {

    if (!this.orderInfo) {
      console.error('no order for update')
      return
    }

    this.orderInfo.deliveryComment = comment
    this.orderInfo.dateDelivery = delivery
    this.orderInfo.buypointId = buypointId
    this.orderInfo.buypoint = buypoint

    this.updateOrder()


  }

  private updateOrder() {

    if (!this.orderInfo) {
      console.error('no order for update')
      return
    }

    this.loading = true

    this.api.updateOrder(this.orderInfo).subscribe(a => {
      this.orderLoaded(a)

    })

  }



  public addOrUpdateItem(newLineEvent: OrderLine): void {

    if (!this.orderInfo) return
    if (!this.id) return
    if (this.loading) return


    this.loading = true
    const newLine = newLineEvent as OrderLine


    const check = this.orderInfo.items.findIndex(a => a.goodId === newLine.goodId)

    // попытка добавить товар, который есть в заказе.
    // считаем, что нужно просто исправить кол-во
    if (check >= 0) {
      //   this.snackBar.open(`уже есть в заказе ${newLine.goodName}`, 'закрыть', { duration: 2000 })

      this.orderInfo.items[check].amount = newLine.amount
      this.orderInfo.items[check].summa = newLine.amount * newLine.price
      this.orderInfo.items[check].price = newLine.price

    } else {

      // добавляем строку в заказ

      newLine.orderId = this.id
      newLine.id = uuidv4();
      newLine.summa = newLine.amount * newLine.price

      // ищем строку, чтобы вставка не нарушала алфавитный порядок
      const idx = this.orderInfo.items.findIndex(a => newLine.good.localeCompare(a.good) < 0)

      if (idx < 0) {
        this.orderInfo.items.push(newLine)
      } else {
        //  idx = idx - 1
        this.orderInfo.items.splice(idx, 0, newLine)
      }

    }

    this.updateOrder()


  }

  public deleteItem(itemId: string): void {

    if (!this.orderInfo) return
    if (this.loading) return
    this.loading = true

    const idx = this.orderInfo.items.findIndex(a => a.goodId === itemId)

    if (idx < 0) {
      console.error("попутка удалить несуществующую строку")
      return
    }

    this.orderInfo.items.splice(idx, 1)

    this.updateOrder()


  }

  public headerChanged(): void {

    if (!this.orderInfo) return
    if (this.loading) return


    this.loading = true
    this.hasError = false

    this.api.updateOrder(this.orderInfo).subscribe(a => {
      this.loading = false
    })

  }

  onCompleteConfirm() {
    void this.router.navigate(['/orders']) //, { queryParams: { id: id } }
  }

  updateOrderItemsPrice(order: OrderModel, prices: ErpPrice) {
    order.items.forEach(x => {
      let findPr = prices.items.find(p => p.goodId === x.goodId)
      if (findPr) {
        x.price = findPr.price ?? x.price
      }
    })
    return this.db.put('orders', order)
  }

}
