<p>token-generator works!</p>

<mat-form-field class="w50" appearance="outline">
    <mat-label>Регион</mat-label>
    <mat-select [(ngModel)]="server" (selectionChange)="updateAgents()">
        <mat-option *ngFor="let serv of servers" [value]="serv.name">
            {{serv.title}}</mat-option>
    </mat-select>
</mat-form-field>
<button mat-icon-button (click)="updateAgents()">
    <mat-icon>refresh</mat-icon>
</button>

<mat-form-field style="width: calc(100% - 55px); margin-right: 4px;" appearance="outline">
    <mat-label>Доступные агенты</mat-label>
    <input type="text" matInput [formControl]="profile" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of profiles$ | async" [value]="option">
            {{option.agentName}}
        </mat-option>
    </mat-autocomplete>
    <button *ngIf="this.profile.value" mat-icon-button matSuffix (click)="this.profile.setValue(undefined)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<button mat-icon-button (click)="requestAccess()">
    <mat-icon>run</mat-icon>
</button>

<a *ngIf="responce && responce.payload" [href]="'https://rpa.extpost.ru/bot-auth/'+responce.payload">ссыль</a>

<pre>
{{profile.value | json}}
<hr>
{{responce | json}}
</pre>
