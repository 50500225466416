<div style="width: 100%; display: block; text-align: right; font-size: 4pt;">
    <button mat-icon-button mat-dialog-close cdkFocusInitial><mat-icon>close</mat-icon> </button>
</div>
<!-- <h1 mat-dialog-title>{{client.name}} </h1> -->

<div mat-dialog-content style="color: black;">
    <h2>{{client.name}} </h2>
    {{client.inn}}/{{client.kpp}}

    <span class="client_info" *ngIf="client.category">Категория: {{client.category}} </span>
    <span class="client_info" *ngIf="client.deliveryDays">Дни доставки: {{deliveryDays(client.deliveryDays)}} </span>
    <span class="client_info" *ngIf="client.route">Маршрут: {{client.route}} </span>
    <span class="client_info" *ngIf="debetDocs.length">Задолженность: </span>

    <ul *ngIf="debetDocs.length">
        <!-- <div>Задолженность:</div> -->
        <li class="debet" *ngFor="let org of orgList">

            {{org.name}}

            <span *ngIf="sumByOrg(org.id) > 0; else credit" class="blue_number"> <span class="primary"> Долг:
                    {{sumByOrg(org.id) |
                    currency:'RUB':'symbol':'1.2-2':'ru'}}</span></span>


            <ng-template #credit>
                <span style="color: darkgoldenrod;">Переплата: {{sumByOrg(org.id) |
                    currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
            </ng-template>

            <div *ngIf="overSumByOrg(org.id) > 0 " class="warn">Просрочено: {{overSumByOrg(org.id) |
                currency:'RUB':'symbol':'1.2-2':'ru'}}</div>



        </li>

    </ul>

</div>

<div mat-dialog-actions>

    <span *ngIf="!data.selectMode">
        <button class="butt" mat-flat-button color="primary" *ngIf="!client.isOwn" mat-dialog-close=""
            [routerLink]="['/client', client.id]">
            Карточка ТТ
        </button>
        <button class="butt" mat-flat-button color="primary" *ngIf="client.isOwn" mat-dialog-close=""
            [routerLink]="['/ors', client.id]">
            Карточка ОРС
        </button>
        <app-create-order-button class="butt" [buypointId]="client.id"
            (clicked)="createOrderClicked()"></app-create-order-button>
        <span style="margin: 4px 8px;"></span>
        <button class="butt" mat-flat-button [color]="client.quests ? 'warn' : 'primary'" mat-dialog-close=""
            [routerLink]="['/quests/', client.id]">
            Задачи: {{client.quests}}
        </button>

    </span>

    <span *ngIf="data.selectMode">
        <button mat-flat-button color="primary" (click)="selectClientClicked(client)">Выбрать</button>
    </span>

</div>