import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';

@Component({
  selector: 'app-order-log',
  templateUrl: './order-log.component.html',
  styleUrls: ['./order-log.component.scss']
})
export class OrderLogComponent implements OnInit {
  @Input() id: string | undefined | null
  result:any[] = [];
  progress = false
  updated: Date | undefined

  constructor(private api: RpaHttpService,
    private snack: MatSnackBar,
    private route: ActivatedRoute) {


  }


  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      this.id = p.get("id")
      if (this.id) {
        this.loadOrderLog()
      }
    })
  }

  loadOrderLog() {
    this.progress = true
    

    this.api.get<any[]>(`/v2/api/erp/GetOrderLog/${this.id}`).subscribe(
      {
        next: (result) => {
          this.result = result
          this.updated = new Date()
          this.progress = false
        },
        error: (e) => {
          console.error(e)
          this.snack.open("не удалось загрузить журнал", "ок", { duration: 2000 })
          this.progress = false
        }
      }
    )
  }


}
