import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { SyncFetchFilter, SyncStartQuery } from 'src/app/filters/SyncFilter';
import { Client } from 'src/app/models/client.model';
import { PagedList } from 'src/app/models/PagedList';
import { Matrix, SyncFetch, SyncItemType, SyncMap, } from 'src/app/models/sync.model';
import { RpaHttpService } from '../http/rpa-http.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { OnlineMatrix } from 'src/app/models/goods/matrix-online';


@Injectable({
  providedIn: 'root'
})
export class RpaClientService {


  constructor(private rpa: RpaHttpService,
    private stor: LocalStorageService
  ) { }

  getClients(): Observable<PagedList<Client>> {
    return this.rpa.post<PagedList<Client>>('/v2/api/Clients/Find')
  }

  syncStart(start: SyncStartQuery) { //kinds: SyncItemType[], full: boolean
    start.deviceUID = this.stor.widevineId
    return this.rpa.post<SyncMap>('/v2/api/sync/start', start)
  }


  syncDone(start: SyncStartQuery) {
    start.deviceUID = this.stor.widevineId
    return this.rpa.post<SyncMap>('/v2/api/sync/done', start)
  }

  async syncFetchPromise(filter: SyncFetchFilter) {
    return await lastValueFrom(this.rpa.post<SyncFetch>('/v2/api/sync/fetch', filter))
  }

  async syncSendPromise(data: SyncFetch) {
    return await lastValueFrom(this.rpa.post<SyncFetch>('/v2/api/sync/upload', data))
  }





  getMatrix(buypointId: string, date: Date): Observable<Matrix> {
    return this.rpa.post<Matrix>('/v2/api/erp/getmatrix', {
      buypointId,
      date
    })
  }


  getMatrixOnline(buypointId: string, date: Date): Observable<OnlineMatrix> {
    return this.rpa.post<OnlineMatrix>('/v2/api/erp/getMatrixOnline', {
      buypointId,
      date
    })
  }

}
