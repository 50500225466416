import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subscription, SubscriptionLike, skip, take } from 'rxjs';
import { PagedList } from 'src/app/models/PagedList';
import { Client } from 'src/app/models/client.model';
import { Good } from 'src/app/models/sync.model';
import { CurrentOrderService } from 'src/app/services/data/current-order.service';
import { ErpService } from 'src/app/services/net/erp.service';

@Component({
  selector: 'app-edit-order-advice',
  templateUrl: './edit-order-advice.component.html',
  styleUrls: ['./edit-order-advice.component.scss']
})
export class EditOrderAdviceComponent implements OnInit, OnDestroy {
  client: Client | undefined
  loadingVal = false

  tm: any
  progressMode: ProgressBarMode = 'determinate';

  public goods: Good[] = []
  subscriptions: Subscription[] = []


  /**
   *
   */
  constructor(private erp: ErpService,
    private currentOrder: CurrentOrderService) {

    const sq = currentOrder.buypoint$
    .pipe(take(1))
    .subscribe({
      next: (client) => {
        if (!client) return
        this.setClient(client)
      }
    })
    this.subscriptions.push(sq)

  }
  setClient(client: Client): void {
    this.client = client
    const sq1 = this.erp.getOffer(this.client.id).subscribe({
      next: (goods) => {
        if (goods)
          this.goods = goods
      }
    })
    this.subscriptions.push(sq1)
  }

  ngOnInit(): void {
    if (!this.client) {
      return
    }

  }

  public set loading(v: boolean) {
    clearTimeout(this.tm)
    this.tm = setTimeout(() => {
      this.loadingVal = v;
      this.progressMode = (v ? 'indeterminate' : 'determinate') as ProgressBarMode

    }, 100);
  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

}
