<h1 mat-dialog-title> <span [class]="data.warn ? 'warn' : '' "> {{data.title}} </span>
</h1>
<div mat-dialog-content [class]="data.warn ? 'warn' : '' ">
  <p [class]="data.warn ? 'warn' : '' " >{{data.question}}</p>
  <!-- mat-form-field>
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onClick(true)"><span
      *ngIf="data.confirmButtonText;else noTextButton">{{data.confirmButtonText}}</span>
    <ng-template #noTextButton>Ок</ng-template>
  </button>
  <button mat-button (click)="onClick(false)" cdkFocusInitial>Отмена</button>
</div>