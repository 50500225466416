import { Component, OnDestroy, OnInit } from '@angular/core';
import { CashOrderAdvance } from 'src/app/models/DebetDocs';
import { DebetService } from 'src/app/services/data/debet.service';
import { DbService } from 'src/app/services/data/idb.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { SubscriptionLike } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/services/confirm/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/services/confirm/ConfirmDialogData';
import { Router } from '@angular/router';
import { SyncService } from 'src/app/services/sync/sync.service';

@Component({
  selector: 'app-advance-list',
  templateUrl: './advance-list.component.html',
  styleUrls: ['./advance-list.component.scss']
})
export class AdvanceListComponent implements OnInit, OnDestroy {

  loading: boolean = true
  isAdmin: boolean = false
  advanceDateStart: Date = new Date()
  advanceDateEnd: Date = new Date()

  cashOrders: CashOrderAdvance[] = []
  cashOrdersView: CashOrderAdvance[] = []

  listFilial: any[] = []
  choosenFilial: string | undefined

  listOrgs: any[] = []
  choosenOrg: string | undefined

  debetService: DebetService | undefined
  dbService: DbService | undefined

  subscriptions: SubscriptionLike[] = []

  constructor(
    private conMng: ConnectionManagerService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private sync: SyncService
  ) {
    this.initDb()
    this.isAdmin = this.conMng.isAdmin()
    this.subscriptions.push(this.conMng.activeServerObservable().subscribe(x => { this.initDb(); }))


  }


  ngOnInit(): void {
    const s = this.sync.syncEnd.subscribe(x => this.findCashOrders())
    this.subscriptions.push(s)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => { subscription.unsubscribe() })
    this.subscriptions = []
  }


  initDb() {

    this.conMng.getDb()
      .then(x => {
        if (x) {
          this.debetService = new DebetService(x)
          this.dbService = x
          this.findCashOrders()
        }
      })
      .catch(err => { this.snack.open(`Не удалось подключиться к базе - ${err}`, 'Закрыть', { duration: 5000 }) })

  }


  swipe(plus: boolean) {
    if (plus) {
      this.advanceDateStart = new Date(this.advanceDateStart.getFullYear(), this.advanceDateStart.getMonth(), this.advanceDateStart.getDate() + 1)
    }
    else {
      this.advanceDateStart = new Date(this.advanceDateStart.getFullYear(), this.advanceDateStart.getMonth(), this.advanceDateStart.getDate() - 1)
    }

    this.advanceDateEnd = this.advanceDateStart
    this.findCashOrders()
  }

  findCashOrders() {
    this.loading = true
    this.choosenFilial = undefined
    this.choosenOrg = undefined
    this.debetService?.getAdvancesByDate(this.advanceDateStart, this.advanceDateEnd)
      .then(x => {
        if (x) {
          this.cashOrders = x
          this.cashOrdersView = x
          this.getFilterAtributes()
        }
        else {
          this.cashOrders = []
          this.cashOrdersView = []
          this.listFilial = []
          this.listOrgs = []
        }
      })
      .catch(err => { this.snack.open(`Ошибка загрузки авансов - ${err}`, 'Закрыть', { duration: 5000 }) })
      .finally(() => this.loading = false)

  }

  getFilterAtributes() {
    this.listFilial = this.cashOrders.map((c) => { return { id: c.filialId, name: c.filial } })
      .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);

    this.listOrgs = this.cashOrders.map((c) => { return { id: c.organizationId, name: c.organization } })
      .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);
  }

  applyFilter() {
    this.loading = true
    const filtered = this.cashOrders.filter(d =>
      (this.choosenOrg === undefined || this.choosenOrg === d.organizationId) &&
      (this.choosenFilial === undefined || this.choosenFilial === d.filialId))

    this.cashOrdersView = filtered
    this.loading = false
  }


  confirmAdvance(advance: CashOrderAdvance) {

    const dlg = this.dialog.open(ConfirmDialogComponent, {
      data: {
        question: 'Уверены, что хотите подвердить АП? После его нужно будет синхронизировать!',
        title: 'Подтвержение АП',
        confirmButtonText: 'Да'
      } as ConfirmDialogData
    })

    dlg.afterClosed().subscribe((res: ConfirmDialogData) => {
      if (res?.result) {
        advance.state = 2
        this.debetService?.putAdvance(advance)
          .then(x => { this.findCashOrders() })
          .catch(err => { this.snack.open(`Ошибка подтверждения АП - ${err}`, 'Закрыть', { duration: 5000 }) })
      }
    })
  }

  deleteAdvance(advance: CashOrderAdvance) {

    const dlg = this.dialog.open(ConfirmDialogComponent, {
      data: {
        question: 'Уверены, что хотите удалить АП?',
        title: 'Удаление АП',
        confirmButtonText: 'Да'
      } as ConfirmDialogData
    })

    dlg.afterClosed().subscribe((res: ConfirmDialogData) => {
      if (res?.result) {
        this.debetService?.deleteAdvance(advance)
          .then(x => { this.findCashOrders() })
          .catch(err => { this.snack.open(`Ошибка удаления АП - ${err}`, 'Закрыть', { duration: 5000 }) })
      }
    })

  }




  advSum() {
    let sum = 0
    this.cashOrdersView.forEach(a => sum += a.summ)

    return sum
  }



  linkOrsOrTT(buypointid: string) {

    this.dbService?.getClient(buypointid).subscribe(x => {
      if (x?.isOwn) {
        this.router.navigate([`/ors/${buypointid}`])
      }
      else {
        this.router.navigate([`/client/${buypointid}`])
      }
    })

  }






}
