import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderLine, OrderModel } from 'src/app/models/order.model';
import { Good } from 'src/app/models/sync.model';
import { QuantityDialogData, QuantityDialogPlainComponent } from '../quantity-dialog/quantity-dialog.component';
import { CurrentOrderService } from 'src/app/services/data/current-order.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, SubscriptionLike } from 'rxjs';
import { ErpPrice } from 'src/app/models/goods/product';

@Component({
  selector: 'app-edit-order-select-goods-item',
  templateUrl: './edit-order-select-goods-item.component.html',
  styleUrls: ['./edit-order-select-goods-item.component.scss']
})
export class EditOrderSelectGoodsItemComponent implements OnInit, OnDestroy {
  @Input() item!: Good
  @Input() clientPriceType: string | undefined
  orderId: string | undefined



  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  subscriptions: SubscriptionLike[] = []

  /**
   *
   */
  constructor(
    public dialog: MatDialog,
    public currentOrder: CurrentOrderService) {


  }

  ngOnInit(): void {


    const sq1 = this.currentOrder.orderInfo$.subscribe({
      next: (o) => {

        if (this.item && this.item.codeExch === "L4994") {
          console.log(o?.id)
        }

        if (this.item.rests !== undefined && this.item.rests?.length) {
          this.item.displayRest = this.item.rests[0].quantity
        } else {
          this.item.displayRest = 0
        }

        if (this.item && this.item.prices && Object.keys(this.item.prices).length > 0 && this.clientPriceType) {
          this.item.displayPrice = this.item.prices[this.clientPriceType]
        } else {
          this.item.displayPrice = 0
        }

        if (!o) return
        this.orderId = o.id

        this.mapOrdered(o)

      }
    })

    const sq2 = this.currentOrder.prices$.subscribe({
      next: (p) => {
        this.mapPrices(p)
      }
    })

    this.subscriptions.push(sq1, sq2)



  }

  mapPrices(p: ErpPrice | undefined) {
    const current = p?.items.find(a => a.goodId == this.item.code)
    if (!current) return

    this.item.displayClientPrice = current.price
    this.item.displaySalePrice = current.salePrice

  }

  mapOrdered(orderInfo: OrderModel) {
    const inOrder = orderInfo?.items.find(a => a.goodId === this.item.code)

    if (!inOrder) {
      this.item.ordered = undefined

    } else {

      this.item.ordered = inOrder.amount
    }
  }


  clickOnItem(item: Good) {


    if (!this.orderId) {
      return
    }


    let price = this.item.displayPrice;

    // перетираем цену по типу - персональной ценой
    if (this.item.displayClientPrice !== undefined && this.item.displayClientPrice > 0) {
      price = this.item.displayClientPrice
    }

    // перетираем персональную цену акционной ценой
    if (this.item.displaySalePrice !== undefined && this.item.displaySalePrice > 0) {
      price = this.item.displaySalePrice
    }



    // отсутствие цены - ошибка. но агент должен иметь возможность сделать заказ.
    if (this.item.displayRest > 0) {

      const dd = {
        line: {
          price: price,
          goodId: item.code,
          good: item.name,
          orderId: this.orderId,
          amount: item.ordered

        } as OrderLine,

        ok: false
      } as QuantityDialogData

      this.openDialog(dd)
    }

  }

  openDialog(dd: QuantityDialogData): void {


    const dialogRef = this.dialog.open(QuantityDialogPlainComponent, {
      width: '600px',
      data: dd,
    })

    dialogRef.afterClosed().subscribe((result: QuantityDialogData) => {
      if (result?.line != undefined && result.line.amount != undefined && result.line.amount > 0 && result.ok) {
        this.currentOrder.addOrUpdateItem(result.line)

      }
    })
  }

  // personalPrice(item: Good) {

  //   if (item.prices !== undefined &&
  //     item.prices &&
  //     Object.keys(item.prices).length &&
  //     this.prices &&
  //     this.prices.items?.length &&
  //     this.client?.priceType) {

  //     const price = item.prices[this.client.priceType]
  //     const good = this.prices?.items.find(x => x.goodId === item.code && price !== x.price)
  //     return good

  //   }

  //   return undefined
  // }


  // public showPrice(good: Good) {
  //   if (good && good.prices && Object.keys(good.prices).length > 0 && this.client && this.client.priceType)
  //     return good.prices[this.client.priceType]

  //   return "0"
  // }




  public ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()

    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

}
