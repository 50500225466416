<div class="page-content-block" *ngIf="connMgr.users.length">
    <h1>Настройка пользователя</h1>
    <div class="settings-area">
        <mat-form-field appearance="outline" style="width: calc(100% - 55px); margin-right: 4px;">
            <mat-label>Пользователь</mat-label>
            <mat-select [(ngModel)]="activeUser.user.Code" (ngModelChange)="changeActiveUser()">
                <mat-option *ngFor="let user of connMgr.users" [value]="user.user.Code">
                    {{user.user.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!--mat-raised-button  class="mat-raised-button-custom" -->
        <button style="vertical-align: middle;" mat-icon-button color="warn"
            (click)="deleteUser()"><mat-icon>delete</mat-icon></button>
    </div>
    <!-- </div>

<div class="page-content-block">-->
    <div> <!-- *ngIf="isAdmin() || isSuper() " -->
        <h2>Привязанные агенты</h2>
        <mat-list *ngIf="connMgr.Connections?.length">
            <ng-container *ngFor="let serv of connMgr.Connections">
                <mat-list-item>
                    <!-- <mat-icon *ngIf="!checkTimeConn(serv);else noPower" matListItemIcon
                    class="primary">power</mat-icon>
                <ng-template #noPower> <mat-icon color="warn" matListItemIcon>power_off</mat-icon></ng-template> -->
                    <div class="text_wrap" [style]="activeServ(serv) ? 'font-weight:bold':''" matListItemLine>
                        {{serv.title}} ({{serv.user.Code}})</div>
                    <!-- <div class="text_wrap" matListItemLine>({{serv.user.Code}})</div> -->

                    <div *ngIf="media.isMobile" matListItemMeta>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon matBadge="!" [matBadgeHidden]="!checkTimeConn(serv) || !media.isMobile"
                                matBadgeSize="small" matBadgeColor="accent"
                                color="primary">more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu">


                            <button mat-menu-item *ngIf="checkTimeConn(serv)" color="accent"
                                (click)="addConn(serv.user.Email, serv.user.Server)" [disabled]="running">
                                <mat-icon color="accent">refresh</mat-icon>
                                <span>Обновить</span>
                            </button>

                            <button mat-menu-item (click)="deleteServ(serv)" style="vertical-align: middle;">
                                <mat-icon color="warn">delete</mat-icon>
                                <span>Удалить</span>
                            </button>

                        </mat-menu>

                    </div>
                    <div *ngIf="!media.isMobile" matListItemMeta>
                        <button *ngIf="checkTimeConn(serv)" mat-raised-button color="accent"
                            (click)="addConn(serv.user.Email, serv.user.Server)" [disabled]="running">Обновить</button>

                        <button mat-icon-button (click)="deleteServ(serv)" style="vertical-align: middle;">
                            <mat-icon color="warn">delete</mat-icon></button>
                    </div>

                </mat-list-item>


                <!-- <mat-divider></mat-divider> // TODO: Надо как то поаккуратнее бы ... -->
            </ng-container>
        </mat-list>

        <br>

        <!-- <mat-form-field appearance="outline" style="width: calc(100% - 55px); margin-right: 4px;">
            <mat-label>Доступные агенты</mat-label>
            <mat-select [(ngModel)]="profile" panelClass="panel_width" disableOptionCentering="true">
                <mat-option *ngFor="let prof of profiles" [value]="prof">
                    {{prof.agentName}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

        <mat-form-field style="width: calc(100% - 55px); margin-right: 4px;" appearance="outline">
            <mat-label>Доступные агенты</mat-label>
            <input type="text" matInput [formControl]="profile" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of profiles$ | async" [value]="option">
                    {{option.agentName}}
                </mat-option>
            </mat-autocomplete>
            <button *ngIf="this.profile.value" mat-icon-button matSuffix (click)="this.profile.setValue(undefined)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <!--mat-raised-button  class="mat-raised-button-custom" -->
        <button mat-icon-button class="mat-raised-button-custom" color="primary" [disabled]="!profile"
            (click)="addConn(profile.value?.email, profile.value?.server)"
            style="vertical-align: middle;"><mat-icon>person_add</mat-icon></button>

    </div>




</div>

<div class="page-content-block">
    <!--  *ngIf="isAdmin() || !connMgr.users || !connMgr.users.length" -->
    <h2>Создание пользователя</h2>
    <div *ngIf="!complete; else completeBranch">
        <mat-form-field class="w50" appearance="outline">
            <mat-label>Регион</mat-label>
            <mat-select [(ngModel)]="server">
                <mat-option *ngFor="let serv of servers" [value]="serv.name">
                    {{serv.title}}</mat-option>
            </mat-select>
        </mat-form-field>

        <br>

        <mat-form-field class="w50" appearance="outline">
            <mat-label>Электронная почта</mat-label>
            <input matInput [(ngModel)]="email">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" [disabled]="!server || !email ||   running "
            (click)="requestAccess() ">Добавить</button>
        <mat-spinner *ngIf="running" diameter="25"></mat-spinner>
    </div>

    <ng-template #completeBranch>
        <h2>{{message}}</h2>
        <p>
            <i>
                указан адрес {{email}}
            </i>
        </p>
        <button mat-flat-button color="primary" (click)="complete = false;">
            Повторить
        </button>
    </ng-template>
</div>

<div class="page-content-block" *ngIf="isAdmin()">
    <h2>Для администратора</h2>
    <mat-form-field class="w50" appearance="outline">
        <mat-label>готовый токен</mat-label>
        <input matInput [(ngModel)]="externalToken">
    </mat-form-field>
    <br>

    <button mat-raised-button color="primary" [disabled]="!externalToken" (click)="applyExternalToken() ">Добавить
        токен</button>
</div>

<div class="page-content-block" >
    <h2>Регистрация через ссылку (для IPhone)</h2>
    <mat-form-field class="w50" appearance="outline">
        <mat-label>Ссылка</mat-label>
        <input matInput [(ngModel)]="appleInput">
    </mat-form-field>
    <br>

    <button mat-raised-button color="primary" [disabled]="!appleInput" (click)="appleReg()">Добавить</button>
</div>

