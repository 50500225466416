import { Component, OnDestroy } from '@angular/core';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnDestroy {
  /**
   *
   */
  version = environment.version
  show = false

  subs: Subscription[] = []
  isAdmin = false
  navList = navList


  constructor(
    public cm: ConnectionManagerService,
    private router: Router
  ) {


    const s1 = cm.activeServerObservable().subscribe(srv => this.setActiveServer(srv))
    this.subs.push(s1)
    const s2 = cm.activeUserchange().subscribe(srv => this.setActiveServer(srv))
    this.subs.push(s2)
    this.setActiveServer(cm.activeServer)
    this.activeNav()

  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe())
    this.subs = []
  }

  setActiveServer(srv: any | undefined): void {
    this.show = srv !== undefined && srv.user !== undefined
    this.isAdmin = this.cm.isAdmin()
  }

  activeNav() {

    const s3 = this.router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {
        this.navList.forEach(x => x.active = false)
        const url = '/' + e.url.split('/')[1]
        const f = this.navList.findIndex(x => x.url === url)

        if (this.navList[f]) {
          this.navList[f].active = true
        }

      }
    })

    // this.subs.push(s3)
  }



}

interface NavigationList {
  name: string
  url: string
  active: boolean
  icon: string
  role?: string
}


export const navList: NavigationList[] =
  [
    {
      name: 'Главная',
      active: false,
      url: '/home',
      icon: 'home'
    },
    {
      name: 'Задачи',
      active: false,
      url: '/quests',
      icon: 'priority_high',
      role: 'auth'
    },
    {
      name: 'Заказы',
      active: false,
      url: '/orders',
      icon: 'shopping_cart',
      role: 'auth'
    },
    {
      name: 'Клиенты',
      active: false,
      url: '/clients',
      icon: 'store',
      role: 'auth'
      // store
      // storefront
    },
    {
      name: 'Каталог',
      active: false,
      url: '/catalog',
      icon: 'shopping_basket',
      role: 'auth'
    },
    {
      name: 'Авансы',
      active: false,
      url: '/advances',
      icon: 'payments',
      role: 'auth'
    },
    {
      name: 'Синхронизация',
      active: false,
      url: '/sync',
      icon: 'sync',
      role: 'auth'
    },
    {
      name: 'Уведомления',
      active: false,
      url: '/notifications',
      icon: 'notifications',
      role: 'isAdmin'
    },
    {
      name: 'Справка',
      active: false,
      url: '/datasheet',
      icon: 'help_outline',
      role: 'auth'
    },
    {
      name: 'Настройки',
      active: false,
      url: '/settings',
      icon: 'settings',
    },

    {
      name: 'admin',
      active: false,
      url: '/admin',
      icon: 'notifications',
      role: 'isAdmin'
    },

  ]
