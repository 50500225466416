import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionEvent, VersionInstallationFailedEvent } from '@angular/service-worker';
import { from } from 'rxjs';

@Component({
  selector: 'app-update-indicator',
  templateUrl: './update-indicator.component.html',
  styleUrls: ['./update-indicator.component.scss']
})
export class UpdateIndicatorComponent implements OnInit {

  updatesisEnabled = false
  hasUpdate = false

  constructor(private updates: SwUpdate,
    private snackBar: MatSnackBar) {
  }


  ngOnInit(): void {
    this.updatesisEnabled = this.updates.isEnabled

    if (this.updatesisEnabled) {

      //console.log("updates is Enabled")

      this.updates.versionUpdates.subscribe({
        next: (event) => {
          this.readEvent(event)
        },
        error: () => {
          this.snackBar.open("Ошибка проверки обновления 101", "ok", { duration: 2400 })
        },

      })

      const sq = from(this.updates.checkForUpdate())
      sq.subscribe((ok) => {

        //        console.log(`checkForUpdate - ${ok}`)

      })
    } else {
      console.log("updates is NOT Enabled")

    }

  }


  readEvent(event: VersionEvent) {
    switch (event.type) {
      case 'VERSION_READY': {
        this.snackBar.open("Новая версия готова к установке", "ok", { duration: 2500 })
        this.hasUpdate = true
        break
      }
      case 'VERSION_DETECTED': {
        this.snackBar.open("Найдена новая версия", "ok", { duration: 1200 })
        break
      }
      case 'NO_NEW_VERSION_DETECTED': {

        console.log(`НЕ найдена новая версия`)
        //this.snackBar.open("НЕ найдена новая версия", "ok", { duration: 1200 })
        break
      }
      case 'VERSION_INSTALLATION_FAILED': {

        console.log(event)
        const cs = event as VersionInstallationFailedEvent

        this.snackBar.open(`Не удалось установить обновление ${cs.error}!`, "ok", { duration: 6800 })

        break
      }

      default: {
        this.snackBar.open("Неизвестное событие обновления", "ok", { duration: 4000 })
      }
    }
  }


  runUpdate() {
    document.location.reload();
  }


  describeError() {
    this.snackBar.open(`Возможно, приложение не получает обновления. Обратитесь в поддержку.`, 'Закрыть', { duration: 7000 })
  }

}
