<span *ngIf="cm.Connections?.length; else noServ">

    <span class="userSelectorWrapper">
        <mat-select #userSelector class="userSelector" [(ngModel)]="server" (ngModelChange)="activeServer($event)"
            disableOptionCentering="false" [disabled]="sync.running || cm.isLocked">

            <mat-option [disabled]="cm.isLocked" *ngFor="let serv of cm.Connections" [value]="serv">
                {{serv.title}}
            </mat-option>

        </mat-select>
        <span class="userSelectorWrapper" style="font-size: small;"
            *ngIf="onlyText; else fullInfo">{{server?.title}}</span>

        <ng-template #fullInfo>
            <span [class]="'userName'" (click)="userSelector.open()">
                <!--  || cm.isLocked -->
                <button mat-icon-button (click)="userSelector.open()">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <span class="humble" *ngIf="!mobileView">{{server?.title}}</span>

            </span>

        </ng-template>


    </span>


</span>

<ng-template #noServ>
    <span class="humble">Нет сервера</span>
</ng-template>