import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CashOrder, CashOrderAdvance, CashOrderBlank, CreateCashOrder, DebetDoc } from 'src/app/models/DebetDocs';
import { Client } from 'src/app/models/client.model';
import { FilialView, OrgView } from 'src/app/models/dict.model';
import { DebetService } from 'src/app/services/data/debet.service';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { v4 } from 'uuid';


@Component({
  selector: 'app-create-advance-dialog',
  templateUrl: './create-advance-dialog.component.html',
  styleUrls: ['./create-advance-dialog.component.scss']
})
export class CreateAdvanceDialogComponent implements AfterViewInit {

  blankAutoCompleteControl: FormControl
  summaControl: FormControl


  debetDoc: DebetDoc[] | undefined

  filteredOptions: Observable<CashOrderBlank[]> | undefined;


  createOrder: CreateCashOrder | undefined

  answercash: CashOrder | undefined;
  errorMessage?: string;
  accept = false

  debetService: DebetService
  //diffsumm: number = (this.data.doc.debetSumm - (this.data.doc.prepaymentSumm ?? 0))

  // cashOrdersBlanks: CashOrderBlank[] = [];


  constructor(public dialog: MatDialogRef<CreateAdvanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDebet,
    private snackBar: MatSnackBar,
    private conmng: ConnectionManagerService,
  ) {

    this.debetService = new DebetService(conmng.getCurrentDb())

    this.blankAutoCompleteControl = new FormControl()
    this.summaControl = new FormControl(this.data.summ.toFixed(2), [Validators.max(parseFloat(this.data.summ.toFixed(2)))]) //(this.diffsumm < 0 ? 0 : this.diffsumm).toFixed(2)

    this.debetDoc = this.data.doc


  }
  ngAfterViewInit(): void {

  }




  createCashOrder() {
    if (!this.debetDoc)
      return

    // const idblank = this.cashOrdersBlanks.find(x => x.number == this.blankAutoCompleteControl.value)

    let restsum = Number(this.summaControl.value)

    const today = new Date()

    // const diffdays = getNumberOfDays(today, this.debetDoc[0].datePay)

    this.debetDoc.sort((n1, n2) => {
      if (getNumberOfDays(n1.datePay, today) > getNumberOfDays(n2.datePay, today)) { return -1 }
      if (getNumberOfDays(n1.datePay, today) < getNumberOfDays(n2.datePay, today)) { return 1 } return 0
    })

    let avans: CashOrderAdvance[] = []
    let countssend = 0
    for (let i = 0; i < this.debetDoc.length; i++) {

      const diffsumm = parseFloat((this.debetDoc[i].debetSumm - (this.debetDoc[i].prepaymentSumm ?? 0)).toFixed(2))

      if (restsum <= 0) {
        // this.notification(0)
        break
      }
      countssend = i + 1
      // let dif = this.currencyPipe.transform(diffsumm) ?? '0'
      // let rest = this.currencyPipe.transform(restsum) ?? '0'
      // console.log(dif)
      // console.log(rest)
      avans.push({
        buyPointId: this.data.buypoint.id,
        created: new Date(),
        // agentId: this.debetDoc[i].agentId,
        agentId: this.conmng.activeServer?.user.Id,
        date: new Date(),
        docDate: this.debetDoc[i].date,
        docId: this.debetDoc[i].docId,
        docType: this.debetDoc[i].type,
        filialId: this.debetDoc[i].filialId,
        id: v4(),
        isCommited: false,
        isDeleted: false,
        // number: this.debetDoc[i].number,
        organizationId: this.debetDoc[i].organizationId,
        source: 1,
        state: 1,
        summ: restsum - diffsumm >= 0 ? parseFloat(diffsumm.toFixed(2)) : parseFloat(restsum.toFixed(2)),
        vendorId: this.debetDoc[i].vendorId,
        buyPoint: this.data.buypoint.name,
        docNumber: this.debetDoc[i].number,
        filial: this.debetDoc[i].filial,
        // agent: this.data.buypoint.agent,
        organization: this.debetDoc[i].organization,

      } as CashOrderAdvance)

      restsum -= diffsumm


    }


    this.debetService.addAdvances(avans)
      .then(x => this.notification(countssend, true))
      .catch(err => { this.errorMessage = err; this.notification(countssend, false) })


  }


  notification(countsend: number, succses: boolean) {
    if (succses) {
      this.snackBar.open(`Создан АП (${countsend}), не забудьте подтвердить в авансах!`, 'Закрыть', { duration: 5000 })
      this.dialog.close(succses);
    }
    else {
      this.snackBar.open('Что то пошло не так: ' + this.errorMessage, 'Закрыть', { duration: 5000 })
    }

  }

  exit() {
    this.dialog.close()
  }




}

function getNumberOfDays(start: Date, end: Date) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}


export interface DialogDataDebet {
  doc: DebetDoc[],
  summ: number,
  filial: FilialView,
  org: OrgView
  buypoint: Client
}