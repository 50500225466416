import { Component, Input, OnInit } from '@angular/core';
import { Client, DeliveryDay } from 'src/app/models/client.model';

@Component({
  selector: 'app-client-delivery-info',
  templateUrl: './client-delivery-info.component.html',
  styleUrls: ['./client-delivery-info.component.scss']
})
export class ClientDeliveryInfoComponent implements OnInit {

  @Input() buypoint!: Client
  data: DeliveryDay[] | undefined;


  ngOnInit(): void {

    this.data = this.buypoint.deliveryDaysOfWeek?.map(d => {
      return {
        name: d.name,

        mon: this.routeText(d.mon),
        tue: this.routeText(d.tue),
        wed: this.routeText(d.wed),
        thu: this.routeText(d.thu),
        fri: this.routeText(d.fri),
        sat: this.routeText(d.sat),
        sun: this.routeText(d.sun),

        monClass: this.routeClass(d.mon),
        tueClass: this.routeClass(d.tue),
        wedClass: this.routeClass(d.wed),
        thuClass: this.routeClass(d.thu),
        friClass: this.routeClass(d.fri),
        satClass: this.routeClass(d.sat),
        sunClass: this.routeClass(d.sun),



      } as DeliveryDay
    })
  }

  routeClass(route: string | undefined) {
    return route === undefined || route === '' ? "routeerror" : route === '<Маршрут не задан>' ? "noroute" : "goodroute"
  }

  routeText(route: string | undefined) {
    return route === undefined || route === '' ? "ошибка" : route
  }

}
