import { Injectable } from '@angular/core';
import { AuthResponse, ProfileRpa, Server, Token } from 'src/app/models/server';
import { RpaHttpService } from '../http/rpa-http.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { Observable } from 'rxjs';
 


@Injectable({
  providedIn: 'root'
})
export class RpaIdClientService {


  constructor(private rpa: RpaHttpService,
    private stor: LocalStorageService) { }

  getServers() {
    return this.rpa.get<Server[]>('/public/Auth/GetServers')
  }

  getToken(code: string, server: string) {
    const filter = {
      code: code,
      server: server,
      deviceUID: this.stor.widevineId
    }
    return this.rpa.post<Token>('/public/Auth/GetToken', filter)
  }

  requestAccess(email: string, server: string, sendEmail: boolean): Observable<AuthResponse> {
    const query = {
      email: email,
      server: server,
      deviceUID: this.stor.widevineId,
      SendEmail: sendEmail
    }
    return this.rpa.post<AuthResponse>('/public/Auth/requestAccess', query)
  }

  getProfiles(query: any) {
    return this.rpa.post<ProfileRpa>('/public/Auth/getprofiles', query)
  }

}

