import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, finalize, from } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { DbService } from 'src/app/services/data/idb.service';

import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { SyncService } from 'src/app/services/sync/sync.service';
import { ClientInfoDialogComponent } from '../client-info-dialog/client-info-dialog.component';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { DebetService } from 'src/app/services/data/debet.service';
import { DebetDoc } from 'src/app/models/DebetDocs';
import { Quest } from 'src/app/models/quests/quest.model';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit, OnDestroy {
  @Input() selectMode = false
  @Output() clientSelected: EventEmitter<Client> = new EventEmitter<Client>()
  clients: Client[] = []
  filclients: Client[] = []

  quests: Quest[] = []

  debetService: DebetService | undefined
  debetDocs: DebetDoc[] = []
  debetLoading: boolean = true

  sub: Subscription[] = []

  private db: DbService | undefined

  constructor(
    private sync: SyncService,

    public cm: ConnectionManagerService,
    public pref: LocalStorageService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // this.getClientsDb()
    const s = this.cm.activeServerObservable().subscribe(() => {
      this.db = undefined
      this.getClientsDb()
    })
    this.sub.push(s)

    const s2 = this.sync.syncEnd.subscribe(x => { this.getClientsDb() })
    this.sub.push(s2)
  }

  getClientsDb() {

    if (!this.db) {
      from(this.cm.getDb()).subscribe(d => {

        if (!d) return

        this.db = d
        this.debetService = new DebetService(this.db)
        this.getClientDbInternal()

      })
    } else {
      this.getClientDbInternal()
    }

  }

  findDebets() {
    this.debetLoading = true
    this.debetService?.getDebetsbyClients(this.clients.map(x => x.id))
      .then(x => {
        const dd = x.flatMap(s => s) as DebetDoc[]
        this.debetDocs = dd
        this.joinDebet()
      })
      .finally(() => { this.debetLoading = false; this.runFilter() })
  }

  joinDebet() {
    this.clients.forEach(c => {
      let sum = 0
      const debetClient = this.debetDocs.filter(d => d.buypointId === c.id)
      const overDebetClient = this.debetDocs.filter(d => d.datePay.getTime() < new Date().getTime() && d.buypointId === c.id)
      debetClient.forEach(d => sum += d.debetSumm)
      c.debet = sum
      sum = 0
      overDebetClient.forEach(d => sum += d.debetSumm)
      c.overtimeDebet = sum
    })
    // console.log(this.clients)
  }

  ngOnDestroy(): void {
    this.sub?.forEach(s => s.unsubscribe())
    this.sub = []
  }

  syncClients() {
    // this.sync.syncClients()
  }



  private getClientDbInternal() {

    if (!this.db) return;

    this.db.getClients().subscribe({
      next: x => {
        x.sort((a, b) => {
          if (a.name > b.name) return 1
          else if (a.name < b.name) return -1
          else return 0
        });
        this.clients = x;
        this.findDebets()
        this.getQuests()
        this.runFilter()

      },
      error: err => { this.snack.open(`Ошибка: ${err}`, 'Закрыть', { duration: 5000 }) }
    })


  }

  private getQuests() {
    // this.db?.getAll('quests').then(x => {
    //   this.quests = x ?? []
    //   this.joinQuests()
    // })
    const filter = {
      BuypointId: '',
      BuypointName: '',
      Modified: undefined,
      Page: 0,
      PageSize: 100,
      States: [1, 2],
      QuestType: 0
    }
    const s1 = this.db?.findQuests(filter)
    .pipe(finalize(()=>{s1?.unsubscribe()}))
      .subscribe(x => {
        this.quests = x?.items ?? []
        this.joinQuests()
      })
  }

  private joinQuests() {
    this.clients.forEach(x => {
      x.quests = this.quests.filter(q => q.buypoint?.id === x.id).length
    })
  }

  runFilter() {
    this.pref.save()
    // if (!this.search) {
    //   // let filter = this.switchFilter([...this.clients])
    //   this.filclients = this.clients
    //   return
    // }
    const low = this.pref.searchOptions.name?.toLowerCase() ?? ""

    let find = this.clients.filter(x => searchLike(x.name, low))

    find = this.debetFilter(find)

    // if (this.pref.searchOptions.noBlock) {
    //   find = find.filter(x => x.locked)
    // }
    // let filter = this.switchFilter(find)
    this.filclients = find
  }

  debetFilter(clients: Client[]) {
    // if (this.pref.searchOptions.hasDebet) {
    //   clients = clients.filter(x => this.debetDocs.map(x => x.buypointId).includes(x.id))
    // }

    // if (this.pref.searchOptions.hasOverDebet) {
    //   const overDebets = this.debetDocs.filter(x => x.datePay.getTime() < new Date().getTime())
    //   clients = clients.filter(x => overDebets.map(x => x.buypointId).includes(x.id))
    // }

    if (this.pref.searchOptions.hasDebet) {
      clients = clients.filter(x => x.debet && x.debet > 0)
    }

    if (this.pref.searchOptions.hasOverDebet) {
      clients = clients.filter(x => x.overtimeDebet && x.overtimeDebet > 0)
    }
    return clients
  }



  info(client: Client) {


    const dialogRef = this.dialog.open(ClientInfoDialogComponent, {
      data: {
        client: client,
        selectMode: this.selectMode
      }
    })

    dialogRef.afterClosed().subscribe((result) => {

      if (result && result.selected) {
        this.clientSelected.next(result.selected)
      }

    })
  }

  switchFilter(filclients: Client[]) {
    this.pref.save()
    return filclients.filter(c => {
      (this.pref.searchOptions.activeContract === c.activeContracts) &&
        (this.pref.searchOptions.noBlock === !(c.locked || !c.active))
      // (!this.localstorage.searchOptions.hasDebet || c.debets > 0) &&
      // (!this.pref.searchOptions.hasOverDebet || c.overtimeDebet > 0) && })
    })

  }

}



function searchLike(text: string, pattern: string) {
  if (typeof pattern !== 'string' || text === null) {
    return false
  }
  pattern = pattern.toUpperCase()
  // Remove special chars
  pattern = pattern.replace(
    new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'),
    '\\$1'
  )
  // Replace % and _ with equivalent regex
  while (pattern.indexOf('  ') > -1) {
    pattern = pattern.replace('  ', ' ')
  }

  pattern = pattern.replace(' ', '.*').replace(/_/g, '.')
  // Check matches

  return RegExp(pattern, 'gi').test(text.toUpperCase())
}
