<cdk-accordion class="example-accordion">



  <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
    [attr.id]="'accordion-header-' + 1" [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + 1">

    <div class="example-accordion-item-header" (click)="accordionItem.toggle()">

      <span class="example-accordion-item-description">
        <!-- [matBadge]="order.warn ? 1 : 0" [matBadgeHidden]="!order.warn" matBadgePosition="before" matBadgeOverlap="false"  -->
        <b *ngIf="order.number;else nonumber">{{order.number}}</b> <ng-template #nonumber><b>НЕ
            ПРИСВОЕН</b></ng-template>
        <mat-icon fontSet=" material-icons-outlined " class="status_indicator"
          style="color:darkorange;margin-left: 4px;" *ngIf="order.warn">
          sms </mat-icon>
        <!--  &nbsp;&nbsp;{{order.date | date: 'dd.LL.yyyy'}}&nbsp;&nbsp; -->

        <span *ngIf="order.totalAmount"> <span [class]="itemsMinus ? 'orange' : 'primary'">
            {{order.totalAmount}} шт. </span> на</span>
        <span class="primary"> {{order.summa |
          currency:'RUB':'symbol':'1.2-2':'ru'}}</span>

        <app-order-status-indicator *ngIf="check1cItems();else status" class="status_indicator"
          [statusId]="order.statusId" [onlyIcon]="true"></app-order-status-indicator>
        <ng-template #status>
          <app-order-status-indicator *ngIf="checkItemslength()" class="status_indicator" [statusId]="order.statusId"
            [onlyIcon]="true"></app-order-status-indicator>
        </ng-template>

        <p>
          {{order.buypoint}}
        </p>


        <p *ngIf="order.orders1c?.length">
          <span *ngIf="order.orders1c">Заявки:</span><br>
          <span *ngFor="let ord1c of order.orders1c">

            <app-order-status-indicator class="status_indicator" [statusId]="ord1c.statusId"
              [onlyIcon]="true"></app-order-status-indicator>

            {{ord1c.number}} <span *ngIf="ord1c.saleNumber">/ <b> РН
                {{ord1c.saleNumber}} {{ord1c.saleStatus}}</b> </span>
            <p>
              на {{ord1c.dateDelivery | date:'dd.MM.YYYY'}}
              <span *ngIf="ord1c.route" class="route_lable">, маршрут {{ord1c.route}} </span>

              <span *ngIf="ord1c.dispatcher" class="route_lable">, экспедитор {{ord1c.dispatcher}} </span>

              <span class="primary"> {{ord1c.amount}}</span>
              шт. на
              <span class="primary"> {{ord1c.summa |
                currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
            </p>
          </span>
        </p>



      </span>

      <mat-icon class="icon" *ngIf="!accordionItem.expanded">expand_more</mat-icon>
      <mat-icon class="icon" *ngIf="accordionItem.expanded">expand_less</mat-icon>

    </div>

    <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + 1" [attr.aria-labelledby]="'accordion-header-' + 1">

      <p> {{order.firm}} / {{order.filial}}</p>

      <p>
        <span class="primary" *ngIf="order.deliveryComment"> Коментарий по доставке: {{order.deliveryComment}}) </span>

        <span *ngIf="order.note"><br> <span class="primary"> Коментарий: </span>
          <span>{{order.note}}</span>
        </span>

        <span class="warn" *ngIf="order.error"><br>Ошибка: {{order.error}}</span>
      </p>



      <span *ngIf="order">
        <button [matBadge]="order.messageCount" matBadgeColor="accent" [matBadgeHidden]="order.messageCount === 0"
          mat-flat-button color="primary" [routerLink]="['/orderinfo', order.id]" class="air-button">
          Просмотр
        </button>

        <button mat-flat-button *ngIf="order.statusId === 1" [routerLink]="['/edit-order', order.id]"
          class="air-button">
          редактировать
        </button>

        <app-order-commit-button [(order)]="order" mode="rollback" class="air-button"
          (completeConfirm)="completeConfirm(order)">
        </app-order-commit-button>

        <app-order-commit-button [(order)]="order" mode="reset" class="air-button"
          (completeConfirm)="completeConfirm(order)">
        </app-order-commit-button>

        <app-order-commit-button [(order)]="order" mode="delete" class="air-button"
          (completeConfirm)="completeConfirm(order)">
        </app-order-commit-button>

        <app-order-commit-button [(order)]="order" mode="commit" class="air-button"
          (completeConfirm)="completeConfirm(order)">
          <!--   (completeConfirm)="completeConfirm($event, ord)" -->
        </app-order-commit-button>

        <app-order-commit-button [(order)]="order" mode="repeat" class="air-button"
          (completeConfirm)="completeConfirm(order)">
          <!--   (completeConfirm)="completeConfirm($event, ord)" -->
        </app-order-commit-button>

        <button mat-flat-button color="primary" (click)="copyOrder(order)" class="air-button">
          Полная копия
        </button>


        <button *ngIf="order.statusId !== 1 && order.statusId !== 2 && order.statusId !== 4 && order.statusId !== 8"
          mat-flat-button color="primary" (click)="copyOrder(order,false)" class="air-button">
          Копия с вычерками
        </button>
        <!-- pre>
          {{order | json}}
        </pre -->
      </span>

    </div>

  </cdk-accordion-item>

</cdk-accordion>