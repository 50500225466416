/* eslint-disable @typescript-eslint/no-explicit-any */
// отклюил lint из-за HTMLInputElement
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Department, DepartmentProfile } from 'src/app/models/Ukrop/Department';

import { SoftCheque } from 'src/app/models/Ukrop/softCheque';
import { UkropClientService } from 'src/app/services/net/ukrop-client.service';

import { ZReport } from 'src/app/models/Ukrop/zreport';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-zreport-add',
  templateUrl: './zreport-add.component.html',
  styleUrls: ['./zreport-add.component.scss']
})
export class ZreportAddComponent implements OnInit {

  @Input() ukrop: DepartmentProfile | undefined
  @Input() type!: number
  @Input() softCheques: SoftCheque[] = []
  @Output() addZComplete: EventEmitter<ZReport> = new EventEmitter<ZReport>()
  @Output() addModeOut: EventEmitter<number> = new EventEmitter();

  public typename = ''
  actualDate = new FormControl(new Date())
  actulaCheque: number | undefined
  file = new FormControl()

  progress = 0

  @Output() addMode = false
  error = ''


  constructor(
    public fb: FormBuilder,
    private api: UkropClientService,
    private snack: MatSnackBar
  ) {

    // this.form = this.fb.group({
    //   actualDate: [new Date()],
    //   avatar: [null]
    // })
  }

  ngOnInit(): void {
    if (this.type == 1) this.typename = 'Z-отчёт'
    else this.typename = 'Чек ИП - аренда'
  }


  setAddMode(m: boolean) {
    this.addMode = m
    if (!m) {
      this.addModeOut.emit(0)
    }
    else {
      this.addModeOut.emit(this.type)
    }
  }

  uploadFile(event: any) {
    try {

      const file = (event.target as HTMLInputElement).files?.item(0)

      if (!file) {
        this.snack.open(`Не удалось загрузить файл - (не удалось взять файл из event)`, 'Закрыть', { duration: 5000 })
        this.error = `event - ${event.target.files}`
      }

      this.file.setValue(file)

    }
    catch (error) {
      this.snack.open(`Не удалось загрузить файл - ${error}`, 'Закрыть', { duration: 5000 })
    }

  }

  submitUser() {
    this.error = ''

    const limit = 5.05
    try {
      if (this.file.value.size > limit * 1024 * 1024) {
        this.error = `Размер файла не должен превышать ${limit} Мб`
        return
      }
    } catch (error) {
      // this.logger.error(error, 'UserTaskAddZComponent')
      this.error = `не указан файл - ${error}`
      return
    }

    if (!this.actulaCheque && this.type === 2) {
      this.error = 'не указан акт'
      return
    }

    if (!this.ukrop || !this.ukrop?.Department) {
      // this.logger.error('нет ТТ', 'UserTaskAddZComponent')
      this.error = 'нет ТТ'
      return
    }
    const d = this.ukrop?.Department as Department
    this.api
      .addUserTask(this.actualDate.value, d.Inn, d.Kpp, this.type, this.file.value, this.actulaCheque)
      .subscribe(
        {
          next: (event: HttpEvent<any>) => {
            this.handleHttpEvent(event);
          },
          error: (err) => {
            this.progress = 0
            this.error = `Ошибка при отправке файла - ${err.message}`
          }
        })
  }

  private handleHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        //              this.logger.verbose('Request has been made!', 'UserTaskAddZComponent')
        this.progress = 1;
        break;
      }
      case HttpEventType.ResponseHeader: {
        // this.logger.verbose(
        //   'Response header has been received!',
        //   'UserTaskAddZComponent'
        // )
        this.progress = 99;
        break;
      }
      case HttpEventType.UploadProgress: {
        this.progress = event.total
          ? Math.round((event.loaded / event.total) * 100)
          : 0;
        //              this.logger.verbose(`Uploaded! ${this.progress}%`, 'UserTaskAddZComponent')
        break;
      }
      case HttpEventType.Response: {
        //            this.logger.verbose(`Uploaded!`, 'UserTaskAddZComponent')
        setTimeout(() => {
          this.addZComplete.emit({
            Id: event.body.Id,
            CompanyId: event.body.CompanyId,
            CompanyInn: event.body.CompanyInn,
            CompanyKpp: event.body.CompanyKpp,
            FileUrl: event.body.FileUrl,
            FileDate: event.body.FileDate,
            ReportDate: event.body.ReportDate,
            ReportStatusId: event.body.ReportStatusId,
            ReportStatus: 'Новый',
            ReportTypeId: event.body.ReportTypeId,
            ReportType: this.typename,
            SoftChequeId: this.actulaCheque
          });
          this.progress = 0;
          this.file.reset();
          this.setAddMode(false);
        }, 500);
        break;
      }
      default: {
        // this.logger.debug(
        //   `Unknown event type ${event.type}: ` + JSON.stringify(event),
        //   'UserTaskAddZComponent'
        // )
        break;
      }
    }
  }
}

