import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ProgressBarMode } from '@angular/material/progress-bar'
import { SubscriptionLike } from 'rxjs'
import { GroupsModalComponent } from 'src/app/components/goods/groups-modal/groups-modal.component'

import { PagedList } from 'src/app/models/PagedList'
import { Good, GoodCountry, Group } from 'src/app/models/sync.model'
import { DbService, GoodsFilter } from 'src/app/services/data/idb.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'
import { Client } from 'src/app/models/client.model'
import { AppSettingsService } from 'src/app/services/app-settings.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { GoodState, PriceType } from 'src/app/models/goods/product'

@Component({
  selector: 'app-edit-order-select-goods-plain-component',
  templateUrl: './edit-order-select-goods-plain-component.component.html',
  styleUrls: ['./edit-order-select-goods-plain-component.component.scss'],
})
export class EditOrderSelectGoodsPlainComponentComponent implements OnInit, OnDestroy {

  @Input() client: Client | undefined

  subscriptions: SubscriptionLike[] = []

  public currentGroup: Group | undefined
  public goods = {} as PagedList<Good>

  page = 0
  pageSize = 50


  loadingVal = false


  orderField: "name" | "price" = "name"
  orderDesc = false
  searchPattern = ""

  orderFields = [
    {
      key: "name",
      value: "По названию"
    },
    {
      key: "price",
      value: "По цене"
    },

  ]

  showPriceSearch = false
  priceStart?: number
  priceEnd?: number
  country = ''
  state: number | undefined = undefined
  countries: GoodCountry[] = []
  contract = ''
  contracts: any[] = []

  lastSearch = ''
  lastPriceStart?: number
  lastPriceEnd?: number
  interval?: any

  states: GoodState[] = []

  progressMode: ProgressBarMode = 'determinate'

  public dbs: DbService

  filterPriceType?: string
  filterPriceTypes: PriceType[] = []

  constructor(public dialog: MatDialog,
    cm: ConnectionManagerService,
    private snack: MatSnackBar,
    private appSettings: AppSettingsService,

  ) {

    this.dbs = cm.getCurrentDb()
  }


  ngOnInit(): void {
    const sq1 = this.dbs.getTopGroups().subscribe(x => {
      if (x !== undefined && x.length > 0) {
        this.setGroup(x[0])
      }

    })

    this.states = this.dbs.goodStates

    if (this.client === undefined) {
      this.filterPriceTypes = this.dbs.priceTypes
      this.filterPriceType = this.filterPriceTypes[0].code
    }

    this.subscriptions.push(sq1)
    this.filtresData()
  }


  filtresData() {
    this.countries = []
    this.contracts = []
    this.dbs.getAll('goodCountries').then(x => this.countries = x ?? [])
    this.dbs.getAll('goodContracts').then(x => this.contracts = x?.sort((a, b) => {
      if (a.contract > b.contract) return 1
      if (a.contract < b.contract) return -1
      return 0
    }) ?? [])
  }

  autoSearch() {
    if (!this.appSettings.autoSearch || (this.lastSearch === this.searchPattern &&
      this.lastPriceStart === this.priceStart &&
      this.priceEnd === this.lastPriceEnd)) {
      return
    }

    // let delay = (this.lastSearch !== this.searchPattern || this.lastPriceStart !== this.priceStart || this.lastPriceEnd !== this.priceEnd) ? 2000 : 0
    const delay = 2000
    this.loading = true

    this.lastSearch = this.searchPattern
    this.lastPriceStart = this.priceStart
    this.lastPriceEnd = this.priceEnd

    // запускаем поиск с задержкой
    // даём возможность набрать слово целиком
    if (this.interval != undefined) {
      clearInterval(this.interval)
    }

    this.interval = setInterval(() => {
      if (this.interval) clearInterval(this.interval)

      this.search()
    }, delay)


  }


  openGroupsDialog() {
    const dialogRef = this.dialog.open(GroupsModalComponent, {
      width: '600px',
      data: {
        animal: 'panda',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      const tmp = result as Group
      if (tmp) {
        this.setGroup(tmp)
      }
    });

  }


  setGroup(group: Group) {
    this.currentGroup = group
    this.page = 0


    this.changePage()
  }



  private searchSub: any

  search() {
    this.page = 0
    this.changePage()
  }

  changePage() {
    if (this.currentGroup) {

      this.loading = true
      const filter = {} as GoodsFilter


      filter.left = this.currentGroup.left
      filter.right = this.currentGroup.right

      filter.pageSize = this.pageSize
      filter.page = this.page
      filter.order = this.orderField
      filter.orderDesc = this.orderDesc
      filter.name = this.searchPattern

      filter.endPrice = this.priceEnd
      filter.startPrice = this.priceStart
      filter.priceType = this.client?.priceType ? this.client.priceType : this.filterPriceType ?? ''

      filter.contractId = this.contract
      filter.country = this.country

      filter.state = this.state

      //
      try {
        if (this.searchSub)
          this.searchSub.unsubscribe()

      } catch (error) {
        this.snack.open('unsubscribe error ' + JSON.stringify(error), 'ok', { duration: 3300 })
      }

      this.searchSub = this.dbs.getGoods(filter).subscribe({
        next: (goods) => {
          this.goods = goods

        },
        error: (e) => {
          this.loading = false
          this.snack.open('ошибка поиска! ' + e, 'ok', { duration: 3300 })
        },
        complete: () => {
          this.loading = false
        }
      })

    } else {
      this.loading = false
    }

  }

  chPage(event: any) {

    this.page = event.pageIndex
    this.pageSize = event.pageSize
    this.changePage()
  }

  tm: any
  public set loading(v: boolean) {
    clearTimeout(this.tm)
    this.tm = setTimeout(() => {
      this.loadingVal = v;
      this.progressMode = (v ? 'indeterminate' : 'determinate') as ProgressBarMode

    }, 100);
  }


  public get loading(): boolean {
    return this.loadingVal
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }



}
