import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from './storage/local-storage.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(
    private localstorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document) {
    this.initSettings()
  }

  initSettings() {
    this.themeSet()
    this.sizeFontSet()
  }

  themeSet() {
    this.localstorage.save()
    if (this.localstorage.searchOptions.darkTheme) {
      this.document.documentElement.classList.add('dark-mode')
    }
    else {
      this.document.documentElement.classList.remove('dark-mode')
    }

  }

  sizeFontSet() {
    this.localstorage.save()
    if (this.localstorage.searchOptions.bigFont) {
      this.document.documentElement.classList.add('big-font')
    } else {
      this.document.documentElement.classList.remove('big-font')
    }
  }


  settingsSave() {
    this.localstorage.save()
  }

  public get options(){
    return this.localstorage.searchOptions
  }

  public get autoSearch() {
    return this.localstorage.searchOptions.autoSearch
  }

  public get focusQuantity() {
    return this.localstorage.searchOptions.quantityFocus
  }


}
