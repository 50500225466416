<div *ngIf="loading">
  <h1>Задача</h1>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="error">
  <h1>Задача</h1>
  <h2>Ошибка загрузки задачи</h2>
  <button mat-raised-button color="accent" (click)="loadQuest()">
    <mat-icon>refresh</mat-icon>обновить
  </button>
</div>

<div class="container" *ngIf="!loading && !error">
  <h1>Задача {{quest.questSet?.questType?.name}}</h1>



  <h2>{{quest.questSet?.name}}
    <app-quest-icon [quest]="quest"></app-quest-icon>
  </h2>
  <h3>{{quest.buypoint?.name}}</h3>
  <h3 *ngIf="quest.text">{{quest.text}}</h3>



  <form class="example-form">


    <!--  всё, кроме картинок -->
    <span *ngFor="let row of quest.items">


      <!-- текстовое поле - 3 -->
      <mat-form-field class="full-width" *ngIf="row.questDefinition.questDefType === 3">
        <mat-label>{{row.questDefinition.name}}</mat-label>
        <input matInput [(ngModel)]="row.value" (change)="saved = false" [name]="'input' + row.questDefinition.pos"
          [required]="row.questDefinition.mandatory" [disabled]="saving || quest.stateId === 3">
      </mat-form-field>

      <!-- число - 1, целое число - 2 -->
      <mat-form-field class="full-width"
        *ngIf="row.questDefinition.questDefType === 1 || row.questDefinition.questDefType === 2 ">
        <mat-label>{{row.questDefinition.name}}</mat-label>
        <input matInput [(ngModel)]="row.value" type="number" [name]="'input' + row.questDefinition.pos"
          (change)="saved = false" [required]="row.questDefinition.mandatory"
          [disabled]="saving || quest.stateId === 3">
      </mat-form-field>

      <!--
           [attr.name]="'a'+row.id"
           [attr.required]="row.questDefinition.mandatory"


          -->

      <!-- фиксированный список - 4 -->
      <!-- span *ngIf="row.questDefinition.questDefType === 4">

         <mat-form-field class="full-width" appearance="fill">
           <mat-label>{{row.questDefinition.name}}</mat-label>
           <mat-select [(ngModel)]="row.value"
             [attr.name]="'input' + row.questDefinition.pos"
             [disabled]="saving || quest.stateId === 3" (selectionChange)="saved = false"
             [required]="row.questDefinition.mandatory">
             <mat-option value="" disabled>Не указано!</mat-option>
             <mat-option *ngFor="let val of row.questDefinition.items"
               [value]="val.listValue">{{val.listValue}}</mat-option>
           </mat-select>
         </mat-form-field>

       </span -->

      <!-- не фиксированный список - 7 -->
      <app-quest-custom-select [questItem]="row" [disabled]="saving || quest.stateId === 3"
        *ngIf="row.questDefinition.questDefType === 7" (xevnt)="customChanged($event)">

      </app-quest-custom-select>

      <!--  фиксированный список (4) или зависимый список (8) -->
      <span *ngIf="row.questDefinition.questDefType === 8 || row.questDefinition.questDefType === 4">
        <app-quest-custom-depend-select [questItem]="row" [parent]="getParent(row)" [trigger]="trigger"
          [disabled]="saving || quest.stateId === 3" (xevnt)="customChanged($event)">

        </app-quest-custom-depend-select>

      </span>
      <!-- not implemented warning -->
      <span style="display: block; background-color: goldenrod; font-weight: bold;"
        *ngIf="row.questDefinition.questDefType === 5 || row.questDefinition.questDefType > 9">

        {{row.questDefinition.questDefType}} - {{row.questDefinition.name}}
      </span>

      <!-- дни недели -->
      <span *ngIf="row.questDefinition.questDefType === 9">
        <app-quest-item-day-of-week [questItem]="row" [disabled]="saving || quest.stateId === 3"
          (xevnt)="customChanged($event)">

        </app-quest-item-day-of-week>

      </span>


    </span>


    <!--   картинки 6 -->
    <span *ngFor="let row of quest.items">



      <app-questionare-ors-photo [questItem]="row" [disabled]="saving || quest.stateId === 3"
        *ngIf="row.questDefinition.questDefType === 6">
      </app-questionare-ors-photo>




    </span>




    <button mat-raised-button color="primary" (click)="save()" [disabled]="saving || quest.stateId === 3">
      <mat-icon *ngIf="!saved">save</mat-icon>
      Сохранить
    </button>


    <button mat-raised-button color="warn" (click)="confirm()" [disabled]="saving || quest.stateId === 3">
      <mat-icon>check</mat-icon>
      Выполнено
    </button>

  </form>

  <!-- <div class="footer-spacer"></div> -->

</div>
<hr>
<!-- pre>
  {{quest | json}}
</pre -->