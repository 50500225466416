<button mat-icon-button (click)="loadOrderLog()" *ngIf="!progress">
    <mat-icon> refresh</mat-icon>
</button>

<mat-progress-bar mode="query" *ngIf="progress"></mat-progress-bar>
<span *ngIf="updated">обновлено {{updated | date:'dd.MM.yyyy HH:mm:ss'}}</span>

<ol *ngIf="result">
    <li *ngFor="let log of result">
        {{log.dt | date:'dd.MM.yyyy HH:mm:ss'}}
        <app-order-status-indicator style="display: inline-block; margin-top: 12px;"
            [statusId]="log.statusId"></app-order-status-indicator>
        {{log.message}}
    </li>

</ol>