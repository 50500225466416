<mat-spinner *ngIf="loading;"></mat-spinner>
<div *ngIf="error">
  <p class="warning">возникла ошибка.</p>


  <!-- button mat-raised-button (click)="load()">
    <mat-icon>refresh</mat-icon>
    обновить
  </button -->
</div>

<div class="ccontainer" *ngIf="!loading && !error">


  <div>
    <h1 (click)="anal = anal+1">Создать заказ</h1>
    <h2>{{buypoint?.name}}</h2>


    <mat-form-field class="extra">
      <mat-label>Комментарий</mat-label>
      <input matInput name="comment" [(ngModel)]="deliveryComment">
    </mat-form-field>
    <br>


    <mat-form-field class="fixed">
      <mat-label> Организация </mat-label>
      <mat-select [formControl]="org">
        <mat-option *ngFor="let contract of contracts" [value]="contract.organizationId">
          {{contract.organization}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="org.errors">Не выбрана организация</mat-error>
    </mat-form-field>

    <br>

    <mat-form-field class="fixed">
      <mat-label>Дата доставки</mat-label>

      <input matInput [matDatepicker]="picker" name="actualDate" [(ngModel)]="delivery"  [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker class="B"></mat-datepicker>

    </mat-form-field>

    <button mat-icon-button *ngIf="delivery" (click)="delivery=undefined; ">
      <mat-icon>clear</mat-icon>
    </button>
    <br>



    <br>
    <h2 *ngIf="buypoint && (!buypoint.active || buypoint.locked)" style="color: red;">Внимание! Клиент заблокирован.
    </h2>


    <br>
    <!--    [disabled]="!rule || !rule.filialId" -->
    <button mat-flat-button color="primary" *ngIf="buypoint" (click)="doCreateOrder()">создать</button>

  </div>

  <!-- Доставка -->
  <div *ngIf="buypoint && buypoint.deliveryDaysOfWeek">
    <h2>сведения о доставке по ТТ</h2>
    <app-client-delivery-info [buypoint]="buypoint"></app-client-delivery-info>
  </div>

</div>

<div *ngIf="anal>6">
  <pre>
  {{agent | json }}
  </pre>
  <pre>
  {{buypoint | json }}
  </pre>

</div>