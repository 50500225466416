<div *ngIf="questItem && questItem.questDefinition.questDefType === 7">
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>{{questItem.questDefinition.name}}</mat-label>


    <mat-select [(ngModel)]="selected" (selectionChange)="onChanges($event)"
      [required]="questItem.questDefinition.mandatory" [disabled]="disabled">
      <mat-option value="" disabled>Не указано</mat-option>
      <mat-option *ngFor="let val of questItem.questDefinition.items"
        [value]="val.listValue">{{val.listValue}}</mat-option>

      <mat-option [value]="secret">прочее</mat-option>

    </mat-select>

  </mat-form-field>

  <mat-form-field class="full-width" *ngIf="selected === secret">
    <mat-label>{{questItem.questDefinition.name}} - ОБЯЗАТЕЛЬНО УКАЗАТЬ</mat-label>
    <input matInput [(ngModel)]="hiddenValue" [disabled]="disabled"
      [required]="questItem.questDefinition.mandatory" (change)="onChanges($event)">
  </mat-form-field>

</div>
