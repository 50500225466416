import { Component } from '@angular/core';
import { CurrentOrderService } from 'src/app/services/data/current-order.service';
 

@Component({
  selector: 'app-catalog-page',
  templateUrl: './catalog-page.component.html',
  styleUrls: ['./catalog-page.component.scss']
})
export class CatalogPageComponent   {
   

   
constructor(co: CurrentOrderService) {
  co.init(undefined)

}
  

}
