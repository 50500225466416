import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionLike } from 'rxjs';
import { AgentModel } from 'src/app/models/agent.model';
import { Client } from 'src/app/models/client.model';
import { CashOrderAdvance, DebetDoc, SumAdvanceByOrgView } from 'src/app/models/DebetDocs';
import { AgentOrgDebetView, FilialView, OrgView } from 'src/app/models/dict.model';
import { RpaHttpService } from 'src/app/services/http/rpa-http.service';
import { CreateAdvanceDialogComponent } from '../advances/create-advance-dialog/create-advance-dialog.component';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { DbService } from 'src/app/services/data/idb.service';
import { DebetService } from 'src/app/services/data/debet.service';

@Component({
  selector: 'app-debet-doc-list',
  templateUrl: './debet-doc-list.component.html',
  styleUrls: ['./debet-doc-list.component.scss']
})
export class DebetDocListComponent implements OnInit, OnDestroy {

  @Input() buypoint!: Client


  DebetDocs: DebetDoc[] = []
  db: DbService;
  svc: DebetService;

  agent: AgentModel | undefined;
  subscriptions: SubscriptionLike[] = [];
  docsLoaded = false;
  errorMessage: string | undefined;
  diffsumm = 0;
  dateNow = new Date()

  choosenSum = 0;
  choosenDocs: DebetDoc[] = []
  showCheckBox = false

  listOrgs: OrgView[] = []

  groupedDebts: AgentOrgDebetView[] = []

  listFilial: FilialView[] = []
  dataFilter: DebetDoc[] = []
  choosenOrg: OrgView | undefined
  choosendFilial: FilialView | undefined
  listAgents: any[] = []
  choosenAgent: any
  filialLocked = false

  currentUserId = ''

  constructor(
    private snack: MatSnackBar,
    private api: RpaHttpService,
    public dialog: MatDialog,
    public cm: ConnectionManagerService) {

    this.listAgents = [
      {
        id: cm.activeServer?.user.Id,
        name: cm.activeServer?.user.name
      },
      {
        id: 1,
        name: 'Прочие агенты'
      },
      {
        id: 2,
        name: 'Все агенты'
      }
    ]
    this.choosenAgent = this.listAgents[0]
    this.db = cm.getCurrentDb()
    this.svc = new DebetService(this.db)
    this.currentUserId = this.cm.activeUser.user.Id


  }
  ngOnInit(): void {

    this.refresh()
  }




  refresh() {
    this.errorMessage = ''
    this.showCheckBox = false
    this.choosenSum = 0;
    this.choosenDocs = [];
    // this.choosenOrg = undefined
    // this.choosendFilial = undefined
    this.filialLocked = false
    this.svc.getClientDocs(this.buypoint.id).subscribe({
      next: (res) => {
        this.DebetDocs = res
        this.getPrepaymentsSum()
        this.docsLoaded = true

        this.dataFilter = res;
        this.listFilial = this.dataFilter.map((c) => { return { id: c.filialId, name: c.filial } as FilialView })
          .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);

        this.listOrgs = this.dataFilter.map((c) => { return { id: c.organizationId, name: c.organization } as OrgView })
          .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);

        // this.listAgents = this.dataFilter.map((c) => { return { id: c.agentId, name: c.agent } as OrgView })
        //   .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);

        // this.choosenAgent = this.listAgents.find(x => x.id === this.cm.activeServer?.user.Id)
        if (!res.find(x => x.agentId === this.listAgents[0].id)) {
          this.choosenAgent = this.listAgents[1]
        }

        // ща будет немного грязь. для демонстрации изменений.
        this.groupedDebts = this.dataFilter
          .map((c) => {
            return {
              id: c.organizationId + c.agentId,
              agentId: c.agentId,
              orgId: c.organizationId,
              agentName: c.agent,
              orgName: c.organization,
              debet: 0,
              docCount: 0,
              over: 0,
              other: c.agentId !== this.currentUserId,
              advanceCount: 0,
              advanceSum: 0,
              debetDocs: []

            } as AgentOrgDebetView
          })
          .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);


        res.forEach(element => {
          const idx = this.groupedDebts.findIndex(c => { return c.id === element.organizationId + element.agentId; })

          this.groupedDebts[idx].debet += element.debetSumm
          if (this.biggerDate(this.dateNow, element.datePay)) { this.groupedDebts[idx].over += element.debetSumm }

          this.groupedDebts[idx].debetDocs.push(element.docId)
        });


        if (this.listFilial.length === 1 && this.listOrgs.length === 1) {
          this.choosenOrg = this.listOrgs[0]
          this.choosendFilial = this.listFilial[0]

          // Новосибирск и Барнаул, Ткацкая
          // const goodFilials = ['88c7d231-e509-4bff-886a-a6b1bb56f2cf', 'cf71d939-d6a5-4b6a-bc0e-d6e1a27cf986']

          // const idx = goodFilials.indexOf(this.choosendFilial.id)

          // this.filialLocked = idx < 0 // this.choosendFilial.id === '522b8d27-13db-11e9-8941-005056be429e'

        }

        this.applyFilter()
      }
    })

  }

  // countsDaysDD(debetDay: Date) {
  //   const counts = new Date().getTime() - debetDay.getTime()
  // }


  openDialog() {


    const dialogRef = this.dialog.open(CreateAdvanceDialogComponent,
      {
        data:
        {
          doc: this.choosenDocs,
          summ: this.choosenSum,
          filial: this.choosendFilial,
          org: this.choosenOrg,
          buypoint: this.buypoint
        },
        width: '450px'
      }
    )
    // let haveresult = false
    dialogRef.afterClosed().subscribe(
      {
        next: result => {
          if (result) {
            this.refresh()
            // haveresult = true
          }
        },
        // complete: () => { if (haveresult) { this.refresh() } }
      })
  }

  // reportDebet(id: string, name: string, filialId: string, filial: string, org: string) {
  //   this.api
  //     .post<string>(`/v2/Reports/reportdebets`, {
  //       outletId: id,
  //       filialCode: filialId,
  //       AgentId: this.agent?.id,
  //       org: org // не используется?
  //     })
  //     .subscribe({
  //       next: (guid: string) => {
  //         console.log(guid)
  //         this.snack.open(`Запрос отчёта ${name} (${filial}) поставлен в очередь`, 'ok', {
  //           duration: 3000,
  //         })
  //       },
  //       error: () => {
  //         this.snack.open(`ошибка отчёта ${name}`, 'ok', {
  //           duration: 3000,
  //         })
  //       },
  //     })
  // }

  chooseDocs(doc: DebetDoc, plus: boolean) {
    this.choosenSum = 0
    if (plus) {
      this.choosenDocs.push(doc)
    }
    else {
      const deleteDoc = this.choosenDocs.findIndex(x => x.docId === doc.docId)
      this.choosenDocs.splice(deleteDoc, 1);
    }
    this.choosenDocs.forEach(d => this.choosenSum = this.choosenSum + (d.debetSumm - (d.prepaymentSumm ?? 0)))

  }

  applyFilter() {
    const filtered = this.dataFilter.filter(d =>
      (this.choosenOrg === undefined || this.choosenOrg.id === d.organizationId) &&
      (this.choosendFilial === undefined || this.choosendFilial.id === d.filialId) &&
      // (this.choosenAgent === undefined || this.choosenAgent.id === d.agentId)
      ((this.choosenAgent === undefined || this.choosenAgent.id === 2) ||
        (this.choosenAgent.id === 1 && this.listAgents[0].id !== d.agentId) ||
        (this.choosenAgent.id === this.listAgents[0].id && d.agentId === this.listAgents[0].id))
    )

    this.choosenDocs = []
    this.choosenSum = 0;
    this.showCheckBox = false
    this.DebetDocs = filtered

  }

  round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }



  cancel() {
    this.choosenSum = 0;
    this.choosenDocs = []
    this.showCheckBox = false;
  }

  calcDays(date1: Date, date2: Date) {
    const dd = new Date(date1);
    const dd1 = new Date(dd.getFullYear(), dd.getMonth(), dd.getDate())
    const dd2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
    const diff = dd1.getTime() - dd2.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays
  }

  biggerDate(date1: Date, date2: Date) {
    if (new Date(date1).getTime() > new Date(date2).getTime())
      return true
    return false
  }



  getPrepaymentsSum() {
    this.svc.getAdvanceByClient(this.buypoint.id)
      .then(x => this.DebetDocs.forEach(d => {
        const preSums = x?.filter(a => a.docId === d.docId && a.state != 4)
        if (preSums?.length) {
          d.prepaymentSumm = preSums.map(s => s.summ).reduce((x1, x2) => x1 + x2, 0)

        }
        this.advSumByOrgAgent(x)

      }))
      .catch(err => this.snack.open(`Ошибка загрузки авансовых платежей - ${err}`, 'Закрыть', { duration: 5000 }))
  }

  // sumByOrg(orgId: string) {
  //   let sum = 0
  //   this.dataFilter.filter(x => x.organizationId === orgId).forEach(x => sum += x.debetSumm)
  //   return sum
  // }

  // overSumByOrg(orgId: string) {
  //   let sum = 0
  //   this.dataFilter.filter(x => x.organizationId === orgId).forEach(x => { if (this.biggerDate(this.dateNow, x.datePay)) sum += x.debetSumm })
  //   return sum
  // }

  // advanceSumByOrg(orgId: string): SumAdvanceByOrgView {
  //   let sum = 0
  //   let count = 0
  //   console.log(orgId)
  //   console.log(this.dataFilter)
  //   this.dataFilter.filter(x => x.organizationId === orgId).forEach(x => { if (x.prepaymentSumm) { sum += x.prepaymentSumm; count++ } })
  //   return { sum: sum, count: count }
  // }

  advSumByOrgAgent(advaces: CashOrderAdvance[] | undefined) {
    if (!advaces) return
    this.groupedDebts.forEach(x => {
      // const dd = advaces.filter(a => a.organizationId + a.agentId === x.id)
      const dd = advaces.filter(a => x.debetDocs.includes(a.docId))
      x.advanceCount = dd.length
      x.advanceSum = dd.map(x => x.summ).reduce((x1, x2) => x1 + x2, 0)

    })

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

}





