import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrkopGateHttpService extends BaseHttpService {

  constructor(http: HttpClient) {
    super(http)
  }
 
  public override getServerUrl(): string {
    
    const ukrop_url = environment.UKROP_API_URL as string
    return ukrop_url
  }

}
