<div class="tab">
  <div class="search">

    <mat-form-field class="search-form-field" appearance="fill">
      <mat-label>Название</mat-label>
      <input matInput type="text" [(ngModel)]="searchPattern" (ngModelChange)="autoSearch()" (keyup.enter)="search()">
      <!-- [disabled]="loading" -->
      <!--  (ngModelChange)="changePage()" -->
      <button *ngIf="searchPattern" matSuffix mat-icon-button aria-label="Clear"
        (click)="searchPattern=''; changePage()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <br>

    <mat-accordion>
      <mat-expansion-panel [expanded]="client === undefined">

        <mat-expansion-panel-header>
          <mat-panel-title>
            Фильтры
          </mat-panel-title>

        </mat-expansion-panel-header>

        <span style="margin-right: 12px;">Группа</span>
        <button style="margin-bottom: 8px;" mat-button (click)="openGroupsDialog()" color="primary"
          [disabled]="loading">{{currentGroup?.name}} <mat-icon>edit</mat-icon> </button><br>



        <span>Цена</span>
        <mat-form-field appearance="outline" class="price">
          <mat-label>от</mat-label>
          <input matInput type="number" [(ngModel)]="priceStart" (ngModelChange)="autoSearch()">
          <button mat-icon-button matSuffix (click)="priceStart = undefined;search()"
            *ngIf="priceStart"><mat-icon>close</mat-icon></button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="price">
          <mat-label>до</mat-label>
          <input matInput type="number" [(ngModel)]="priceEnd" (ngModelChange)="autoSearch()">
          <button mat-icon-button matSuffix (click)="priceEnd = undefined;search()"
            *ngIf="priceEnd"><mat-icon>close</mat-icon></button>
        </mat-form-field>

        <div>
          <ng-container *ngIf="contracts?.length">

            <mat-form-field appearance="outline" class="filter_field">
              <mat-label>Контракт</mat-label>
              <mat-select (selectionChange)="changePage()" [(ngModel)]="contract" [disabled]="loading"
                panelClass="panel_width">
                <mat-option value="">Все контракты</mat-option>
                <mat-option *ngFor="let val of contracts" [value]="val.contractCode">{{val.contract}}</mat-option>
              </mat-select>
            </mat-form-field>

          </ng-container>

          <ng-container *ngIf="countries?.length">
            <mat-form-field appearance="outline" class="filter_field">
              <mat-label>Страна</mat-label>
              <mat-select panelClass="panel_width" (selectionChange)="changePage()" [(ngModel)]="country"
                [disabled]="loading">
                <mat-option value="">Все страны</mat-option>
                <mat-option *ngFor="let val of countries" [value]="val.country">{{val.country}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>


          <ng-container *ngIf="states?.length">
            <mat-form-field appearance="outline" class="filter_field">
              <mat-label>Статус</mat-label>
              <mat-select panelClass="panel_width" (selectionChange)="changePage()" [(ngModel)]="state"
                [disabled]="loading">
                <mat-option [value]="0">Любой статус</mat-option>
                <mat-option *ngFor="let val of states" [value]="val.id">{{val.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="client === undefined">
            <mat-form-field  appearance="outline" class="filter_field">
              <mat-label>Тип цены</mat-label>
              <mat-select (selectionChange)="changePage()" [(ngModel)]="filterPriceType" [disabled]="loading">

                <mat-option *ngFor="let val of filterPriceTypes" [value]="val.code">{{val.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>

        <!-- <br> -->
        <mat-form-field appearance="outline" style="width: 165px;">
          <mat-label>Сортировка</mat-label>
          <mat-select (selectionChange)="changePage()" [(ngModel)]="orderField" [disabled]="loading">
            <mat-option *ngFor="let val of orderFields" [value]="val.key">{{val.value}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-slide-toggle class="price" [(ngModel)]="orderDesc" (ngModelChange)="changePage()" [disabled]="loading">
          Наоборот
        </mat-slide-toggle>

      </mat-expansion-panel>
    </mat-accordion>


    <button style="margin-top: 1em;margin-bottom: 1em;" mat-raised-button color="primary" (click)="search()">Поиск
      <mat-icon>
        search
      </mat-icon>
    </button>

  </div>

  <mat-progress-bar [mode]="progressMode"></mat-progress-bar>


  <div *ngFor="let item of goods.items">
    <app-edit-order-select-goods-item [item]="item"
      [clientPriceType]="client?.priceType ?? filterPriceType"></app-edit-order-select-goods-item>
  </div>



  <mat-paginator *ngIf="goods.items?.length" (page)="chPage($event)" [length]="goods.total" [pageSize]="pageSize"
    [pageSizeOptions]="[ 10, 25, 50, 100, 500, 1000]" [disabled]="loading">
  </mat-paginator>

</div>