<div *ngIf="questItem && !questItem.hidden">


  <mat-form-field appearance="fill" style="width: 80%;">
    <mat-label>{{questItem.questDefinition.name}}</mat-label>
    <mat-select [(ngModel)]="this.questItem.valueId"
      [attr.name]="'input' + questItem.questDefinition.pos"
      (selectionChange)="mySelectionChanged()"
      [required]="questItem.questDefinition.mandatory" [disabled]="disabled">
      <mat-option value=0 disabled>Не указано</mat-option>
      <mat-option *ngFor="let val of items | async" [value]="val.id">
        {{val.listValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <span class="air">
    <button mat-mini-fab (click)="infoShow()" *ngIf="info" color="accent">
      <mat-icon>question_mark</mat-icon>
    </button>
  </span>
</div>
