import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { SyncCategoryModel, SyncItemType, syncCategories } from 'src/app/models/sync.model';

import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLike } from 'rxjs';
import { SyncDialogComponent } from '../sync-dialog/sync-dialog.component';
import { SyncService } from 'src/app/services/sync/sync.service';
import { SyncTimerService } from 'src/app/services/sync/Sync-timer.service';
import { ConfirmDialogComponent } from 'src/app/services/confirm/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/services/confirm/ConfirmDialogData';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-synchronization-page',
  templateUrl: './synchronization-page.component.html',
  styleUrls: ['./synchronization-page.component.scss']
})
export class SynchronizationPageComponent implements OnDestroy {
  deviceId = ''
  sub?: SubscriptionLike[] = []
  syncCat: string[] = []
  logs: string[] = []
  categories: SyncCategoryModel[] = []
  running = false
  full = false
  timer = 0
  timeoutHandler: NodeJS.Timeout | undefined

  @ViewChild('holdsync', { static: false }) holdBut!: ElementRef

  constructor(
    public dialog: MatDialog,
    private timerServ: SyncTimerService,
    stor: LocalStorageService
  ) {
    this.deviceId = stor.widevineId
    syncCategories.forEach(c => {
      this.categories.push({
        id: c.id,
        name: c.name,
        checked: true,
      })
    })

    const timerSub = this.timerServ.timerObs().subscribe(x => {
      this.timer = x;
    })

    this.sub?.push(timerSub)
  }

  ngOnDestroy(): void {
    this.sub?.forEach(x => x.unsubscribe())
    this.sub = []
  }


  public run(full = false) {
    const cat: SyncItemType[] = []

    this.categories.forEach(a => {
      if (a.checked) {

        cat.push(a.id)
      }
    })


    const dialogRef = this.dialog.open(SyncDialogComponent, {
      width: '99%',
      data: {
        categories: cat,
        full: full,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.timer = 10000
      // const int = setInterval((x) => {
      //   this.timer -= 1000
      //   if (this.timer === 0) {
      //     clearInterval(int)
      //   }
      // }, 1000)

      //      this.updateData()


    });

  }


  public mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      // this.timeoutHandler = 0;
    }
  }

  public mousedown() {
    this.timeoutHandler = setTimeout(() => {
      // this.timeoutHandler = 0;
      const dg = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Полная синхронизация',
          question: 'ПРЕДУПРЕЖДЕНИЕ! Вы хотите выполнить "полную синхронизацию". Не выполняйте, если об этом не просит техподдержка.',
          warn: true,
          confirmButtonText: 'Подтвердить'
        } as ConfirmDialogData
      })

      dg.afterClosed().subscribe((x: ConfirmDialogData) => {
        if (x.result) {
          this.run(true)
        }
      })

    }, 1000);
  }




  someChecked() {
    if (this.categories.find(x => x.checked === true)) return true
    return false
  }




}

