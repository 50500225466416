import { SyncFetch } from "src/app/models/sync.model";
import { SyncRealDataJob } from "./AbstractSyncRealDataJob";


export class SyncOrdersJob extends SyncRealDataJob {


    override async clean(): Promise<void> {
        await this.db.clear("orders")
         
    }

    async save(r: SyncFetch) {



        const add = r.items.filter(a => a.preorder && a.state === 1).map(a => a.preorder!.preorder);
        const del = r.items.filter(a => a.preorder && a.state === 2).map(a => a.id);


        await this.db.deleteArray('orders', del);

        add.forEach(p => {
            if (!(p.date instanceof Date)) {
                p.date = new Date(Date.parse(p.date))
            }
            if (p.dateDelivery && !(p.dateDelivery instanceof Date)) {
                p.dateDelivery = new Date(Date.parse(p.dateDelivery))
            }
            
        });

        await this.db.addArray('orders', add);

    }

}
