<!-- <pre> {{acts[0] | json}} </pre> -->
<h1>Акты аренды оборудования</h1>

<mat-button-toggle-group [(ngModel)]="variantView" name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="list"><mat-icon>format_list_bulleted</mat-icon></mat-button-toggle>
    <mat-button-toggle value="table"><mat-icon
            fontSet="material-icons-outlined">table_chart</mat-icon></mat-button-toggle>
</mat-button-toggle-group>
<br>
<br>
<!-- <br> -->

<ng-container *ngIf="!acts?.length && !loading">Нет актов</ng-container>

<mat-spinner style="margin: 0 auto;" *ngIf="loading"></mat-spinner>
<ng-container *ngIf="variantView === 'list' ">


    <ng-container *ngFor="let act of acts">
        <mat-card class="act_card">

            <mat-card-header>
                <mat-card-title>Акт {{act.Id}} от {{act.ChequeDate | date:'dd.MM.YYYY'}}</mat-card-title>
                <!-- <mat-card-subtitle>{{act.Status}}</mat-card-subtitle> -->
                <!-- <mat-card-title>СНО: {{act.ChequeSnoName}}</mat-card-title> -->
                <!-- <mat-card-subtitle>{{act.ChequeDate | date:'dd.MM.YYYY'}}</mat-card-subtitle> -->
            </mat-card-header>

            <mat-card-content>
                <div><b>Статус:</b> {{act.Status}}</div>
                <div><b>Сумма:</b> {{act.ChequeAmount | currency:'RUB':'symbol':'1.2-2':'ru'}}</div>
                <div><b>СНО:</b> {{act.ChequeSnoName}}</div>
                <div class="primary" *ngIf="act.IdOld"><b>Загружен из рп маркета</b> </div>
            </mat-card-content>

            <mat-card-actions style="display: block;">

                <ng-container *ngIf="!progress;else load">

                    <button class="button_marg" mat-raised-button color="primary"
                        (click)="downloadFile(act.Id)"><mat-icon>save</mat-icon>Скачать</button>

                    <button class="button_marg" *ngIf="haveZrep(act)" mat-raised-button color="primary"
                        (click)="imgPreview(act)"><mat-icon>preview</mat-icon>Фото</button>

                    <mat-icon style="color: green;"
                        *ngIf="statusreport(act) === 2 ||statusreport(act) === 4 || statusreport(act) === 5">done
                    </mat-icon>



                    <button *ngIf="!haveZrep(act)" mat-raised-button color="primary" (click)="fileInput.click()">
                        <mat-icon>add</mat-icon> Добавить: Чек ИП аренда
                    </button>
                    <input hidden #fileInput type="file" id="file" (change)="uploadFile($event, act)">
                    <ng-container *ngIf="act.StatusId === 9 || act.StatusId === 10">
                        <br><br>
                        <button mat-raised-button color="accent" (click)="setChequeStatusConfirm(act, 2)"
                            matTooltip="Повторить выгрузку">
                            <mat-icon>sync</mat-icon> Повторить выгрузку
                        </button>
                    </ng-container>

                </ng-container>

                <ng-template #load>
                    <mat-progress-spinner *ngIf="loadCheckId === act.Id" [diameter]="30" mode="determinate"
                        [value]="progress">
                    </mat-progress-spinner>
                </ng-template>

            </mat-card-actions>

        </mat-card>
    </ng-container>
</ng-container>

<ng-container *ngIf="variantView === 'table' && acts?.length">
    <div class="thin-table">
        <table width="90%" class="thin-table">
            <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Статус</th>
                <th>Действия</th>
                <th>Акт</th>


            </tr>
            <ng-container *ngFor="let check of acts">
                <tr>
                    <td>{{check.ChequeDate | date:'dd.MM.yyyy'}}</td>
                    <td>{{check.ChequeAmount | currency:'RUB':'symbol':'1.2-2':'ru'}}</td>
                    <td>{{check.Status}}</td>
                    <td>
                        <button *ngIf="check.StatusId === 9" mat-icon-button color="accent"
                            (click)="setChequeStatusConfirm(check, 2)" matTooltip="Повторить выгрузку">
                            <mat-icon>sync</mat-icon>
                        </button>

                    </td>
                    <td rowspan="2">
                        <button mat-mini-fab color="primary" style="margin-right: 1em;"
                            (click)="downloadFile(check.Id)">
                            <mat-icon>save</mat-icon>

                        </button>
                        <button *ngIf="haveZrep(check)" style="margin-right: 1em;" mat-mini-fab color="primary"
                            (click)="imgPreview(check)">
                            <mat-icon>preview</mat-icon>
                        </button>
                        <mat-icon style="color: green;margin-top: 1em;"
                            *ngIf="statusreport(check) === 2 ||statusreport(check) === 4 || statusreport(check) === 5">done
                        </mat-icon>

                        <button *ngIf="!haveZrep(check)" mat-mini-fab color="primary" (click)="fileInput.click()">
                            <mat-icon>add</mat-icon>

                        </button>
                        <input hidden #fileInput type="file" id="file" (change)="uploadFile($event, check)">
                    </td>
                </tr>
                <tr>
                    <td colspan="3">СНО: {{check.ChequeSnoName}}</td>
                    <td colspan="3">
                        <mat-progress-spinner *ngIf="loadCheckId == check.Id" [diameter]="30" mode="determinate"
                            [value]="progress">
                        </mat-progress-spinner>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</ng-container>
<br><br>