import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { firstValueFrom } from 'rxjs'
import { OrderConfirmDialogComponent } from 'src/app/components/orders/order-confirm-dialog/order-confirm-dialog.component'
import { UkropRest } from 'src/app/models/Ukrop/Rest'
import { ConfirmError, OrderModel } from 'src/app/models/order.model'
import { DbService } from 'src/app/services/data/idb.service'
import { OrdersService } from 'src/app/services/data/orders.service'
import { UkropClientService } from 'src/app/services/net/ukrop-client.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'


@Component({
  selector: 'app-order-commit-button',
  templateUrl: './order-commit-button.component.html',
  styleUrls: ['./order-commit-button.component.scss'],
})
export class OrderCommitButtonComponent implements OnInit {

  @Input() mode!: 'commit' | 'rollback' | 'delete' | 'reset' | 'repeat'
  @Input() order!: OrderModel
  @Output() orderChange = new EventEmitter<OrderModel>();


  text: string | undefined
  db: DbService

  loading = false
  color = ''
  targetStatus = 0
  private ord: OrdersService
  constructor(
    public dialog: MatDialog,
    cm: ConnectionManagerService,
    private http: UkropClientService) {
    this.db = cm.getCurrentDb()
    this.ord = new OrdersService(cm.getCurrentDb())

  }

  ngOnInit(): void {
    switch (this.mode) {
      case 'commit':
        this.text = 'подтвердить'
        this.color = 'primary'
        this.targetStatus = 2
        break
      case 'reset':
        this.text = 'повторить'
        this.color = 'warn'
        this.targetStatus = 2
        break
      case 'rollback':
        this.text = 'сбросить ошибку'
        this.color = 'primary'
        this.targetStatus = 1
        break
      case 'delete':
        this.text = 'отменить'
        this.color = 'warn'
        // this.targetStatus = 3
        this.targetStatus = 11
        break
      // case 'repeat':
      //   this.text = 'повторить проведение'
      //   this.color = 'accent'
      //   this.targetStatus = 2
      //   break
    }
  }

  isActive(): boolean {
    switch (this.mode) {
      case 'reset':
        return !this.loading && this.order.statusId == 4
      case 'commit':
        return !this.loading && this.order.statusId == 1
      case 'rollback':
        // отключил сброс ошибки
        return  false; // !this.loading && this.order.statusId == 3
      case 'delete':
        return !this.loading && (this.order.statusId == 1 || this.order.statusId == 2)
      // case 'repeat':
      //   return !this.loading && this.order.statusId === 9 && this.order.orders1c.find(x => x.statusId === 7) !== undefined
    }
    return false
  }

  async setOrderStatus(force: boolean) {
    this.loading = true
    const client = await firstValueFrom(this.db.getClient(this.order.buypointId))
    let rests: UkropRest[] = []
    if (client) {
      try {
        rests = await firstValueFrom(this.http.getRests(client?.inn, client?.kpp))
      } catch (error) {
        rests = []
      }

    }


    this.ord.Confirm(this.order, this.targetStatus, force, rests)

      .subscribe({
        next: (data: ConfirmError | undefined) => {
          if (data === undefined) {

            return
          }


          this.loading = false

          if (data.error) {
            this.openDialog(data)
          } else {
            //TODO ???
            this.orderChange.emit(this.order)
            console.log(`emit ${this.order.date}-${this.order.summa}`)
          }
        },
        error: (error) => {
          this.loading = false

          this.openDialog({
            error: true,
            canForce: true,
            message: error,
          })
        },
      })
  }

  openDialog(confirmError: ConfirmError): void {
    const dialogRef = this.dialog.open(OrderConfirmDialogComponent, {
      width: '850px',
      data: {
        error: confirmError,
        order: this.order,
        action: this.text,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.force === true) {
        this.setOrderStatus(true)
      }
    })
  }
}
