import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmator-dialog',
  templateUrl: './confirmator-dialog.component.html',
  styleUrls: ['./confirmator-dialog.component.scss']
})
export class ConfirmatorDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) { }
}


export interface ConfirmData {
  title: string,
  question: string,
  buttonOkName: string,
  buttonCancelName: string,
}