import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, from } from 'rxjs'
import { Client } from 'src/app/models/client.model'
import { OrderModel, Orders1c } from 'src/app/models/order.model'
import { DbService } from 'src/app/services/data/idb.service'
import { OrdersService } from 'src/app/services/data/orders.service'
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent implements OnInit, OnDestroy {
  id = ''
  orderInfo: OrderModel | undefined
  preOrder: OrderModel | undefined
  buypoint: Client | undefined
  loading: boolean
  hasError = false
  sub: Subscription | undefined
  ignore = 0

  private api: OrdersService
  db: DbService

  constructor(
    private route: ActivatedRoute,
    private cm: ConnectionManagerService,
    private router: Router
  ) {
    this.loading = true

    this.db = cm.getCurrentDb()
    this.api = new OrdersService(this.db)

  }

  ngOnInit(): void {
    this.sub = this.cm.activeServerObservable()
      .subscribe(() => {
        this.ignore++;
        if (this.ignore > 1) {
          this.router.navigateByUrl('/orders');
        }
      })

    this.route.paramMap.subscribe((params) => {
      this.setId(params.get('id') as string)
    })
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe()
  }


  setId(orederId: string): void {
    this.id = orederId
    this.reload()
  }

  reload() {
    this.loading = true
    this.hasError = false

    if (this.id !== '0') {

      this.api.getOrder(this.id).subscribe(
        {
          next: (data: OrderModel | undefined) => {
            if (!data) {
              return
            }
            else {

              from(this.db.getClient(data.buypointId)).subscribe((c) => {
                this.buypoint = c

              })

              this.setData(data)
            }

          },
          error: (error) => {
            console.log(error)

            this.hasError = true
          },
          complete: () => {
            this.loading = false
          }

        }

      )
    }
  }

  setData(data: OrderModel) {
    this.orderInfo = data
    this.preOrder = data
    if (this.check1cItems() && this.orderInfo.orders1c) {
      this.preOrder = this.newPreord(this.orderInfo.orders1c[0])
    }

    if (!data.orders1c || !data.orders1c.length) {
      this.orderInfo.summa1C = this.orderInfo.summa
    }
    else {
      this.orderInfo.summa1C = data.items.map(x => x.summa1C).reduce((a, b) => a + b, 0)
    }

  }

  orderChanged(order: OrderModel): void {
    this.setData(order)
  }

  // getPreorders(orderId?: string) {

  //   if (!orderId) return

  //   this.api.getPreorders(orderId)
  //     .then(x => {
  //       if (x) this.preOrders = x;
  //       // this.mainOrder(orderId)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       this.hasError = true
  //     })

  // }


  orderMain() {
    if ((!this.orderInfo?.kindId || this.orderInfo?.kindId === 1) && (this.orderInfo?.id === this.preOrder?.id)) {
      return true
    }
    return false
  }

  newPreord(preord: Orders1c) {
    const items = this.orderInfo?.items.filter(x => x.order1cId === preord.id)
    const prOrder: OrderModel = JSON.parse(JSON.stringify(preord))
    prOrder.items = []

    

    if (items) {
      prOrder.items = items
      prOrder.summa = items.map(x => x.summa).reduce((a, b) => a + b, 0)
      prOrder.summa1C = items.map(x => x.summa1C).reduce((a, b) => a + b, 0)
    }

    if (preord.id === this.orderInfo?.id) {
      prOrder.summa1C = this.orderInfo.summa1C
    }


    return prOrder

  }

  check1cItems() {
    if (this.orderInfo?.items?.filter(x => !x.order1cId).length) return false

    return true
  }


}
