<div *ngIf="dataSource; else errMsg">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tree" [matTreeNodePaddingIndent]="16">
      <button mat-icon-button disabled></button>
      <button mat-flat-button (click)="selectNode(node)">{{node.item}}</button>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="16">
      <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <button mat-flat-button (click)="selectNode(node)">{{node.item}}</button>
      <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate"
        class="example-tree-progress-bar"></mat-progress-bar>
    </mat-tree-node>
  </mat-tree>

</div>

<ng-template #errMsg>
  error
</ng-template>