
import { openDB, StoreNames, IDBPTransaction, deleteDB } from 'idb';
import type { IDBPDatabase } from 'idb'

import { myDBSchema } from './myDBSchema';



export class myDbWrapper {

    constructor(private name: string) {

    }

    public open
        (): IDBPDatabase<myDBSchema> | PromiseLike<IDBPDatabase<myDBSchema>> {

        // НЕ забываем поднимать версию
        return openDB<myDBSchema>(this.name, 9, {
            upgrade: (db, oldVersion, newVersion, transaction) => {
                this.upgrade(db, oldVersion, newVersion, transaction);
            }
        });
    }





    //#region migrations

    upgrade(db: IDBPDatabase<myDBSchema>, oldVersion: number | null, newVersion: number | null, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) { //, transaction, event

        if (newVersion === undefined) {
            console.log("undefined new version")
            return
        }

        if (oldVersion === newVersion) {
            return
        }

        const upgraders = [
            this.upgrade1, // initial migration
            this.noop, // v1 -> v2
            this.noop, // v2 -> v3
            this.noop, // v3 -> v4
            this.noop, // v4 -> v5
            this.noop, // v5 -> v6
            this.upgrade7, // v6 -> v7
            this.upgrade8, // v7 -> v8
            this.upgrade9
        ]
        for (let i = (oldVersion ?? 1) + 1; i <= (newVersion ?? 0); i++) {
            upgraders[i - 1](db, transaction)
        }


    }


    upgrade1(db: IDBPDatabase<myDBSchema>, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) {
        console.log('upgrade db')
        // groups
        const productStore = db.createObjectStore('groups', {
            keyPath: 'code',
            autoIncrement: false
        });

        productStore.createIndex('by-level', 'level');
        productStore.createIndex('by-parent', 'parent');


        // goods 
        const goodStore = db.createObjectStore('goods', {
            keyPath: 'code',
            autoIncrement: false
        });

        goodStore.createIndex('by-left', 'left');

        // matrix

        const matrixStore = db.createObjectStore('matrix', {
            keyPath: 'id',
            autoIncrement: false
        });

        matrixStore.createIndex('by-category', 'categoryId', { unique: false });

        // clients
        db.createObjectStore('clients', { keyPath: 'id', autoIncrement: false })


        // orders
        const orderStore = db.createObjectStore('orders', {
            keyPath: 'id',
            autoIncrement: false
        });

        orderStore.createIndex('by-client', 'buypointId', { unique: false });
        orderStore.createIndex('by-date', 'date', { unique: false });
        orderStore.createIndex('by-status', 'statusId', { unique: false });

        // quests
        const questStore = db.createObjectStore('quests', {
            keyPath: 'id',
            autoIncrement: false
        });

        questStore.createIndex('by-client', 'buypoint.id', { unique: false });
        questStore.createIndex('by-modified', 'modified', { unique: false });

        // advance
        const advanceStore = db.createObjectStore('advances', {
            keyPath: 'id',
            autoIncrement: false
        });

        advanceStore.createIndex('by-date', 'date', { unique: false });
        advanceStore.createIndex('by-client', 'buyPointId', { unique: false });
        advanceStore.createIndex('by-status', 'state', { unique: false });

        // orderStore.createIndex('by-client', 'buypointId');
        // orderStore.createIndex('by-date', 'created', { unique: false });

        const debetStore = db.createObjectStore('debets', {
            keyPath: 'id',
            autoIncrement: false
        });

        debetStore.createIndex('by-client', 'buypointId');
        debetStore.createIndex('by-date', 'date', { unique: false });


    }

    noop(db: IDBPDatabase<myDBSchema>, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) {
        console.log("empty migration")
    }

    upgrade7(db: IDBPDatabase<myDBSchema>, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) {

        // orders
        const orderStore = transaction.objectStore('orders')
        //удаление в 8, больше не нужен
        // orderStore.createIndex('by-preorder', 'preorderId', { unique: false });



    }

    upgrade8(db: IDBPDatabase<myDBSchema>, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) {

        // orders
        const orderStore = transaction.objectStore('orders')


        orderStore.createIndex('by-deliveryDate', 'dateDelivery');
        if (orderStore.indexNames.contains('by-preorder')) {
            orderStore.deleteIndex('by-preorder');
        }


    }
    upgrade9(db: IDBPDatabase<myDBSchema>, transaction: IDBPTransaction<myDBSchema, StoreNames<myDBSchema>[], "versionchange">) {


        db.createObjectStore('goodContracts', {
            keyPath: 'contractCode',
            autoIncrement: false
        })

        db.createObjectStore('goodCountries', {
            keyPath: 'country',
            autoIncrement: false
        })



    }
    upgrade4(db: IDBPDatabase<myDBSchema>) {

        console.log("for future use")

    }

    //#endregion



    async deleteXDB(connectionId: string) {

        await deleteDB(connectionId, {
            blocked: (a, e) => {
                console.log(a)
                console.log(e)
            }
        })
    }

}