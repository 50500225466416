import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { SubscriptionLike } from 'rxjs'
import { AgentModel } from 'src/app/models/agent.model'
 
import { LocalStorageService } from 'src/app/services/storage/local-storage.service'

@Component({
  selector: 'app-create-order-button',
  templateUrl: './create-order-button.component.html',
  styleUrls: ['./create-order-button.component.scss'],
})
export class CreateOrderButtonComponent implements OnInit, OnDestroy {
  @Input() buypointId: string | undefined
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>()
  agent?: AgentModel

  disabled = true

  subscriptions: SubscriptionLike[] = []

  constructor( private router: Router,
    
    public localstorage: LocalStorageService,
) {}

  ngOnInit(): void {

    this.disabled = false
    // const sub = this.svc.agentObservable.subscribe({
    //   next: (agent) => {
    //     this.agent = agent

    //     if (
    //       this.agent &&
    //       this.agent.rules &&
    //       this.agent.rules.length > 0 &&
    //       this.buypointId &&
    //       this.buypointId !== ''
    //     ) {
    //       this.disabled = false
    //     }
    //   },
    // })

    // this.subscriptions.push(sub)
  }


  createOrder() {
    this.clicked.next(true)
    this.router.navigate(['/create-order', this.buypointId])
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }
}
