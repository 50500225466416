import { Injectable } from '@angular/core';
import { RpaIdClientService } from '../net/rpa-id-client.service';
import { ConnectionManagerService } from '../storage/connection-manager.service';
import { AuthResponse, Server, UserData } from 'src/app/models/server';
import jwt_decode from 'jwt-decode';
import { Subject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncUserConnectionService {

  servers: Server[] = []


  constructor(
    private api: RpaIdClientService,
    private connMgr: ConnectionManagerService,
  ) { }


  async updConn() {
    if (!this.needAccess()) {
      return false
    }

    try {
      this.servers =  await firstValueFrom(this.api.getServers())
      await this.requestAccess()
    }
    catch {
      return false
    }

    return true

  }


  private needAccess() {
    if (!this.connMgr.activeServer?.user) {
      return false
    }

    if (new Date(this.connMgr.activeServer.user.exp * 1000).getTime() < new Date().getTime()) {
      return true
    }
    return false
  }

  private async requestAccess() {
    if (!this.connMgr.activeServer?.user.Email || !this.connMgr.activeServer?.user.Server) return
    let resp = await firstValueFrom(this.api.requestAccess(this.connMgr.activeServer?.user.Email, this.connMgr.activeServer?.user.Server, false))
    this.localUpdcon(resp)
  }

  private localUpdcon(auth: AuthResponse) {
    let token = auth.payload
    let user = jwt_decode<UserData>(token)
    // не прочитали токен
    if (!user) {
      return
    }

    let agentCode = user.Code
    let server = user.Server

    // в токене указан неизвестный код сервера
    const findserv = this.servers.find(x => x.name === server)
    if (!findserv) {
      return
    }

    const connectionId = server + '-' + agentCode;;

    const current = this.connMgr.Connections.find(x => x.id === connectionId)

    // такое подключение уже есть
    if (current) {

      let refTokenDecode = jwt_decode<UserData>(current.rereshToken)

      if (refTokenDecode.exp < user.exp) {

        // обновляем токен
        current.rereshToken = token
        current.user = user

        this.connMgr.updateConnection(current)
        return
      }

    }


  }
}
