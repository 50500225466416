import { DatePipe } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'

import { SubscriptionLike } from 'rxjs'
import { Client, ClientContract } from 'src/app/models/client.model'
import { OrderModel } from 'src/app/models/order.model'

import { Connection } from 'src/app/models/server'
import { DbService } from 'src/app/services/data/idb.service'

import { OrdersService } from 'src/app/services/data/orders.service'

import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service'
import { validate } from 'uuid'



@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
})
export class CreateOrderComponent implements OnInit, OnDestroy {
  buypoinId = ''
  agent: Connection | undefined

  buypoint: Client | undefined
  error = false
  createError = false

  deliveryComment = ''
  delivery: Date | undefined
  anal: number

  private svc: OrdersService
  private db: DbService
  contracts: ClientContract[] = []
  org: FormControl = new FormControl('')
  minDate: Date
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cm: ConnectionManagerService,
    private snack: MatSnackBar,
    
  ) {
     
    this.anal = 0

    this.db = cm.getCurrentDb()
    this.svc = new OrdersService(this.db)

    this.minDate = new Date() //(new Date()).getDate() + (1000 * 60 * 60 * 24));
    this.minDate.setDate(this.minDate.getDate()+1)

  }

  ngOnInit(): void {

    this.anal = 0

    this.route.paramMap.subscribe((params) => {
      this.buypoinId = params.get('id') as string

      this.agent = this.cm.activeServer


      this.db.getClient(this.buypoinId).subscribe((val) => {
        if (!val) {
          return
        }
        this.buypoint = val
        this.loading = false
        this.contracts = val.contracts

        if(this.contracts?.length === 1){
          this.org.setValue(this.contracts[0].organizationId)
        }

      })


    })
  }



  loading = true

  subscriptions: SubscriptionLike[] = []

  onChangeEvent(e: any) {
    // console.log('todo')
    // console.log(e)
  }

  public doCreateOrder() {


    if (!this.agent) {
      this.snack.open('Не определён агент. Обновите страницу', 'ok', { duration: 3800 })
      return
    }

    if (!this.org.value) {
      this.org.setErrors([Validators.required])
      this.org.markAsTouched();
      this.snack.open('Не выбрана организация!', 'ok', { duration: 3800 })
      return
    }



    if (this.buypoinId !== undefined) {



      this.loading = true
      this.createError = false

      const view = {
        buypoint: this.buypoint?.name,
        buypointId: this.buypoinId,
        agentId: this.agent.user.Id,
        deliveryComment: this.deliveryComment,
        dateDelivery: this.delivery,
        date: new Date(),
        statusId: 1,
        firm: this.contracts.find(x => x.organizationId === this.org.value)?.organization ?? '',
        firmId: this.org.value

        // orgId: this.rule.organizationId,
        // filialId: this.rule.filialId,
        // ruleId: this.rule.id,


      } as OrderModel;

      this.svc.createOrder(view)

        .subscribe({
          next: (order) => {
            void this.router.navigate(['/edit-order', order.id])
          },
          error: (serror) => {
            this.createError = true
            this.loading = false
            this.snack.open(serror, 'ok', { duration: 3800 })
          },
        })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }
}
