<div class="tab-container" *ngIf="orderInfo; ">
  <h2>Заказ {{orderInfo.number}}</h2>
  Клиент <b>{{buypoint}}</b>

  <button mat-flat-button (click)="changeClient()" style="margin-left: 1em;" color="primary">Изменить</button>
  <span *ngIf="orderInfo.firm"><br>Организация <b>{{orderInfo.firm}}</b> </span>
  <br />

  <!-- span *ngIf="orderInfo.buypoint.category" class="air">Категория
    {{orderInfo.buypoint.category}}</span -->


  <span class="label">Сумма</span><span class="air currency">
    {{orderInfo.summa | currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
  <br>
  <mat-form-field class="extra">
    <mat-label>Комментарий</mat-label>
    <input matInput name="comment" [(ngModel)]="deliveryComment" (change)="onCommentChanged($event)"
      (keyup)="onCommentChanged($event)" (paste)="onCommentChanged($event)">
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-label>Дата доставки</mat-label>

    <input matInput [matDatepicker]="picker" name="actualDate" [value]="delivery"
      (dateChange)="onDeliveryChanged('change', $event)" [min]="minDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker class="B"></mat-datepicker>

    <!-- input matInput placeholder="PДата Z-отчёта" -->
    <!-- mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
  <mat-hint>Hint</mat-hint -->
  </mat-form-field>

  <button mat-icon-button *ngIf="delivery" (click)="delivery=undefined; onDeliveryChanged('change', undefined)">
    <mat-icon>clear</mat-icon>
  </button>


  <br>
  <button style="margin-right: 8px;" mat-flat-button color="accent" *ngIf="headerChanged"
    (click)="save()">сохранить</button>
  <button mat-flat-button color="primary" [disabled]="loading" (click)="updOrderPrice()">Обновить
    цены</button>


  <div style="margin-top: 12px;">
    <div *ngFor="let item of orderInfo.items" class="row">
      <span class="rests-item"> {{item.good}}</span>
      <div class="break"></div> <!-- break -->
      <span class="label">кол-во</span>
      <span class="air amount">{{item.amount}} шт,</span>

      <span class="label">цена</span>
      <span class="air currency">{{item.price | currency:'RUB':'symbol':'1.2-2':'ru'}},</span>


      <span class="label">сумма</span>
      <span class="air currency">{{item.summa | currency:'RUB':'symbol':'1.2-2':'ru'}}</span>
      <span *ngIf="item.promotionCode">
        <span class="label">код акции</span>
        <span class="air warn">{{item.promotionCode }}</span>
      </span>
      <span class="spacer"></span>
      <span>
        <button mat-icon-button color="accent" (click)="deleteItem(item.goodId)">
          <mat-icon>delete</mat-icon>
        </button>
      </span>
      <span class="caption">
        <button mat-icon-button color="primary" (click)="clickOnItem(item)" class="caption">
          <mat-icon class=" caption">edit</mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>