import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DebetDoc } from 'src/app/models/DebetDocs';
import { Client } from 'src/app/models/client.model';
import { OrgView } from 'src/app/models/dict.model';
import { DebetService } from 'src/app/services/data/debet.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';

@Component({
  selector: 'app-client-info-dialog',
  templateUrl: './client-info-dialog.component.html',
  styleUrls: ['./client-info-dialog.component.scss']
})
export class ClientInfoDialogComponent {
  client: Client
  debetService: DebetService
  debetDocs: DebetDoc[] = []
  orgList: OrgView[] = []

  constructor(public dialogRef: MatDialogRef<ClientInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientInfoDialogData,
    private conMng: ConnectionManagerService,
    private snack: MatSnackBar
  ) {

    this.client = data.client
    this.debetService = new DebetService(conMng.getCurrentDb())
    this.findDebets()
  }



  findDebets() {
    this.debetService.getClientDocs(this.client.id)
      .subscribe({
        next: x => {
          this.debetDocs = x;
          this.orgList = x.map((c) => { return { id: c.organizationId, name: c.organization } as OrgView })
            .filter((value, index, self) => self.findIndex(v => v.id == value.id) === index);


        },
        error: err => { this.snack.open(`Не удалось загрузить задолженность - ${err}`, 'Закрыть', { duration: 5000 }) }
      })
  }

  sumByOrg(orgId: string) {
    let sum = 0
    this.debetDocs.filter(x => x.organizationId === orgId).forEach(x => sum += x.debetSumm)
    return sum
  }

  overSumByOrg(orgId: string) {
    let sum = 0
    this.debetDocs.filter(x => x.organizationId === orgId).forEach(x => { if (new Date().getTime() > new Date(x.datePay).getTime()) sum += x.debetSumm })
    return sum
  }



  deliveryDays(days: string) {
    switch (days) {
      case '1111111':
        return 'Все дни'
      case '1111110':
        return 'Пн-Сб'
      case '1111100':
        return 'Пн-Пт'
      default:
        {
          let ret = ''
          const daysret: string[] = ['Пн,', 'Вт,', 'Ср,', 'Чт,', 'Пт,', 'Сб,', 'Вс,']
          for (let i = 0; i < days.length; i++) {
            if (days[i] === '1') {
              ret += daysret[i] + ' '
            }
          }
          return ret.slice(0, ret.length - 2)
        }
    }
  }


  createOrderClicked() {
    this.dialogRef.close({})
  }

  selectClientClicked(selected: Client) {
    this.dialogRef.close({ selected })
  }

}

export interface ClientInfoDialogData {
  client: Client
  selectMode: boolean

}