import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FilterSoftCheque } from 'src/app/filters/SoftChequeFilter';
import { DepartmentProfile } from 'src/app/models/Ukrop/Department';
import { UkropRest } from 'src/app/models/Ukrop/Rest';
import { SoftCheque } from 'src/app/models/Ukrop/softCheque';
import { ZReport } from 'src/app/models/Ukrop/zreport';
import { UrkopGateHttpService } from '../http/urkop-gate-http.service';

export interface UploadImageForm {
  actualDate: Date
  inn: string
  kpp: string
  profileImage: File
  type: number
  questId?: string | number
  softChequeId?: number
}


@Injectable({
  providedIn: 'root'
})
export class UkropClientService {



  constructor(private http: UrkopGateHttpService,
    private datePipe: DatePipe,
  ) { }

  zReportUrl(fileUrl: string): string {
    return this.http.wrapUrl(`api/integration/getimagez?name=${fileUrl}`)
  }

  getImgZ(imgname: string): Observable<Blob> {
    const uri = `api/integration/getimagez?name=${imgname}`
    return this.http.getImage(uri,)
  }

  findDepartment(inn: string, kpp: string): Observable<DepartmentProfile> {
    return this.http.get<DepartmentProfile>(`api/departments/dept/profile/${inn}/${kpp}`)
  }

  getRests(inn: string, kpp: string, date?: string) {
    return this.http.post<UkropRest[]>(`api/statistic/restsfind`, {
      inn: inn,
      kpp: kpp,
      DateQuery: date
    })
  }

  findSoftCheque(filter: FilterSoftCheque) {
    return this.http.post<SoftCheque[]>('api/integration/softcheque/find', filter)
  }


  setDocumentStatus(docId: number, toStatus: number) {
    const doc = { DocId: docId, DocStatus: toStatus }
    return this.http.post<{ documentStatusId: number, documentStatus: string }>('/api/documents/SetDocumentStatus', doc)
  }
  setStatusSoftCheque(id: number, statusId: number) {
    return this.http.post<ZReport>('api/integration/zreports/update/setstatus', { id: id, reportStatusId: statusId })
  }

  findZReports(filter: FilterSoftCheque) {
    return this.http.post<ZReport[]>('api/integration/zreports/find', filter)
  }

  removeZReport(ids: number[]): Observable<string[]> {
    const filter = {
      ids: ids
    } as FilterSoftCheque
    return this.http.post<string[]>('api/integration/zreports/remove', filter)
  }

  addUserTask(value: Date | null, Inn: string, Kpp: string, type: number, file: File, actulaCheque: number | undefined) {
    return this.uploadFile({
      actualDate: value ?? new Date(),
      inn: Inn,
      kpp: Kpp,
      profileImage: file,
      type: type,
      //questId: '0',
      softChequeId: actulaCheque
    })
  }


  private uploadFile(form: UploadImageForm) {
    const formData = new FormData()
    const actualDateText = this.datePipe.transform(form.actualDate, 'yyyy-MM-dd')

    // this.logger.info(
    //   `send: ${form.inn}/${form.kpp} ${form.questId} ${actualDateText} ${form.profileImage.size}! `,
    //   'FileUploadService'
    // )
    // const questId = +form?.questId

    formData.append('ActualDate', actualDateText as string)
    formData.append('INN', form.inn)
    formData.append('KPP', form.kpp)
    formData.append('File', form.profileImage)
    formData.append('ReportTypeId', form.type.toString())
    //formData.append('QuestId', questId.toString())

    if (form.softChequeId) formData.append('SoftChequeId', form.softChequeId.toString())


    const uri = `api/integration/addreportz`
    return this.http
      .postForm(uri, formData)

  }


  downloadAct(chequeId: number) {
    const uri = this.http.wrapUrl(`api/integration/softcheque/download_act?checkId=${chequeId}`)
    return this.http.getImage(uri)
  }
}

