import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { DepartmentProfile } from 'src/app/models/Ukrop/Department';

import { UkropClientService } from 'src/app/services/net/ukrop-client.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';
import { SubscriptionLike } from 'rxjs'

@Component({
  selector: 'app-ors-info',
  templateUrl: './ors-info.component.html',
  styleUrls: ['./ors-info.component.scss']
})
export class OrsInfoComponent implements OnInit, OnDestroy {
  clientId = ''
  ors: Client | undefined
  profile: DepartmentProfile | undefined
  error = ''
  loading = false
  onlineUkrop = false
  priceTypeName = 'Не определено'
  subs: SubscriptionLike[] = []

  ignoreFitrst = 0
  isAdmin = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: UkropClientService,
    private snack: MatSnackBar,
    public connMgr: ConnectionManagerService) {
    this.isAdmin = connMgr.isAdmin()
  }

  ngOnInit(): void {
    this.getClient()
    const s = this.connMgr.activeServerObservable().subscribe((x) => {
      this.ignoreFitrst++;
      if (this.ignoreFitrst > 1) {
        this.router.navigate(['/home'])
      }
    })
    this.subs.push(s)
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscription) => { subscription.unsubscribe() })
    this.subs = []
  }



  getClient() {
    this.loading = true
    this.route.paramMap.subscribe(x => {
      this.clientId = x.get('id') as string;

      const s = from(this.connMgr.getDb()).subscribe((database) => {
        if (!database) return

        database.getClient(this.clientId).subscribe({
          next: x => {
            if (!x) {
              this.error = 'Не найден клиент'
            } this.ors = x; 
            
            this.getDepartment()

            const pr = database.priceTypes.find(t => t.code === x?.priceType)
            if (pr) {
              this.priceTypeName = pr.name
            }
    

          },
          error: () => { this.snack.open('Ошибка при загрузке клиента', 'Закрыть', { duration: 5000 }); this.loading = false }
        })

      })

      this.subs.push(s)

    })

  }

  getDepartment() {
    if (!this.ors?.inn || !this.ors?.kpp) {
      this.loading = false
      return
    }
    const s = this.http.findDepartment(this.ors.inn, this.ors.kpp).subscribe({
      next: x => {
        this.profile = x;
        this.onlineUkrop = true
        this.loading = false
      },
      error: () => {
        this.snack.open(`Ошибка при загрузе УКРОП`, 'Закрыть', { duration: 5000 });
        this.loading = false
      }
    })

    this.subs.push(s)
  }

}
