<mat-spinner *ngIf="loading"></mat-spinner>


<div *ngIf="error">
  <h2 class="warning">
    возникла ошибка при загрузке данных торговой точки

  </h2>
  <button mat-raised-button (click)="reload()">
    <mat-icon>refresh</mat-icon> обновить
  </button>
</div>

<div *ngIf="!loading   && buypoint">
  <h1>{{buypoint.name}}</h1>
 




  <mat-tab-group [selectedIndex]="tabindex" mat-align-tabs="start" [disablePagination]="true" class="no-paging">

    <!--  -->
    <mat-tab label="Реквизиты">
      <ng-template matTabContent>
        <table width="100%">
          <tr>
            <td width="50%">

              <dl>

                <dt>Клиент</dt>
                <dd class="g"> {{buypoint.name}}</dd>


                <dt>ИНН</dt>
                <dd class="g"> {{buypoint.inn}}</dd>

                <dt>КПП</dt>
                <dd class="g">{{buypoint.kpp}} </dd>

                <dt>Код 1С</dt>
                <dd class="g">{{buypoint.code}} </dd>

                <dt>Тип цен</dt>
                <dd class="g">{{buypoint.priceType}} - {{priceTypeName}} </dd>




              </dl>

              <app-create-order-button [buypointId]="buypoint.id">
              </app-create-order-button>
            </td>


          </tr>


        </table>
        <br>



      </ng-template>
    </mat-tab>
    <!-- Доставка -->
    <mat-tab *ngIf="buypoint.deliveryDaysOfWeek" label="Доставка">
      <app-client-delivery-info [buypoint]="buypoint"></app-client-delivery-info>
    </mat-tab>
    <!-- Задолженность -->
    <mat-tab>
      <ng-template mat-tab-label>Задолженность</ng-template>
      <ng-template matTabContent>

        <app-debet-doc-list [buypoint]="buypoint"></app-debet-doc-list>
      </ng-template>
    </mat-tab>
    <!--  История заказов -->
    <mat-tab>
      <ng-template mat-tab-label>История заказов</ng-template>
      <ng-template matTabContent>
        <app-point-orders [buypoint]="buypoint"></app-point-orders>
        <br><br>
      </ng-template>
    </mat-tab>

    <!-- mat-tab  >
      <ng-template mat-tab-label>Кеги</ng-template>
      <ng-template matTabContent>

        <app-pickup-order-history [buypoint]="buypoint"></app-pickup-order-history>
      </ng-template>
    </mat-tab -->

    <mat-tab *ngIf="isAdmin">
      <ng-template mat-tab-label>
        <mat-icon style="color: blueviolet;">menu</mat-icon>
      </ng-template>
      <pre>
      {{buypoint | json}}
      </pre>

    </mat-tab>

  </mat-tab-group>

</div>