import { SyncItemType } from "src/app/models/sync.model";
import { lastValueFrom } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { AbstractSyncDataJob } from "./AbstractSyncDataJob";
import { SyncRunParameters } from "./sync.service";
import { SyncStartQuery } from "src/app/filters/SyncFilter";


export class SyncDoneJob extends AbstractSyncDataJob {
    cat: SyncItemType[] = [];
    full = false;


    public setCat(para: SyncRunParameters) {
        this.cat = para.categories
        this.full = para.full
    }


    override async run(): Promise<boolean> {



        this.count = 1

        const start: SyncStartQuery = {
            kinds: this.cat,
            full: this.full,
            deviceUID: "update in httpCredit.syncDone",
            refreshToken: "not needed"
        }

        const sq = this.httpCredit.syncDone(start)

        try {

            await lastValueFrom(sq)


        } catch (error: unknown) {

            if (error instanceof Error) {
                this.error = true
                if (error.message !== undefined) { this.message = error.message }
                return false
            } if (error instanceof HttpErrorResponse) {
                this.error = true
                if (error.message !== undefined) { this.message = error.message }
                return false
            } else {
                console.error(error)
                this.message = 'unknown error'
                // if (error.error !== undefined) { this.message = error.error }
            }


            return false
        } finally {
            this.step++
            this.completed = true
        }




        return true

    }

}
