import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-network-indicator',
  templateUrl: './network-indicator.component.html',
  styleUrls: ['./network-indicator.component.scss']
})
export class NetworkIndicatorComponent  implements OnInit, OnDestroy {

  offlineEvent!: Observable<Event>;
  onlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  online: boolean | undefined

  
    url = "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"


  constructor(private http: HttpClient,
    private snackBar:MatSnackBar){

    this.checkConnect(false)
 
   
  }
  
  ngOnInit(): void {
    this.handleAppConnectivityChanges();
  
  }


checkConnect(withNotify = true){
  const nocache="?nc="+Math.random().toString()
  this.http.get(this.url + nocache,{
    responseType: "text"
  })
  .subscribe({
    next: ()=>{
     this.online = true
    },
    error: (err)=>{
      this.online = false
      console.log("internet check failed successfully. url = "+this.url)
      console.log(err)

      if (withNotify){
        let m=""
        if (err!=undefined && err.message!=undefined){
          m=err.message
        }else{
          m=JSON.stringify(err)
        }

        this.snackBar.open(`Нет интернета. Ошибка: `+m, 'Закрыть', { duration: 7000 })
      }
    }
  })
}

  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      // handle online mode
      this.online = true
      console.log('Online...');
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      // handle offline mode
      this.online = false
      console.log('Offline...');
    }));
    
  }



  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
