<div class="example-container" [class.example-is-mobile]="media.isMobile">

  <mat-toolbar [class]="'example-toolbar' +' '+ headerColor " [class.example-is-mobile]="media.isMobile">

    <mat-toolbar-row class="toolbar-row-top">

      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span [routerLink]="['/home']" style="margin-right: 5px;"><span style="color: yellow;">RP</span><span
          style="color: red">A</span>
        Market </span>
      <span class="customVersionPanel" style="font-size: small;">{{this.version}}</span>
      <span class="example-spacer"></span>
      <span>
        <button *ngIf="sync.running;else syn" mat-icon-button (click)="openSyncDialog()">
          <mat-spinner class="size_spinner spinner" diameter="25"></mat-spinner>
        </button>

        <ng-template #syn>
          <button *ngIf="showSync" [style]="time > 0 ? 'color:darkgrey;': 'color: yellow;'" mat-icon-button
            (click)="openSyncDialog()" [disabled]="time > 0">
            <mat-icon>sync</mat-icon></button>
        </ng-template>
      </span>
      <app-update-indicator></app-update-indicator>
      <app-network-indicator></app-network-indicator>
      <app-user-server style="overflow: hidden;" [mobileView]="media.isMobile"></app-user-server>

    </mat-toolbar-row>

    <mat-toolbar-row class="toolbar-row-bottom">

      <span class="example-spacer"></span>
      <app-user-server [onlyText]="true" [mobileView]="media.isMobile" style="overflow: hidden;"></app-user-server>
      <span class="example-spacer"></span>

    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" autosize [style.marginTop.px]="media.isMobile ? 85 : 0">

    <mat-sidenav #drawer class="example-sidenav" [mode]="media.isMobile ? 'over' : 'side'"
      [fixedInViewport]="media.isMobile" fixedTopGap="85" autoFocus="false">

      <app-main-menu (click)="media.isMobile ? drawer.toggle() : undefined"></app-main-menu>

    </mat-sidenav>

    <div *ngIf="dbActivated; else notActivated">

      <div class="main-content">
        <router-outlet></router-outlet>
      </div>

    </div>

    <ng-template #notActivated>

      <mat-spinner [mode]="'indeterminate'"></mat-spinner>
    </ng-template>

  </mat-sidenav-container>


</div>