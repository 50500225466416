import { AfterContentInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { from } from 'rxjs';
import { OrderLine } from 'src/app/models/order.model';
import { Good } from 'src/app/models/sync.model';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { DbService } from 'src/app/services/data/idb.service';
import { ConnectionManagerService } from 'src/app/services/storage/connection-manager.service';



@Component({
  selector: 'app-quantity-dialog-plain',
  templateUrl: './quantity-dialog.component.html',
  styleUrls: ['./quantity-dialog.component.scss']
})
export class QuantityDialogPlainComponent implements OnInit, AfterContentInit {

  @ViewChild('quantityInput') quantityInput!: ElementRef;

  isAdmin!: boolean
  showDisc = false
  good: Good
  quantity = 0
  price = 0
  promotionCode = ''
  manualEdit = false
  focus  = false

  db: DbService
  constructor(
    public dialogRef: MatDialogRef<QuantityDialogPlainComponent>,
    //public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: QuantityDialogData,
    cm: ConnectionManagerService,
    settingsService: AppSettingsService
  ) {
    this.good = {} as Good
    this.good.code = data.line.goodId
    this.good.ratioPackage = 0
    this.good.minPart = 0
    this.price = this.data.line.price
    this.promotionCode = this.data.line.promotionCode
    this.db = cm.getCurrentDb()
    this.focus = settingsService.focusQuantity
  }

  manual() {
    this.manualEdit = true
  }

  ngOnInit(): void {

    const prom = this.db.get('goods', this.data.line.goodId)
    from(prom).subscribe(g => {
      if (!g) return
      this.good = g

      if (this.good.stateId){
        const state = this.db.goodStates.find(a=>a.id == this.good.stateId)
        if (state){
          this.good.stateColor = state.color
        }
      }

      // if (this.good && this.good !== undefined && this.good.prices && this.good.prices.length > 0) {

      //   this.price = this.good.prices[0].price !== this.data.line.price ? this.data.line.price : this.good.prices[0].price
      // }

    }
    )

    this.quantity = this.data.line.amount ?? 0
  }



  ngAfterContentInit(): void {
    if (this.focus) {
      setTimeout(() => {
        this.quantityInput.nativeElement.focus()
      }, 200);
    }
  }

  priceChange(event: any) {
    this.quantity = Number.parseInt(event.target.value)
    if (Number.isNaN(this.quantity)) {
      this.quantity = 0
    }
  }

  public onSend(): void {

    if (!this.quantity || this.quantity < this.good.minPart) {
      return
    }

    this.data.ok = true
    if (this.good && this.good !== undefined && this.good.prices && Object.keys(this.good.prices).length > 0) {
      // this.data.line.price = this.good.prices[0].price ?? 0
      this.data.line.price = this.price
      this.data.line.amount = this.quantity
      this.data.line.promotionCode = this.promotionCode
    }

    this.dialogRef.close(this.data);




  }

  add(amount: number) {
    this.quantity += amount

    if (!this.quantity || this.quantity < 0) {
      this.quantity = 0
    }

  }

  addBox(toFull = false) {

    if (!this.quantity) {
      this.quantity = 0
    }


    if (toFull && this.quantity % this.good.ratioPackage !== 0) {
      this.add(this.good.ratioPackage - this.quantity % this.good.ratioPackage)
    } else {
      this.add(this.good.ratioPackage)
    }

  }

  removeBox() {
    if (!this.quantity) {
      this.quantity = 0
    }

    this.add(-this.good.ratioPackage)

  }



  well(): boolean {
    if (this.good.ratioPackage < 2) return true

    if (!this.quantity) return true

    return this.quantity % this.good.ratioPackage === 0

  }


  goodRatioLabel() {
    //  ${this.good.measure}
    return `${Math.trunc((this.quantity ?? 0) / (this.good.ratioPackage))} кор x ${this.good.ratioPackage} шт.`
  }

  badRatioLabel() {
    // ${this.good.measure} пока вручную пишем про коробку и шт
    return `${Math.trunc((this.quantity ?? 0) / (this.good.ratioPackage))} кор x ${this.good.ratioPackage} + ${Math.trunc((this.quantity ?? 0) % (this.good.ratioPackage))} шт.`
  }

}


export interface QuantityDialogData {
  line: OrderLine

  ok: boolean

}

