<span *ngIf="!updatesisEnabled">
    <button mat-icon-button (click)="describeError()">
            <span  style="color: red;">
                <mat-icon>error_outline</mat-icon>
            </span>
    </button>
</span>

<span *ngIf="hasUpdate">
    <button mat-icon-button (click)="runUpdate()" >
            <span  style="color: violet;">
                <mat-icon>error_outline</mat-icon>
            </span>
    </button>
</span>
